import React from 'react'
import Navbar from '../components/Navbar'
import PermanentEmpBanner from '../components/PermanentEmpBanner'
import DottedLines from '../images/DottedLines.png'
import Passport from '../images/passport.png'
import Footer from '../components/Footer'
import PermanentEmpHow from '../components/PermanentEmpHow'
import howitworks from '../images/howitworks.png'
import VisaProvision457Comp from '../components/VisaProvision457Comp'

const VisaProvision457 = () => {
  return (
    <>
      <Navbar />
      <PermanentEmpBanner
        heroHeading='457 to PR (Permanent Residency)'
        heroSubHeading='There are a number of ways 457 visa holders can transition to permanent residency (457 to PR)'
        connectButton='Let’s Connect'
        Banner={Passport}
        DottedLines={DottedLines}
      />
      <VisaProvision457Comp/>
      <PermanentEmpHow
        EmpHowHeading='How it works'
        EmpHowImage={howitworks}
        EmpHowSubHead1='Step 1'
        EmpHowSubHead2='Step 2'
        EmpHowSubHead3='Step 3'
        EmpHowSubHead4='Step 4'
        EmpHowDesc1='You have been working for the same employer for two years.'
        EmpHowDesc2='You have been working for the same employer for LESS than two.'
        EmpHowDesc3='Your employer is not willing to sponsor you for Permanent Residency.'
        EmpHowDesc4='Your partner is an Australian permanent resident or citizen.'
        learnMoreDesc='Learn More'
      />
      <Footer />
    </>
  )
}

export default VisaProvision457