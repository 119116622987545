import React from 'react'
import '../styles/VisaOption457.css'

const VisaProvision457Comp = () => {
    return (
        <>
            <div className='VisaOptionLayoutMain'>
                <div className='VisaOptionHeadBox'>
                    <div className='VisaOptionHeading'>Grandfathering Provisions for ENS & RSMS TRT</div>
                </div>
                <div className='VisaOptionSubLayout'>
                    <div className='VisaOptionDesc'>
                        <li>If your 457 visa was lodged or granted before April 18th 2017, you aren’t affected by some of the new 457 to permanent resident law changes.</li>
                    </div>
                </div>
                <div className='VisaOptionSecondLayout'>
                    <div className='VisaProvisionBox'>
                        <div className='VisaProvisionHead'>Occupation</div>
                        <div className='VisaProvisionDesc'>If your occupation has been moved to the short-term or regional occupation list, or removed from the skilled occupation lists altogether, you can still apply for permanent residency through the transition stream</div>
                    </div>
                    <div className='VisaProvisionBox'>
                        <div className='VisaProvisionHead'>Employer Nomination</div>
                        <div className='VisaProvisionDesc'>You are only required to work for the same nominating employer for two years before applying for PR.
                            If you have changed employers or renewed your 457 or TSS visa since these law changes, you will still have access to these grandfathering provisions.</div>
                    </div>
                    <div className='VisaProvisionBox'>
                        <div className='VisaProvisionHead'>Training Levy – Skilling Australians Fund (SAF)</div>
                        <div className='VisaProvisionDesc'>Your permanent visa application will attract the new training levy (SAF) of $3000 or $5000 (Depending on your employer’s turnover).
                            This levy must be paid by your employer. If your 457 visa was lodged after 18th of April 2017, different rules apply.</div>
                    </div>
                </div>
                <div className='VisaOptionThirdLayout'>
                    <div className='VisaProvisionBox1'>
                        <div className='VisaProvisionHead'>Work Experience</div>
                        <div className='VisaProvisionDesc'>You must have a minimum for three years total experience in your nominated occupation.</div>
                    </div>
                    <div className='VisaProvisionBox1'>
                        <div className='VisaProvisionHead'>Age</div>
                        <div className='VisaProvisionDesc'>The age limit for applying for permanent residency through the transition stream remains at 50 years.</div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default VisaProvision457Comp