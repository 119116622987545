import React from 'react'
import "../styles/heading.css"

function Heading(props) {
    const containerStyle = {
        backgroundColor: props.containerColor || '#F4F4F4', 
        color: props.textColor || '#014A91', 
      };
  return (
    <div className='Overview_faq_container_head' style={containerStyle}>
    {props.heading}
  </div>
  )
}

export default Heading