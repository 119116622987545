import React, { useState } from 'react'
import NavBarComponent from '../components/NavBarComponent'
import Herobanner1 from '../images/Reading.mp4';
import "../styles/Tools.css"
import DottedLines from '../images/DottedLines.png'
import HeroBanner from '../components/HeroBanner'
import Footer from '../components/Footer';
import { useNavigate } from 'react-router-dom';
import ConsultComp2 from '../components/ConsultComp2';

function Gms() {
    const [data] = useState([
        { age: "at least 18 but less than 25 years", points: 25 },
        { age: "at least 25 but less than 33 years", points: 30 },
        { age: "at least 33 but less than 40 years", points: 25 },
        { age: "at least 40 but less than 45 years", points: 15 },
       
      ]);
      const [Englishdata] = useState([
        { age: "Competent English", points: 0 },
        { age: "Proficient English", points: 10 },
        { age: "Superior English", points: 20},
       
      ]);
      const [Expdata] = useState([
        { age: "Less than 3 years", points: 0 },
        { age: "At least 3 but less than 5 years", points: 5 },
        { age: "At least 5 but less than 8 years", points: 10},
        { age: "At least 8 years", points: 15},
       
      ]);
      const [AusExpdata] = useState([
        { age: "Less than 1 year", points: 0 },
        { age: "At least 1 but less than 3 years", points: 5 },
        { age: "At least 5 but less than 8 years", points: 10},
        { age: "At least 5 but less than 8 years", points: 15},
        { age: "At least 8 years", points: 20},
       
      ]);
      const [RequirementsData] = useState([
        { age: "A Doctorate from an Australian educational institution or a Doctorate from another educational institution, that is of a recognised standard.", points: 20 },
        { age: "At least a Bachelor degree from an Australian educational institution or at least a Bachelor qualification, from another educational institution, that is of a recognised standard.", points: 15 },
        { age: "A diploma or trade qualification from an Australian educational institution.", points: 10},
        { age: "Attained a qualification or award recognised by the relevant assessing authority for your nominated skilled occupation as being suitable for that occupation", points: 10},
       
       
      ]);
      const [PartnerData] = useState([
        { age: "RequirementPoints Your spouse or de facto partner must also be an applicant for this visa and meet age, English and skill criteria  For you to be eligible for the award of these points your partner must be an applicant for the same visa subclass and must not be an Australian permanent resident or an Australian citizen.  Additionally, you will need to provide evidence that when you were invited to applyfor this visa that they: were under 45 years old  had competent English had nominated a skilled occupation that is on the same skilled occupation list as your nominated skilled occupation had a suitable skills assessment from the relevant assessing authority for their nominated skilled occupation, and the assessment wasn’t for a Subclass 485 visa.", points: 10 },
        { age: "Your spouse or de facto partner must also be an applicant for this visa and has competent English For you to be eligible for the award of these points your partner must be an applicant for the same visa subclass and must not be an Australian permanent resident or an Australian citizen.", points: 5 },
        { age: "You are single or your partner is an Australian citizen or permanent resident", points: 10},
       
       
      ]);
    const [activeTab, setActiveTab] = useState('Visa');
    const navigate = useNavigate();
    const handleNavigate189 = () => {
        window.scrollTo(0,0);
         navigate('/SkilledVisa189')
      }
      const handleNavigate190 = () => {
        window.scrollTo(0,0);
         navigate('/Visa190')
      }
      const handleNavigate491 = () => {
        window.scrollTo(0,0);
         navigate('/Visa491')
      }
    const handleTabClick = tabName => {
      setActiveTab(tabName);
    };
   
    
  return (
    <div>
          <NavBarComponent />
        <HeroBanner
        heroHeading='General Skilled Migration Point Test'
        heroSubHeading='Complete our General Skilled Migration (GSM) points test to find out if you may be eligible to apply for a Skilled Independent 189, Skilled Nominated 190 or Regional Sponsored Visa 491.'
        connectButton='Let’s Connect'
        Banner={[Herobanner1]}
        DottedLines={DottedLines}
      />
          <div className="TabsContainer_Layout_Container">
        <div className="TabsContainer_Tools">
      <div
        className={`Tab ${activeTab === 'Visa' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('Visa')}
      >
       Visa Options
      </div>
      <div
        className={`Tab ${activeTab === 'Age' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('Age')}
      >
       Age
      </div>
      <div
        className={`Tab ${activeTab === 'English' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('English')}
      >
       English
      </div>
      <div
        className={`Tab ${activeTab === 'Experience' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('Experience')}
      >
        Experience
      </div>
      <div
        className={`Tab ${activeTab === 'Qualification' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('Qualification')}
      >
       Qualification
      </div>
      <div
        className={`Tab ${activeTab === 'Australian' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('Australian')}
      >
       Australian Education
      </div>
      <div
        className={`Tab ${activeTab === 'Partner' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('Partner')}
      >
      Partner Skills
      </div>
      </div>
      {activeTab === 'Visa' &&
         <div className='Tabs_container_layout_Field'>
            <div className='Tools_gms_TextArea'>
 Our online points test applies to the below Skilled Visas:
<li><u onClick={handleNavigate189} style={{ color: "#B0383D", cursor: 'pointer' }}>189 Visa (Independent) </u></li>
<li><u onClick={handleNavigate190} style={{ color: "#B0383D", cursor: 'pointer' }}>190 Visa (State & Territory Nominated)</u></li>
<li><u onClick={handleNavigate491} style={{ color: "#B0383D", cursor: 'pointer' }}>491 Visa (State, Territory or Family Sponsored)</u></li>
The Skilled Occupation List determines which occupations are eligible to apply for these visas.
         </div>
         </div>
      }
{activeTab === 'Age' &&
         <div className='Tabs_container_layout_Field'>
              <table>
        <thead>
          <tr>
            <th>Age</th>
            <th>Points</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <tr key={item.id}>
              <td>{item.age}</td>
              <td>{item.points}</td>
            </tr>
          ))}
        </tbody>
      </table>
        
         </div>
      }

{activeTab === 'English' &&
         <div className='Tabs_container_layout_Field'>
               <table>
        <thead>
          <tr>
            <th>English</th>
            <th>Points</th>
          </tr>
        </thead>
        <tbody>
          {Englishdata.map((item) => (
            <tr key={item.id}>
              <td>{item.age}</td>
              <td>{item.points}</td>
            </tr>
          ))}
        </tbody>
      </table>
         </div>
      }

{activeTab === 'Experience' &&
         <div className='Tabs_container_layout_Field'>
                  <table>
        <thead>
          <tr>
            <th>Overseas skilled employment – (outside Australia) No. of Years</th>
            <th>Points</th>
          </tr>
        </thead>
        <tbody>
          {Expdata.map((item) => (
            <tr key={item.id}>
              <td>{item.age}</td>
              <td>{item.points}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <table>
        <thead>
          <tr>
            <th>Australian skilled employment – (in Australia) No. of Years</th>
            <th>Points</th>
          </tr>
        </thead>
        <tbody>
          {AusExpdata.map((item) => (
            <tr key={item.id}>
              <td>{item.age}</td>
              <td>{item.points}</td>
            </tr>
          ))}
        </tbody>
      </table>
         </div>
      }

{activeTab === 'Qualification' &&
         <div className='Tabs_container_layout_Field'>
               <table>
        <thead>
          <tr>
            <th>Requirements</th>
            <th>Points</th>
          </tr>
        </thead>
        <tbody>
          {RequirementsData.map((item) => (
            <tr key={item.id}>
              <td>{item.age}</td>
              <td>{item.points}</td>
            </tr>
          ))}
        </tbody>
      </table>
         </div>
      }

{activeTab === 'Australian' &&
         <div className='Tabs_container_layout_Field'>
            <div>
                <div className='Table_Head_Title'>Specialist education qualification</div>
      <table>
        <thead>
          <tr>
            <th>Requirements</th>
            <th>Points</th>
          </tr>
        </thead>
        <tbody> 
            <tr >
              <td>A Masters degree by research or a Doctorate degree from an Australian educational institution that included at least 2 academic years study in a relevant field.</td>
              <td>10</td>
            </tr>
        </tbody>
      </table>
      </div>
      <div>
                <div className='Table_Head_Title'>Australian study requirement</div>
      <table>
        <thead>
          <tr>
            <th>Requirements</th>
            <th>Points</th>
          </tr>
        </thead>
        <tbody> 
            <tr >
              <td>You must have at least 1 degree, diploma or trade qualification from an Australian educational institution that meets the Australian study requirement.</td>
              <td>5</td>
            </tr>
        </tbody>
      </table>
      </div>
      <div>
                <div className='Table_Head_Title'>Professional Year in Australia</div>
      <table>
        <thead>
          <tr>
            <th>Requirements</th>
            <th>Points</th>
          </tr>
        </thead>
        <tbody> 
            <tr >
              <td>Completion of a Professional Year in Australia</td>
              <td>5</td>
            </tr>
        </tbody>
      </table>
      </div>
      <div>
                <div className='Table_Head_Title'>Credentialled community language</div>
      <table>
        <thead>
          <tr>
            <th>Requirements</th>
            <th>Points</th>
          </tr>
        </thead>
        <tbody> 
            <tr >
              <td>Hold a recognised qualification in a credentialled community language</td>
              <td>5</td>
            </tr>
        </tbody>
      </table>
      </div>
      <div>
                <div className='Table_Head_Title'>Study in regional Australia</div>
      <table>
        <thead>
          <tr>
            <th>Requirements</th>
            <th>Points</th>
          </tr>
        </thead>
        <tbody> 
            <tr >
              <td>RequirementPointsYou must have at least 1 degree, diploma or trade qualification from an Australian educational institution that satisfies the Australian study requirement obtained while living and studying in an eligible area of regional Australia</td>
              <td>5</td>
            </tr>
        </tbody>
      </table>
      </div>
         </div>
      }
      {activeTab === 'Partner' &&
         <div className='Tabs_container_layout_Field'>
             <table>
        <thead>
          <tr>
            <th>Requirements</th>
            <th>Points</th>
          </tr>
        </thead>
        <tbody>
          {PartnerData.map((item) => (
            <tr key={item.id}>
              <td>{item.age}</td>
              <td>{item.points}</td>
            </tr>
          ))}
        </tbody>
      </table>
         </div>
      }
</div>
<ConsultComp2
       containerColor="rgba(255, 247, 241, 1)"
      title="Take the General Migration Points test Today"
      desc="If you are not sure if you score enough points, use our online calculator to find out."
      btn="Do the Test"
      />

<Footer />

    </div>
  )
}

export default Gms