import React, { useEffect, useState } from "react";
import "../styles/Blog.css";
import { useNavigate } from "react-router-dom";
import { txtDB } from "../config/fireBase";
import {  collection,  getDocs } from "firebase/firestore";
import '../styles/HelpYou.css'

const HelpYouBlog = (props) => {
    const [ , setDisplay] = useState(false);
    const navigate = useNavigate();
    const [data,setData] = useState([])
    const [loading, setLoading] = useState(false);
    const [link, setLink] = useState('');
    const [links, setLinks] = useState([]);
    const getData = async () => {
      setLoading(true);
      try {
        const valRef = collection(txtDB, 'txtData');
        const dataDb = await getDocs(valRef);
        const allData = dataDb.docs.map((val) => ({ ...val.data(), id: val.id }));
        setData(allData);
        console.log(allData);
         setDisplay(true);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    
    useEffect(() => {
      const fetchData = async () => {
        setLoading(true);
        await getData();
        setLoading(false);
      };
    
      fetchData();
    }, []);

    const fetchLinks = async () => {
      try {
        const linkRef = collection(txtDB, 'linkDB');
        const snapshot = await getDocs(linkRef);
  
        const linksData = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
  
        setLinks(linksData);
      } catch (error) {
        console.error('Error fetching links:', error);
      }
    };
  
    useEffect(() => {
      fetchLinks();
    }, []);
    const handleMenuItemClick = (path) => {
      navigate(path);
    };
    const containerStyle = {
        backgroundColor: props.containerColor || '#F4F4F4', 
        color: props.textColor || '#014A91', 
      };
      const containerStyle2 = {
        backgroundColor: props.containerColor2 || '#F4F4F4', 
        color: props.textColor2 || 'rgba(59, 59, 59, 1)', 
      };
      const handleReadMore  = async (id) => {
        // navigate("/DeatiledBlog")
        navigate(`/DeatiledBlogUser/${id}`);
      };
      
    return (
        <>
            <div className='helpYouMainLayout'>
                <div className='helpYouLayout'>
                    <div className='helpYouHeadingBox' style={containerStyle}>
                        <div className='helpYouTitle' >{props.helpYouTitle}</div>
                    </div>
                    <div className='helpYouDesc' style={containerStyle2}>
                   
                        <div className='helpOuterBox' >
                        {data.map((value ,index) => (
                            <div className='helpSliderBox' key={value.id}>
                                <div className='helpDescImage' >
                                    <img className='helpYou-Image' src={value.imgUrl}  alt='' />
                                    {/* <video className='helpYou-Image' autoPlay muted loop playsInline>
                            <source src={props.helpYou1} type='video/mp4' />
                        </video> */}
                                </div>
                                <div className='helpYou-Desc'>
                                    <div className='helpDescHeading'>{value.txtVal}</div>
                                    <div className='helpDescContent'>{value.txtDesVal}</div>
                                    <div className='helpDescLearn' onClick={() => {handleReadMore(value.id); window.scrollTo(0,0);}}>{props.helpDescLearn}</div>
                                </div>
                                
                            </div>
                          
                            ))}
                        </div>
                    
                    </div>

                </div>
            </div>
        </>
    )
}

export default HelpYouBlog