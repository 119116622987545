import React from 'react'
import '../styles/Overview494.css'

import { useNavigate } from 'react-router-dom';

const Overview494Comp = (props) => {
    const navigate = useNavigate();
    const handleNavigate191 = () => {
        window.scrollTo(0,0);
         navigate('/EmployerTssVisa')
      }
      const handleNavigate186 = () => {
        window.scrollTo(0,0);
         navigate('/Visa186Overview')
      }
      const handleNavigate187 = () => {
        window.scrollTo(0,0);
         navigate('/Visa187')
      }
      const handleNavigate482 = () => {
        window.scrollTo(0,0);
         navigate('/Family482')
      }
    return (
        <>
            <div className='OverView494-layout'>
                <div className='overview494-layout-one'>
                    <div className='overview494-sublayout-one'>
                        <div className='overview494-headbox'>
                            <div className='overview494-heading'>{props.overview494Heading1}</div>
                        </div>
                        <div className='overview494-headbox'>
                            <div className='overview494-head-content'>
                                <li>{props.overview494Content1}</li>
                                <li>{props.overview494Content2}</li>
                            </div>
                        </div>
                        <div className='overview494-headbox'>
                            <div className='overview494-head-content-two'>
                                <li>{props.overview494Content3}</li>
                                <li>{props.overview494Content4}</li>
                                <li>{props.overview494Content5} <u onClick={handleNavigate191} style={{ color: '#B0383D', cursor: 'pointer' }}>191 Regional Skilled Visa</u></li>
                            </div>
                        </div>
                    </div>
                    <div className='overview494-sublayout-two'>
                        <div className='overview494-headbox1'>
                            <div className='overview494-heading1'>{props.overview494Heading2}</div>
                        </div>
                        <div className='overview494-headbox1'>
                            <div className='overview494-head-content1'>
                                <li>{props.overview494Content6}</li>
                            </div>
                        </div>
                        <div className='overview494-headbox1'>
                            <div className='overview494-head-content-two1'>
                                <li>{props.overview494Content7}</li>
                                <li>{props.overview494Content8}</li>
                                <li>{props.overview494Content9} <u style={{ color: '#B0383D', cursor: 'pointer' }}>skill assessment</u></li>
                                <li>{props.overview494Content10} <u style={{ color: '#B0383D', cursor: 'pointer' }}>competent English</u></li>
                                <li>{props.overview494Content11}</li>
                            </div>
                        </div>
                    </div>
                    <div className='overview494-sublayout-three'>
                        <div className='overview494-headbox'>
                            <div className='overview494-heading'>{props.overview494Heading3}</div>
                        </div>
                        <div className='overview494-headbox'>
                            <div className='overview494-head-content'>
                                <li>{props.overview494Content12}</li>
                            </div>
                        </div>
                        <div className='overview494-headbox'>
                            <div className='overview494-head-content-two'>
                                <li>{props.overview494Content13}</li>
                                <li><u onClick={handleNavigate482} style={{color:'#B0383D', cursor:'pointer'}}>482 TSS Visa</u> <u onClick={handleNavigate186} style={{color:'#B0383D', cursor:'pointer'}}>186 Visa (ENS)</u> <u style={{color:'#B0383D', cursor:'pointer'}} onClick={handleNavigate187}>187 Visa (RSMS)</u> {props.overview494Content14}</li>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Overview494Comp