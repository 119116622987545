import React, { useState } from 'react'
import DottedLines from '../images/DottedLines.png'
import NzBanner from "../images/NzBanner.mp4"
import NavBarComponent from '../components/NavBarComponent'
import ConsultationComponent from '../components/ConsultationComponent'
import Footer from '../components/Footer'
import "../styles/NzFamily.css"
import PartnerVisaHeadComp from '../components/PartnerVisaHeadComp'
import HeroBanner from '../components/HeroBanner'

import { useNavigate } from 'react-router-dom';
function NzFamily() {
  const [activeTab, setActiveTab] = useState('Overview');

  const handleTabClick = tabName => {
    setActiveTab(tabName);
  };
  const navigate = useNavigate();
  const handleMenuItemClick = (path) => {
    navigate(path);
  };
  return (
    <div>
      <NavBarComponent />
      <HeroBanner
        heroHeading='New Zealand Citizen Family Visa'
        heroSubHeading='This is a temporary visa for the family members of New Zealand Citizens living in Australia.'
        connectButton='Let’s Connect'
        Banner={[NzBanner]}
        DottedLines={DottedLines}
      />
         <div className="TabsContainer">
      <div
        className={`Tab ${activeTab === 'Overview' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('Overview')}
      >
        Overview
      </div>
      <div
        className={`Tab ${activeTab === 'Eligibility' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('Eligibility')}
      >
       Eligibility
      </div>
      <div
        className={`Tab ${activeTab === 'ProcessingTimes' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('ProcessingTimes')}
      >
       Processing Times
      </div>
      <div
        className={`Tab ${activeTab === 'Family' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('Family')}
      >
       Including Family
      </div>
    </div>
    {activeTab === 'Overview' &&
       <div className='Tabs_container_layout'>
             <div className='PartnerVisaHeadComp_Title'>Overview</div>
             <div style={{paddingTop:"24px"}}>
             <div className='AboutUsVisa_box_fields_content'>
               <div className='AboutUsVisa_box_title'>New Zealand Citizen Family visa (Subclass 461) allows non-New Zealand family members of a New Zealand Citizen to live and work in Australia for up to five years.</div>
          <div className='AboutUsVisa_box_desc'>
          <li>   This is a temporary visa for the family members of New Zealand Citizens living in Australia.</li>
          <li>Applicants who apply while inside Australia must be inside Australia when the visa is granted.</li>
          <li>Applicants who apply while outside Australia must be outside Australia when the visa is granted.</li>
          <li>If you and your family members are both non-New Zealand Citizens, you may be eligible to apply for a different <u onClick={() =>{ handleMenuItemClick('/FamilyPartner'); window.scrollTo(0,0);}} style={{ color: '#B0383D', cursor: 'pointer',fontSize:"16px" }}>partner </u> or <u onClick={() =>{ handleMenuItemClick('/FamilyVisa'); window.scrollTo(0,0);}} style={{ color: '#B0383D', cursor: 'pointer',fontSize:"16px" }}>family visa.</u></li>
          </div>
        </div>
             </div>
        </div>
     }
      {activeTab === 'Eligibility' &&
       <div className='Tabs_container_layout'>
             <div className='PartnerVisaHeadComp_Title'>Eligibility</div>
              <div style={{paddingTop:"24px"}}>
             <div className='AboutUsVisa_box_fields_content'>
               <div className='AboutUsVisa_box_title'>You may be eligible if</div>
          <div className='AboutUsVisa_box_desc'>
          <li> You are not a New Zealand Citizen.</li>
          <li>You are an eligible family member of a New Zealand Citizen </li>
          <li>You are an eligible family member of a Special Category Subclass 444 Visa</li>
          </div>
        </div>
             </div>
        </div>
     }
      {activeTab === 'ProcessingTimes' &&
       <div className='Tabs_container_layout'>
             <div className='PartnerVisaHeadComp_Title'>Processing Times</div>
              <div style={{paddingTop:"24px"}}>
             <div className='AboutUsVisa_box_fields_content'>
               <div className='AboutUsVisa_box_title'>The Department of Immigration and Border Protection’s current processing times for this visa</div>
          <div className='AboutUsVisa_box_desc'>
          <li>75% of application processed within 13 months </li>
          <li> 90% of application processed within 19 months</li>
          </div>
        </div>
             </div>
        </div>
     }
      {activeTab === 'Family' &&
       <div className='Tabs_container_layout'>
             <div className='PartnerVisaHeadComp_Title'>Including Family</div>
             <div style={{paddingTop:"24px"}}>
             <div className='AboutUsVisa_box_fields'>
        <div className='AboutUsVisa_box_fields_content'>
          <div className='NzFamily_content'>
          <div className='AboutUsVisa_box_title'>General</div>
          <div className='AboutUsVisa_box_desc'>
          New Zealand Citizens can apply to bring the following family members to Australia to be with them:
<li>Married or de facto partner</li>
<li>Dependent children/step-children </li>
 </div></div>
        </div>
        <div className='AboutUsVisa_box_fields_content'>
        <div className='NzFamily_content'>
          <div className='AboutUsVisa_box_title'>Age</div>
          <div className='AboutUsVisa_box_desc'>
          This visa has no age limit </div>
        </div>
        </div>
        <div className='AboutUsVisa_box_fields_content'>
        <div className='NzFamily_content'>
        <div className='AboutUsVisa_box_title'>Health & Character</div>
          <div className='AboutUsVisa_box_desc'>All family members must meet the health and character requirements. All applicants over 16 must provide police clearances. </div>
        </div>
        </div>
        </div>
             </div>
        </div>
     }

<div className='ConsultationComponent_field'>
            <ConsultationComponent
            title="Are You Eligible?"
            description="Take our online eligibility assessment to find out if you meet the requirements for the 482 Visa            "
            button="Check Eligibility"
            />
             <ConsultationComponent 
            title="Book A Consultation"
            description="Speak to one of our migration agents and simply book a consultation. We'll assist you in understanding the costs involved"
            button="Consult"
            />
             <ConsultationComponent
            title="Get A Quote"
            description="Have a visa in mind? Contact us for a fixed price quote. We'll assist you in understanding the costs involved.            "
            button="Get a quote"
            />
            </div>
            <Footer />


    </div>
  )
}

export default NzFamily