import React from 'react'
import '../styles/Whyus.css'

const WhyUs = (props) => {
    return (
        <>
            <div className='whyus-layout'>
                <div className='whyus-heading'>
                    <div className='why-us-heading'>{props.whyUsHeadingMain}</div>
                </div>
                <div className='why-main-layout'>
                    <div className='why-layout-one'>
                        <div className='why-layout-exp'>
                            <div className='why-heading'>{props.whyUsHeading1}</div>
                            <div className='why-desc'>{props.whyUsDesc1}</div>
                        </div>
                        <div className='why-layout-ext'>
                            <div className='why-heading1'>{props.whyUsHeading2}</div>
                            <div className='why-desc1'>{props.whyUsDesc2}</div>
                        </div>
                    </div>
                    <div className='why-layout-two'>
                        <div className='why-two-sublayout1'>
                            <div className='why-two-service'>
                                <div className='why-two-head'>{props.whyUsHeading3}</div>
                                <div className='why-two-desc'>{props.whyUsDesc3}</div>
                            </div>
                            <div className='why-two-trans'>
                                <div className='why-two-head'>{props.whyUsHeading4}</div>
                                <div className='why-two-desc'>{props.whyUsDesc4}</div>
                            </div>
                        </div>
                        <div className='why-two-sublayout2'>
                            <div className='why-two-support'>{props.whyUsHeading5}</div>
                            <div className='why-two-support-desc'>{props.whyUsDesc5}</div>
                        </div>
                    </div>
                </div>
                <div className='why-main-layout2'>
                    <div className='why-two-service'>
                        <div className='why-two-head'>{props.whyUsHeading1}</div>
                        <div className='why-two-desc'>{props.whyUsDesc1}</div>
                    </div>
                    <div className='why-two-service'>
                        <div className='why-two-head'>{props.whyUsHeading2}</div>
                        <div className='why-two-desc'>{props.whyUsDesc2}</div>
                    </div>
                    <div className='why-two-service'>
                        <div className='why-two-head'>{props.whyUsHeading3}</div>
                        <div className='why-two-desc'>{props.whyUsDesc3}</div>
                    </div>
                    <div className='why-two-service'>
                        <div className='why-two-head'>{props.whyUsHeading4}</div>
                        <div className='why-two-desc'>{props.whyUsDesc4}</div>
                    </div>
                    <div className='why-two-service'>
                        <div className='why-two-head'>{props.whyUsHeading5}</div>
                        <div className='why-two-desc'>{props.whyUsDesc5}</div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default WhyUs