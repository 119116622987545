import React from 'react'
import "../styles/Tss.css"
function TssHeadComponent(props) {
  return (
    <div className='option3_layout'>
        <div className='Tss_head_container'>
         <div className='Tss_head'>There are four main pathways from the TSS visa to Permanent Residency  </div>
     <div className='TssHead_desc'>
          <li>Employer Sponsored Temporary Transition Stream (ENS/RSMS)</li>
          <li>Employer Sponsored Direct Entry Stream (ENS/RSMS)</li>
          <li>General Skilled Migration (189/190/489)</li>
          <li>Partner Migration (As the partner of an Australian Permanent Resident or Citizen)</li>
     </div>
     </div>
    </div>
  )
}
export default TssHeadComponent