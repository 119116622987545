import React, { useState } from 'react'
import overviewImg from "../images/OverviewImg.png";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ConsultationComponent from './ConsultationComponent';
import ContentBoadyOverview from './ContentBoadyOverview';
function EmployerSponseredOverview() {
    const [openAccordionIndex, setOpenAccordionIndex] = useState([1, 2, 3,4,5]);

    const handleAccordionToggle = (index) => {
      setOpenAccordionIndex((prevOpenAccordionIndex) => {
        if (prevOpenAccordionIndex.includes(index)) {
          return prevOpenAccordionIndex.filter((item) => item !== index);
        } else {
          return [...prevOpenAccordionIndex, index];
        }
      });
    };
  return (
    <div>
        <div className='shortTermLayout'>
        <div className='Overview_faq_container_head'>
        Temporary Skill Shortage visa (subclass 482) Overview
        </div>
        <div className='faq_accordian_video_contaner'>
          <div className='overview_accordian_container'>
            {[1, 2].map((index) => (
              <div className='overview_accordian-submain' key={index} >
                <div className='overview_accordian-heading'>
                The Subclass 482 Visa (TSS) Stream
                  {/* <span>{openAccordionIndex.includes(index) ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}</span> */}
                </div>
                {openAccordionIndex.includes(index) && (
                  <div className='overview_accordian-details'>Short-Term Stream – up to two yearsMedium-Term Stream – up to four yearsLabour Agreement Stream   </div>
                )}
              </div>
            ))}
          </div>
          <div className='faq_container_image'>
         <img src={overviewImg} alt="" className='overview-img'/>
           
          </div>
        </div>
      
      </div>
      <ContentBoadyOverview heading="482 Visa General Eligibility"/>
      <div className='ConsultationComponent_field'>
        <ConsultationComponent title="Are You Eligible?" 
        description="Take our online eligibility assessment to find out if you meet the requirements for the 482 Visa"
        button="Check Eligibility"
        />
         <ConsultationComponent title="Book A Consultation" 
        description="Speak to one of our migration agents about the 482 Visa. Simply book a consultation."
        button="Consult"
        />
         <ConsultationComponent title="Book A Consultation" 
        description="Have a visa in mind? Contact us for a fixed price quote. We'll assist you in understanding the costs involved."
        button="Consult"
        />
        </div>
    </div>
  )
}

export default EmployerSponseredOverview