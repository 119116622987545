import React from 'react'
import Heading from '../components/Heading'
import "../styles/LabourAgreement.css"

function LabourAgreement() {
  return (
    <div >
        <Heading heading="Labour Agreement Stream" containerColor="#FFF7F1" textColor="#B0383D" />
        <div className='LabourAgreement_layout_content'>
        <div className='LabourAgreement_Desc'>
  The Labour Agreement TSS Visa stream allows applicants to stay for up to 4 years, which may have the option to apply for another TSS Visa or Permanent Residency. This TSS stream may be utilized in exceptional circumstances where a business can demonstrate a need that cannot be met by Australian workers and standard visa programs are not available. These agreements are negotiated between an employer and the Department to enable an employer to recruit overseas workers that may have:
  <ul>
    <li>Specified age</li>
    <li>Specified Occupation</li>
    <li>Skill level</li>
    <li>English language</li>
    <li>Salary</li>
  </ul>
</div>
            <div className='LabourAgreement_section'>
                <div className='LabourAgreement_section-container'>
                    <div className='LabourAgreement_section-container_title'>General </div>
                    <div className='LabourAgreement_section-container_desc'>
                            <li>Applicants may have a transitional pathway to Permanent Residency, depending on their employer’s labour agreement terms.</li>
                            <li>  Immediate family members may be included, with the support of the employer </li>
                       
                        
                       </div>
                </div>
                <div className='LabourAgreement_section-container'>
                <div className='LabourAgreement_section-container_title'>English </div>
                    <div className='LabourAgreement_section-container_desc'>
                        
                            <li>Applicants must meet the English level specified in their employer’s labour agreement</li>
                           
                       
                        
                       </div>
                </div>
                <div className='LabourAgreement_section-container'>
                <div className='LabourAgreement_section-container_title'>Stay Period </div>
                    <div className='LabourAgreement_section-container_desc'>
                        
                            <li>Labour Agreement TSS Visa holders can stay in Australia for up to 4 years</li>
                           
                       

                </div>
                </div>
            </div>
            <div className='LabourAgreement_extrayLay'>
                <div className='LabourAgreement_section-container_title'>Extending Stay</div>
                <div className='LabourAgreement_section-container_desc_extrayLay'>
                    If your Labour Agreement 482 (TSS) visa is expiring soon and you would like to continue working for your employer:
• your employer will need to submit a new nomination• you can apply for a new 482 TSS visa• You may also be eligible to apply for one of the below permanent visas, depending on the terms of your employer’s Labour Agreement (The employer will need to hold a valid labour agreement when offering permanent sponsorship)</div>

            </div>
            <div></div>
        </div>
   
    </div>
  )
}

export default LabourAgreement