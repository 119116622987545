import React from 'react'
import Heading from './Heading'
import "../styles/includingFamily.css"
function IncludingFamily(props) {
  return (
    <div>
          <Heading heading='Including Family' containerColor="#FFF7F1" textColor="#B0383D"  />
          <div className='including_family_container'>
          <div className='including_family_content'>{props.content1}</div>
          <div className='including_family_content'>{props.content2}</div>
          <div className='including_family_content'>{props.content3}</div>
          </div>
    </div>
  )
}

export default IncludingFamily