import BlogBanner from "../images/ManUsingLap.mp4"
import DottedLines from '../images/DottedLines.png'
import NavBarComponent from '../components/NavBarComponent'
import HeroBanner from '../components/HeroBanner'
import Familyimg from "../images/Flag.mp4"
import {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    signInWithPopup,
    signOut,
  } from "firebase/auth";
import { auth, googleProvider } from "../config/fireBase";
import React, { useEffect, useState } from "react";
import { imgDB,txtDB } from "../config/fireBase";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { addDoc, collection, deleteDoc, doc, getDocs } from "firebase/firestore";
import Familyimg1 from "../images/WomenOnlineWriting.mp4"
import Familyimg2 from "../images/BlogWomenHandFilling.mp4"
import BlogHeadComp from '../components/BlogHeadComp'
import "../styles/Blog.css"
import { v4 } from "uuid";
import Footer from '../components/Footer'
import { useNavigate } from 'react-router-dom';

function Adminpanel() {
    const [display, setDisplay] = useState(false);
    const[user,setUser] =useState(null);
    const [loading, setLoading] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [uploadEligible, setUploadEligible] = useState(false);
    const [ upload, setUpload] = useState(0);
    const [popupData, setPopupData] = useState({
        isOpen: false,
        index: 0,
      });
    const navigate = useNavigate();
    const handleButtonClick1 = () => {
      window.scrollTo(0,0);
      navigate("/DeatiledBlog");
  
    };
    const logout = async () => {
        try {
            setUser();
          await signOut(auth);
          console.log(auth);
          setDisplay(false);

        } catch (err) {
          console.error(err);
        }
      };

      const [txt,setTxt] = useState('')
      const [img,setImg] = useState('')
      const [data,setData] = useState([])

  
      const handleUpload = async (e) => {
        console.log(e.target.files[0]);
        const imgs = ref(imgDB, `Imgs/${v4()}`);
        try {
            setUploading(true); 
          const data = await uploadBytes(imgs, e.target.files[0]);
          const val = await getDownloadURL(data.ref);
          setImg(val);
          setUploadEligible(true);
          setUpload(upload + 1);
        } catch (error) {
            setUploading(false);
          console.error(error);
        }
        setUploading(false);
      };
      
      const getData = async () => {
        setLoading(true);
        try {
          const valRef = collection(txtDB, 'txtData');
          const dataDb = await getDocs(valRef);
          const allData = dataDb.docs.map((val) => ({ ...val.data(), id: val.id }));
          setData(allData);
          console.log(allData);
           setDisplay(true);
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      };
      
      useEffect(() => {
        const fetchData = async () => {
          setLoading(true);
          await getData();
          setLoading(false);
        };
      
        fetchData();
      }, []);

    const handleClick = async () =>{
            const valRef = collection(txtDB,'txtData')
            await addDoc(valRef,{txtVal:txt,imgUrl:img})
            alert("Data added successfully");
            setUploadEligible(false);
            setTxt('');
            setImg('');
            getData();
    }

    const handleDelete = async (id) => {
      try {
        const valRef = doc(txtDB, 'txtData', id);
        await deleteDoc(valRef);
        alert("Data deleted successfully");
        getData();
      } catch (error) {
        console.error(error);
      }
    };
 
  return (
    <div>
        <NavBarComponent />
        <HeroBanner
        heroHeading='Blogs'
        heroSubHeading='Our registered migration agents regularly share new, tips and tricks and other interesting information for migrants. '
        connectButton='Let’s Connect'
        Banner={[BlogBanner]}
        DottedLines={DottedLines}
        />
         <BlogHeadComp
        FamilyHeaderTitle="The Benefits Of Studying In Australia"
        familyDescription="Australia has become a top destination for international students seeking to further their education. With its high-quality education system, diverse cultural experiences..."
        FamilyPartnerImg= {Familyimg1}
        imageAlt="Alt text for your image"
        swapImageAndDescription={true}
        onClick1={handleButtonClick1}
         btn="Read More"
        />
        <BlogHeadComp
        containerColor="rgba(255, 247, 241, 1)"
        textColor="rgba(176, 56, 61, 1)"
        containerColor2="rgba(255, 247, 241, 1)"
        textColor2="rgba(59, 59, 59, 1)"
        FamilyHeaderTitle="The Top 5 Reasons Why People Choose To Migrate To Australia"
        familyDescription="Australia is a popular destination for people from all over the world who are seeking a new life abroad. In this article, we’ll explore the top 5 reasons why people choose to migrate to Australia.Australia offers a high quality of life with access to excellent healthcare, education, and public services.."
        FamilyPartnerImg= {Familyimg}
        onClick1={handleButtonClick1}
        imageAlt="Alt text for your image"
        swapImageAndDescription={false}
         btn="Read More"
        />
          <BlogHeadComp
        FamilyHeaderTitle="Understanding The Different Types Of Australian Visas"
        familyDescription="Australia offers a range of visas for people who want to visit, study, work, or live in the country. In this article, we’ll provide an overview of the different types of Australian visas and their requirements..."
        FamilyPartnerImg= {Familyimg2}
        imageAlt="Alt text for your image"
        swapImageAndDescription={true}
        onClick1={handleButtonClick1}
         btn="Read More"
        />
        <Footer />
    </div>
  )
}

export default Adminpanel