import React, { useState } from 'react'
import '../styles/Option1Overview.css'

import { XCircle } from "@phosphor-icons/react";
const Option3Overview = ({ closeModal }) => {
    const [activeTab, setActiveTab] = useState('Overview');

    const handleTabClick = tabName => {
      setActiveTab(tabName);
    };
    return (
        <>
            <div className='modalLayout'>
                <div className='modalLayoutMain'>
                <div style={{ textAlign: 'right', paddingTop: '16px', paddingRight: '16px' }}>
                        <XCircle onClick={closeModal} size={32} />
                    </div>
                    <div className='OptionOverviewStyling'>   
                        <div className='OptionOverviewHeading'>Option 3</div>
                        <div className='OptionOverviewContentBox'>
                            <div className='OptionOverviewContentDesc'>
                                <li>457 Visa holders who do not have an Australian employer willing to sponsor them for permanent residency may be able to apply through the <u style={{color:'#B0383D', cursor:'pointer'}}>General Skilled Migration Program</u>.</li>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Option3Overview