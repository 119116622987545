import React, { useEffect, useRef, useState } from 'react'
import '../styles/HeroBanner.css'
import { WhatsappLogo } from '@phosphor-icons/react';

const HeroBanner = (props) => {
  
    const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
    const videoSources = props.Banner;
    const videoRef = useRef(null);
  
    const handleVideoEnd = () => {
      setCurrentVideoIndex((prevIndex) => (prevIndex + 1) % videoSources.length);
    };
  
    useEffect(() => {
      const videoElement = videoRef.current;
  
      const handleNextVideo = () => {
        setCurrentVideoIndex((prevIndex) => (prevIndex + 1) % videoSources.length);
      };
  
      videoElement.addEventListener('ended', handleNextVideo);
  
      // Cleanup event listener when component unmounts
      return () => {
        videoElement.removeEventListener('ended', handleNextVideo);
      };
    }, [videoSources]);
  
    useEffect(() => {
      // Reset the video to the beginning when the currentVideoIndex changes
      videoRef.current.currentTime = 0;
    }, [currentVideoIndex]);
  
    const handleConnectButtonClick = () => {
      // const phoneNumber = "9632131407";
      // const whatsappLink = `https://wa.me/${phoneNumber}`;
      // const whatsappWebLink = `https://web.whatsapp.com/send?phone=${phoneNumber}`;
    
      // const isUserLoggedIn = localStorage.getItem("WABrowserId") !== null;
      // const targetLink = isUserLoggedIn ? whatsappLink : whatsappWebLink;
    
      // window.open(targetLink, '_blank');
      <a target="_blank" href="https://wa.me/918888999989"></a>
  };
  
      
    return (
        <>
            <div className='HeroBanner'>
                <div className='Banner-image '>
                <video ref={videoRef} className='HeroBannerImage' autoPlay muted loop playsInline onEnded={handleVideoEnd}>
            <source className='hero-image' src={videoSources[currentVideoIndex]} type='video/mp4' />
          </video>


                </div>
                <div className='hero'>
                    <div className='hero-styling'>
                        <div className='hero-content'>
                            <div className='hero-heading'>
                                {props.heroHeading}
                            </div>
                            <div className='heroStyling'>
                                <div className='hero-subheading'>
                                    {props.heroSubHeading}
                                </div>
                                {/* <div className='connectButton'> */}
                                {/* <button type='button' className='connect-button'>
  <a target="_blank" rel="noopener noreferrer" href="https://wa.me/918888999989">
    {props.connectButton}
  </a>
</button> */}
<div className='connectButton'>
     <a target="_blank" href="https://wa.me/9663907648" style={{ textDecoration: 'none' }} className='connect-button'>
      <WhatsappLogo size={24} />
 {props.connectButton}
     </a>

</div>

                                    
                                {/* </div> */}
                            </div>
                        </div>
                    </div>
                    <div className='dotted-lines'>
                        <img src={props.DottedLines} alt='dots' />
                    </div>
                </div>
            </div>
        </>
    )
}

export default HeroBanner