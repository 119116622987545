import React from 'react'
import "../styles/howComponent187.css"

function SkilledHowComp(props) {
    const containerStyle = {
        backgroundColor: props.containerColor || '#F4F4F4', 
        color: props.textColor || '#014A91', 
      };
  return (
    <div>  <div className='VisaHow187Layout'>
    <div className='VisaHowHeadBox' style={containerStyle}>
        <div className='VisaHowHeading' style={containerStyle}>{props.EmpHowHeading}</div>
    </div>
    {/* <div className='VisaHowImage'>
        <img className='VisaHow-Image' src={props.EmpHowImage} alt='howImage' />
    </div> */}
    <div className='VisaHowImage'>
  <video className='VisaHow-Image' autoPlay muted loop playsInline>
    <source src={props.EmpHowImage} type='video/mp4' />
    Your browser does not support the video tag.
  </video>
</div>

    <div className='VisaHowSubLayout' >
        <div className='VisaHow-SubLayout'style={containerStyle}>
            <div className='VisaHowSubHead'>{props.EmpHowSubHead1}</div>
            <div className='VisaHowDesc'>{props.EmpHowDesc1}</div>
        </div>
        <div className='VisaHow-SubLayout'style={containerStyle}>
            <div className='VisaHowSubHead'>{props.EmpHowSubHead2}</div>
            <div className='VisaHowDesc'>{props.EmpHowDesc2}</div>
        </div>
        <div className='VisaHow-SubLayout' style={containerStyle}>
            <div className='VisaHowSubHead'>{props.EmpHowSubHead3}</div>
            <div className='VisaHowDesc'>{props.EmpHowDesc3}</div>
        </div>
    </div>
    <div className='VisaHowSubLayout2'>
        <div className='VisaHow-SubLayout'style={containerStyle} >
            <div className='VisaHowSubHead'>{props.EmpHowSubHead4}</div>
            <div className='VisaHowDesc'>{props.EmpHowDesc4}</div>
        </div>
        <div className='VisaHow-SubLayout' style={containerStyle}>
            <div className='VisaHowSubHead'>{props.EmpHowSubHead5}</div>
            <div className='VisaHowDesc'>{props.EmpHowDesc5}</div>
        </div>
    </div>
   
</div></div>
  )
}

export default SkilledHowComp