import React from 'react';
import HopeLogo from '../images/HopeLogo.png';
import { useNavigate } from 'react-router-dom';


function LoginNavBar() {
    const navigate = useNavigate();
    const handleNavigateHome = () => {
        window.scrollTo(0,0);
        navigate("/");
      }
  return (
    <div>
         <div className='navbar' >
                <div className='logo' style={{zIndex:1}}>
                    <img className='HopeLogo_NavBar' src={HopeLogo} alt='Logo' onClick={handleNavigateHome} style={{zIndex:1}}/>
                </div>
            
            </div>
           
    </div>
  )
}

export default LoginNavBar