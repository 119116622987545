import React, { useState } from 'react'
import '../styles/Option2Overview.css'
import Option2Eligibility from './Option2Eligibility';
import Option2Family from './Option2Family';


import { XCircle } from "@phosphor-icons/react";
const Option2Overview = ({ closeModal }) => {
    const [activeTab, setActiveTab] = useState('Overview');

    const handleTabClick = tabName => {
      setActiveTab(tabName);
    };
    return (
        <>
            <div className='modalLayout'>
            <div className='modalLayoutMain'>
            <div style={{ textAlign: 'right', paddingTop: '16px', paddingRight: '16px' }}>
                        <XCircle onClick={closeModal} size={32} />
                    </div>
                <div className='OptionOverviewStyling'>

                <div className="TabsContainer">
      <div
        className={`Tab ${activeTab === 'Overview' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('Overview')}
      >
       Overview
      </div>
      <div
        className={`Tab ${activeTab === 'Eligibility' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('Eligibility')}
      >
      Eligibility
      </div>
      <div
        className={`Tab ${activeTab === 'Family' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('Family')}
      >
      Including Family
      </div>
      
                   </div>

                   {activeTab === 'Overview' &&
                   <div className='Tabs_container_layout'>
                      <div className='EligibilitymodalLayout'>
                <div className='OptionOverviewStyling'>
                    <div className='OptionOverviewHeading'>Option 2: Direct Entry Stream (DE)</div>
                    <div className='OptionTwoLayout'>
                        <div className='OptionTwoDesc'>
                            <li>457 Visa holders who have not been in Australia for two years, or who have changed employers while working in Australia, may still be eligible to apply for Permanent Residency through the Direct Entry Stream.</li>
                            <li>Applicants must have an employer willing to sponsor them for permanent residency.</li>
                        </div>
                    </div>
                    <div className='OptionTwoLayout'>
                        <div className='OptionTwoHead'>Skilling Australians Fund levy</div>
                        <div className='OptionTwoDesc-two'>
                            <li>Employers will be required to pay the new Skilling Australians Fund levy for all 457 to Permanent 186 or 187 Visa applications. The levy is payable in full at the time of application.</li>
                            <li>SMALL BUSINESSES (ANNUAL TURNOVER LESS THAN $10 MILLION) – $3,000</li>
                            <li>OTHER BUSINESSES (ANNUAL TURNOVER MORE THAN $10 MILLION) – $5,000</li>
                            <li>The Business must pay the levy, the applicant cannot make any payment towards the SAF levy. There are no exemptions.</li>
                        </div>
                    </div></div></div></div>

                  }
                  {activeTab ==='Eligibility' && 
 <div className='Tabs_container_layout'>
<Option2Eligibility/>
 </div>
                  
                  }
                    {activeTab ==='Family' && 
 <div className='Tabs_container_layout'>
<Option2Family/>
 </div>
                  
                  }
                </div>
                </div>
            </div>
        </>
    )
}

export default Option2Overview