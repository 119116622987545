import React, { useState } from 'react'
import NavBarComponent from '../components/NavBarComponent'
import DottedLines from '../images/DottedLines.png'
import Passport from '../images/passport.png'
import PermanentEmpBanner from '../components/PermanentEmpBanner'
import Footer from '../components/Footer'
import "../styles/Family457.css"
import ConsultationComponent from '../components/ConsultationComponent'
import HeroBanner from '../components/HeroBanner'
import Herobanner457 from '../images/FamilyVisa457Banner.mp4';

import { useNavigate } from 'react-router-dom';
function Family457Visa() {
    const [activeTab, setActiveTab] = useState('Overview');

    const handleTabClick = tabName => {
      setActiveTab(tabName);
    };
    const navigate = useNavigate();
    const handleMenuItemClick = (path) => {
      navigate(path);
    };
  return (
    <div>
        <NavBarComponent />
        <HeroBanner
        heroHeading='457 Family Visa'
        heroSubHeading='For family members of the Subclass 457 visa'
        connectButton='Let’s Connect'
        Banner={[Herobanner457]}
        DottedLines={DottedLines}
      />

       <div className="TabsContainer">
      <div
        className={`Tab ${activeTab === 'Overview' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('Overview')}
      >
        Overview
      </div>
      <div
        className={`Tab ${activeTab === 'Eligibility' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('Eligibility')}
      >
       Eligibility
      </div>
    </div>

      {activeTab === 'Overview' &&
       <div className='Tabs_container_layout'>
        <div className='PartnerVisaHeadComp_Title'>Overview</div>
        <div style={{paddingTop:"24px"}}>
        <div className='AboutUsVisa_box_fields_content'>
        <div className='AboutUsVisa_box_title'>A 457 Family Visa is for the immediate family of a Temporary Work (Subclass 457) Visa holder</div>
          <div className='AboutUsVisa_box_desc'>A <u onClick={() =>{ handleMenuItemClick('/Family457Visa'); window.scrollTo(0,0);}} style={{ color: '#B0383D', cursor: 'pointer',fontSize:"16px" }}>Temporary Work (Subclass 457) visa</u> holder can add their spouse or de facto partner and children to their visa as secondary applicants.
Choose the option that applies to you:
<li>If you aren’t a 457 visa holder yet, choose the option that applies to you:</li>
<li> <u onClick={() =>{ handleMenuItemClick('/Family457Visa'); window.scrollTo(0,0);}} style={{ color: '#B0383D', cursor: 'pointer',fontSize:"16px" }}>I want to apply for a 457 Visa with my family</u></li>
<li><u onClick={() =>{ handleMenuItemClick('/Visa457Emp'); window.scrollTo(0,0);}} style={{ color: '#B0383D', cursor: 'pointer',fontSize:"16px" }}>I need an employer to sponsor me and my family on a 457 Visa</u></li>
<li>Adding a secondary applicant to a 457 Visa entitles a spouse or defect partner and immediate family of a 457 Visa holder to enter and remain in Australia while the main applicant’s visa is valid.</li>
<li>Secondary applicants can live, work and study while residing in Australia.</li>
<li>If you do not yet hold a 457 visa, you can apply for the visa together with your family or, if you do not yet have an employer to sponsor you and your family.</li>
</div>
        </div>
        </div>
      </div>
        }
          {activeTab === 'Eligibility' &&
       <div className='Tabs_container_layout'>
           <div className='PartnerVisaHeadComp_Title'>Eligibility</div>
           <div style={{paddingTop:"24px"}}>
        <div className='AboutUsVisa_box_fields_content'>
        <div className='AboutUsVisa_box_title'>Applicants can include or add the following family members to their 457 visa</div>

          <div className='AboutUsVisa_box_desc'><li>Married or de facto partner</li>
          <li>Dependent children/step-children</li>
          <li>Our team of migration agents are experts in Employer Sponsored 457 and 457 Secondary Applicant Visa applications.</li>
          <li>We have assisted hundreds of 457 clients to bring their partners and families to join them in Australia. Adding family members:</li>
          <li>457 Family members can be included on original 457 visa application</li>
          <li>Family members can be added to your 457 visa after approval</li>
          <li>Employers must support visa applications or additional family members</li>
</div>
        </div>
        <div className='AboutUsVisa_box_fields'>
        <div className='AboutUsVisa_box_fields_content_457'>
          <div className='AboutUsVisa_box_title'>Children</div>
          <div className='AboutUsVisa_box_desc'>
          To be eligible to be included or added to a 186 visa, a child must:
          <li>Be your child or a stepchild from a current or a previous relationship (in certain circumstances);</li>
          <li>Not be married, engaged to be married, or have a de facto partner; and must be:</li>
          <li>Under 18 years of age or</li>
          <li>Over 18 years of age but not yet turned 23, and be dependent on you or your partner or</li>
          <li>Over 23 years of age and be unable to earn a living to support themselves due to physical or cognitive limitations and be dependent on you or your partner (Note: The child will still need to meet Australia’s health requirement)or</li>
          <li>A dependent child of a child who is eligible under 1, 2 or 3 above.</li>
   </div>
        </div>
        <div className='AboutUsVisa_box_fields_content_457'>
        <div className='AboutUsVisa_box_title'>Partners</div>
          <div className='AboutUsVisa_box_desc'>Your married or de facto partner can be the same or opposite sex. Applicants must prove:
The relationship is genuine and continuing
You live together, or do not live separately on a permanent basis.
Your partner is at least 18 years of age when the application is lodged (there are some exceptions)
You are not related by family (if you are in a de facto relationship)
You and your partner have a mutual commitment to a shared life to the exclusion of all others
</div>
        </div>

        </div>
        <div className='AboutUsVisa_box_fields'>
        <div className='AboutUsVisa_box_fields_content'>
          <div className='AboutUsVisa_box_title'>English</div>
          <div className='AboutUsVisa_box_desc'>Family members are not required to show evidence of their English level</div>
          </div>
        <div className='AboutUsVisa_box_fields_content'>
        <div className='AboutUsVisa_box_title'>Age </div>
          <div className='AboutUsVisa_box_desc'>Children 23 years and over cannot be included in your application. (Unless there are exceptional circumstances)</div>
        </div>
        <div className='AboutUsVisa_box_fields_content'>
        <div className='AboutUsVisa_box_title'>Health & Character </div>
          <div className='AboutUsVisa_box_desc'>All family members must meet the health and character requirements. All applicants over 16 must provide police clearances.</div>
        </div>
        
        </div>


        </div>
      </div>
        }
        <div className='ConsultationComponent_field'>
            <ConsultationComponent 
            title="Are You Eligible?"
            description="Take our online eligibility assessment to find out if you meet the requirements for Visa            "
            button="View List"
            />
             <ConsultationComponent
            title="Book A Consultation"
            description="Speak to one of our migration agents and simply book a consultation. We'll assist you in understanding the costs involved"
            button="Consult"
            />
             <ConsultationComponent
            title="Get A Quote"
            description="Have a visa in mind? Contact us for a fixed price quote. We'll assist you in understanding the costs involved.            "
            button="Get a quote"
            />
            </div>
            <Footer />
    </div>
  )
}

export default Family457Visa