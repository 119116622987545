import React from 'react';
import "../styles/subNav.css";
import { useNavigate } from 'react-router-dom';

function SubNav() {
    const navigate = useNavigate();

    const handleNavigationVisa187 = () => {
        window.scrollTo(0,0);
        navigate("/Visa187");
    };

    const handleNavigationVisa = () => {
        window.scrollTo(0,0);
        navigate("/VisaExemption");
    };
 const handleNavigationVisaTss =() =>{
    window.scrollTo(0,0);
    navigate("/EmployerTssVisa")
 }
 const handleNavigationEmployerVisa = () => {
   // console.log('handleNavigationEmployerVisa called');
   window.scrollTo(0, 0);
   navigate("/Permoverview");
 }
 

 /* */
 const handleNavigationVisa482 = () => {
    window.scrollTo(0,0);
    navigate("/EmployerSponsered482")
 }
 const handleNavigationVisa494 = () => {
    window.scrollTo(0,0);
    navigate("/Visa494")
 }
 const handleNavigationVisa457 = () => {
    window.scrollTo(0,0);
    navigate("/Visa457Emp")
 }
 const handleNavigationVisa186 = () => {
    window.scrollTo(0,0);
    navigate("/Employer186")
 }
 const handleNavigationVisaJob = () => {
   window.scrollTo(0,0);
   navigate("/JobVacancies")
}

    return (
        <div className='sub-nav-container'>
            <div onClick={handleNavigationVisa482} className='sub_nav_container'>482 Visa</div>
            <div onClick={handleNavigationVisa494} className='sub_nav_container'>494 Visa</div>
            <div onClick={handleNavigationVisa457} className='sub_nav_container'>457 to PR Visa</div>
            <div onClick={handleNavigationVisa186} className='sub_nav_container'>186 Visa(ENS)</div>
            <div onClick={handleNavigationVisa187} className='sub_nav_container'>187 Visa (RSMS)</div>
            <div onClick={handleNavigationVisa} className='sub_nav_container'>186 & 187 Exemptions</div>
            <div onClick={handleNavigationVisaTss} className='sub_nav_container'>TSS Visa to PR </div>
            <div onClick={handleNavigationVisaJob} className='sub_nav_container'>Job Vacancies </div>
            <div onClick={handleNavigationEmployerVisa} className='sub_nav_container'>Permanent Employer Sponsored</div>
           
        </div>
    );
}
export default SubNav;
