import React from 'react'
import Navbar from '../components/Navbar'
import PermanentEmpBanner from '../components/PermanentEmpBanner'
import DottedLines from '../images/DottedLines.png'
import Passport from '../images/passport.png'
import PermanentEmpOv from '../components/PermanentEmpOv'
import Footer from '../components/Footer'
import "../styles/Visa186Holder.css"
import Visa186Holder from '../components/Visa186Holder'
import Visa186Eligibility from '../components/Visa186Eligibility'
import howitworks from '../images/howitworks.png'
import Visa186How from '../components/Visa186How'

const Visa186Overview = () => {
    return (
        <>
            <Navbar />
            <PermanentEmpBanner
                heroHeading='Employer Nomination Scheme ENS (Subclass 186 Visa)'
                connectButton='Let’s Connect'
                Banner={Passport}
                DottedLines={DottedLines}
            />
            <div className='Visa186Overview_layout'>
            <PermanentEmpOv
                empOVHeading='The Subclass 186 Visa has 3 streams:'
                empOVsubHead1='DIRECT ENTRY STREAM:'
                empOVsubHead2='AGREEMENT STREAM:'
                empOVsubHead3='TRANSITIONAL STREAM:'
                empOVDesc1='For applicants who are qualified or have sufficient experience in an eligible occupation. See the Direct Entry Stream tab for specific requirements.'
                empOVDesc2='For applicants who are sponsored by an employer under a labour agreement.'
                visa186extra='See the Transition Steam tab for specific requirements.'
            />
            <Visa186Holder
                visaHolderHeading='186 Visa holders can:'
                visaHolderContent1='work in Australia indefinitely'
                visaHolderContent2='study in Australia'
                visaHolderContent3='travel to and from Australia for 5 years'
                visaHolderContent4='enrol in Medicare (Australia’s public healthcare system)'
                visaHolderContent5='sponsor your relatives to come to Australia'
                visaHolderContent6='apply for Australian citizenship, if eligible'
            />
            <Visa186Eligibility
                whyUsHeadingMain='186 Visa General Eligibility'
                eligibilitysubHeading='You might be eligible to apply for the 186 Permanent Visa if you have an Australian employer who is willing to sponsor you and meet the following requirements:'
                whyUsHeading5='Experience'
                whyUsDesc5='Applicants must have at least 3 years of work experience in the nominated occupation.'
                whyUsDesc55='Part-time work may be considered. Casual work will not be considered.'
                whyUsHeading1='Sponsor'
                whyUsHeading2='Age'
                whyUsDesc1='You must be nominated by a business in Australia before lodging your visa'
                whyUsDesc2='The age limit is 45, unless exempt. See exemptions in specific stream tabs.'
                whyUsHeading3='Employer Nomination'
                whyUsHeading4='Length of Stay'
                whyUsDesc3='Applicants must be nominated by an Australian employer before applying for this visa and must apply within 6 months of the nomination being approved if the applications aren’t lodged together.'
                whyUsDesc31='The visa cannot be granted if:'
                whyUsDesc32='The nomination is withdrawn, or'
                whyUsDesc33='The position is no longer available'
                whyUsDesc4='This is a permanent visa, however, the 186 visa has a travel component that expires 5 years after the date of visa grant.'
                whyUsDesc41='After this time, applicants can apply for one of the below:'
                whyUsDesc42='Australian Citizenship'
                whyUsDesc43='Resident Return Visa'
            />
            <Visa186How
                EmpHowHeading='How it works'
                EmpHowImage={howitworks}
                EmpHowSubHead1='Step 1'
                EmpHowSubHead2='Step 2'
                EmpHowSubHead3='Step 3'
                EmpHowSubHead4='Step 4'
                EmpHowSubHead5='Step 5'
                EmpHowDesc1='You have an Australian employer who is willing to sponsor you.'
                EmpHowDesc2='Our migration agents liaise with you and your employer to collect documents.'
                EmpHowDesc3='We lodge your nomination and visa application with the Department.'
                EmpHowDesc4='We wait until a decision is made on your nomination and visa application.'
                EmpHowDesc5='You are expected to work for your employer for 2 years after approval.'
            />
            </div>
            <Footer />
        </>
    )
}

export default Visa186Overview