import React from 'react'
import "../styles/AboutUs187Visa.css"
import ConsultationComponent from './ConsultationComponent'
import HowComponent187 from './HowComponent187'
import Visa187Img from "../images/Visa187Img.png"
import Heading from './Heading'
import { useNavigate } from 'react-router-dom';

function AboutVisa() {
  const navigate = useNavigate();
  const handleMenuItemClick = (path) => {
    navigate(path);
  };
  return (
    <div>
        <Heading heading="The 187 visa allows you to" />
        <div className='AboutUsVisa_Box'>
      <li>  Stay in Australia permanently</li>
<li>Work and study in Australia</li>
<li>Enrol in Medicare (Australia’s public healthcare system)</li>
<li>Sponsor your relatives to come to Australia</li>
<li>Travel to and from Australia for 5 years</li>
<li>Apply for Australian Citizenship (If eligible)</li>

        </div>
        <div className='AboutUsVisa_box_fields'>
        <div className='AboutUsVisa_box_fields_content'>
          <div className='AboutUsVisa_box_title'>Include your family</div>
          <div className='AboutUsVisa_box_desc'>
          187 visa holders may be able to include the below family members to their application
<li>Partner</li>
<li>Dependent child</li>
<li>Partner’s dependent child</li>
Family members must meet the health and character requirements. If you already hold a permanent 187 (RSMS) Visa and you would like to bring your family to Australia, you will need to apply for one of the <u onClick={() =>{ handleMenuItemClick('/FamilyVisa'); window.scrollTo(0,0);}} style={{ color: '#B0383D', cursor: 'pointer',fontSize:"18px" }}>family visas.</u>
          </div>
        </div>
        <div className='AboutUsVisa_box_fields_content'>
        <div className='AboutUsVisa_box_title'>Length of Stay</div>
          <div className='AboutUsVisa_box_desc'>This is a permanent visa, however, the 187 visa has a travel component that expires 5 years after the date of visa grant.
After this time, applicants can apply for one of the below:
<li><u onClick={() =>{ handleMenuItemClick('/CitizenScreen'); window.scrollTo(0,0);}} style={{ color: '#B0383D', cursor: 'pointer',fontSize:"18px" }}>  Australian Citizenship</u></li>
<li>Resident Return Visa</li>
Speak to one of our registered migration agents to find out whether you may be eligible to apply for the 187 Visa.</div>
        </div>
        </div>
        <div className='AboutUsVisa_box_fields_content'>
        <div className='AboutUsVisa_box_title'>Employer Nomination</div>
          <div className='AboutUsVisa_box_desc'>Applicants must be nominated for by an Australian employer before applying for this visa and must apply within 6 months of the nomination being approved if the applications aren’t lodged together.
The visa cannot be granted if:
<li>The nomination is withdrawn, or</li>
<li>The position is no longer available</li></div>
        </div>

          <HowComponent187
                EmpHowHeading='How it works'

                EmpHowImage={Visa187Img}

                EmpHowSubHead1='Step 1'

                EmpHowSubHead2='Step 2'

                EmpHowSubHead3='Step 3'

                EmpHowSubHead4='Step 4'

                EmpHowSubHead5='Step 5'

                EmpHowDesc1='You have an Australian employer who is willing to sponsor you.'

                EmpHowDesc2='Our migration agents liaise with you and your employer to collect documents.'

                EmpHowDesc3='We lodge your nomination and visa application with the Department.'

                EmpHowDesc4='We wait until a decision is made on your nomination and visa application.'

                EmpHowDesc5='You are expected to work for your employer for 2 years after approval.'

            />
            <div className='ConsultationComponent_field'>
            <ConsultationComponent 
            title="Are You Eligible?"
            description="Take our online eligibility assessment to find out if you meet the requirements for the 482 Visa            "
            button="Check Eligibility"
            />
             <ConsultationComponent 
            title="Book A Consultation"
            description="Speak to one of our migration agents and simply book a consultation. We'll assist you in understanding the costs involved"
            button="Consult"
            />
             <ConsultationComponent
            title="Get A Quote"
            description="Have a visa in mind? Contact us for a fixed price quote. We'll assist you in understanding the costs involved.            "
            button="Get a quote"
            />
            </div>
    </div>
  )
}

export default AboutVisa