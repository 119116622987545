import React from 'react'
import "../styles/Tools.css"
import NavBarComponent from '../components/NavBarComponent'
import HeroBanner from '../components/HeroBanner'
import Herobanner1 from '../images/postcode.mp4';
import DottedLines from '../images/DottedLines.png'
import Footer from '../components/Footer';

function RmsPostCode() {
  return (
    <div>
          <NavBarComponent />
        <HeroBanner
        heroHeading='Post Codes'
        heroSubHeading='Our list of eligible RSMS postcodes for the Regional Sponsored Migration Scheme (RSMS) Subclass 187 Visa below'
        connectButton='Let’s Connect'
        Banner={[Herobanner1]}
        DottedLines={DottedLines}
      />
      <div className='Rms_layout'>
       <div className='Working_Title'>
        <div className='Working_Sub_Title'>The Working Holiday Visa allows citizens from eligible countries to come to Australia to work and travel for 12 months, with the possibility to extend their stay</div>
        <div className='Working_desc'>The Australian Government has announced changes to the Working Holiday Maker (WHM) visa program in an effort to support regional areas of Australia. Applicants may now be eligible to apply for a third working holiday visa, extending their stay for a total of three years.</div>
      </div>
      <div className='Table_layout'>
        <div className='Table_content'>
      <div className='table_head'>Countries</div>
        <div className='Table_column'>
            <div className='Table_container'>
            
            <div className='table_Title'>New South Wales</div>
            </div>
            <hr className='Divider'/>
            <div className='Table_container'>
            <div className='table_Title'>Victoria	</div>
            </div>
            <hr className='Divider'/>
            <div className='Table_container'>
            <div className='table_Title'>Queensland</div>
            
            </div>
            <hr className='Divider'/>
            <div className='Table_container'>
            <div className='table_Title'>Western Australia	</div>
            
            </div>
            <hr className='Divider'/>
            <div className='Table_container'>
            <div className='table_Title'>South Australia		</div>
            
            </div>
            <hr className='Divider'/>
            <div className='Table_container'>
            <div className='table_Title'>Tasmania	</div>
            
            </div>
            <hr className='Divider'/>
            <div className='Table_container'>
            <div className='table_Title'>Australian Capital Territory		</div>
            
            </div>
            <hr className='Divider'/>
            <div className='Table_container'>
            <div className='table_Title'>Northern Territory		</div>
            
            </div>
            <hr className='Divider'/>
            <div className='Table_container'>
            <div className='table_Title'>Norfolk Island	</div>
            
            </div>
            <hr className='Divider'/>
            <div className='Table_container'>
            <div className='table_Title'>Other Territories	</div>
            
            </div>
        </div>
        </div>
        <div className='Table_content'>
        <div className='table_head'>Cities and major regional centers</div>
        <div className='Table_column'>
        <div className='Table_container'>
        <div className='table_desc'>2259, 2264 to 2308, 2500 to 2526, 2528 to 2535, 2574</div>
        </div>
        <hr className='Divider'/>
        <div className='Table_container'>
        
        <div className='table_desc'>3211 to 3232, 3235, 3240, 3328, 3330 to 3333, 3340 and 3342</div>
        </div>
        <hr className='Divider'/>
        <div className='Table_container'>
        
        <div className='table_desc'>4207 to 4275, 4517 to 4519, 4550 to 4551, 4553 to 4562, 4564 to 4569 and 4571 to 4575</div>
        </div>
        <hr className='Divider'/>
        <div className='Table_container'>
        
        <div className='table_desc'>6000 to 6038, 6050 to 6083, 6090 to 6182, 6208 to 6211, 6214 and 6556 to 6558</div>
        </div>
        <hr className='Divider'/>
        <div className='Table_container'>
        
        <div className='table_desc'>5000 to 5171, 5173 to 5174, 5231 to 5235, 5240 to 5252, 5351 and 5950 to 5960</div>
        </div>
        <hr className='Divider'/>
        <div className='Table_container'>
        
        <div className='table_desc'>7000, 7004 to 7026, 7030 to 7109, 7140 to 7151 and 7170 to 7177</div>
        </div>
        <hr className='Divider'/>
        <div className='Table_container'>
        
        <div className='table_desc'>All postcodes in the Australian Capital Territory</div>
        </div>
        <hr className='Divider'/>
        <div className='Table_container'>
        
        <div className='table_desc'>None</div>
        </div>
        <hr className='Divider'/>
        <div className='Table_container'>
        
        <div className='table_desc'>None</div>
        </div>
        <hr className='Divider'/>
        <div className='Table_container'>
        
        <div className='table_desc'>None</div>
        </div>
       
       
        </div>
        </div>
        <div className='Table_content'>
        <div className='table_head'>Regional centers and other regional areas</div>
        <div className='Table_column'>
        <div className='Table_container'>
        <div className='table_desc'> 2250 to 2258, 2260 to 2263, 2311 to 2490, 2527, 2536 to 2551, 2575 to 2739, 2753 to 2754, 2756 to 2758 and 2773 to 2898</div>
        </div>
        <hr className='Divider'/>
        <div className='Table_container'>
      
        <div className='table_desc'> 3097 to 3099, 3139, 3233 to 3234, 3236 to 3239, 3241 to 3325, 3329, 3334, 3341, 3345 to 3424, 3430 to 3799, 3809 to 3909, 3912 to 3971 and 3978 to 3996</div>
        </div>
        <hr className='Divider'/>
        <div className='Table_container'>
      
        <div className='table_desc'>4124 to 4125, 4133, 4183 to 4184, 4280 to 4287, 4306 to 4498, 4507, 4552, 4563, 4570 and 4580 to 4895</div>
        </div>
        <hr className='Divider'/>
        <div className='Table_container'>
      
        <div className='table_desc'> All postcodes in Western Australia not mentioned in ‘Cities and major regional centres’ category above</div>
        </div>
        <hr className='Divider'/>
        <div className='Table_container'>
      
        <div className='table_desc'> None</div>
        </div>
        <hr className='Divider'/>
        <div className='Table_container'>
      
        <div className='table_desc'>All postcodes in Tasmania not mentioned in ‘Cities and major regional centres’ category above</div>
        </div>
        <hr className='Divider'/>
        <div className='Table_container'>
      
        <div className='table_desc'>None</div>
        </div>
        <hr className='Divider'/>
        <div className='Table_container'>
      
        <div className='table_desc'> All postcodes in the Northern Territory</div>
        </div>
        <hr className='Divider'/>
        <div className='Table_container'>
      
        <div className='table_desc'>All postcodes in Norfolk Island</div>
        </div>
        <hr className='Divider'/>
        <div className='Table_container'>
      
        <div className='table_desc'> All postcodes in a Territory other than the Australian Capital Territory, the Northern Territory or Norfolk Island</div>
        </div>
        </div>
        </div>
      </div>
      </div>
      <Footer />
    </div>
  )
}

export default RmsPostCode