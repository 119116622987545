import React from 'react'
import ShortTermBody from '../components/ShortTermBody'

const VisaShortTerm = () => {
    return (
        <>
            <ShortTermBody
                shortHeading='Short Term Stream'
                shortTermDesc='The short-term TSS (Subclass 482) Visa stream allows applicants to stay for up to 2 years or up to 4 if an International Trade Obligation (ITO) applies.'
                shortGeneralHead1='General'
                shortGeneralDesc1='Applicants must have a minimum of 2 years of work experience in the relevant field'
                shortGeneralDesc2='Occupation must be listed on the Short-Term (STSOL)'
                skills='Skilled Occupations List'
                shortGeneralDesc3='Applicants must meet genuine temporary entrant requirement'
                shortGeneralDesc4='This visa does not provide a transitional pathway to Permanent Residency'
                shortGeneralDesc5='Immediate family members may be included, with the support of the employer'
                shortGeneralHead2='English'
                shortGeneralDesc6='The primary applicant for the TSS Visa must meet the minimum English requirement.'
                shortGeneralDesc7='You will need to take an English test unless one of the following applies:'
                shortGeneralDesc8='you are a passport holder from Canada, Ireland, UK, USA or NZ'
                shortGeneralDesc9='you have completed at least 5 years of full-time study in at least a secondary level institution and where the medium of instruction was English.'
                shortGeneralDesc10='If you need to take an English test, you can take'
                here='Here'
                shortGeneralHead3='Extending Your Stay'
                shortGeneralDesc11='If your Short-term 482 (TSS) visa is expiring soon and you would like to continue working for your employer, you may be eligible to renew your visa for another 2 years.'
                // shortGeneralDesc111='If your Short-term 482 (TSS) visa is expiring soon and you would like to continue working for your employer, you may be eligible to renew your visa for another 2 years.'
                // shortGeneralDesc112='If your Short-term 482 (TSS) visa is expiring soon and you would like to continue working for your employer, you may be eligible to renew your visa for another 2 years.'
                shortGeneralDesc12='If you are already on your second 482 Temporary Skill Shortage Visa, you cannot renew. Generally, if your occupation is listed on the STSOL, you will have limited permanent visa options, however, you may find a pathway to permanent residency through you may find a pathway to permanent residency through '
                subclass='subclass'
                subClassHoverContent1='190 visa (State Nominated)'
                subClassHoverContent2='491 visa (Regional Sponsored)'
                subClassHoverContent3='494 visa (Employer-Sponsored Regional)'
                stayPeriodHead='Stay Period'
                stayPeriodContent='Short-Term TSS Visa holder can stay in Australia for: '
                stayPeriodContent1='2 years in most cases'
                stayPeriodContent2='4 years if an ITO applies but the maximum length will depend on the actual ITO relevant to your circumstances'
                stayPeriodContent3='5 years if you are a Hong Kong passport holder'
            />
        </>
    )
}

export default VisaShortTerm