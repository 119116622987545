import React, { useState } from 'react'
import Emp457Banner from "../images/Visa457Banner.mp4"
import DottedLines from '../images/DottedLines.png'
import NavBarComponent from '../components/NavBarComponent';
import HeroBanner from '../components/HeroBanner';
import howitworks from '../images/MetingInOffice.mp4'
import ConsultationComponent from '../components/ConsultationComponent';
import Footer from '../components/Footer';
import { XCircle } from "@phosphor-icons/react";
import EnglishModal from '../components/EnglishModal';
import VisaOption457Comp from '../components/VisaOption457Comp';
import VisaProvision457Comp from '../components/VisaProvision457Comp';
import PermanentEmpHow from '../components/PermanentEmpHow';
import Option1Overview from '../components/Option1Overview';
import Option2Overview from '../components/Option2Overview';
import Option3Overview from '../components/Option3Overview';
import Option4Overview from '../components/Option4Overview';

function Visa457Emp() {
    const [activeTab, setActiveTab] = useState('457Pr');

    const [isModalOpen2, setIsModalOpen2] = useState(false);
    const [isModalOpen3, setIsModalOpen3] = useState(false);
    const [isModalOpen4, setIsModalOpen4] = useState(false);

    const handleTabClick = tabName => {
      setActiveTab(tabName);
    };
  
  
    const openModal2 = () => {
      setIsModalOpen2(true);
    };
    const openModal3 = () => {
      setIsModalOpen3(true);
    };
    const openModal4 = () => {
      setIsModalOpen4(true);
    };
  
   
    const closeModal2 = () => {
      setIsModalOpen2(false);
    };
    const closeModal3 = () => {
      setIsModalOpen3(false);
    };
    const closeModal4 = () => {
      setIsModalOpen4(false);
    };
  const handleButtonClick = (onClick) => {
    if (typeof onClick === 'function') {
      onClick();
      openModal(); // Open modal on button click
    } else {
      console.error('Invalid onClick function provided');
    }
  };
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
      setIsModalOpen(true);
  };

  const closeModal = () => {
      setIsModalOpen(false);
  };

  return (
    <div>
         <NavBarComponent />
        <HeroBanner
       heroHeading='457 to PR (Permanent Residency)'
       heroSubHeading='There are a number of ways 457 visa holders can transition to permanent residency (457 to PR)'
       connectButton='Let’s Connect'
        Banner={[Emp457Banner]}
        DottedLines={DottedLines}
      />
        <div className="TabsContainer">
      <div
        className={`Tab ${activeTab === '457Pr' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('457Pr')}
      >
        457 to PR Visa Options
      </div>
      <div
        className={`Tab ${activeTab === '457Grandfathering' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('457Grandfathering')}
      >
       457 to PR Grandfathering Provisions
      </div>  
    </div>
    {activeTab === '457Pr' &&
       <div className='Tabs_container_layout'>
        
      <VisaOption457Comp />
      {isModalOpen && 
      <div className="modal-background">
                <div className='modalFinal'>
                    {/* <div style={{ textAlign: 'right', paddingTop: '16px', paddingRight: '16px' }}>
                        <XCircle onClick={closeModal} size={32} />
                    </div> */}
                    <Option1Overview closeModal={closeModal} />
                </div>
            </div>}
            {isModalOpen2 && <div className="modal-background">
                <div className='modalFinal'>
                    {/* <div style={{ textAlign: 'right', paddingTop: '16px', paddingRight: '16px' }}>
                        <XCircle onClick={closeModal2} size={32} />
                    </div> */}
                    <Option2Overview closeModal={closeModal2}/>
                </div>
            </div>}
            {isModalOpen3 && <div className="modal-background">
                <div className='modalFinal'>
                    {/* <div style={{ textAlign: 'right', paddingTop: '16px', paddingRight: '16px' }}>
                        <XCircle onClick={closeModal3} size={32} />
                    </div> */}
                    <Option3Overview closeModal={closeModal3}/>
                </div>
            </div>}
            {isModalOpen4 && <div className="modal-background">
                <div className='modalFinal'>
                    {/* <div style={{ textAlign: 'right', paddingTop: '16px', paddingRight: '16px' }}>
                        <XCircle onClick={closeModal4} size={32} />
                    </div> */}
                    <Option4Overview closeModal={closeModal4}/>
                </div>
            </div>}
        </div>
        }
         {activeTab === '457Grandfathering' &&
       <div className='Tabs_container_layout'>
       <VisaProvision457Comp />
       {isModalOpen && <div className="modal-background">
                <div className='modalFinal'>
                    {/* <div style={{ textAlign: 'right', paddingTop: '16px', paddingRight: '16px' }}>
                        <XCircle onClick={closeModal} size={32} />
                    </div> */}
                    <Option1Overview closeModal={closeModal}/>
                </div>
            </div>}
            {isModalOpen2 && <div className="modal-background">
                <div className='modalFinal'>
                    {/* <div style={{ textAlign: 'right', paddingTop: '16px', paddingRight: '16px' }}>
                        <XCircle onClick={closeModal2} size={32} />
                    </div> */}
                    <Option2Overview closeModal={closeModal2}/>
                </div>
            </div>}
            {isModalOpen3 && <div className="modal-background">
                <div className='modalFinal'>
                    {/* <div style={{ textAlign: 'right', paddingTop: '16px', paddingRight: '16px' }}>
                        <XCircle onClick={closeModal3} size={32} />
                    </div> */}
                    <Option3Overview closeModal={closeModal3}/>
                </div>
            </div>}
            {isModalOpen4 && <div className="modal-background">
                <div className='modalFinal'>
                    {/* <div style={{ textAlign: 'right', paddingTop: '16px', paddingRight: '16px' }}>
                        <XCircle onClick={closeModal4} size={32} />
                    </div> */}
                    <Option4Overview closeModal={closeModal4}/>
                </div>
            </div>}
      
        </div>
        }
         <PermanentEmpHow
        EmpHowHeading='How it works'
        EmpHowImage={howitworks}
        EmpHowSubHead1='Option 1'
        EmpHowSubHead2='Option 2'
        EmpHowSubHead3='Option 3'
        EmpHowSubHead4='Option 4'
        onClick1={openModal}
        onClick2={openModal2}
        onClick3={openModal3}
        onClick4={openModal4}
        EmpHowDesc1='You have been working for the same employer for two years.'
        learnMoreDesc=" Learn More"
        EmpHowDesc2='You have been working for the same employer for LESS than two. Learn More'
        EmpHowDesc3='Your employer is not willing to sponsor you for Permanent Residency. Learn More'
        EmpHowDesc4='Your partner is an Australian permanent resident or citizen. Learn More'
      />
        <div className='ConsultationComponent_field'>
        <ConsultationComponent containerColor="#FFF7F1"  
             textColor="#014A91" title="Other Sponsored Visas" 
        description="View other similar visa options that could be applicable to you."
        button="Check Eligibility"
        />
         <ConsultationComponent  containerColor="#FFF7F1"  
             textColor="#014A91" title="Book A Consultation" 
        description="Speak to one of our migration agents and simply book a consultation."
        button="Consult"
        />
         <ConsultationComponent  containerColor="#FFF7F1"  
             textColor="#014A91" title="Get A Quote" 
        description="Have a visa in mind? Contact us for a fixed price quote. We'll assist you in understanding the costs involved.        "
        button="Consult"
        />
        </div>
<Footer />
        
    </div>
  )
}

export default Visa457Emp