import React from 'react'
import '../styles/Pathway.css'

const WhyHope = (props) => {
    const containerStyle = {
        backgroundColor: props.containerColor || '#F4F4F4', 
        color: props.textColor || '#014A91', 
      };
    return (
        <>
            <div className='pathway-layout_oversea'>
               
                    <div className='patway-heading_Oversea' style={containerStyle}>{props.pathwayHeadingMain}</div>
                
                <div className='pathway-layout-one'>
                    <div className='pathway-sublayout-one'>
                       
                            <div className='pathway-questions_Oversea' style={containerStyle} >
                                <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }} >
                                    
                                <div className='pathway-desc_Oversea' >{props.pathwayDesc1}</div>
                                </div>
                            </div>
                       
                       
                            <div className='pathway-questions_Oversea' style={containerStyle}>
                                <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }} >
                                <div className='pathway-desc_Oversea'>{props.pathwayDesc2}</div>
                                   
                                </div>
                                
                            </div>
                     
                       
                            <div className='pathway-questions_Oversea' style={containerStyle}>
                                <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }} >
                                <div className='pathway-desc_Oversea'>{props.pathwayDesc3}</div>
                                   
                                </div>
                                
                              
                            </div>
                       
                    </div>
                    <div className='pathway-image'>
   {/* <video className='pathwayMapImage' autoPlay muted loop playsInline>
      <source className='pathway-map-image' src={props.pathwayImage} type='video/mp4' />
   </video> */}
   <img className='pathwayMapImage' src={props.pathwayImage} alt="" />
</div>
                </div>
            </div>
        </>
    )
}

export default WhyHope