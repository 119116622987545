import React from 'react'
import HowComponent187 from './HowComponent187'
import Visa187Img from "../images/Visa187Img.png"
import ConsultationComponent from './ConsultationComponent'
import Heading from './Heading'
import "../styles/FamilyVisa.css"

function Family187Visa() {
  return (
    <div>
      <Heading heading="Include your family in your Regional Sponsor Migration Scheme Subclass 187 Visa"/>
      <div className='container_layout'>
      <div className='VisaFamily_Box'>187 visa holders may be able to include the below family members to their application
      <li>Dependent children/step-children</li>
<li>Partner’s dependent child or children</li>
<li>Partner</li>

        </div>
        <div className='VisaFamily_Box_fields'>
          <div className='VisaFamily_Box_fields_container'>
          <div className='AboutUsVisa_box_title'>Work Experience</div>
          <div className='AboutUsVisa_box_desc'>
          Applicants over 18 must demonstrate Functional English. However, you can choose to pay an additional application fee to the department for family members who have less than functional English.
          </div>
          <hr className='Divider' />
          <div className='AboutUsVisa_box_title'>Age</div>
          <div className='AboutUsVisa_box_desc'>
          Children 23 years and over cannot be included in your application. (Unless there are exceptional circumstances)
                    </div>
          </div>
          <div className='VisaFamily_Box_fields_container'>
          <div className='AboutUsVisa_box_title'>Partners</div>
          <div className='AboutUsVisa_box_desc'>
          <li>Your married or de facto partner can be the same or opposite sex. Applicants must prove</li>
          <li>The relationship is genuine and continuing</li>
          <li>You live together, or do not live separately on a permanent basis.</li>
          <li>Your partner is at least 18 years of age when the application is lodged (there are some exceptions)</li>
          <li>You are not related by family (if you are in a de facto relationship)</li>
          <li>You and your partner have a mutual commitment to a shared life to the exclusion of all others</li>
          </div>

          </div>
          <div className='VisaFamily_Box_fields_container'>
          <div className='AboutUsVisa_box_title'>Health Character</div>
          <div className='AboutUsVisa_box_desc'>
          <li>All family members must meet the health and character requirements. All applicants over 16 must provide police clearances.</li>
          <li>If you already hold a permanent 187 (ENS) Visa and you would like to bring your family to Australia, you will need to apply for one of the family visas.</li>
          </div>
     
        </div>
        </div>
        <div className='VisaFamily_Box'>
        <div className='AboutUsVisa_box_title'>Children</div>
        <div className='VisaFamily_Box_desc'><li>To be eligible to be included or added to a 186 visa, a child must</li>
<li>Be your child or a stepchild from a current or a previous relationship (in certain circumstances)</li>
<li>Not be married, engaged to be married, or have a de facto partner; and must be:• under 18 years of age or</li>
<li>Over 18 years of age but not yet turned 23, and be dependent on you or your partner or</li>
<li>Over 23 years of age and be unable to earn a living to support themselves due to physical or cognitive limitations and be dependent on you or your partner (Note: The child will still need to meet Australia’s health requirement)or </li>
<li>A dependent child of a child who is eligible under 1, 2 or 3 above.</li></div>

        </div>
        </div>
          <HowComponent187
                EmpHowHeading='How it works'

                EmpHowImage={Visa187Img}

                EmpHowSubHead1='Step 1'

                EmpHowSubHead2='Step 2'

                EmpHowSubHead3='Step 3'

                EmpHowSubHead4='Step 4'

                EmpHowSubHead5='Step 5'

                EmpHowDesc1='You have an Australian employer who is willing to sponsor you.'

                EmpHowDesc2='Our migration agents liaise with you and your employer to collect documents.'

                EmpHowDesc3='We lodge your nomination and visa application with the Department.'

                EmpHowDesc4='We wait until a decision is made on your nomination and visa application.'

                EmpHowDesc5='You are expected to work for your employer for 2 years after approval.'

            />
            <div className='ConsultationComponent_field'>
            <ConsultationComponent 
            title="Are You Eligible?"
            description="Take our online eligibility assessment to find out if you meet the requirements for the 482 Visa            "
            button="Check Eligibility"
            />
             <ConsultationComponent 
            title="Book A Consultation"
            description="Speak to one of our migration agents and simply book a consultation. We'll assist you in understanding the costs involved"
            button="Consult"
            />
             <ConsultationComponent
            title="Get A Quote"
            description="Have a visa in mind? Contact us for a fixed price quote. We'll assist you in understanding the costs involved.            "
            button="Get a quote"
            />
            </div>
    </div>
  )
}

export default Family187Visa