import React from 'react'
import "../styles/IndividualVisa.css"

function MigrantComp(props) {
    const renderListItems = (desc) => {
        if (Array.isArray(desc)) {
          return (
            <>
              {desc.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </>
          );
        } else {
          return <p>{desc}</p>;
        }
      };
  return (
    <div className='IndividualWhat_layout'>
    <div className='Individual_Comp_Fields'>
      <div className='Individual_Comp_container'>
        <div className='Individual_Comp_container_box'>
          <div className='Individual_Comp_container_box_title'>{props.Title1}</div>
          <div className='Individual_Comp_container_desc'>
            {renderListItems(props.desc1)}
          </div>
        </div>
        <div className='Individual_Comp_container_box'>
          <div className='Individual_Comp_container_box_title'>{props.Title2}</div>
          <div className='Individual_Comp_container_desc'>
            {renderListItems(props.desc2)}
          </div>
        </div>
      </div>
      <div className='Individual_Comp_container'>
        <div className='Individual_Comp_container_box2'>
          <div className='Individual_Comp_container_box_title'>{props.Title3}</div>
          <div className='Individual_Comp_container_desc'>
            {renderListItems(props.desc3)}
          </div>
        </div>
        <div className='Individual_Comp_container_box2'>
          <div className='Individual_Comp_container_box_title'>{props.Title4}</div>
          <div className='Individual_Comp_container_desc'>
            {renderListItems(props.desc4)}
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default MigrantComp