import React, { useRef } from 'react';
import '../styles/Country.css'
import { CaretDown } from "@phosphor-icons/react";
import { useNavigate } from 'react-router-dom';

const Country = (props) => {
  const navigate = useNavigate();
  const countryRef = useRef(null);
  const handleButtonClick = (onClick) => {
    if (typeof onClick === 'function') {
      onClick();
    } else {
      console.error('Invalid onClick function provided');
    }
  };
  const handleHover = () => {
    const container = countryRef.current.parentNode;
    const scrollTo = countryRef.current.offsetTop - container.offsetTop;
    container.scrollTop = scrollTo;
  };

  return (
    <>
      <div className='country-layout' ref={countryRef} onMouseEnter={handleHover}>
        <div className='country-scroll' ref={countryRef} >
          <div className='country'>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingRight: '10px', cursor: 'pointer' }}>
              <div className='country-name'>{props.countryName1}</div>
              <div><CaretDown size={28} /></div>
            </div>
            <div className='row1'>
              <button className='info-button' type='button' onClick={() => handleButtonClick(props.onClick1)}>{props.infoButton1} </button>
              <button className='info-button' type='button' onClick={() => handleButtonClick(props.onClick2)}>{props.infoButton2}</button>
              <button className='info-button' type='button' onClick={() => handleButtonClick(props.onClick3)}>{props.infoButton3}</button>
              <button className='info-button' type='button' onClick={() => handleButtonClick(props.onClick4)}>{props.infoButton4}</button>
              <button className='info-button' type='button' onClick={() => handleButtonClick(props.onClick5)}>{props.infoButton5}</button>
              {/* <button className='info-button' type='button'>{props.infoButton6}</button>
              <button className='info-button' type='button'>{props.infoButton7}</button> */}
            </div>
          </div>
          <div className='country'>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingRight: '10px', cursor: 'pointer' }}>
              <div className='country-name'>{props.countryName2}</div>
              <div><CaretDown size={28} /></div>
            </div>
            <div className='row1'>
              <button className='info-button' type='button' onClick={() => handleButtonClick(props.onClick12)}>{props.infoButtonSec1}</button>
              <button className='info-button' type='button' onClick={() => handleButtonClick(props.onClick13)}>{props.infoButtonSec2}</button>
              <button className='info-button' type='button' onClick={() => handleButtonClick(props.onClick14)}>{props.infoButtonSec3}</button>
              <button className='info-button' type='button' onClick={() => handleButtonClick(props.onClick15)}>{props.infoButtonSec4}</button>
              <button className='info-button' type='button' onClick={() => handleButtonClick(props.onClick16)}>{props.infoButtonSec5}</button>
              {/* <button className='info-button' type='button' onClick={() => handleButtonClick(props.onClick16)}>{props.infoButtonSec6}</button> */}

              {/* <button className='info-button' type='button'>{props.infoButton6}</button>
              <button className='info-button' type='button'>{props.infoButton7}</button> */}
            </div>
          </div>
          <div className='country'>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingRight: '10px', cursor: 'pointer' }}>
              <div className='country-name'>{props.countryName3}</div>
              <div><CaretDown size={28} /></div>
            </div>
            <div className='row1'>
              <button className='info-button' type='button' onClick={() => handleButtonClick(props.onClick31)}>{props.infoButton31}</button>
              <button className='info-button' type='button' onClick={() => handleButtonClick(props.onClick32)}>{props.infoButton32}</button>
              <button className='info-button' type='button' onClick={() => handleButtonClick(props.onClick33)}>{props.infoButton33}</button>
              <button className='info-button' type='button' onClick={() => handleButtonClick(props.onClick34)}>{props.infoButton34}</button>
              <button className='info-button' type='button' onClick={() => handleButtonClick(props.onClick355)}>{props.infoButton355}</button>
              
            </div>
          </div>
          <div className='country'>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingRight: '10px', cursor: 'pointer' }}>
              <div className='country-name'>{props.countryName4}</div>
              <div><CaretDown size={28} /></div>
            </div>
            <div className='row1'>
              <button className='info-button' type='button' onClick={() => handleButtonClick(props.onClick41)} >{props.infoButton41}</button>
              <button className='info-button' type='button' onClick={() => handleButtonClick(props.onClick42)}>{props.infoButton42}</button>
              <button className='info-button' type='button' onClick={() => handleButtonClick(props.onClick43)}>{props.infoButton43}</button>
              {/* <button className='info-button' type='button'>{props.infoButton44}</button>
              <button className='info-button' type='button'>{props.infoButton45}</button> */}
              
            </div>
          </div>
          {/* <div className='country'>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingRight: '10px', cursor: 'pointer' }}>
              <div className='country-name'>{props.countryName5}</div>
              <div><CaretDown size={28} /></div>
            </div>            
            <div className='row1'>
              <button className='info-button' type='button'>{props.infoButton15}</button>
              <button className='info-button' type='button'>{props.infoButton25}</button>
              <button className='info-button' type='button'>{props.infoButton35}</button>
              <button className='info-button' type='button'>{props.infoButton45}</button>
              <button className='info-button' type='button'>{props.infoButton55}</button>

  
            </div>
          </div> */}
        </div>
      </div>
    </>
  )
}

export default Country
