import React from 'react'
import ConsultationComponent from './ConsultationComponent'
import HowComponent187 from './HowComponent187'
import Visa187Img from "../images/Visa187Img.png"
import Heading from './Heading'
import "../styles/eligibility.css"
import { Divider } from '@mui/material'
import { useNavigate } from 'react-router-dom';

function Eligibility() {
  const navigate = useNavigate();
  const handleMenuItemClick = (path) => {
    navigate(path);
  };
  return (
    <div>
     <Heading heading="You may be eligible to apply for a 187 (RSMS) permanent visa"/>
     <div className='AboutUsVisa_Box'>
     You may be able to apply for a Subclass 187 Visa through the direct entry stream if you meet the following requirements:

        </div>
        <div className='AboutUsVisa_box_fields'>
        <div className='AboutUsVisa_box_fields_content'>
          <div className='AboutUsVisa_box_title'>Work Experience</div>
          <div className='AboutUsVisa_box_desc'>
          You must have been working for your employer for at least 2 or 3 years, while holding a 457 Visa or 482 TSS Visa     </div>
          <hr className='Divider' />
          <div className='AboutUsVisa_box_title'>Age</div>
          <div className='AboutUsVisa_box_desc'>You are under 45 years of age or
you may be older than 45 years if you are exempt. <u onClick={() =>{ handleMenuItemClick('/VisaExemption'); window.scrollTo(0,0);}} style={{ color: '#B0383D', cursor: 'pointer',fontSize:"18px" }}>See 186 & 187 Visa exemptions </u></div>
        </div>
        <div className='AboutUsVisa_box_fields_content'>
        <div className='AboutUsVisa_box_title'>English</div>
          <div className='AboutUsVisa_box_desc'>You must provide evidence of competent English which can be evidenced by one of the below: 
<li>A valid passport from the United Kingdom, the United States of America, Canada, New Zealand or the Republic of Ireland. or</li>
<li> IELTS – Minimum score of 6 in each component</li>
<li>OET – Minimum score of B in each component</li>
<li>TOEFL iBT – Minimum scores of 12 for listening, 13 for reading, 21 for writing and 18 for speaking</li>
<li>PTE – Minimum score of 50 in each component or</li>
<li> 5 years of full-time study in a secondary or higher education institution where all tuition was delivered in English.</li>
</div>
        </div>
        </div>
        <div className='Eligibility_box_fields'>
        <div className='Eligibility_box_fields_content_Eligibility2'>
          <div className='AboutUsVisa_box_title'>Nomination</div>
          <div className='AboutUsVisa_box_desc'>
          You must be nominated by an Australian employer and apply for the 187 visa within 6 months of the nomination being approved (If the visa and nomination applications were not lodged at the same time) The visa will not be granted if the nomination is withdrawn. The Department does not link new nominations to existing visa applications.</div>
        </div>
        <div className='Eligibility_box_fields_content_Eligibility'>
        <div className='AboutUsVisa_box_title'>Health & Character</div>
          <div className='AboutUsVisa_box_desc'>Meet the health, character and age requirements (Unless you are exempt)
</div>
        </div>
        </div>

  <HowComponent187
                EmpHowHeading='How it works'

                EmpHowImage={Visa187Img}

                EmpHowSubHead1='Step 1'

                EmpHowSubHead2='Step 2'

                EmpHowSubHead3='Step 3'

                EmpHowSubHead4='Step 4'

                EmpHowSubHead5='Step 5'

                EmpHowDesc1='You have an Australian employer who is willing to sponsor you.'

                EmpHowDesc2='Our migration agents liaise with you and your employer to collect documents.'

                EmpHowDesc3='We lodge your nomination and visa application with the Department.'

                EmpHowDesc4='We wait until a decision is made on your nomination and visa application.'

                EmpHowDesc5='You are expected to work for your employer for 2 years after approval.'

            />
            <div className='ConsultationComponent_field'>
            <ConsultationComponent 
            title="Are You Eligible?"
            description="Take our online eligibility assessment to find out if you meet the requirements for the 482 Visa            "
            button="Check Eligibility"
            />
             <ConsultationComponent 
            title="Book A Consultation"
            description="Speak to one of our migration agents and simply book a consultation. We'll assist you in understanding the costs involved"
            button="Consult"
            />
             <ConsultationComponent
            title="Get A Quote"
            description="Have a visa in mind? Contact us for a fixed price quote. We'll assist you in understanding the costs involved.            "
            button="Get a quote"
            />
            </div>
    </div>
  )
}

export default Eligibility