import React from 'react'
import '../styles/OurTeam.css'
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CaretDown, CaretUp } from "@phosphor-icons/react";
const ToggleableCaret = ({ isOpen }) => {
    return isOpen ? <CaretUp size={28} /> : <CaretDown size={28} />;
};
const OurTeam = (props) => {
    const [openAccordion, setOpenAccordion] = useState(1);

    const toggleAccordion = (accordionNumber) => {
        setOpenAccordion(openAccordion === accordionNumber ? null : accordionNumber);
    };
    const navigate = useNavigate();
    const handleMenuItemClick = (path) => {
      navigate(path);
    };
    return (
        <>
            <div className='ourteam-layout'>
                <div className='team-layout-one'>
                    <div className='team-main-heading'>
                        <div className='team-heading'>{props.teamHeading}</div>
                    </div>
                    <div className='team-main-image'>
                        <img className='our-team' src={props.ourTeam} alt='team' />
                    </div>
                </div>
                <div className='team-layout-two'>
                    <div className='team-main-heading1'>
                        <div className='team-subheading'>{props.teamSubHeading1}</div>
                        <div className='team-sub-desc'>{props.teamSubDesc1}</div>
                    </div>
                    <div className='team-main-heading1'>
                        <div className='team-subheading1'>{props.teamSubHeading2}</div>
                        <div className='team-sub-desc'>{props.teamSubDesc2}</div>
                    </div>
                    <div className='team-main-heading1'>
                        <div className='team-subheading1'>{props.teamSubHeading3}</div>
                        <div className='team-sub-desc'>{props.teamSubDesc3}</div>
                    </div>
                </div>
                <div className='team-layout-two-main'>
                    {[1].map((accordionNumber) => (
                        <div className='team-main-heading1' key={accordionNumber}>
                            <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }} >
                                <div className='pathway-sublayout-ques'>{props.teamSubHeading1}</div>
                                {/* <div><ToggleableCaret isOpen={openAccordion === accordionNumber} /></div> */}
                            </div>
                            {openAccordion === accordionNumber && (
                                <>
                                    <div className='team-sub-desc'>{props.teamSubDesc1}</div>
                                </>
                            )}
                        </div>
                    ))}
                    {/* {[2].map((accordionNumber) => ( */}
                        <div className='team-main-heading1'>
                            <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }} >
                                <div className='pathway-sublayout-ques'>{props.teamSubHeading2}</div>
                                {/* <div><ToggleableCaret isOpen={openAccordion === accordionNumber} /></div> */}
                            </div>
                            {/* {openAccordion === accordionNumber && ( */}
                                <>
                                    <div className='team-sub-desc'>{props.teamSubDesc2}</div>
                                </>
                            {/* )} */}
                        </div>
                    
                    {/* {[3].map((accordionNumber) => ( */}
                        <div className='team-main-heading1' >
                            <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }} >
                                <div className='pathway-sublayout-ques'>{props.teamSubHeading3}</div>
                                {/* <div><ToggleableCaret isOpen={openAccordion === accordionNumber} /></div> */}
                            </div>
                            {/* {openAccordion === accordionNumber && ( */}
                                <>
                                    <div className='team-sub-desc'>{props.teamSubDesc3}</div>
                                </>
                            {/* )} */}
                        </div>
                    {/* ))} */}
                </div>
            </div>
        </>
    )
}

export default OurTeam