import React from 'react'
import Herobanner1 from '../images/OurServicesBanner.mp4';
import DottedLines from '../images/DottedLines.png'
import NavBarComponent from '../components/NavBarComponent'
import HeroBanner from '../components/HeroBanner';
import pathwaymapImage from "../images/OurServcesImg.png"
import WhyHope from '../components/WhyHope';
import WhyUs from '../components/WhyUs';
import Footer from '../components/Footer';
import OurTeam from '../components/OurTeam'
import ourTeam from '../images/ServicesBanner.png'
import EnglishComp from '../components/EnglishComp';

function Services() {
  return (
    <div>
        <NavBarComponent />
        <HeroBanner
        heroHeading='Our Services'
        heroSubHeading='At Hope Education & Migration, we offer a wide range of services to help you achieve your education and migration goals. Here are some of the services we offer.'
        connectButton='Let’s Connect'
        Banner={[Herobanner1]}
        DottedLines={DottedLines}
      />
       <OurTeam
        teamHeading='Education Services'
        ourTeam={ourTeam}
        teamSubHeading1='Course Selection'
        teamSubHeading2='Application Assistance'
        teamSubHeading3='Student Visa Support'
        teamSubDesc1='Our education consultants can help you choose the right course and university based on your interests, academic background, and career goals.'
        teamSubDesc2='We can assist you with your university application, ensuring that you meet all the necessary requirements and deadlines.'
        teamSubDesc3='We can provide guidance and support with the student visa application process, ensuring that you have all the necessary documents and meet the visa requirements.'
      />
       <WhyHope
        pathwayHeadingMain='Migration Services'
        textColor="rgba(176, 56, 61, 1)"
        containerColor="rgba(255, 247, 241, 1)"
        pathwayDesc1='At Hope Education & Migration, we are committed to providing personalized and professional services to help students achieve their goals of studying abroad. Our experienced team of consultants has a deep understanding of the education systems in Canada, Australia, and the USA and can provide tailored guidance and support based on each student’s unique needs.'
        pathwayDesc2='We also offer a wide range of resources and support services, including language testing and training, job placement services, and cultural orientation. Our goal is to provide students with a comprehensive and seamless experience from start to finish.'
        pathwayDesc3='Contact Us Today If you are interested in studying in Canada, Australia, or the USA and would like more information about our services, please contact us today. Our team of consultants would be happy to answer any questions you may have and help you get started on your journey to study in abroad.'
        pathwayImage={pathwaymapImage}
        visaFinderButton=''
      />
      <EnglishComp/>
       <WhyUs
        whyUsHeadingMain='Our Process'
        whyUsHeading3='03.Initial Consultation'
        whyUsHeading4='04.Assessment'
        whyUsHeading5='05.Customized Plan'
        whyUsHeading2='02.Implementation'
        whyUsHeading1='01.Ongoing Support'
        whyUsDesc3='During the initial consultation, we will discuss your goals, preferences, and circumstances to get a better understanding of your needs. We will also provide you with information about our services and answer any questions you may have.'
        whyUsDesc4='Based on the information gathered during the initial consultation, our consultants will assess your eligibility for different visa options or courses of study.'
        whyUsDesc5='We will work with you to develop a customized plan that meets your needs and goals. This plan will include the services we will provide, as well as the fees and charges associated with those services.'
        whyUsDesc2='Once the plan has been developed, we will begin implementing it, providing you with the necessary guidance and support every step of the way.'
        whyUsDesc1='We will continue to provide you with ongoing support throughout the process, answering your questions and providing you with the assistance you need to achieve your goals.'
      />
      <Footer />
    </div>
  )
}

export default Services