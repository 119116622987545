import React, { useEffect, useState } from "react";
import { imgDB,txtDB } from "../config/fireBase";
import { v4 } from "uuid";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { addDoc, collection, deleteDoc, doc, getDocs } from "firebase/firestore";
import {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    signInWithPopup,
    signOut,
  } from "firebase/auth";
  import HopeLogo from '../images/HopeLogo.png';
  
  import { auth, googleProvider } from "../config/fireBase";
import BlogBanner from "../images/ManUsingLap.mp4"
import DottedLines from '../images/DottedLines.png'
import NavBarComponent from '../components/NavBarComponent'
import HeroBanner from '../components/HeroBanner'
import Familyimg from "../images/Flag.mp4"
import Familyimg1 from "../images/WomenOnlineWriting.mp4"
import Familyimg2 from "../images/BlogWomenHandFilling.mp4"
import BlogHeadComp from '../components/BlogHeadComp'
import "../styles/Blog.css"
import "../styles/contactUs.css"
import aish from "../images/Banner.png"
import Footer from '../components/Footer'
import { useNavigate, useParams } from "react-router-dom";
import { CaretLeft ,SignOut, Trash} from '@phosphor-icons/react'
import { CircularProgress } from "@mui/material";
import HeroAdmin from "../components/HeroAdmin";
import { ToastContainer ,toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Links(props) {
    const navigate = useNavigate();
    const[user,setUser] =useState(null);
    const [display, setDisplay] = useState(false);   
      const [txt, setTxt] = useState('');
      const [link, setLink] = useState('');
      const [showDeleteModal, setShowDeleteModal] = useState(false);
const [deleteLinkId, setDeleteLinkId] = useState(null);

      const [links, setLinks] = useState([]);
      const logout = async () => {
        try {
            setUser();
          await signOut(auth);
          console.log(auth);
          window.scrollTo(0,0);
          navigate("/");
          setDisplay(false);

        } catch (err) {
          console.error(err);
        }
      };
      const handleNavigateHome = () => {
        window.scrollTo(0,0);
        navigate("/");
      }
      const handleClickLink = async (e) => {
        e.preventDefault();
    
        if (txt.trim() !== '' && link.trim() !== '') {
          try {
            const linkRef = collection(txtDB, 'linkDB');
            await addDoc(linkRef, { title: txt, linkUrl: link });
            fetchLinks();
            setTxt('');
            setLink('');
            toast.success("added" ,{ autoClose: 10 })
          } catch (error) {
            console.error('Error adding data:', error);
          }
        } else {
          toast.error('Please fill in all fields before adding data.' ,{ autoClose: 10 });
        }
      };
    
      const fetchLinks = async () => {
        try {
          const linkRef = collection(txtDB, 'linkDB');
          const snapshot = await getDocs(linkRef);
    
          const linksData = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
    
          setLinks(linksData);
        } catch (error) {
          console.error('Error fetching links:', error);
        }
      };
    
      useEffect(() => {
        fetchLinks();
      }, []);
      const handleDeleteLink = async (id) => {
        try {
          const valRef = doc(txtDB, 'linkDB', id);
          await deleteDoc(valRef);
          await fetchLinks();
          setShowDeleteModal(false);
          toast.success("Data deleted successfully" ,{ autoClose: 10 });
        } catch (error) {
          console.error(error);
        }
      };
      const openDeleteModal = (id) => {
        setDeleteLinkId(id);
        setShowDeleteModal(true);
      };
      
      
  return (
    <div className="link_main_layout">
      {/* <ToastContainer position="top-center"/> */}
       {/* <div className='navbar'>
                <div className='logo'>
                    <img className='HopeLogo' src={HopeLogo} alt='Logo' onClick={handleNavigateHome}/>
                </div>
                
                <div onClick={logout}  >LogOut <SignOut /></div>
            </div> */}
        {/* <HeroAdmin
        heroHeading='Blog Links'
        heroSubHeading='Our registered migration agents regularly share new, tips and tricks and other interesting information for migrants. '
        // connectButton='Let’s Connect'
        Banner={[BlogBanner]}
        DottedLines={DottedLines}
        /> */}
       <div>
       {/* <div onClick={() => navigate("/BlogAdmin")} className="back_btn">
          <CaretLeft onClick={() => navigate("/BlogAdmin")} /> Back
          </div> */}
<div className='Conatct_us_container_link'>
<div className='Link_container_left2'>Add Links</div>
<div className='Link_container_right2'>
<form className='help-screen-form'>
        <div>Title</div>
        <input type="text" name="title" className='help-form-textField' required onChange={(e) => setTxt(e.target.value)} />
        <div >Link</div>
        <input type="text" name="link" className='help-form-textField' required onChange={(e) => setLink(e.target.value)} />
        <button className="family_header_desc_btn_admin" type="submit" onClick={handleClickLink}>
          Add
        </button>
      </form>
</div>
    </div>
</div>
<div >
<div className='Conatct_us_container_link'>
<div className='Link_container_left'>Links</div>
<div className='Link_container_right'>
      <ul>
        {links.map(link => (
          <li key={link.id} className="Links_conatiner_fields">
            <div className="admin_links_head">
            <div style={{display:"flex" ,flexDirection:"column",gap:"24px"}}>
         <div className="label_head"><span>{link.title}</span></div>
         <div className="label_head">{link.linkUrl}</div>
         </div>
         <div><Trash onClick={() => openDeleteModal(link.id)} size={24} color="#000"/></div>
         </div>
         <hr className='Divider' />
          </li>
          
        ))}
        
      </ul>
      {showDeleteModal && (
  <div className="modal-overlay">
    <div className="delete-modal">
      <p style={{fontSize:"18px"}}>Are you sure you want to delete this Link?</p>
      <div className="sure_btns">
        <button className="sure_ok" onClick={() => handleDeleteLink(deleteLinkId)}>Ok</button>
        <button className="sure_cancel" onClick={() => setShowDeleteModal(false)}>Cancel</button>
      </div>
    </div>
  </div>
)}

      </div>
      </div>
      </div>

        {/* <Footer /> */}
    </div>
  )
}
export default Links