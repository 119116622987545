import React from 'react'
import OverSeaVideo from "../images/WhychooseEducation.mp4"
import "../styles/OverseaWhy.css"
function OverSeaWhyComp() {
  return (
    <div className='OverSeaLayout'>
        <div className='Oversea_Why_Title'>Why Choose Hope Education & Migration?</div>
        <div className='Oversea_Why_Content'>
            <div>
            <video className='OverSeaWhyVideo' autoPlay muted loop playsInline>
                            <source src={OverSeaVideo} type='video/mp4' />
                        </video>
            </div>
            <div className='OverSea_des'>
                <div className='OverSea_box'>At Hope Education & Migration, we are committed to providing personalized and professional services to help students achieve their goals of studying abroad. Our experienced team of consultants has a deep understanding of the education systems in Canada, Australia, and the USA and can provide tailored guidance and support based on each student’s unique needs.</div>

                <div className='OverSea_box'>We also offer a wide range of resources and support services, including language testing and training, job placement services, and cultural orientation. Our goal is to provide students with a comprehensive and seamless experience from start to finish.</div>

                <div className='OverSea_box'>Contact Us Today If you are interested in studying in Canada, Australia, or the USA and would like more information about our services, please contact us today. Our team of consultants would be happy to answer any questions you may have and help you get started on your journey to studying abroad.</div>
                </div>
        </div>

    </div>
  )
}

export default OverSeaWhyComp