import React, { useState } from 'react'
import Herobanner1 from '../images/WomenHandFilling.mp4';
import DottedLines from '../images/DottedLines.png'
import HeroBanner from '../components/HeroBanner';
import NavBarComponent from '../components/NavBarComponent';
import Footer from '../components/Footer';
import Heading from '../components/Heading';
function VisaWorkingHoliday() {
    const [activeTab, setActiveTab] = useState('Overview');

    const handleTabClick = tabName => {
      setActiveTab(tabName);
    };
  return (
    <div>
         <NavBarComponent />
        <HeroBanner
        heroHeading='Working Holiday Visa (Subclass 417)'
        heroSubHeading='Below visas will give you the option of temporarily migrating to Australia to live and work. Please note that you must meet the respective eligibility criteria.'
        connectButton='Let’s Connect'
        Banner={[Herobanner1]}
        DottedLines={DottedLines}
      />
        <div className="TabsContainer">
      <div
        className={`Tab ${activeTab === 'Overview' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('Overview')}
      >
        Overview
      </div>
      <div
        className={`Tab ${activeTab === 'Eligibility' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('Eligibility')}
      >
        Eligibility
      </div>
      <div
        className={`Tab ${activeTab === 'Counties' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('Counties')}
      >
       Eligibility Counties
      </div>
      <div
        className={`Tab ${activeTab === 'Family' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('Family')}
      >
        Including Family
      </div>
      </div>
      {activeTab === 'Overview' &&
       <div className='Tabs_container_layout'>
      
        <div className='WorkingHoliday_Layout'>
        <Heading heading="Working Holiday Visa" containerColor="rgba(255, 247, 241, 1)" textColor="rgba(186, 81, 85, 1)"/>
     <div className='Working_Title'>
        <div className='Working_Sub_Title'>The Working Holiday Visa allows citizens from eligible countries to come to Australia to work and travel for 12 months, with the possibility to extend their stay</div>
      </div>
      <div className='Working_Title'>
        <div className='Working_desc'>Working Holiday Visa holders can:
        <li>Do short-term work in Australia for up to 6 months per employer</li>
        <li>Study for up to 4 months</li>
        <li>Travel to and from Australia as many times as you want</li>
        <li>Do specified work to become eligible for a second and soon to be third Working Holiday visa</li>
You may be able to apply for a second and third working holiday (417) visa if you have completed the specified work.

You may also be eligible to apply for another more permanent visa if you meet the requirements:

<li>189 Visa</li>
<li>190 Visa</li>
<li>187 Visa (RSMS Visa)</li>
<li>186 Visa (ENS Visa)</li>
<li>Student Visa</li>
<li>Partner Visa</li>
<li>Employer Sponsored Visa</li>


    </div>
     </div>
     <div className='Working_Title'>
        <div className='Working_Sub_Title'>Changes coming soon</div>
        <div className='Working_desc'>The Australian Government has announced changes to the Working Holiday Maker (WHM) visa program in an effort to support regional areas of Australia. Applicants may now be eligible to apply for a third working holiday visa, extending their stay for a total of three years.
    </div>
     </div>

     </div>
      
        </div>
        }
         {activeTab === 'Eligibility' &&
         <div className='Tabs_container_layout'>
            <div className='WorkingHoliday_Layout'>
            <Heading heading="Working Holiday Visa" containerColor="rgba(255, 247, 241, 1)" textColor="rgba(186, 81, 85, 1)"/>
            <div className='Working_Title'>
        <div className='Working_Sub_Title'>To apply for a Working Holiday Visa you must meet the following criteria</div>
     </div>
     <div className='Working_Title'>
     <div className='Working_desc'>You are aged 18 – 30 years – Unless you are a Canadian or Irish citizen (You can apply up to 35 years)
If you are applying for your second working holiday visa, you must have completed 3 months of specified work on your first Working Holiday visa
    </div>
     </div>
            </div>

         </div>

         }
         {activeTab === 'Counties' && 
 <div className='Tabs_container_layout'>
     <div className='WorkingHoliday_Layout'>
            <Heading heading="Working Holiday Visa" containerColor="rgba(255, 247, 241, 1)" textColor="rgba(186, 81, 85, 1)"/>
            <div className='Working_Title'>
        <div className='Working_Sub_Title'>You may be able to apply for a Working Holiday Visa if you are a citizen of the following countries</div>
     </div>
     <div className='Working_Title'>
     <div className='Working_desc'><li>Belgium</li>
<li>Canada</li>
<li>Republic of Cyprus</li>
<li>Denmark</li>
<li>Estonia</li>
<li>Finland</li>
<li>France</li>
<li>Germany</li>
<li>Hong Kong Special Administrative Region of the People’s Republic of China (including British National Overseas passport holders)</li>
<li>Republic of Ireland</li>
<li>Italy</li>
<li>Japan</li>
<li>Republic of Korea</li>
<li>Malta</li>
<li>Netherlands</li>
<li>Norway</li>
<li>Sweden</li>
<li>Taiwan (other than an official or diplomatic passport)</li>
<li>The United Kingdom of Great Britain and Northern Ireland</li>
    </div>
     </div>
            </div>
    </div>
         }
             {activeTab === 'Family' && 
 <div className='Tabs_container_layout'>
     <div className='WorkingHoliday_Layout'>
            <Heading heading="Working Holiday Visa" containerColor="rgba(255, 247, 241, 1)" textColor="rgba(186, 81, 85, 1)"/>
           
     <div className='Working_Title'>
     <div className='Working_desc'>
        <li>You cannot include any family members in your application.</li>
        <li>You cannot be accompanied by dependent children while you are in Australia</li>
    </div>
     </div>
            </div>
    </div>
         }

      <Footer />
    </div>
  )
}

export default VisaWorkingHoliday