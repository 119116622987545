import React from 'react'
import "../styles/VisaBookNow.css"
function VisaBookNow(props) {
  return (
    <div className='VisaBookNow_Layout'>
        <div className='VisaBookNow_Conatiner'>
            <div><img src={props.img} alt="" className='Visa_Book_now_img'/></div>
            <div className='VisaBookNow_Content'>
                <div className='VisaBookNow_Title'>{props.Title} </div>
                <div className='VisaBookNow_Desc'>{props.Desc} </div>
                {/* <div className='VisaBookNow_Btn'>{props.Btn} </div> */}
            </div>

        </div>
    </div>
  )
}

export default VisaBookNow