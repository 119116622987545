import React from 'react'
import Navbar from '../components/Navbar'
import PermanentEmpBanner from '../components/PermanentEmpBanner'
import DottedLines from '../images/DottedLines.png'
import Passport from '../images/passport.png'
import Footer from '../components/Footer'
import Visa186Requirements from '../components/Visa186Requirements'
import howitworks from '../images/howitworks.png'
import Visa186How from '../components/Visa186How'

const Visa186Entry = () => {
  return (
    <>
      <Navbar />
      <PermanentEmpBanner
        heroHeading='Employer Nomination Scheme ENS (Subclass 186 Visa)'
        connectButton='Let’s Connect'
        Banner={Passport}
        DottedLines={DottedLines}
      />
      <Visa186Requirements
        whyUsHeadingMain='Meet the following requirements:'
        whyUsHeading5='Age'
        whyUsDesc5='You must be under 45 years of age when you apply for this visa.'
        whyUsDesc51='You may be older than 45 years if you are;'
        whyUsDesc52='You are nominated as an academic by an Australian University'
        whyUsDesc53='You are nominated as a scientist, researcher or technical specialist by a Scientific Government Agency'
        whyUsDesc54='You hold a New Zealand Subclass 444 or 461 Visa and have been working for your nominated employer for two years.'
        whyUsHeading1='Experience'
        whyUsHeading2='Health & Character'
        whyUsDesc1='You must have at least 3 years of relevant work experience'
        whyUsDesc2='Meet the health and character requirements (Unless you are exempt). This requires police and medical checks.'
        whyUsHeading3='English'
        whyUsHeading4='Nomination'
        whyUsDesc3='The primary applicant for the 186 Visa must have Competent English.
        You will need to take an English test unless one of the following applies:
        You are a passport holder from Canada, Ireland, UK, USA or NZ
        If you need to take an English test, you can take one of the tests:'
        whyUsDesc42='You must be nominated by an Australian employer and apply for the 186 visa within 6 months of the nomination being approved (If the visa and nomination applications were not lodged at the same time)'
        whyUsDesc43='The visa will not be granted if the nomination is withdrawn. The Department does not link new nominations to existing visa applications.'
      />
      <Visa186How
        EmpHowHeading='How it works'
        EmpHowImage={howitworks}
        EmpHowSubHead1='Step 1'
        EmpHowSubHead2='Step 2'
        EmpHowSubHead3='Step 3'
        EmpHowSubHead4='Step 4'
        EmpHowSubHead5='Step 5'
        EmpHowDesc1='You have an Australian employer who is willing to sponsor you.'
        EmpHowDesc2='Our migration agents liaise with you and your employer to collect documents.'
        EmpHowDesc3='We lodge your nomination and visa application with the Department.'
        EmpHowDesc4='We wait until a decision is made on your nomination and visa application.'
        EmpHowDesc5='You are expected to work for your employer for 2 years after approval.'
      />
      <Footer />
    </>
  )
}

export default Visa186Entry