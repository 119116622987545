import React, { useState } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../config/fireBase';
import login from "../images/Passport.mp4"
import '../styles/LoginScreen.css'; 
import LoginNavBar from '../components/LoginNavBar';
import Blog from './Blog';
import { Visibility, VisibilityOff } from "@mui/icons-material";
import DisplayPage from '../components/DisplayPage';
import Adminpanel from '../components/Adminpanel';
import BlogAdmin from '../components/BlogAdmin';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import "../styles/login2.css"
import { ToastContainer ,toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import loginLogo from "../images/HopeLogo2.png"

function Login2() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [display, setDisplay] = useState(false);
    const [user, setUser] = useState(null);
    const [showPassword, setShowPassword] = useState(false); 
    const signIn = async () => {
      try {
        if (email !== 'hope@gmail.com') {
          toast.error('Not Allowed');
          return;
        }
        const response = await signInWithEmailAndPassword(auth, email, password);
        console.log(response);
        setUser(response?.user?.email);
        // alert('Logged in');
        toast.success('Logged in' ,{ autoClose: 10 });
        setDisplay(true);
      } catch (err) {
        // alert('Could not log in');
        toast.error('Could not log in', { autoClose: 10 });
        console.error(err);
      }
    };
    
    const handleTogglePassword = () => {
      setShowPassword(!showPassword);
    };
    const handleSubmit = async (event) => {
      event.preventDefault();
      signIn();
      
    };
    if (user) {
      return <BlogAdmin />;
    }
  return (
    <div className='login2_container'>
                <ToastContainer position="top-center"/>

        <div className='login_left'>
            <img src={loginLogo} alt="" />
        </div>
        <div className='login_right'>
        {!user ? (
      <div >
      <form onSubmit={handleSubmit} className='login2_form'>
        <h2> Admin Login</h2>
        <div className="input-group">
            <div className='label'>Email address</div>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="input-group">
        <div className='label'>Password</div>
          {/* <input
            type="password"
            placeholder="Password"
            value={password}
            type={showPassword ? 'text' : 'password'}
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              endAdornment: passwordEntered && (
                <IconButton onClick={handleTogglePassword}>
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              ),
            }}
          /> */}
          <TextField
      className='textfield'
      placeholder="Password"
      type={showPassword ? 'text' : 'password'}
      value={password}
      onChange={(e) => setPassword(e.target.value)}
      InputProps={{
        endAdornment: password ? (
          <InputAdornment position="end">
            <IconButton onClick={handleTogglePassword} edge="end">
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ) : null,
      }}
    />
          
        </div>
        <button className='btn_login' onClick={signIn}>Login</button>
        </form>
      </div>) : display ? (
       <DisplayPage user={user} setUser={setUser}/>
       ) : (
        <>
           <Adminpanel user={user} setUser={setUser} />
          
          </>
      )}
        </div>
    </div>
  )
}

export default Login2