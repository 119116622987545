import React from 'react'
import '../styles/Option1Family.css'

const Option2Family = () => {
  return (
    <>
      <div className='EligibilitymodalLayout'>
        <div className='OptionOverviewStyling'>
          <div className='OptionOverviewHeading'>Option 2: Direct Entry Stream (DE)</div>
          <div className='OptionOneContentBox1'>
            <div className='OptionOneFamHead'>General</div>
            <div className='OptionOneFamDesc'>
              <div>Applicants can include the following people in their transitional permanent residency application:</div>
              <li>Dependent children/step-children</li>
              <li>Partner</li>
            </div>
          </div>

          <div className='OptionOneContentBox-layout'>
            <div className='OptionOneContentBox2'>
              <div className='OptionOneFamHead'>Partners</div>
              <div className='OptionOneFamDesc'>
                <li>Your married or de facto partner can be the same or opposite sex. Applicants must prove:</li>
                <li>The relationship is genuine and continuing</li>
                <li>You live together, or do not live separately on a permanent basis.</li>
                <li>Your partner is at least 18 years of age when the application is lodged (there are some exceptions)</li>
                <li>You are not related by family (if you are in a de facto relationship)</li>
                <li>You and your partner have a mutual commitment to a shared life to the exclusion of all others</li>
              </div>
            </div>
            <div className='OptionOneContentBox2'>
              <div className='OptionOneFamHead'>English</div>
              <div className='OptionOneFamDesc'>
                <div>Applicants over 18 must demonstrate Functional English. You can demonstrate Functional English without IELTS if you:</div>
                <li>Completed all years of primary education and at least three years of secondary education in an educational institution in or outside Australia and all instructions were in English or</li>
                <li>Completed at least five years of secondary education in an institution in or outside Australia and all instructions were in English.</li>
              </div>
            </div>
          </div>

          <div className='OptionOneContentBox'>
            <div className='OptionOneFamHead'>Children</div>
            <div className='OptionOneFamDesc'>
              <div>To be eligible to be included or added to a TSS visa, a child must:</div>
              <li>Be your child or a stepchild from a current or a previous relationship (in certain circumstances);</li>
              <li>Not be married, engaged to be married, or have a de facto partner; and must be:</li>
              <li>Under 18 years of age or</li>
              <li>Over 18 years of age but not yet turned 23, and be dependent on you or your partner or</li>
              <li>Over 23 years of age and be unable to earn a living to support themselves due to physical or cognitive limitations and be dependent on you or your partner (Note: The child will still need to meet Australia’s health requirement)or</li>
              <li>A dependent child of a child who is eligible under 1, 2 or 3 above.</li>
            </div>
          </div>

          <div className='OptionOneContentBox'>
            <div className='OptionOneFamHead'>Health & Character</div>
            <div className='OptionOneFamDesc'>
              <li>All family members must meet the health and character requirements. All applicants over 16 must provide police clearances.</li>
              <li>If you have any questions about these requirements or would like more information on who you can include on your 457 to PR application speak to one of our migration experts</li>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Option2Family