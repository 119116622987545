import React, { useState } from 'react'
import DottedLines from '../images/DottedLines.png'
import CitizenBanner from "../images/TwoPartner.mp4"
import NavBarComponent from '../components/NavBarComponent'
import HeroBanner from '../components/HeroBanner'
import Heading from '../components/Heading'
import Footer from '../components/Footer'
import "../styles/IndividualVisa.css"
import ConsultationComponent from '../components/ConsultationComponent'
import MigrantComp from '../components/MigrantComp'

function CitizenScreen() {
    const [activeTab, setActiveTab] = useState('Eligibility');
    const handleTabClick = tabName => {
      setActiveTab(tabName);
    };
  return (
    <div>
         <NavBarComponent/>
        <HeroBanner
        heroHeading='Australian Citizenship'
        heroSubHeading='To be eligible to apply for Australian Citizenship, you must meet application criteria depending on your living situation in Australia.'
        connectButton='Let’s Connect'
        Banner={[CitizenBanner]}
        DottedLines={DottedLines}
        />
          <div className="TabsContainer">
  <div
    className={`Tab ${activeTab === 'Eligibility' ? 'isActive' : ''}`}
    onClick={() => handleTabClick('Eligibility')}
  >
    Eligibility
  </div>
  <div
    className={`Tab ${activeTab === 'Migrant' ? 'isActive' : ''}`}
    onClick={() => handleTabClick('Migrant')}
  >
   Migrant/ Permanent Resident
  </div>
  <div
    className={`Tab ${activeTab === 'Partners' ? 'isActive' : ''}`}
    onClick={() => handleTabClick('Partners')}
  >
   Partners of Australian Citizen
  </div>
  <div
    className={`Tab ${activeTab === 'NZ' ? 'isActive' : ''}`}
    onClick={() => handleTabClick('NZ')}
  >
   NZ Citizen in Australia
  </div>
  </div>
  {activeTab === 'Eligibility' &&
  <div className='Tabs_container_layout'>
    <Heading heading="ELIGIBILITY" containerColor="rgba(255, 247, 241, 1)" textColor="rgba(176, 56, 61, 1)" />
    <div className='LabourAgreement_extrayLay'>
      <div className='LabourAgreement_section-container_title'>
        To be eligible to apply for Australian Citizenship, you must meet application criteria depending on your living situation in Australia
      </div>
      <div className='LabourAgreement_section-container_desc_extrayLay'>
        To become an Australian Citizen, there are a number of requirements you need to meet in order to be eligible to apply.
        Specific application criteria will depend on your Australian Residency; however, the below requirements are applicable to all applications.
        <li>You must be of good character</li>
        <li>You must meet the general residence requirement</li>
        <li>You must have an adequate knowledge of the responsibilities and privileges of Australian citizenship</li>
        <li>You must intend to reside or maintain a close and continuing association with Australia.</li>
        You may fall into one of the below categories:
        <li>Migrant with permanent residence</li>
        <li>New Zealand citizen living in Australia</li>
        <li>Spouse or partner of an Australian citizen</li>
      </div>
    </div>
  </div>
}
{activeTab === 'Migrant' &&
        <div className='Tabs_container_layout'>
             <Heading heading="MIGRANT/ PERMANENT RESIDENT" containerColor="rgba(255, 247, 241, 1)" textColor="rgba(176, 56, 61, 1)"/>   
             <MigrantComp
             Title1="General"
             Title2="Residence Requirement"
             Title3="Children"
             Title4="Calculate Your Permanent Residency"
             desc1={["If you are a migrant with Permanent Residency, you must meet the below requirements to be eligible to apply for Australian Citizenship:",
             "You must be a permanent resident at the time of application and time of decision",
             " You must meet the residency requirements",
             "You must be of good character",
             "You must demonstrate basic knowledge of the English language",
             "You must intend to live or maintain a close and continuing association with Australia",
             "You must have an adequate knowledge of the responsibilities and privileges of Australian citizenship.(Applicants over 60 years of age are not required to sit the citizenship test.)"
            ]}
             desc2={["You must have lived in Australia for four years immediately before applying while holding a valid Australian visa",
            "You must have been a permanent resident for at least 12 months immediately applying",
            "You must not have been absent from Australia for more than one year in total, during the four year period",
            "You must not have been absent from Australia for more than 90 days in the 12 months before applying."
            
            
            ]}
             desc3="Children under 16 years of age can be included in your citizenship application if they are permanent residents.Children under 16 years of age must be permanent residents but do not need to meet the residency requirements."
             desc4="If you were granted a permanent residency before arriving in Australia, your permanent residence date will be the date that you arrived in Australia on that visa.If you were granted a permanent residency after you arrived in Australia, your permanent residence date will be your permanent visa grant date"
              />
        </div>
    }
     {activeTab === 'Partners' &&
       <div className='Tabs_container_layout'>
         <Heading heading="PARTNER OF AUSTRALIAN CITIZEN" containerColor="rgba(255, 247, 241, 1)" textColor="rgba(176, 56, 61, 1)"/>
         <MigrantComp
             Title1="General"
             Title2="Residence Requirement"
             Title3="Children"
             Title4="Calculate Your Permanent Residency"
             desc1={["If you are a Spouse or Partner of an Australian Citizen, you must meet the below requirements to be eligible to apply for Australian Citizenship:",
             "You must be a permanent resident at the time of application and time of decision",
             " You must meet the residency requirements",
             "You must be of good character",
             "You must demonstrate basic knowledge of the English language",
             "You must have an adequate knowledge of the responsibilities and privileges of Australian citizenship",
             " You must intend to live or maintain a close and continuing association with Australia.(Applicants over 60 years of age are not required to sit the citizenship test.)"
            ]}
             desc2={["You must have lived in Australia for four years immediately before applying while holding a valid Australian visa",
            " You must have been a permanent resident for the 12 months immediately applying",
            "You must not have been absent from Australia for more than one year in total, during the four year period",
            "You must not have been absent from Australia for more than 90 days in the 12 months before applying."
            
            
            ]}
             desc3="Children under 16 years of age can be included in your citizenship application if they are permanent residents.Children under 16 years of age must be permanent residents but do not need to meet the residency requirements."
             desc4="If your permanent visa was granted before you arrived in Australia, your permanent residence date is the date that you arrived in Australia on that visa.
             If your permanent visas granted after you arrived in Australia, your permanent residence date will be the date your permanent visa was granted"
              />
       </div>
    }
    {activeTab === 'NZ' &&
        <div className='Tabs_container_layout'>
         <Heading heading="NZ CITIZEN IN AUSTRALIA" containerColor="rgba(255, 247, 241, 1)" textColor="rgba(176, 56, 61, 1)"/>
         <div className='IndividualWhat_layout'>
         <div className='Individual_Comp_Fields'>
         <div className='Individual_Comp_container'>
        <div className='Individual_Comp_container_box'>
          <div className='Individual_Comp_container_box_title'>General</div>
          <div className='Individual_Comp_container_desc'>
          A New Zealand Citizen living in Australia must meet the below requirements to be eligible to apply for Australian Citizenship:
         <li>You must hold a permanent visa, or are a ‘protected SCV’ holder under the Social Security Act 1991</li>
         <li>You must meet the residence requirement</li>
         <li>You must be of good character (if you are over 18 years)</li>
         <li>Eligible protected SCV holders include those who:</li>
         <li>were in Australia on 26 February 2001 on a Special Category Visa (SCV); or</li>
         <li>were in Australia for 12 months in the 2 years immediately before this date; or</li>
have been assessed as protected SCV holders (have a Centrelink certificate stating that they were living in Australia on a particular date). • New Zealand Citizens are automatically be granted a Special Category visa (subclass 444) upon arriving in Australia. This is not a permanent visa. Unless you have applied for another Australian visa, you will hold this temporary visa.
          </div>
        </div>
        <div className='Individual_Comp_container_box'>
          <div className='Individual_Comp_container_box_title'>Residence Requirement</div>
          <div className='Individual_Comp_container_desc'>
          • You must have lived in Australia for four years immediately before applying while holding a valid Australian visa • You must have been a permanent resident for the 12 months immediately applying • You must not have been absent from Australia for more than one year in total, during the four year period • You must not have been absent from Australia for more than 90 days in the 12 months before applying
          </div>
        </div>
      </div>
      </div>
      </div>
        </div>
    }
       <div className='ConsultationComponent_field'>
            <ConsultationComponent 
            title="Pointes test"
            description="Use our online points calculator to find out if you score the minimum points required to apply for a skilled visa.  "
            button="Let’s do it"
            />
             <ConsultationComponent 
            title="Book A Consultation"
            description="If you would like to discuss your Australian visa eligibility with a registered migration agent, book your consultation now."
            button="Consult"
            />
             <ConsultationComponent
            title="Get A Quote"
            description="Know what visa you want to apply for but need some pricing? Get in touch with us and we will get back to you with a fixed price quote. "
            button="Get a quote"
            />
            </div>
    <Footer/>




    </div>
  )
}

export default CitizenScreen