import React from 'react'
import "../styles/service.css"
import serviceImg from "../images/serviceImg.png"

function Service() {
  return (
    <div className='service_migration_container'>
        <div className='service_migration_head'>
            <div className='service_migration_title'>
            Our Services for Migration & Immigration to Australia Include:
            </div>

        </div>
     
        <div className='service_migration_body'>
      <div style={{display:"flex" ,flexDirection:"column" ,gap:"24px"}}>
            <div className='service_migration_desc'>
               
                <div className='service_migration_desc_sections'>
                    <div className='service_migration_desc_sections_title'>Consultation & Assessment</div>
                    <div className='service_migration_desc_sections_desc'>Our consultants assess your eligibility for Australian immigration, considering qualifications, work experience, education, and language proficiency. Receive transparent advice and program recommendations tailored to your needs.</div>

                </div>
                <div className='service_migration_desc_sections'>
                    <div className='service_migration_desc_sections_title'>Visa Application Assistance</div>
                    <div className='service_migration_desc_sections_desc'>Once we have determined the best immigration program for you, we will assist you with preparing and submitting your visa application, ensuring that all required documents are included and that your application is completed accurately and efficiently.</div>

                </div>
                <div className='service_migration_desc_sections'>
                    <div className='service_migration_desc_sections_title'>Pre- Departure Services</div>
                    <div className='service_migration_desc_sections_desc'>Prepare for your move to Australia with our pre-departure services. Receive guidance on travel arrangements, housing options, banking, healthcare, and education. We ensure you are well-equipped for a smooth transition to your new life Down Under.</div>

                </div>
                <div className='service_migration_desc_sections'>
                    <div className='service_migration_desc_sections_title'>Post Arrival Services</div>
                    <div className='service_migration_desc_sections_desc'>Ease into your new life in Australia with our post-arrival services. We assist with obtaining a Tax File Number (TFN), job search, language class enrollment, and connection to community resources. Navigate the transition confidently with our support.</div>
                </div>
                </div>
               
            
            <div className='service_migration_desc_sections_end'>
                    <div className='service_migration_desc_sections_title'>Business Migration Services</div>
                    <div className='service_migration_desc_sections_desc_end'>We also offer business migration services for individuals and families interested in starting or investing in a business in Australia. Our team of experienced business migration consultants can provide guidance and support for business visa applications, business plans, market research, and business establishment services.</div>

                </div>
                </div>
            <div className='service_migration_img'>
                <img src={serviceImg} alt="" className='service_img'/>

            </div>

        </div>
     
        <div className='service_migration_conclution'>
       <div className='service_migration_conclution_text'> At Hope Education & Migration, we are committed to providing our clients with personalized and professional migration and immigration services to Australia. Contact us today to schedule a consultation with one of our experienced migration and immigration consultants and take the first step towards your new life in Australia.
       </div>
        </div>


    </div>
  )
}

export default Service