import React, { useState } from 'react'
import "../styles/SkilledVisa.css"
import Business14 from "../images/Business14.mp4"
import DottedLines from '../images/DottedLines.png'
import HeroBanner from '../components/HeroBanner'
import NavBarComponent from '../components/NavBarComponent'
import { useNavigate } from 'react-router-dom';
import SkilledComp1 from '../components/SkilledComp1'
import SkilledComp2 from '../components/SkilledComp2'
import SkilledComp3 from '../components/SkilledComp3'
import SkilledComp4 from '../components/SkilledComp4'
import Footer from '../components/Footer'
function SkilledVisa() {
    const [activeTab, setActiveTab] = useState('Visa189');
    const handleTabClick = tabName => {
      setActiveTab(tabName);
    };
    const navigate = useNavigate();

    const handleNavigation189Visa = () => {
        navigate("/SkilledVisa189");
    };
    const handleNavigation491Visa = () => {
        navigate("/Visa491");
    };
 const handleNavigation191Visa =() =>{
    navigate("/Visa191")
 };
 const handleNavigation190Visa = () => {
    navigate('/Visa190')
 };
 const handleNavigation489Visa = () =>{
    navigate('/Visa489')
 }
  return (
    <div>
          <NavBarComponent/>
          <HeroBanner
   heroHeading='Skilled Visas Australia'
   heroSubHeading='The General Skilled Migration program is aimed at highly skilled workers (Subclass 189/190/491/191)'
   connectButton='Let’s Connect'
    Banner={[Business14]}
    DottedLines={DottedLines}
  />
         
         <div className='sub-nav-container'>
            <div onClick={handleNavigation189Visa} className='sub_nav_container'>189 Visa</div>
            <div onClick={handleNavigation491Visa} className='sub_nav_container'>491 Visa</div>
            <div onClick={handleNavigation191Visa} className='sub_nav_container'>191 Visa</div>
            <div onClick={handleNavigation190Visa} className='sub_nav_container'>190 Visa</div>
            <div onClick={handleNavigation489Visa} className='sub_nav_container'>489 Visa</div>
        </div>
          <div className="TabsContainer">
      <div
        className={`Tab ${activeTab === 'Visa189' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('Visa189')}
      >
        189 Visa
      </div>
      <div
        className={`Tab ${activeTab === 'Visa190' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('Visa190')}
      >
       190 Visa
      </div>
      <div
        className={`Tab ${activeTab === 'Visa489' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('Visa489')}
      >
      489 Visa
      </div>
      <div
        className={`Tab ${activeTab === 'Visa491' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('Visa491')}
      >
      491 Visa
      </div>
      <div
        className={`Tab ${activeTab === 'Visa191' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('Visa191')}
      >
      191 Visa
      </div>
    
          </div>
    {activeTab === 'Visa189' &&
       <div className='Tabs_container_layout'>
        <SkilledComp1 
        Title="General Skilled Migration"
        Title2="Work and study in Australia permanently and sponsor family"
        content2="The 189 Visa is a points-tested permanent visa for skilled workers who are not sponsored by an Australian employer, family member or nominated by a state or territory. Applicants must obtain a minimum of 65 points on the point assessment to be eligible to apply for the Skilled Independent visa (Subclass 189). To apply for this visa, an applicant must submit an expression of interest (EOI) and be invited by SkillSelect. Upon receiving an invitation to apply for this visa, you have 60 days to submit your application. Once the Skilled Independent (Subclass 189) Visa has been granted, the visa holder and accompanying family members are are able to live, work and study in Australia as permanent residents."
        content1="The Skilled Visa to Australia is for skilled individuals or families looking to permanently migrate to Australia, to fill Australia’s skill shortages. Visas within the General Skilled Migration program are points tested and are either independent, State / Territory sponsored or family-sponsored permanent visas."
        />
        <SkilledComp2 
        Head="What is your situation?"
        Title="I am not sponsored by anyone"
        Desc="Points-tested permanent visa for skilled workers who are not sponsored by an Australian employer, family member or nominated by a state or territory."
        Title2="State will sponsor me"
        Desc2="The Skilled Nominated Visa is a points-tested permanent visa for skilled workers who are nominated by an Australian State or an Australian Territory government."
        Title3="My family will sponsor me"
        Desc3="The Skilled Regional Visa is designed to encourage skilled workers to live and work is specified regional parts of Australia for up to five years."
        Title4="Employer will sponsor me"
        Desc4="Employer Sponsored Visas allow skilled overseas workers to live and work in Australia for their nominating Australian employer. Visa applicants must be qualified."
        Title5="I am a New Zealand citizen"
        Desc5="Visa holders who have been contributing to the Australian economy can apply for Permanent Residency. If granted this visa they can apply for Australian Citizenship after 1 year."
        />
        <SkilledComp3 
        Head="What is your situation?"
        Title="Is my skill on the skilled occupation list?"
        Desc="To meet the relevant criteria for some skilled visas you must have an occupation that is listed."
        Title2="How many points do I score in the points test?"
        Desc2="You must score a minimum of 65 points to be eligible. Use our calculator to find out if you qualify."
        Title3="Where can I find more info on invitation rounds?"
        Desc3="Invitation rounds for General Skilled Migration run once a month. Have a look at the current round and past rounds."
        />
        <SkilledComp4 
        head="Can’t find what you are looking for?"
        Title="Not sure what visa you could be eligible for?"
        Desc="Australian visas can be confusing, especially if you might have more than one option. Use our Visa Finder to view your visa options."
        btn="Consult"
        Title2="Want to speak to someone about your case?"
        Desc2="Not sure where to go from here or if you would even be eligible for an Australian Visa? Speak to an experienced migration agent today."
        btn2="Lets Do it"
        
        />

       </div>
     }
     {activeTab === 'Visa190' &&
       <div className='Tabs_container_layout'>
 <SkilledComp1 
        Title="General Skilled Migration"
        Title2="Points-tested permanent visa for skilled workers who are nominated by an Australian State or Territory government"
        content2="The Skilled Nominated Visa is a points-tested permanent visa for skilled workers who are nominated by an Australian State or Territory government. Applicants must obtain a minimum of 65 points on a points assessment to be eligible to apply for a Skilled Nominated Visa (Subclass 190) and must be nominated by a participating State or Territory government in Australia. To apply for this visa, you must submit an expression of interest (EOI) and be invited by SkillSelect."
        content1="The Skilled Visa to Australia is for skilled individuals or families looking to permanently migrate to Australia, to fill Australia’s skill shortages. Visas within the General Skilled Migration program are points tested and are either independent, State / Territory sponsored or family-sponsored permanent visas."
       
        />
        <SkilledComp2 
        Head="What is your situation?"
        Title="I am not sponsored by anyone"
        Desc="Points-tested permanent visa for skilled workers who are not sponsored by an Australian employer, family member or nominated by a state or territory."
        Title2="State will sponsor me"
        Desc2="The Skilled Nominated Visa is a points-tested permanent visa for skilled workers who are nominated by an Australian State or an Australian Territory government."
        Title3="My family will sponsor me"
        Desc3="The Skilled Regional Visa is designed to encourage skilled workers to live and work is specified regional parts of Australia for up to five years."
        Title4="Employer will sponsor me"
        Desc4="Employer Sponsored Visas allow skilled overseas workers to live and work in Australia for their nominating Australian employer. Visa applicants must be qualified."
        Title5="I am a New Zealand citizen"
        Desc5="Visa holders who have been contributing to the Australian economy can apply for Permanent Residency. If granted this visa they can apply for Australian Citizenship after 1 year."
        />
        <SkilledComp3 
        Head="What is your situation?"
        Title="Is my skill on the skilled occupation list?"
        Desc="To meet the relevant criteria for some skilled visas you must have an occupation that is listed."
        Title2="How many points do I score in the points test?"
        Desc2="You must score a minimum of 65 points to be eligible. Use our calculator to find out if you qualify."
        Title3="Where can I find more info on invitation rounds?"
        Desc3="Invitation rounds for General Skilled Migration run once a month. Have a look at the current round and past rounds."
        />
       </div>
     }
     {activeTab === 'Visa489' &&
       <div className='Tabs_container_layout'>
 <SkilledComp1 
        Title="General Skilled Migration"
         Title2="The 489 visa is closed and has been replaced by the 491 visa"
        content1="The Skilled Visa to Australia is for skilled individuals or families looking to permanently migrate to Australia, to fill Australia’s skill shortages. Visas within the General Skilled Migration program are points tested and are either independent, State / Territory sponsored or family-sponsored permanent visas."     
        />
        <SkilledComp2 
        Head="What is your situation?"
        Title="I am not sponsored by anyone"
        Desc="Points-tested permanent visa for skilled workers who are not sponsored by an Australian employer, family member or nominated by a state or territory."
        Title2="State will sponsor me"
        Desc2="The Skilled Nominated Visa is a points-tested permanent visa for skilled workers who are nominated by an Australian State or an Australian Territory government."
        Title3="My family will sponsor me"
        Desc3="The Skilled Regional Visa is designed to encourage skilled workers to live and work is specified regional parts of Australia for up to five years."
        Title4="Employer will sponsor me"
        Desc4="Employer Sponsored Visas allow skilled overseas workers to live and work in Australia for their nominating Australian employer. Visa applicants must be qualified."
        Title5="I am a New Zealand citizen"
        Desc5="Visa holders who have been contributing to the Australian economy can apply for Permanent Residency. If granted this visa they can apply for Australian Citizenship after 1 year."
        />
        <SkilledComp3 
        Head="What is your situation?"
        Title="Is my skill on the skilled occupation list?"
        Desc="To meet the relevant criteria for some skilled visas you must have an occupation that is listed."
        Title2="How many points do I score in the points test?"
        Desc2="You must score a minimum of 65 points to be eligible. Use our calculator to find out if you qualify."
        Title3="Where can I find more info on invitation rounds?"
        Desc3="Invitation rounds for General Skilled Migration run once a month. Have a look at the current round and past rounds."
        />
       </div>
     }
      {activeTab === 'Visa491' &&
       <div className='Tabs_container_layout'>
 <SkilledComp1 
        Title="General Skilled Migration"
        Title2="Come to Australia and live in a regional area for up to five years"
        content2="The Skilled Regional Visa is designed to encourage skilled workers to live and work is specified regional parts of Australia for up to five years. Applicants must be sponsored by an Australian relative who is living in a designated area, or by a State or Territory government. "
        content1="The Skilled Visa to Australia is for skilled individuals or families looking to permanently migrate to Australia, to fill Australia’s skill shortages. Visas within the General Skilled Migration program are points tested and are either independent, State / Territory sponsored or family-sponsored permanent visas."     
        
        />
        <SkilledComp2 
        Head="What is your situation?"
        Title="I am not sponsored by anyone"
        Desc="Points-tested permanent visa for skilled workers who are not sponsored by an Australian employer, family member or nominated by a state or territory."
        Title2="State will sponsor me"
        Desc2="The Skilled Nominated Visa is a points-tested permanent visa for skilled workers who are nominated by an Australian State or an Australian Territory government."
        Title3="My family will sponsor me"
        Desc3="The Skilled Regional Visa is designed to encourage skilled workers to live and work is specified regional parts of Australia for up to five years."
        Title4="Employer will sponsor me"
        Desc4="Employer Sponsored Visas allow skilled overseas workers to live and work in Australia for their nominating Australian employer. Visa applicants must be qualified."
        Title5="I am a New Zealand citizen"
        Desc5="Visa holders who have been contributing to the Australian economy can apply for Permanent Residency. If granted this visa they can apply for Australian Citizenship after 1 year."
        />
        <SkilledComp3 
        Head="What is your situation?"
        Title="Is my skill on the skilled occupation list?"
        Desc="To meet the relevant criteria for some skilled visas you must have an occupation that is listed."
        Title2="How many points do I score in the points test?"
        Desc2="You must score a minimum of 65 points to be eligible. Use our calculator to find out if you qualify."
        Title3="Where can I find more info on invitation rounds?"
        Desc3="Invitation rounds for General Skilled Migration run once a month. Have a look at the current round and past rounds."
        />
       </div>
     }
     {activeTab === 'Visa191' &&
       <div className='Tabs_container_layout'>
 <SkilledComp1 
        Title="General Skilled Migration"
        Title2="This Visa will allow Subclass 494 and 491 visa holders to transition to permanent residency"
        content2="The subclass 191 skilled regional visa will be open to new applications in November 2022."
        content1="The Skilled Visa to Australia is for skilled individuals or families looking to permanently migrate to Australia, to fill Australia’s skill shortages. Visas within the General Skilled Migration program are points tested and are either independent, State / Territory sponsored or family-sponsored permanent visas."     
       
        />
        <SkilledComp2 
        Head="What is your situation?"
        Title="I am not sponsored by anyone"
        Desc="Points-tested permanent visa for skilled workers who are not sponsored by an Australian employer, family member or nominated by a state or territory."
        Title2="State will sponsor me"
        Desc2="The Skilled Nominated Visa is a points-tested permanent visa for skilled workers who are nominated by an Australian State or an Australian Territory government."
        Title3="My family will sponsor me"
        Desc3="The Skilled Regional Visa is designed to encourage skilled workers to live and work is specified regional parts of Australia for up to five years."
        Title4="Employer will sponsor me"
        Desc4="Employer Sponsored Visas allow skilled overseas workers to live and work in Australia for their nominating Australian employer. Visa applicants must be qualified."
        Title5="I am a New Zealand citizen"
        Desc5="Visa holders who have been contributing to the Australian economy can apply for Permanent Residency. If granted this visa they can apply for Australian Citizenship after 1 year."
        />
        <SkilledComp3 
        Head="What is your situation?"
        Title="Is my skill on the skilled occupation list?"
        Desc="To meet the relevant criteria for some skilled visas you must have an occupation that is listed."
        Title2="How many points do I score in the points test?"
        Desc2="You must score a minimum of 65 points to be eligible. Use our calculator to find out if you qualify."
        Title3="Where can I find more info on invitation rounds?"
        Desc3="Invitation rounds for General Skilled Migration run once a month. Have a look at the current round and past rounds."
        />
       </div>
     }
     <Footer />
    </div>
  )
}

export default SkilledVisa