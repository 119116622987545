import React from 'react'
import PermanentEmpOv from '../components/PermanentEmpOv'

function PermEmpOverview(props) {
  return (
    <div>
         <PermanentEmpOv
        empOVHeading='The Subclass 186 visa and 187 Visas have three streams:'
        empOVsubHead1='DIRECT ENTRY STREAM:'
        empOVsubHead2='AGREEMENT STREAM:'
        empOVsubHead3='TRANSITIONAL STREAM:'
        empOVDesc1='For applicants who are formally qualified or have sufficient experience in an eligible occupation and location.'
        empOVDesc2='For applicants who are sponsored by an employer under a labour agreement.'
      /> 
    </div>
  )
}

export default PermEmpOverview