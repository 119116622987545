import React from 'react'
import NavBarComponent from '../components/NavBarComponent'
import HeroBanner from '../components/HeroBanner'
import DottedLines from '../images/DottedLines.png'
import Herobanner1 from '../images/aerial.mp4';
import Herobanner2 from '../images/BannerVideo2.mp4';
import Heading from '../components/Heading';
import "../styles/VisaFinder.css"
import { useNavigate } from 'react-router-dom';
import Footer from '../components/Footer';
import ConsultationComponent from '../components/ConsultationComponent';
import ConsultCompVisa from '../components/ConsultCompVisa';

function VisaFinderLandingScreen() {
    const navigate = useNavigate();
    const handleButtonClick1 = () => {
        window.scrollTo(0,0);
        navigate("/LwTemporarily");
    
      };
      const handleButtonClick2 = () => {
        window.scrollTo(0,0);
        navigate("/LwPermanently");
    
      };
      const handleButtonClick3 = () => {
        window.scrollTo(0,0);
        navigate("/JoinFamily");
    
      };
      const handleButtonClick4 = () => {
        window.scrollTo(0,0);
        navigate("/StudyDevelop");
    
      };
      const handleButtonClick5 = () => {
        window.scrollTo(0,0);
        navigate("/VisitWork");
    
      };
      const handleButtonClick6 = () => {
        window.scrollTo(0,0);
        navigate("/");
    
      };
  return (
    <div>
     <NavBarComponent />
      <HeroBanner
        heroHeading='Visa Finder'
        heroSubHeading='Australian visa option can be confusing, simply select what you would like to do in Australia and we will show you your visa options.'
        connectButton='Let’s Connect'
        Banner={[Herobanner1, Herobanner2]}
        DottedLines={DottedLines}
      />
      <div className='Visa_finder_layout'>
      <div className='Consult_layout'>
      <Heading heading="Your Australian aspirations?" containerColor="rgba(255, 247, 241, 1)" textColor="rgba(176, 56, 61, 1)"/>
        <div className='ConsultationComponent_field' style={{marginTop:"24px"}}>
            <ConsultCompVisa
            containerColor="rgba(255, 247, 241, 1)"
            title="Live & Work Temporarily"
            description="Take our online eligibility assessment to find out if you meet the requirements for the 482 Visa "
            button="View options"
            onClick1={handleButtonClick1}
            />
             <ConsultCompVisa 
             containerColor="rgba(255, 247, 241, 1)"
            title="Live & Work Permanently"
            description="Speak to one of our migration agents about the 482 Visa. Simply book a consultation."
            button="View options"
            onClick1={handleButtonClick2}
            />
             <ConsultCompVisa
             containerColor="rgba(255, 247, 241, 1)"
            title="Join Family Members"
            description="Have a visa in mind? Contact us for a fixed price quote. We'll assist you in understanding the costs involved."
            button="View options"
            onClick1={handleButtonClick3}
            />
            </div> 
            <div className='ConsultationComponent_field' style={{marginTop:"24px"}}>
            <ConsultCompVisa
            containerColor="rgba(255, 247, 241, 1)"
            title="Study and Develop"
            description="Take our online eligibility assessment to find out if you meet the requirements for the 482 Visa"
            button="View options"
            onClick1={handleButtonClick4}
            />
             <ConsultCompVisa 
             containerColor="rgba(255, 247, 241, 1)"
            title="Visit and Work"
            description="Speak to one of our migration agents about the 482 Visa. Simply book a consultation."
            button="View options"
            onClick1={handleButtonClick5}
            />
             <ConsultCompVisa
             containerColor="rgba(255, 247, 241, 1)"
            title="View all Options"
            description="Have a visa in mind? Contact us for a fixed price quote. We'll assist you in understanding the costs involved."
            button="View options"
            onClick1={handleButtonClick6}
            />
            </div> 
            </div>
        <Footer />
      </div>
    </div>
  )
}

export default VisaFinderLandingScreen