import React from 'react'
import "../styles/SkilledComp2.css"
function SkilledComp2(props) {
  return (
    <div className='SkilledComp2_layout'>
        <div className='SkilledComp2_Title'>{props.Head}</div>
        <div className='skilledComp2_Field'>
            <div className='skilledComp2_box'>
                <div className='SkilledComp2_box_Title'>{props.Title}</div>
                <div className='SkilledComp2_box_Desc'>{props.Desc}</div> 
            </div>
            <div className='skilledComp2_box'>
            <div className='SkilledComp2_box_Title'>{props.Title2}</div>
                <div className='SkilledComp2_box_Desc'>{props.Desc2}</div> 
            </div>
            <div className='skilledComp2_box'>
            <div className='SkilledComp2_box_Title'>{props.Title3}</div>
                <div className='SkilledComp2_box_Desc'>{props.Desc3}</div> 
            </div>
        </div>
        <div className='skilledComp2_Field'>
            <div className='SkilledComp2_box__end'>
                <div className='SkilledComp2_box_Title_end'>{props.Title4}</div>
                <div className='SkilledComp2_box_Desc_end'>{props.Desc4}</div> 
            </div>
            <div className='SkilledComp2_box__end'>
            <div className='SkilledComp2_box_Title_end'>{props.Title5}</div>
                <div className='SkilledComp2_box_Desc_end'>{props.Desc5
                }</div> 
            </div>
        </div>

    </div>
  )
}

export default SkilledComp2