import React from 'react'
import '../styles/Option2Overview.css'

const Option2Eligibility = () => {
    return (
        <>
            <div className='EligibilitymodalLayout'>
                <div className='OptionOverviewStyling'>
                    <div className='OptionOverviewHeading'>Option 2: Direct Entry Stream (DE)</div>
                    <div className='OptionTwoLayout'>
                        <div className='OptionTwoDesc'>
                            <li>You may be able to apply for Permanent Residency through the direct entry stream if you meet the following requirements:</li>
                        </div>
                    </div>
                    <div className='OptionTwoSublayout'>
                        <div className='OptionTwoSublayout-one'>
                            <div className='OptionTwoDescBox'>
                                <div className='OptionTwoDesc'>Work Experience</div>
                                <li className='OptionTwoEliDesc'>You must have at least 3 years work experience in your nominated occupation</li>
                            </div>
                            <div className='OptionTwoDescBox'>
                                <div className='OptionTwoDesc'>Health & Character</div>
                                <li className='OptionTwoEliDesc'>Meet the health and character requirements (Unless you are exempt)</li>
                            </div>
                            <div className='OptionTwoDescBox'>
                                <div className='OptionTwoDesc'>Age</div>
                                <li className='OptionTwoEliDesc'>You are under 45 years of age or you can be older than 45 years if you are nominated under an eligible ANZCO.</li>
                            </div>
                        </div>
                        <div className='OptionTwoSublayout-main'>
                            <div className='OptionTwoSublayout-two'>
                                <div className='OptionTwoSub-one'>
                                    <div className='OptionTwoDesc'>Skills</div>
                                    <li className='OptionTwoEliDescrip'>You will need to undertake a skill assessment before applying for a 186 or 187 Visa through the direct entry stream unless exempt. <u style={{color:'#B0383D', cursor:'pointer'}}>See 186 & 187 Visa skill exemptions</u></li>
                                </div>
                                <div className='OptionTwoSub-one'>
                                    <div className='OptionTwoDesc'>English</div>
                                    <li className='OptionTwoEliDescrip'>You must demonstrate competent EnglishDon’t meet this requirement? <u style={{cursor:'pointer'}}>Click here</u> to increase your English score.</li>
                                </div>
                            </div>
                            <div className='OptionTwoSublayout-three'>
                                <div className='OptionTwoDesc'>Occupation</div>
                                <li className='OptionTwoEliDescrip'>186 Visa (ENS) ApplicantsYour occupation must be on the <u style={{color:'#B0383D', cursor:'pointer'}}>Medium and Long-term Strategic Skills List (MLTSSL)</u></li>
                                <li className='OptionTwoEliDescrip'><u style={{color:'#B0383D', cursor:'pointer'}}>187 Visa (RSMS)</u> Applicants</li>
                                <li className='OptionTwoEliDescrip'>Your occupation must be on the <u style={{color:'#B0383D', cursor:'pointer'}}>Regional Occupation List (ROL)</u></li>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Option2Eligibility