import React from 'react'
import Herobanner1 from '../images/CloseUp.mp4';
import DottedLines from '../images/DottedLines.png'
import NavBarComponent from '../components/NavBarComponent'
import HeroBanner from '../components/HeroBanner';
import Footer from '../components/Footer';
import VisaYourOption2 from '../components/VisaYourOption2';
import { useNavigate } from 'react-router-dom';

function LwTemporarily() {
  const navigate = useNavigate();
  const handleButtonClick1 = () => {
    window.scrollTo(0,0);
    navigate("/employerSponsered");

  };
  const handleButtonClick2 = () => {
    window.scrollTo(0,0);
    navigate("/VisitoFinderrVisa");
  };
  const handleButtonClick3 = () => {
    window.scrollTo(0,0);
    navigate("/TempGraduateVisa");
  };
  const handleButtonClick4 = () => {
    window.scrollTo(0,0);
    navigate("/Family482");
  };
  const handleButtonClick5 = () => {
    window.scrollTo(0,0);
    navigate("/VisaWorkingHoliday");
  };
  return (
    <div>
        <NavBarComponent />
        <HeroBanner
        heroHeading='Temporary Australian Visas'
        heroSubHeading='Below visas will give you the option of temporarily migrating to Australia to live and work. Please note that you must meet the respective eligibility criteria.'
        connectButton='Let’s Connect'
        Banner={[Herobanner1]}
        DottedLines={DottedLines}
      />
       <VisaYourOption2
  Head="Your Options"
  title1="482 Employer Visa"
  desc1="If you have at least two years of work experience in an eligible occupation, you may be able to apply for a temporary Employer Sponsored Visa."
  btn1="Learn More"
  onClick1={handleButtonClick1}
  title2="Visitor Visa"
  desc2="If you want to come to Australia for a holiday and not work."
  btn2="Learn More"
  onClick2={handleButtonClick2}
  title3="Temp Graduate Visa"
  desc3="If you have graduated in Australia you could extend your stay to gain work experience and potentially apply for a skilled or employer sponsored visa. "
  btn3="Learn More"
  onClick3={handleButtonClick3}
  title4="482 Family visa"
  desc4="If you are married or a de facto partner to someone who holds a valid 482 visa, you might be able to apply for the 482 family visa.​"
  btn4="Learn More"
  onClick4={handleButtonClick4}
  title5="Working Holiday Visa"
  desc5="If you are a citizen from an eligible country you could come to Australia to work and travel for 12 months on a 417 visa, with the possibility to extend your stay by 12 months."
  btn5="Learn More"
  onClick5={handleButtonClick5}
  />

      
      <Footer />
    </div>
  )
}

export default LwTemporarily