import React, { useState } from 'react'
import "../styles/ConsultationComponent.css"
import { useNavigate } from 'react-router-dom';
import ContactModal from './ContactModal';
function ConsultationComponent(props) {
  const navigate = useNavigate();
  const handleButtonClick = (onClick) => {
    if (typeof onClick === 'function') {
      onClick();
    } else {
      console.error('Invalid onClick function provided');
    }
  };
  const containerStyle = {
    backgroundColor: props.containerColor || '#F4F4F4', 
    color: props.textColor || '#014A91', 
  };
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
      setIsModalOpen(true);
  };

  const closeModal = () => {
      setIsModalOpen(false);
  };
  return (
    <div className='ConsultationComponent_component'  style={containerStyle}>
        <div className='ConsultationComponent_component_title'>{props.title}</div>
        <div  className='ConsultationComponent_component_description'>{props.description}</div>
        <div className='ConsultationComponent_component_button' onClick={openModal}>{props.button}</div>
        {isModalOpen && <div className="modal-background">
                <div className='modalFinal'>
                    {/* <div style={{ textAlign: 'right', paddingTop: '16px', paddingRight: '16px' }}>
                        <XCircle onClick={closeModal} size={32} />
                    </div> */}
                    <ContactModal closeModal={closeModal}/>

                </div>
            </div>}

    </div>
  )
}

export default ConsultationComponent