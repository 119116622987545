import React from 'react'
import Herobanner1Family from '../images/EmployerBanner.mp4';
import NavBarComponent from '../components/NavBarComponent'
import DottedLines from '../images/DottedLines.png'
import HeroBanner from '../components/HeroBanner'
import Footer from '../components/Footer';
import NotSureImg from "../images/NotSureImg.png"
import VisaFinderNotSure from '../components/VisaFinderNotSure';
import VisaYourOption2 from '../components/VisaYourOption2';
import { useNavigate } from 'react-router-dom';

function LwPermanently() {
  const navigate = useNavigate();
  const handleButtonClick1 = () => {
    window.scrollTo(0,0);
    navigate("/VisaGSM");

  };
  const handleButtonClick2 = () => {
    window.scrollTo(0,0);
    navigate("/VisaPP");
  };
  const handleButtonClick3 = () => {
    window.scrollTo(0,0);
    navigate("/VisaPES");
  };
  const handleButtonClick4 = () => {
    window.scrollTo(0,0);
    navigate("/VisaInnovation");
  };
  const handleButtonClick5 = () => {
    window.scrollTo(0,0);
    navigate("/VisaNz");
  };
  return (
    <div>
       <NavBarComponent/>
    <HeroBanner
    heroHeading='Permanent Australian Visas'
    heroSubHeading='Below visas will give you the option of permanently migrating to Australia to live and work. Please note that you must meet the respective eligibility criteria.'
    connectButton='Let’s Connect'
    Banner={[Herobanner1Family]}
    DottedLines={DottedLines}
  />
    <VisaYourOption2
  Head="Your Options"
  title1="General Skilled Migration"
  desc1="There is a number of visa options available to skilled workers. The General Skilled Migration Program is aimed at skilled workers in particular occupations willing to migrate to Australia to improve Australia’s workforce."
  btn1="Learn More"
  onClick1={handleButtonClick1}
  title2="Permanent Partner  Visa"
  desc2="You could be eligible for one of the 3 partner visas if your spouse or partner (same or opposite sex) is an Australian permanent resident, Australian citizen or eligible New Zealand citizen."
  btn2="Learn More"
  onClick2={handleButtonClick2}
  title3="Permanent Employer Sponsored Visa"
  desc3="If you have graduated in Australia you could extend your stay to gain work experience and potentially apply for a skilled or employer sponsored visa.  "
  btn3="Learn More"
  onClick3={handleButtonClick3}
  title4="Business Innovation & Investment Visa"
  desc4="This visa allows you to own and manage a business, conduct business and investment activity or undertake an entrepreneurial activity in Australia."
  btn4="Learn More"
  onClick4={handleButtonClick4}
  title5="New Zealand Citizen Visa"
  desc5="New Zealand Citizens who have been living and working in Australia for five years may be eligible to apply for permanent residency through the Skilled Independent New Zealand Stream (Subclass 189)"
  btn5="Learn More"
  onClick5={handleButtonClick5}
  />
   <VisaFinderNotSure 
  title="Not sure what to do next?"
  desc="Australian visas can be confusing. Often, the obvious choice isn’t the best one. Our registered migration agents are happy to help and answer all you questions."
  btn="Book consultation"
  img={NotSureImg}
  />
  <Footer />
    </div>
  )
}

export default LwPermanently