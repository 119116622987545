import React from 'react'
import "../styles/SkilledComp3.css"

function SkilledComp3(props) {
  return (
    <div className='SkilledComp3_layout'>
          <div className='SkilledComp3_Title'>{props.Head}</div>
          <div className='SkilledComp3_fields'>
            <div className='SkilledComp3_fields_Box'>
            <div className='SkilledComp3_box_Title'>{props.Title}</div>
                <div className='SkilledComp3_box_Desc'>{props.Desc}</div> 
            </div>
            <div className='SkilledComp3_fields_Box'>
            <div className='SkilledComp3_box_Title'>{props.Title2}</div>
                <div className='SkilledComp3_box_Desc'>{props.Desc2}</div> 
            </div>
            <div className='SkilledComp3_fields_Box'>
            <div className='SkilledComp3_box_Title'>{props.Title3}</div>
                <div className='SkilledComp3_box_Desc'>{props.Desc3}</div> </div> 
          </div>
        
    </div>
  )
}

export default SkilledComp3