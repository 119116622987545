import React, { useState } from 'react'
import NavBarComponent from '../components/NavBarComponent'
import PermanentEmpBanner from '../components/PermanentEmpBanner'
import DottedLines from '../images/DottedLines.png'
import Family from "../images/Dancing.mp4"
import ConsultationComponent from '../components/ConsultationComponent'
import Footer from '../components/Footer'
import { useNavigate } from 'react-router-dom';
import "../styles/AboutUs187Visa.css"
import HeroBanner from '../components/HeroBanner'
function MarriagePartner() {
    const [activeTab, setActiveTab] = useState('Overview');

    const handleTabClick = tabName => {
      setActiveTab(tabName);
    };
    const navigate = useNavigate();
    const handleMenuItemClick = (path) => {
      navigate(path);
    };
  return (
    <div>
        <NavBarComponent />
        <HeroBanner
        heroHeading='Marriage Partner Visa'
        heroSubHeading='Our registered migration agents can help assess your family visa eligibility in a professional and timely manner. We have assisted hundreds of clients with the preparation and lodgement of successful visa applications.'
        connectButton='Let’s Connect'
        Banner={[Family]}
        DottedLines={DottedLines}
      />
            <div className="TabsContainer">
      <div
        className={`Tab ${activeTab === 'Overview' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('Overview')}
      >
      Overview
      </div>
      <div
        className={`Tab ${activeTab === 'AbouttheVisa' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('AbouttheVisa')}
      >About the Visa
      </div>
      <div
        className={`Tab ${activeTab === 'Eligibility' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('Eligibility')}
      >
     Eligibility
      </div>
      <div
        className={`Tab ${activeTab === 'ProcessingTimes' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('ProcessingTimes')}
      >
     Processing Times
      </div>
    </div>

    {activeTab === 'Overview' &&
       <div className='Tabs_container_layout'>
         <div className='PartnerVisaHeadComp_Title'>Overview</div>
         <div className='AboutUsVisa_box_fields'>
 <div className='AboutUsVisa_box_fields_content'>
  <div className='AboutUsVisa_box_title'>This visa is for people who want to come to Australia to marry their prospective spouse</div>
          <div className='AboutUsVisa_box_desc'>
          The Prospective Marriage Visa is a 9 month temporary visa. Applicants must have plans to get married within this time to be eligible to apply for a permanent partner visa.
            <li>Subclass 300 Visa holders can:</li>
            <li>Stay in Australia for 9 months from the date of grant of visa.</li>
            <li> Work in Australia</li>
            <li>Study in Australia at your own expense</li>
            <li>Travel to and from Australia as you please.</li>
      </div>
        </div>
        <div className='AboutUsVisa_box_fields_content'>
  <div className='AboutUsVisa_box_title'>Basic Requirments</div>
          <div className='AboutUsVisa_box_desc'>
          To be eligible to apply for the prospective marriage visa, applicants must:
            <li>Have proof of intent to marry their prospective husband or wife.</li>
            <li>Have met their prospective spouse in person</li>
            <li>Be at least 18 years of age</li>
            <li>If you already hold a prospective marriage visa, you may be eligible to apply for a <u onClick={() =>{ handleMenuItemClick('/FamilyPartner'); window.scrollTo(0,0);}} style={{ color: '#B0383D', cursor: 'pointer',fontSize:"16px" }}>Partner Visa </u></li>
      </div>
        </div>
        </div>
        </div> 
      
        }
         {activeTab === 'AbouttheVisa' &&
       <div className='Tabs_container_layout'>
   <div className='PartnerVisaHeadComp_Title'>Partner Visas are processed in two stages</div>
   <div className='AboutUsVisa_box_fields'>
 <div className='AboutUsVisa_box_fields_content'>
  <div className='AboutUsVisa_box_title'>General</div>
          <div className='AboutUsVisa_box_desc'>
            <li>Stay in Australia for up to 9 months from the date of grant of visa</li>
            <li>Work in Australia</li>
            <li> Work in Australia</li>
            <li>Study in Australia at your own expense</li>
            <li>Travel to and from Australia as you please.</li>
      </div>
      <hr className='Divider'/>
      <div className='AboutUsVisa_box_title'>You Marriage</div>
          <div className='AboutUsVisa_box_desc'>
          <li> You must enter Australia before the date specified on your Prospective Marriage visa grant letter.</li>
          <li>You must marry your prospective spouse before your Marriage visa expires.</li>
          <li>Your marriage must be valid under Australian law</li>
      </div>
        </div>
        <div className='AboutUsVisa_box_fields_content'>
  <div className='AboutUsVisa_box_title'>Include Your Family</div>
          <div className='AboutUsVisa_box_desc'>
          Marriage visa applicants may be able to include the below family members in their application
          <li>Partner</li>
          <li>Dependent child</li>
          <li>Partner’s a dependent child</li>
          <li>Family members must meet the health and character requirements.</li>
      </div>
      <hr className='Divider'/>
      <div className='AboutUsVisa_box_title'>Extend your stay</div>
          <div className='AboutUsVisa_box_desc'>
          <li> This is a temporary visa. Applicants can stay in Australia for up to 9 months from the date of visa approval.</li>
          <li> Applicants can apply for the permanent partner visa while in Australia:</li>
      </div>
        </div>
        </div>
        <div className='AboutUsVisa_box_fields_content'>
        <div className='AboutUsVisa_box_title'>After the wedding</div>
        <div className='AboutUsVisa_box_desc'>
            <li>Before the prospective marriage visa expires</li>
           <li>The application fees for the permanent partner visa will be less than the usual price when applied for under a valid marriage visa.</li>
           </div>
   </div>
        </div>
        }
         {activeTab === 'Eligibility' &&
       <div className='Tabs_container_layout'>
   <div className='PartnerVisaHeadComp_Title'>Eligibility</div>
   <div style={{paddingTop:"24px"}}>
   <div className='AboutUsVisa_box_fields_content'>
        <div className='AboutUsVisa_box_title'>To be eligible to apply for the prospective marriage visa, applicants must:</div>
          <div className='AboutUsVisa_box_desc'>
<li>Have proof of intent to marry their prospective husband or wife.</li>
<li> Have met their prospective spouse in person.</li>
<li> Be at least 18 years of age </li>
</div>
        </div>
        <div className='AboutUsVisa_box_fields'>
        <div className='AboutUsVisa_box_fields_content'>
          <div className='AboutUsVisa_box_title'>General</div>
          <div className='AboutUsVisa_box_desc'><li>Applicants must intend to be married within 9 months of arriving in Australia The applicant cannot be related to sponsor</li> </div>
          </div>
        <div className='AboutUsVisa_box_fields_content'>
        <div className='AboutUsVisa_box_title'>Age </div>
          <div className='AboutUsVisa_box_desc'><li>Applicants must be 18 years or over to be eligible to apply of this visa</li></div>
        </div>
        <div className='AboutUsVisa_box_fields_content'>
        <div className='AboutUsVisa_box_title'>Health & Character </div>
          <div className='AboutUsVisa_box_desc'><li>All family members must meet the health and character requirements.</li></div>
        </div>
        
        </div>
        </div>
        </div>
        }
         {activeTab === 'ProcessingTimes' &&
       <div className='Tabs_container_layout'>
    <div className='PartnerVisaHeadComp_Title'>Processing Times</div>
    <div style={{paddingTop:"24px"}}>
    <div className='AboutUsVisa_box_fields_content'>
          <div className='AboutUsVisa_box_title'>How long will it take to get a decision on your visa?</div>
          <div className='AboutUsVisa_box_desc'>
          <li>An estimate is unavailable due to the low volume of applications.</li>
          </div>
          </div>
          </div>
        </div>
        }
          <div className='ConsultationComponent_field'>
            <ConsultationComponent
            title="Are You Eligible?"
            description="Take our online eligibility assessment to find out if you meet the requirements for the 482 Visa            "
            button="Check Eligibility"
            />
             <ConsultationComponent 
            title="Book A Consultation"
            description="Speak to one of our migration agents and simply book a consultation. We'll assist you in understanding the costs involved"
            button="Consult"
            />
             <ConsultationComponent
            title="Get A Quote"
            description="Have a visa in mind? Contact us for a fixed price quote. We'll assist you in understanding the costs involved.            "
            button="Get a quote"
            />
            </div>
            <Footer />

   
    </div>
  )
}

export default MarriagePartner