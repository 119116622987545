import React from 'react';
import "../styles/subNav.css";
import { useNavigate } from 'react-router-dom';

function SubNavAdmin() {
    const navigate = useNavigate();
 const handleNavigationAdminBlog = () => {
   window.scrollTo(0,0);
   navigate("/BlogAdmin")
}
const handleNavigateLink =()=>{
    window.scrollTo(0,0);
    navigate("/links");
  }
    return (
        <div className='sub-nav-container'>
            <div onClick={handleNavigationAdminBlog} className='sub_nav_container'>Add Blogs</div>
            <div onClick={handleNavigateLink} className='sub_nav_container'>Add Links</div>
           
        </div>
    );
}
export default SubNavAdmin;
