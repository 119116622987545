import React from 'react'
import NavBarComponent from '../components/NavBarComponent'
import Footer from '../components/Footer'
import "../styles/visa187.css"
import { useState } from 'react'
import Visa187Overview from '../components/Visa187Overview'
import AboutVisa from '../components/AboutVisa'
import Eligibility from '../components/Eligibility'
import Family187Visa from '../components/Family187Visa'
import VisaStream187 from '../components/VisaStream187'
import HeroBanner from '../components/HeroBanner'
import DottedLines from '../images/DottedLines.png'
import Family from "../images/Visa187Banner3.mp4"

function Visa187() {
    const [activeTab, setActiveTab] = useState('Overview');

    const handleTabClick = tabName => {
      setActiveTab(tabName);
    };
  return (
    <div>
        <NavBarComponent />
        <HeroBanner
       heroHeading='Regional Sponsor Migration Scheme RSMS (Subclass 187)'
       heroSubHeading=''
       connectButton='Let’s Connect'
        Banner={[Family]}
        // Banner={['/video1.mp4', 'video2.mp4', 'video3.mp4']}
        DottedLines={DottedLines}
      />
        <div className="TabsContainer">
      <div
        className={`Tab ${activeTab === 'Overview' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('Overview')}
      >
        Overview
      </div>
      <div
        className={`Tab ${activeTab === 'AboutVisa' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('AboutVisa')}
      >
        AboutVisa
      </div>
      <div
        className={`Tab ${activeTab === 'Eligibility' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('Eligibility')}
      >
        Eligibility
      </div>
      <div
        className={`Tab ${activeTab === 'IncludingFamily' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('IncludingFamily')}
      >
        Including Family
      </div>
      
      <div
        className={`Tab ${activeTab === 'VisaStreams' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('VisaStreams')}
      >
       Visa Streams
      </div>
      
    </div>
    {activeTab === 'Overview' &&
       <div className='Tabs_container_layout'>
        <Visa187Overview />
      
        </div>
        }
         {activeTab === 'AboutVisa' &&
       <div className='Tabs_container_layout'>
        <AboutVisa />
      
        </div>
        }
         {activeTab === 'Eligibility' &&
       <div className='Tabs_container_layout'>
        <Eligibility />
      
        </div>
        }
         {activeTab === 'IncludingFamily' &&
       <div className='Tabs_container_layout'>
      <Family187Visa />
        </div>
        }
         {activeTab === 'VisaStreams' &&
       <div className='Tabs_container_layout'>
      <VisaStream187 />
        </div>
        }
      
        <Footer/>
    </div>
  )
}

export default Visa187