import React from 'react'
import Heading from './Heading'
import "../styles/TssOption1.css"
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function TssOption1() {
    const [activeTab, setActiveTab] = useState('Overview');

    const handleTabClick = tabName => {
      setActiveTab(tabName);
    }; 
    const navigate = useNavigate();
    const handleNavigate = () => {
        window.scrollTo(0,0);
         navigate('/EmployerSponsered482')
      }  
      const handleNavigate186 = () => {
        window.scrollTo(0,0);
         navigate('/VisaExemption')
      } 
      const handleNavigateEnglish = () => {
        window.scrollTo(0,0);
         navigate('/VisaExemption')
      }   
  return (
    <div className='option3_layout'>
          <div className='TssOption1_heading'>Option 1: Temporary Residence Transition Stream (TRT)</div>
           <div className="TabsContainer_option1">
      <div
        className={`Tab ${activeTab === 'Overview' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('Overview')}
      >
        Overview
      </div>
      <div
        className={`Tab ${activeTab === 'Eligibility' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('Eligibility')} >
        Eligibility
      </div>
      <div
        className={`Tab ${activeTab === 'IncludingFamily' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('IncludingFamily')}
      >
        Including Family
      </div>  
    </div>
    {activeTab === 'Overview' &&
       <div className='Tabs_container_layout'>
        <div className='Tss_Overview_container'>
       <li> TSS Visa holders who have been working in Australia with the same employer for three years may be eligible to apply for Permanent Residency through the Transitional Stream.</li>
       <li> To be eligible to transition from TSS to permanent residency, your occupation must be on the medium long-term occupation list.</li>
       <li> Depending on their location, TSS visa holders will apply through either the Employer Nominated Scheme (ENS) or the Regional Employer Sponsored Scheme (RSMS).</li>
       <li> The employer must be willing to sponsor the applicant for permanent residency under the Temporary Residence Transition stream and must lodge a valid nomination with the Department of Home Affairs. This is one of the most common pathways to permanent residency and has many benefits.</li>
       <li> The employer must also be willing to pay the Skilling Australian Fund (SAF) levy.</li>
        </div>
        </div>
        }
        {activeTab === 'Eligibility' &&
       <div className='Tabs_container_layout'>
       <div className='Tss_Eligibility_Container'>
        <div className='Tss_Eligibility_fields'>
            <div className='Tss_Eligibility_fields_container'>
            <div className='AboutUsVisa_box_title'>General</div>
                <div className='VisaFamily_Box_desc'><li>Hold a valid <u onClick={handleNavigate} style={{ color: '#B0383D', cursor: 'pointer',fontSize:"16px" }}>482 TSS Visa</u></li>
                <li>Have been sponsored (as the primary applicant) by your nominating employer on a 482 or TSS Visa for at least three years.</li>
                <li>Your occupation must be listed on the MLTSSL for 186 applications</li>
                <li>Your occupation must be listed on the Regional RSMS Occupation List for 187 applications</li>
              </div>
            </div>
            <div className='Tss_Eligibility_fields_container'>
            <div className='AboutUsVisa_box_title'>English</div>
                <div className='VisaFamily_Box_desc'><li>You must demonstrate competent english unless you are exempt.<u onClick={handleNavigateEnglish} style={{ color: '#B0383D', cursor: 'pointer',fontSize:"16px" }}>  See 186 & 187 Visa english exemptions</u></li>
                <li>Do you need to increase your English score before you can apply?  for details about short English courses for IELTS and PTE.</li>
              
              </div>
            </div>
        </div>
        <div className='Tss_Eligibility_fields_end'>
        <div className='AboutUsVisa_box_title'>Age</div>
                <div className='VisaFamily_Box_desc'><li>You are under 45 years of age unless you are exempt.<u onClick={handleNavigate186} style={{ color: '#B0383D', cursor: 'pointer',fontSize:"16px" }}> See 186 & 187 Visa age exemptions</u></li></div>
              
        </div>
       </div>
      
        </div>
        }
          {activeTab === 'IncludingFamily' &&
        <div className='Tabs_container_layout'>
        <div className='Tss_Eligibility_Container'>
         <div className='Tss_Eligibility_fields'>
             <div className='Tss_Eligibility_fields_container'>
             <div className='AboutUsVisa_box_title'>General</div>
                 <div className='VisaFamily_Box_desc'>Applicants can include the following people in their transitional permanent residency application:
                   <li>Dependent children/step-children</li>
                   <li>Partner</li>
                 </div>
             </div>
             <div className='Tss_Eligibility_fields_container'>
             <div className='AboutUsVisa_box_title'>Health & Character</div>
                 <div className='VisaFamily_Box_desc'>
                 <li>All family members must meet the health and character requirements. All applicants over 16 must provide police clearances.</li>
                  <li>If you have any questions about these requirements or would like more information on who you can include on your 457 to PR application speak to one of our migration experts</li>
               </div>
             </div>
         </div>
         <div className='Tss_Eligibility_fields'>
             <div className='Tss_Eligibility_fields_container'>
             <div className='AboutUsVisa_box_title'>Partner</div>
                 <div className='VisaFamily_Box_desc'>Your married or de facto partner can be the same or opposite sex. Applicants must prove:
                 <li>The relationship is genuine and continuing</li>
                 <li>You live together, or do not live separately on a permanent basis.</li>
                 <li>Your partner is at least 18 years of age when the application is lodged (there are some exceptions)</li>
                 <li>You are not related by family (if you are in a de facto relationship)</li>
                 <li>You and your partner have a mutual commitment to a shared life to the exclusion of all others</li>
                
                 </div>
             </div>
             <div className='Tss_Eligibility_fields_container'>
             <div className='AboutUsVisa_box_title'>English</div>
                 <div className='VisaFamily_Box_desc'>
                 Applicants over 18 must demonstrate Functional English. You can demonstrate Functional English without IELTS if you:
                 <li>Completed all years of primary education and at least three years of secondary education in an educational institution in or outside Australia and all instructions were in English. or</li>
                 <li>Completed at least five years of secondary education in an institution in or outside Australia and all instructions were in English.</li>
                </div>
             </div>
         </div>
         <div className='Tss_Eligibility_fields_end'>
         <div className='AboutUsVisa_box_title'>Children</div>
                 <div className='VisaFamily_Box_desc'>To be eligible to be included or added to a TSS visa, a child must:
                 <li>Be your child or a stepchild from a current or a previous relationship (in certain circumstances);</li>
                 <li>Not be married, engaged to be married, or have a de facto partner; and must be:</li>
                 <li>Under 18 years of age or</li>
                 <li>Over 18 years of age but not yet turned 23, and be dependent on you or your partner or</li>
                 <li>Over 23 years of age and be unable to earn a living to support themselves due to physical or cognitive limitations and be dependent on you or your partner (Note: The child will still need to meet Australia’s health requirement)or</li>
                 <li>A dependent child of a child who is eligible under 1, 2 or 3 above.</li>
</div>
               
         </div>
        </div>
       
         </div>
        }
    </div>
  )
}

export default TssOption1