import React from 'react'
import '../styles/Family494.css'
const Family494Comp = () => {
    return (
        <>
            <div className='family494-layout'>
                <div className='family494-headbox'>
                    <div className='family494-heading'>Include Your Family</div>
                </div>
                <div className='family494-headbox'>
                    <div className='family494-subcontent'>
                        <div>Applicants can include the following people in their 494 application:</div>
                        <li>Dependent children/step-children</li>
                        <li>Partner’s dependent child or children</li>
                        <li>Partner</li>
                    </div>
                </div>
                <div className='family494-headbox-layout'>
                    <div className='family494-headbox1'>
                        <div className='family494-subcontent'>
                            <div className='family494-subheading'>Partners</div>
                            <div className='family494-subheading-desc'>
                                <div>Your married or de facto partner can be the same or opposite sex. Applicants must prove:</div>
                                <li>The relationship is genuine and continuing</li>
                                <li>You live together, or do not live separately on a permanent basis.</li>
                                <li>Your partner is at least 18 years of age when the application is lodged (there are some exceptions)</li>
                                <li>You are not related by family (if you are in a de facto relationship)</li>
                                <li>You and your partner have a mutual commitment to a shared life to the exclusion of all others</li>
                            </div>
                        </div>
                    </div>
                    <div className='family494-headbox1'>
                        <div className='family494-subcontent'>
                            <div className='family494-subheading'>Children</div>
                            <div className='family494-subheading-desc'>
                                <div>To be eligible to be included or added to a 186 visa, a child must:</div>
                                <li>Be your child or a stepchild from a current or a previous relationship (in certain circumstances);</li>
                                <li>Not be married, engaged to be married, or have a de facto partner; and must be:</li>
                                <li>Under 18 years of age or</li>
                                <li>Over 18 years of age but not yet turned 23, and be dependent on you or your partner or</li>
                                <li>Over 23 years of age and be unable to earn a living to support themselves due to physical or cognitive limitations and be dependent on you or your partner (Note: The child will still need to meet Australia’s health requirement)or</li>
                                <li>A dependent child of a child who is eligible under 1, 2 or 3 above</li>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='family494-headbox'>
                    <div className='family494-subcontent'>
                        <div className='family494-subheading'>English</div>
                        <div className='family494-subheading-desc'>
                            <div>Applicants over 18 must demonstrate functional English. You can demonstrate Functional English without IELTS if you:</div>
                            <li>Completed all years of primary education and at least three years of secondary education in an educational institution in or outside Australia and all instructions were in English.</li>
                            <div style={{textAlign:'center'}}>or</div>
                            <li>Completed at least five years of secondary education in an institution in or outside Australia and all instructions were in English.</li>
                        </div>
                    </div>
                </div>
                <div className='family494-headbox'>
                    <div className='family494-subcontent'>
                        <div className='family494-subheading'>Health & Character</div>
                        <div className='family494-subheading-desc'>
                            <div>All family members must meet health and character requirements. All applicants over 16 must provide police clearances.</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Family494Comp