import React, { useState } from 'react'
import NavBarComponent from '../components/NavBarComponent'
import DottedLines from '../images/DottedLines.png'
import Passport from '../images/Business14.mp4'
import PermanentEmpBanner from '../components/PermanentEmpBanner'
import ConsultationComponent from '../components/ConsultationComponent'
import Footer from '../components/Footer'
import SkilledVisaHow from "../images/489Banner.mp4"
import "../styles/Visa489.css"
import SkilledHowComp from '../components/SkilledHowComp'
import Heading from '../components/Heading'

import { useNavigate } from 'react-router-dom';
import HeroBanner from '../components/HeroBanner'

function SkilledVisa489() {
  const [activeTab, setActiveTab] = useState('Overview');

  const navigate = useNavigate();
  const handleTabClick = tabName => {
    setActiveTab(tabName);
  };
  const handleMenuItemClick = (path) => {
    navigate(path);
  };
  return (
    <div>
      <NavBarComponent/>
      <HeroBanner
        heroHeading='489 Visa'
        heroSubHeading='The Regional Sponsored Visa (Subclass 489) was designed to encourage skilled workers and their families to live and work in specified regional or low-population parts of Australia for up to four years'
        connectButton='Let’s Connect'
        Banner={[Passport]}
        DottedLines={DottedLines}
      />
        <div className="TabsContainer">
      <div
        className={`Tab ${activeTab === 'Overview' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('Overview')}
      >
        Overview
      </div>
      <div
        className={`Tab ${activeTab === 'Eligibility' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('Eligibility')}
      >
       Eligibility
      </div>
      <div
        className={`Tab ${activeTab === 'ProcessingTime' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('ProcessingTime')}
      >
       Processing Time
      </div>
      <div
        className={`Tab ${activeTab === 'IncludingFamily' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('IncludingFamily')}
      >
       Including Family
      </div>
    
    </div>

      {activeTab === 'Overview' &&
       <div className='Tabs_container_layout'>
        <div className='Visa489_Single_Box_layout'>
          <Heading heading="The 489 visa is now closed to new applications" containerColor="rgba(255, 247, 241, 1)" textColor="rgba(176, 56, 61, 1)"/>
          <div className='Visa489_Single_Box'> 
          In November 2019, the 489 Visa was replaced by the Skilled Work Regional (Provisional) – Subclass 491 Visa
The 489 Skilled Regional (Provisional) Visa allows you to:

<li>Stay in Australia for up to 4 years</li>
<li>Live, work and study in a specified region of Australia</li>
<li>Travel to and from Australia as many times as you want, while the visa is valid</li>
<li>Apply for Permanent Skilled Regional 887 Visa (if eligible)</li>

If you are nominated for this visa, you will need to live and work in a regional or low-population growth metropolitan area

If you are sponsored by a relative, you will need to live, work and study only in a designated area of Australia.

If your 489 visa is expiring soon and you can apply for Permanent Skilled Regional 887 Visa (if eligible)You may also be eligible to apply for one of the below permanent skilled or employer sponsored visas:
<li style={{textDecoration:"underline" ,lineHeight:"25px",cursor:"pointer"}}  
 onClick={() => {
        handleMenuItemClick('/SkilledVisa189');
        window.scrollTo(0, 0);
      }}>189 visa</li>
<li style={{textDecoration:"underline",lineHeight:"25px",cursor:"pointer"}}  
 onClick={() =>{ handleMenuItemClick('/Visa190'); window.scrollTo(0,0);}}>190 visa</li>
<li style={{textDecoration:"underline",lineHeight:"25px",cursor:"pointer"}}  
 onClick={() =>{handleMenuItemClick('/Visa191'); window.scrollTo(0,0);}}>191 Visa</li>

There are three pathways to the visa:
<li>the Extended stay pathway</li>
<li>the Invited pathway</li>
<li>the Subsequent entry pathway</li>

 This visa might lead to a permanent Skilled Regional visa (subclass 887).

          </div>
          </div>
      </div>
        }
          {activeTab === 'Eligibility' &&
       <div className='Tabs_container_layout'>
          <Heading heading="The 489 visa is now closed to new applications" containerColor="rgba(255, 247, 241, 1)" textColor="rgba(176, 56, 61, 1)"/>
          <div className='Visa489_Single_Box_layout'>
          <div className='LabourAgreement_layout_content'>
          <div className='Visa489_Single_Box'> 
        Applicants can hold a subclass 475, 487, 495 or 496 visas (Extended Stay pathway)Applicants can be sponsored by either a relative who is living in a designated area or a State or Territory Government to be eligible. (Invited pathway)
Applicants must:
<li> have an occupation on the relevant occupation list</li>
<li> have a successful skill assessment for the relevant occupation</li>
<li> be nominated to apply by a state or territory government agency or eligible relative</li>

Expression of Interest 
Applicants can submit an Expression of Interest (EOI) and be invited to apply for this visa by the Department of Immigration. 
Applicants must score at least 65 points to be eligible to apply for the 489 Visa, however, you may need more points in order to receive an invitation. 
Take a look at the latest invitation round to the number of invitations issued. 
Take our Online Points Test to find you how many points you score.

After receiving an invitation, applicants must lodge their Regional Sponsored 489 Visa within 60 days.

You may be able to apply for a Regional Sponsored Visa (Subclass 489) if you meet the following requirements: (Invited pathway)
         </div>
            <div className='LabourAgreement_section'>
                <div className='LabourAgreement_section-container'>
                    <div className='LabourAgreement_section-container_title'>Health & Character </div>
                    <div className='LabourAgreement_section-container_desc'>
                    You must meet the health & character requirements
                       </div>
                </div>
                <div className='LabourAgreement_section-container'>
                <div className='LabourAgreement_section-container_title'>Age </div>
                    <div className='LabourAgreement_section-container_desc'>
                    You are under 45 when you receive an invitation to apply
                       </div>
                </div>
                <div className='LabourAgreement_section-container'>
                <div className='LabourAgreement_section-container_title'>English </div>
                    <div className='LabourAgreement_section-container_desc'>
                    You can provide evidence of Competent English
                </div>
                </div>
            </div>
            <div className='LabourAgreement_extrayLay'>
                <div className='LabourAgreement_section-container_title'>Relative Sponsorship</div>
                <div className='LabourAgreement_section-container_desc_extrayLay'>
                You may be able to apply for a Regional Sponsored Visa if they are sponsored by an eligible relative who is living in a designated area.An eligible relative must be either an Australian citizen, permanent resident or eligible New Zealand citizen. An eligible relative must also be one of the following to the applicant:
<li> Parent</li>
<li> Brothers and Sisters</li>
<li> Uncles and Aunts</li>
<li> Nephews and Nieces</li>
<li> First Cousins</li>
<li> Grandparents</li>
Applicants who have been sponsored by a relative for two years and have worked full time for at least 12 months in a regional or low population area may qualify for a Skilled Regional Subclass 887 Permanent Visa.
            </div>
            <div>
              </div>
            </div>
            <div className='LabourAgreement_section'>
                <div className='LabourAgreement_section-container'>
                    <div className='LabourAgreement_section-container_title'>State or Territory Nomination</div>
                    <div className='LabourAgreement_section-container_desc'>
                    You must be Nominated by an Australian State or Territory. Each State and Territory Government have their own list of eligible occupations and may have specific requirements for skills and work experience.
                       </div>
                </div>
                <div className='LabourAgreement_section-container'>
                <div className='LabourAgreement_section-container_title'>Occupation & Skills  </div>
                    <div className='LabourAgreement_section-container_desc'>
                    Your occupation must be on the relevant list of eligible skilled occupations.
You must perform a successful skill assessment through the authorized assessing authority to show that you have the skills and qualifications to work in your chosen occupation.
                       </div>
                </div>
                <div className='LabourAgreement_section-container'>
                <div className='LabourAgreement_section-container_title'>Points Test & Invitation </div>
                    <div className='LabourAgreement_section-container_desc'>
                    You must lodge an Expression of Interest (EOI) and be invited to apply for this visa
You must score a minimum of 65 points to be invited to apply.
You must meet the points score eligibility at the time you are invited to apply for the visa.
Calculate your points
                </div>
                </div>
            </div>
        </div>
        </div>
      </div>
        }
          {activeTab === 'ProcessingTime' &&
       <div className='Tabs_container_layout'>
          <Heading heading="The 489 visa is now closed to new applications" containerColor="rgba(255, 247, 241, 1)" textColor="rgba(176, 56, 61, 1)"/>
      </div>
        }
          {activeTab === 'IncludingFamily' &&
       <div className='Tabs_container_layout'>
          <Heading heading="The 489 visa is now closed to new applications" containerColor="rgba(255, 247, 241, 1)" textColor="rgba(176, 56, 61, 1)"/>
          <div className='Visa489_Single_Box'> 
          Applicants can include the following people in their transitional permanent residency application:
<li>Dependent children/step-children</li>
<li>Partner’s dependent child or children</li>
<li>Partner</li>
         </div>
         <div className='Visa489_Double_Box_field'>
          <div className='Visa489_Double_Box'>
          <div className='Visa489_Content_box_title'>Health & Character</div>
          <div className='Visa489_Content_box_desc'>All family members must meet health and character requirements. All applicants over 16 must provide police clearances.</div>
          </div>
          <div className='Visa489_Double_Box'>
          <div className='Visa489_Content_box_title'>English</div>
          <div className='Visa489_Content_box_desc'> Applicants over 18 must demonstrate Functional English or pay an additional application fee to be exempt. Otherwise, if you would like to improve your English score, our English Language School partner can help.
           </div>
          </div>
         </div>
         <div className='Visa489_Content_box'>
          <div className='Visa489_Content_box_title'>Partner</div>
          <div className='Visa489_Content_box_desc'>
          Your married or de facto partner can be the same or opposite sex. Applicants must prove:
<li>the relationship is genuine and continuing</li>
<li>your partner is at least 18 years of age when the application is lodged (there are some exceptions)</li>
<li>you are not related by family (if you are in a de facto relationship)</li>
<li>you and your partner have a mutual commitment to a shared life to the exclusion of all others, and</li>
<li>you live together, or do not live separately on a permanent basis.</li>
          </div>
         </div>
         <div className='Visa489_Content_box'>
         <div className='Visa489_Content_box_title'>Children</div>
          <div className='Visa489_Content_box_desc'>To be eligible to be included or added to a 189 visa, a child must:
<li>be your child or a stepchild from a current or a previous relationship (in certain circumstances)</li>
<li>not be married, engaged to be married, or have a de facto partner; and must be</li>
<li>under 18 years of ageor</li>
<li>over 18 years of age but not yet turned 23, and be dependent on you or your partneror</li>
<li>over 23 years of age and be unable to earn a living to support themselves due to physical or cognitive limitations and be dependent on you or your partner (Note: The child will still need to meet Australia’s health requirement)or</li>
<li>a dependent child of a child who is eligible under 1, 2 or 3 above.</li></div>
         </div>
      </div>
        }
        <SkilledHowComp 
        EmpHowHeading="How it works"
        EmpHowImage={SkilledVisaHow}
        EmpHowSubHead1="Step 1"
        EmpHowDesc1="Take our Online Points test to calculate your score"
        EmpHowSubHead2="Step 2"
        EmpHowDesc2="Choose a family member in a regional area, State or Territory to sponsor you"
        EmpHowSubHead3="Step 3"
        EmpHowDesc3="Wait until we receive your invitation to apply"
        EmpHowSubHead4="Step 4"
        EmpHowDesc4="Our agents prepare and lodge your application and documents"
        EmpHowSubHead5="Step 5"
        EmpHowDesc5="Our agents liaise with the DHA until your application is approved"

        />
        
<div className='ConsultationComponent_field'>
            <ConsultationComponent 
            title="View other visa options"
            description="Perhaps there is an alternative visa option you could explore? Use our Visa Finder to conduct your research. "
            button="Consult"
            containerColor="rgba(255, 247, 241, 1)"
           
            />
             <ConsultationComponent 
            title="Book A Consultation"
            description="If you would like to discuss your Australian visa eligibility with a registered migration agent, book your consultation now."
            button="Consult"
            containerColor="rgba(255, 247, 241, 1)"
            />
             <ConsultationComponent
            title="Get A Quote"
            description="Know what visa you want to apply for but need some pricing? Get in touch with us and we will get back to you with a fixed price quote. "
            button="Get a quote"
            containerColor="rgba(255, 247, 241, 1)"
            />
            </div>
            <Footer />
    </div>
  )
}

export default SkilledVisa489