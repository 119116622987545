import React, { useState } from 'react'
import DottedLines from '../images/DottedLines.png'
import ParentBanner from "../images/ParentBanner.mp4"
import NavBarComponent from '../components/NavBarComponent';
import ConsultationComponent from '../components/ConsultationComponent';
import Footer from '../components/Footer';
import HeroBanner from '../components/HeroBanner';

import { useNavigate } from 'react-router-dom';

function TemporaryParentVisa() {
  const navigate = useNavigate();
  const handleMenuItemClick = (path) => {
    navigate(path);
  };
  
  return (
    <div>
          <NavBarComponent />
          <HeroBanner
        heroHeading='Temporary Parent Visa'
        heroSubHeading=''
        connectButton='Let’s Connect'
        Banner={[ParentBanner]}
        DottedLines={DottedLines}
      />
       <div className='Tabs_container_layout'>
       <div style={{paddingTop:"24px"}}>
        <div className='AboutUsVisa_box_fields_content'>
          <div className='AboutUsVisa_box_desc'><li>On March 1 2019, Minister Coleman announced introduction of a new five year Temporary Sponsored Parent visa.</li>
          <li>The new Subclass 870 Parent (Temporary) Visa sponsorship applications will be open for applications on 17 April 2019.</li>
          <li>A sponsorship application will need to be approved before a sponsored parent will be able to apply for a Sponsored Parent (Temporary) visa. Applications for the visa are intended to open from 1 July 2019.</li>
          <li>Eligible parents must be the biological, adoptive, or step-parent of the sponsor, who must be an Australian citizen, Australian permanent resident, or eligible New Zealand citizen.</li>
          <li>Parents will be able to stay for up to five years at a time without departing and the Department has announced that up to 15,000 per year will be granted.</li>
          </div>
          </div>
        </div>
        <div style={{paddingTop:"24px"}}>
        <div className='AboutUsVisa_box_fields_content'>
          <div className='AboutUsVisa_box_desc'>Other features include:
          <li>No Balance of Family test will be applied</li>
          <li>No work’ conditions will be applied</li>
          <li>Require health insurance</li>
          <li>Must provide evidence of access to funds</li>
          <li>The Sponsored Parent (Temporary) Visa will replace the current three year and five year Tourist visas, which are available for parents of Australian citizens and permanent residents.</li>
          <li>Although the new Sponsor Parent visa is more expensive than the Parent Tourist visa, the main benefit is that parents won’t need to leave the country every twelve months. Parent will have to leave the country for at least 90 days, after five years.</li>
          <li>For more information about the new <u onClick={() =>{ handleMenuItemClick('/FamilyVisa'); window.scrollTo(0,0);}} style={{ color: '#B0383D', cursor: 'pointer',fontSize:"16px" }}>Sponsored Parent Visa click here</u></li>
          <li>There are a number of different visa options for parents. <u onClick={() =>{ handleMenuItemClick('/FamilyPartner'); window.scrollTo(0,0);}} style={{ color: '#B0383D', cursor: 'pointer',fontSize:"16px" }}> See all parent visas</u></li></div>
        </div>
        </div>
        </div>
        <div className='ConsultationComponent_field'>
            <ConsultationComponent
            title="Are You Eligible?"
            description="Take our online eligibility assessment to find out if you meet the requirements for the 482 Visa            "
            button="Check Eligibility"
            />
             <ConsultationComponent 
            title="Book A Consultation"
            description="Speak to one of our migration agents and simply book a consultation. We'll assist you in understanding the costs involved"
            button="Consult"
            />
             <ConsultationComponent
            title="Get A Quote"
            description="Have a visa in mind? Contact us for a fixed price quote. We'll assist you in understanding the costs involved.            "
            button="Get a quote"
            />
            </div>
            <Footer/>
    </div>
  )
}

export default TemporaryParentVisa