import React from 'react'
import "../styles/VisaYourOption.css"

function VisaYourOption2(props) {
    // const navigate = useNavigate();
    const handleButtonClick = (onClick) => {
      if (typeof onClick === 'function') {
        onClick();
      } else {
        console.error('Invalid onClick function provided');
      }
    };
  return (
    <div className='VisaFinderOtion_layout'>
        <div className='VisaFinderOtion_Title'>{props.Head}</div>
        <div className='VisaFinderOtion_content'>
            <div className='VisaFinderOtion_content_box_tri'>
                <div className='VisaFinderOtion2_content_box_tite'>{props.title1}</div>
                <div className='VisaFinderOtion2_content_box_desc '>{props.desc1}</div>
                <div className='VisaFinderOtion_content_box_btn' onClick={() => handleButtonClick(props.onClick1)}>{props.btn1}</div>
            </div>
            <div className='VisaFinderOtion_content_box_tri'>
            <div className='VisaFinderOtion2_content_box_tite'>{props.title2}</div>
                <div className='VisaFinderOtion2_content_box_desc '>{props.desc2}</div>
                <div className='VisaFinderOtion_content_box_btn'onClick={() => handleButtonClick(props.onClick2)}>{props.btn2}</div>
            </div>
            <div className='VisaFinderOtion_content_box_tri'>
                <div className='VisaFinderOtion2_content_box_tite'>{props.title3}</div>
                <div className='VisaFinderOtion2_content_box_desc '>{props.desc3}</div>
                <div className='VisaFinderOtion_content_box_btn' onClick={() => handleButtonClick(props.onClick3)}>{props.btn1}</div>
            </div>
        </div>
        <div className='VisaFinderOtion_content'>
            <div className='VisaFinderOtion_content_box_double'>
                <div className='VisaFinderOtion2_content_box_tite'>{props.title4}</div>
                <div className='VisaFinderOtion2_content_box_desc '>{props.desc4}</div>
                <div className='VisaFinderOtion_content_box_btn' onClick={() => handleButtonClick(props.onClick4)}>{props.btn1}</div>
            </div>
            <div className='VisaFinderOtion_content_box_double'>
            <div className='VisaFinderOtion2_content_box_tite'>{props.title5}</div>
                <div className='VisaFinderOtion2_content_box_desc '>{props.desc5}</div>
                <div className='VisaFinderOtion_content_box_btn'onClick={() => handleButtonClick(props.onClick5)}>{props.btn2}</div>
            </div>
        </div>
    </div>
  )
}

export default VisaYourOption2