import React, { useEffect, useRef, useState } from 'react'
import '../styles/HeroBanner.css'

const HeroAdmin = (props) => {
  
    const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
    const videoSources = props.Banner;
    const videoRef = useRef(null);
  
    const handleVideoEnd = () => {
      setCurrentVideoIndex((prevIndex) => (prevIndex + 1) % videoSources.length);
    };
  
    useEffect(() => {
      const videoElement = videoRef.current;
  
      const handleNextVideo = () => {
        setCurrentVideoIndex((prevIndex) => (prevIndex + 1) % videoSources.length);
      };
  
      videoElement.addEventListener('ended', handleNextVideo);
  
      // Cleanup event listener when component unmounts
      return () => {
        videoElement.removeEventListener('ended', handleNextVideo);
      };
    }, [videoSources]);
  
    useEffect(() => {
      // Reset the video to the beginning when the currentVideoIndex changes
      videoRef.current.currentTime = 0;
    }, [currentVideoIndex]);
  
    const handleConnectButtonClick = () => {
        const phoneNumber = "+91 9663961038";
        const whatsappWebLink = `https://web.whatsapp.com/send?phone=${phoneNumber}`;
      
        const isUserLoggedIn = localStorage.getItem("WABrowserId") !== null;
        window.open(isUserLoggedIn ? whatsappWebLink : "https://web.whatsapp.com/", '_blank');
      };
      
    return (
        <>
            <div className='HeroBanner'>
                <div className='Banner-image '>
                <video ref={videoRef} className='HeroBannerImage' autoPlay muted loop playsInline onEnded={handleVideoEnd}>
            <source className='hero-image' src={videoSources[currentVideoIndex]} type='video/mp4' />
          </video>


                </div>
                <div className='hero'>
                    <div className='hero-styling'>
                        <div className='hero-content'>
                            <div className='hero-heading'>
                                {props.heroHeading}
                            </div>
                            <div className='heroStyling'>
                                <div className='hero-subheading'>
                                    {props.heroSubHeading}
                                </div>
                                {/* <div className='connectButton'>
                                    <button type='button' className='connect-button' onClick={handleConnectButtonClick}>{props.connectButton}</button>
                                    
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className='dotted-lines'>
                        <img src={props.DottedLines} alt='dots' />
                    </div>
                </div>
            </div>
        </>
    )
}

export default HeroAdmin