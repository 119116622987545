import React from 'react'
import "../styles/EnglishComp.css"

function EnglishComp() {
  return (
    <div className='English_comp_layout'>
        <div className='English_Comp_title'>English Language Courses</div>
        <div className='English_Comp_desc'>
          We offer a range of English language courses to help you improve your language skills, whether you are a student preparing for university studies or a migrant looking to improve your chances of success in Australia.
          <ul style={{fontSize:"18px"}}>
            <li>IELTS coaching</li>
            <li>PTE coaching</li>
            <li>French</li>
            <li>German</li>
            <li>Mandrin classes</li>
            <li>TOEFL</li>
          </ul>
          </div>

    </div>
  )
}

export default EnglishComp