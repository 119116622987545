import React from "react";
import EmployerSponsered from "./pages/EmployerSponsered";
import { BrowserRouter, Route, Routes ,HashRouter } from "react-router-dom";
import LabourAgreement from "./pages/LabourAgreement";
import EmployerSponseredLanding from "./pages/EmployerSponseredLanding";
import Visa187 from "./pages/Visa187";
import VisaExemptions from "./pages/VisaExemptions";
import Visa187Screen from "./pages/Visa187Screen";
import EnglishExemptions from "./pages/EnglishExemptions";
import AgeExemption from "./pages/AgeExemption";
import SkillExemptions from "./pages/SkillExemptions";
import EmployerTssVisa from "./pages/EmployerTssVisa";
import Visa186Overview from "./pages/Visa186Overview";
import Visa186Entry from './pages/Visa186Entry'
import Visa186Transition from './pages/Visa186Transition'
import Visa186Family from './pages/Visa186Family'
import Perm187 from './pages/Perm187'
import Perm186 from './pages/Perm186'
import PermOverview from './pages/PermOverview'
import VisaMediumTerm from './pages/VisaMediumTerm';
import VisaShortTerm from './pages/VisaShortTerm';
import LangingPage from './pages/LangingPage';
import Overview494 from './pages/Overview494';
import Applicant494 from './pages/Applicant494';
import Family494 from './pages/Family494';
import Option1Overview from "./components/Option1Overview";
import Option1Eligibility from "./components/Option1Eligibility";
import Option2Overview from "./components/Option2Overview";
import Option2Eligibility from "./components/Option2Eligibility";
import Option1Family from "./components/Option1Family";
import Option2Family from "./components/Option2Family";
import Option3Overview from "./components/Option3Overview";
import Option4Overview from "./components/Option4Overview";
import VisaOptions457 from "./pages/VisaOptions457";
import VisaProvision457 from "./pages/VisaProvision457";
import PermanentEmployerSponseredLanding from "./pages/PermanentEmployerSponseredLanding";
import FamilyVisaPartner from "./pages/FamilyVisaPartner";
import FamilyPartnerVisaLanding from "./pages/FamilyPartnerVisaLanding";
import OnshorePartner from "./pages/OnshorePartner";
import OffShorePartner from "./pages/OffShorePartner";
import MarriagePartner from "./pages/MarriagePartner";
import SkilledVisa190 from "./pages/SkilledVisa190";
import NzFamily from "./pages/NzFamily";
import FamilyParent from "./pages/FamilyParent";
import FamilyChild from "./pages/FamilyChild";
import TemporaryParentVisa from "./pages/TemporaryParentVisa";
import Family457Visa from "./pages/FamilyVisa457";
import Family482Visa from "./pages/Family482Visa";
import HopeFaq from "./pages/HopeFaq";
import InternationalRecruitment from "./pages/InternationalRecruitment";
import SkilledVisa from "./pages/SkilledVisa";
import SkilledVisa191 from "./pages/SkilledVisa191"
import SkilledVisa187 from "./pages/SkilledVisa189";
import SkilledVisa489 from "./pages/SkilledVisa489"
import SkilledVisa491 from "./pages/SkilledVisa491"
import SkilledVisa189 from "./pages/SkilledVisa189";
import CountryLandingPage from "./pages/CountryLandingPage";
import OverSeaStudy from "./pages/OverSeaStudy";
import PermOverviewLanding from "./pages/PermOverview";
import PermEmpOverview from "./pages/PermEmpOverview";
import Employer186 from "./pages/Employer186";
import ContactUs from "./pages/ContactUs";
import Services from "./pages/Services";
import Visa494 from "./pages/Visa494";
import Visa457Emp from "./pages/Visa457Emp";
import Blog from "./pages/Blog";
import DeatiledBlog from "./pages/DeatiledBlog";
import IndividualVisa from "./pages/IndividualVisa";
import CitizenScreen from "./pages/CitizenScreen";
import VisaFinderLandingScreen from "./pages/VisaFinderLandingScreen";
import LwTemporarily from "./pages/LwTemporarily";
import LwPermanently from "./pages/LwPermanently";
import JoinFamily from "./pages/JoinFamily";
import StudyDevelop from "./pages/StudyDevelop";
import VisitWork from "./pages/VisitWork";
import WorkinHoliday from "./pages/WorkinHoliday";
import VisitorVisa from "./pages/VisitorVisa";
import VisaFinderGraduate from "./pages/VisaFinderGraduate";
import VisaFinderStudent from "./pages/VisaFinderStudent";
import VisaGSM from "./pages/VisaGSM";
import VisaPP from "./pages/VisaPP";
import VisaPES from "./pages/VisaPES";
import VisaNz from "./pages/VisaNz";
import VisaInnovation from "./pages/VisaInnovation";
import VisaWorkingHoliday from "./pages/VisaWorkingHoliday";
import TempGraduateVisa from "./pages/TempGraduateVisa";
import VisitoFinderrVisa from "./pages/VisitoFinderrVisa";
import JobVacancies from "./pages/JobVacancies";
import Tools from "./pages/Tools";
import RmsPostCode from "./pages/RmsPostCode";
import Gms from "./pages/Gms";
import ContactForm from "./pages/Form";
import Login from "./pages/Login";
import DeatiledBlog2 from "./pages/DetailedBlog2";
import BlogAdmin from "./components/BlogAdmin";
import DeatiledBlogUser from "./pages/DetailedBlogUsers";
import Links from "./pages/Links";
import Login2 from "./pages/Login2";
import SurveyForm from "./pages/SurveyForm";
import PointTest from "./pages/PointTest";
import PrivacyPage from "./pages/PrivacyPage";
// import InternationalRecruitment from "./pages/InternationalRecruitment"



function App() {


  return (
    <HashRouter>
      <Routes>
      <Route path="/employerSponsered" element={ <EmployerSponsered />} />
      <Route path="/employerLanding" element={ <EmployerSponseredLanding />} />
      <Route path="/labourAgg" element={ <LabourAgreement />} />
      <Route path="/Visa187" element={ <Visa187 />} />
      <Route path="/VisaExemption" element={ <VisaExemptions />} />
      <Route path="/Visa187Screen" element={ <Visa187Screen />} />
      <Route path="/EnglishExemptions" element={ <EnglishExemptions />} />
      <Route path="/AgeExemptions" element={ <AgeExemption />} />
      <Route path="/SkillExemptions" element={ <SkillExemptions />} />
      <Route path="/EmployerTssVisa" element={ <EmployerTssVisa />} />
      <Route path="/" element={<LangingPage/>} />
      <Route path="/faq" element={<HopeFaq/>} />
      <Route path="/OverSeaStudy" element={<OverSeaStudy/>} />
      <Route path="/countryLanding" element={<CountryLandingPage/>} />
      <Route path="/SkilledVisa" element={<SkilledVisa/>} />
      <Route path="/InternationalRecruitment" element={<InternationalRecruitment/>} />
        <Route path="/VisaShortTerm" element={<VisaShortTerm/>} />
        <Route path="/VisaMediumTerm" element={<VisaMediumTerm/>} />
        <Route path="/PermEmpOverview" element={<PermEmpOverview/>} />
        <Route path="/Employer186" element={<Employer186/>} />
        <Route path="/Permoverview" element={<PermOverviewLanding/>} />
        <Route path="/PermanentEmployer" element={<PermanentEmployerSponseredLanding/>} />
        <Route path="/Perm186" element={<Perm186/>} />
        <Route path="/Perm187" element={<Perm187/>} />
        <Route path="/Visa186Overview" element={<Visa186Overview/>} />
        <Route path="/Visa186Entry" element={<Visa186Entry/>} />
        <Route path="/Visa186Transition" element={<Visa186Transition/>} />
        <Route path="/Visa186Family" element={<Visa186Family/>} />
        <Route path="/Overview494" element={<Overview494/>} />
        <Route path="/Family494" element={<Family494/>} />
        <Route path="/Applicant494" element={<Applicant494/>} />
        <Route path="/Option1Overview" element={<Option1Overview/>} />
        <Route path="/Option1Eligibility" element={<Option1Eligibility/>} />
        <Route path="/Option1Family" element={<Option1Family/>} />
        <Route path="/Option2Overview" element={<Option2Overview/>} />
        <Route path="/Option2Eligibility" element={<Option2Eligibility/>} />
        <Route path="/Option2Family" element={<Option2Family/>} />
        <Route path="/Option3Overview" element={<Option3Overview/>} />
        <Route path="/Option4Overview" element={<Option4Overview/>} />
        <Route path="/VisaOptions457" element={<VisaOptions457/>} />
        <Route path="/VisaProvision457" element={<VisaProvision457/>} />
        <Route path="/FamilyVisa" element={<FamilyVisaPartner/>} />
        <Route path="/SkilledVisa189" element={<SkilledVisa189/>} />
        <Route path="/Visa190" element={<SkilledVisa190/>} />
        <Route path="/Visa191" element={<SkilledVisa191/>} />
        <Route path="/Visa489" element={<SkilledVisa489/>} />
        <Route path="/Visa491" element={<SkilledVisa491/>} />
        <Route path="/Family482" element={<Family482Visa/>} />
        <Route path="/Nz" element={<NzFamily/>} />
        <Route path="/FamilyParent" element={<FamilyParent/>} />
        <Route path="/Family457Visa" element={<Family457Visa/>} />
        <Route path="/child" element={<FamilyChild/>} />
        <Route path="/TemporaryParentVisa" element={<TemporaryParentVisa/>} />
        <Route path="/FamilyPartner" element={<FamilyPartnerVisaLanding/>} />
        <Route path="/OnshorePartner" element={<OnshorePartner/>} />
        <Route path="/OffShorePartner" element={<OffShorePartner/>} />
        <Route path="/MarriagePartner" element={<MarriagePartner/>} />
        <Route path="/EmployerSponsered482" element={<EmployerSponsered/>} />
        <Route path="/InternationalRecruitment" element={<InternationalRecruitment/>} />
        <Route path="/ContactUs" element={<ContactUs/>} />
        <Route path="/Services" element={<Services/>} />
        <Route path="/Visa494" element={<Visa494/>} />
        <Route path="/Visa457Emp" element={<Visa457Emp/>} />
        <Route path="/blog" element={<Blog/>} />
        <Route path="/detailed-blog/:id" element={<DeatiledBlog/>} />
        <Route path="/DeatiledBlogUser/:id" element={<DeatiledBlogUser/>} />
        <Route path="/IndividualVisa" element={<IndividualVisa/>} />
        <Route path="/CitizenScreen" element={<CitizenScreen/>} />
        <Route path="/VisaFinderLandingScreen" element={<VisaFinderLandingScreen/>} />
        <Route path="/LwTemporarily" element={<LwTemporarily/>} />
        <Route path="/LwPermanently" element={<LwPermanently/>} />
        <Route path="/JoinFamily" element={<JoinFamily/>} />
        <Route path="/StudyDevelop" element={<StudyDevelop/>} />
        <Route path="/VisitWork" element={<VisitWork/>} />
        <Route path="/WorkinHoliday" element={<WorkinHoliday/>} />
        <Route path="/VisitorVisa" element={<VisitorVisa/>} />
        <Route path="/VisaFinderGraduate" element={<VisaFinderGraduate/>} />
        <Route path="/VisaFinderStudent" element={<VisaFinderStudent/>} />
        <Route path="/VisaGSM" element={<VisaGSM/>} />
        <Route path="/VisaPP" element={<VisaPP/>} />
        <Route path="/VisaPES" element={<VisaPES/>} />
        <Route path="/VisaInnovation" element={<VisaInnovation/>} />
        <Route path="/VisaNz" element={<VisaNz/>} />
        <Route path="/VisaWorkingHoliday" element={<VisaWorkingHoliday/>} />
        <Route path="/TempGraduateVisa" element={<TempGraduateVisa/>} />
        <Route path="/VisitoFinderrVisa" element={<VisitoFinderrVisa/>} />
        <Route path="/JobVacancies" element={<JobVacancies/>} />
        <Route path="/tools" element={<Tools/>} />
        <Route path="/RmsPostCode" element={<RmsPostCode/>} />
        <Route path="/Gms" element={<Gms/>} />
        <Route path="/ContactForm" element={<ContactForm/>} />
        {/* <Route path="/Login" element={<Login/>} /> */}
        <Route path="/Login" element={<Login2/>} />
        <Route path="/DeatiledBlog2" element={<DeatiledBlog2/>} />
        <Route path="/BlogAdmin" element={<BlogAdmin/>} />
        <Route path="/links" element={<Links/>} />
        <Route path="/SurveyForm" element={<SurveyForm/>} />
        <Route path="/PointTest" element={<PointTest/>} />
        <Route path="/PrivacyPage" element={<PrivacyPage/>} />

      </Routes>
    </HashRouter>
   
  );
}

export default App;