import React from 'react'
import Navbar from '../components/Navbar'
import DottedLines from '../images/DottedLines.png'
import PermanentEmpBanner from '../components/PermanentEmpBanner'
import Passport from '../images/passport.png'
import Applicant494Comp from '../components/Applicant494Comp'
import Footer from '../components/Footer'
const Applicant494 = () => {
  return (
    <>
      <Navbar />
      <PermanentEmpBanner
        heroHeading='Skilled Employer Sponsored Regional (Provisional) Subclass 494'
        connectButton='Let’s Connect'
        Banner={Passport}
        DottedLines={DottedLines}
      />
      <Applicant494Comp/>
      <Footer/>
    </>
  )
}

export default Applicant494