import React, { useState } from 'react';
import ContactUsBanner from '../images/ConatctUsBanner.mp4';
import DottedLines from '../images/DottedLines.png';
import NavBarComponent from '../components/NavBarComponent';
import HeroBanner from '../components/HeroBanner';
import HeadOffice from '../components/HeadOffice';
import Footer from '../components/Footer';
// import "../styles/contactUs.css";
import * as yup from 'yup';
import "../styles/contactUs.css"
import { useFormik } from 'formik'; 
import { Button, TextField } from '@mui/material';

function ContactUs() {
  const phoneRegExp = /^([6789]{1})([\d]{3})[(\D\s)]?[\d]{3}[(\D\s)]?[\d]{3}$/;
  const emailRegExp = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
  const fullNameRegExp = /^(?!.\s{2})(?!.[.'-]{2})[A-Z][A-Za-z' ]{0,48}[A-Za-z]$/;
  const validationSchema = yup.object({
    email: yup
      .string("Enter your email")
      .email("Enter a valid email")
      .matches(emailRegExp, "Email is not valid")
      .trim()
      .required("Email is required"),
    phone: yup
      .string()
      .matches(phoneRegExp, "Phone number is not valid")
      .required("Phone number is required")
      .min(10, "Phone number must consist of 10 digits")
      .trim()
      .max(10, "Phone number must consist of 10 digits"),
    name: yup
      .string("Enter Your First Name")
      .matches(fullNameRegExp, "Name is invalid")
      .trim()
      .required("Name is required"),
 
  });
 
  const [loading, setLoading] = useState(false);
  
  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      business: '',
      phone: '',
    },
    // validationSchema: validationSchema,
    onSubmit: async () => {
      try {
        setLoading(true);
        const response = await fetch(
          `https://prod-17.southindia.logic.azure.com:443/workflows/21b31796c962432a810f908ca44c9922/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=1prHC8t6hpieaBRUmuD0fuaKB1YHTJj_V8d0e3gW97c`,
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-type": "application/json",
            },
            body: JSON.stringify({
              "to" :'bengaluru@hopegroup.com.au',
              "subject":'Hope enquiry ',
              name: formik.values.name.trim(),
              email: formik.values.email,
              phone:  formik.values.phone.trim(),
              message: formik.values.business.trim(),
            }),
          }
        );
        if (response.status === 204) {
          setLoading(false);
          formik.resetForm();
          console.log(response)
        } else {
          setLoading(false);
          console.error('Form submission failed:', response.statusText);
        }
      } catch (error) {
        setLoading(false);
        console.error('Error submitting form:', error);
      }
    }
  });
  
  return (
    <div>
        <NavBarComponent />
        <HeroBanner
        heroHeading='Contact Us'
        heroSubHeading=''
        connectButton='Let’s Connect'
        Banner={[ContactUsBanner]}
        DottedLines={DottedLines}
        />
        <div className='Conatct_us_container'>
            <div className='Conatct_us_container_left'>We Would Love To Hear From You!</div>
            <div className='Conatct_us_container_right'>
            <form onSubmit={formik.handleSubmit} className='help-screen-form'>
          
          <TextField
            type="text"
            name="name"
            className='help-form-textField'
            value={formik.values.name}
            placeholder='Enter Full Name'
            onChange={formik.handleChange}
            required
          />
           {/* <TextField
            type="text"
            name="name"
            className='help-form-textField'
            value={formik.values.name}
            placeholder='Enter Full Name'
            onChange={formik.handleChange}
            required
          /> */}

          <TextField
            type="email"
            className='help-form-textField'
            name="email"
            placeholder='Enter Email Address'
            value={formik.values.email}
            onChange={formik.handleChange}
            required
          />
          <TextField
            type="tel"
            name="phone"
            placeholder='Enter Phone Number'
            className='help-form-textField'
            value={formik.values.phone}
            onChange={formik.handleChange}
            required
          />
           <textarea
            type="text"
            className='help-form-textField_area'
            name="business"
            placeholder='Enter Query'
            value={formik.values.business}
            onChange={formik.handleChange}
            required
          />
          <input type="submit" value="Let's connect" className='submit-button-help'/>
        </form>
            </div>
        </div>
        <HeadOffice />
        <Footer />
    </div>
  );
}

export default ContactUs;
