import React from 'react'
import "../styles/TssOption2.css"
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function TssOption2() {
    const [activeTab, setActiveTab] = useState('Overview');
    const navigate = useNavigate();
    const handleTabClick = tabName => {
      setActiveTab(tabName);
    };   
    const handleNavigateSkilled = () => {
      window.scrollTo(0,0);
       navigate('/SkilledVisa')
    }  
    const handleNavigate186 = () => {
      window.scrollTo(0,0);
       navigate('/SkilledVisa')
    } 
    const handleNavigateRegional = () => {
      window.scrollTo(0,0);
       navigate('/Visa457Emp')
    }  
    const handleNavigateMedium = () => {
      window.scrollTo(0,0);
       navigate('/employerSponsered')
    }  
  return (
    <div className='option3_layout'>
          <div className='TssOption2_heading'>Option 2: Temporary Residence Transition Stream (TRT)</div>
           <div className="TabsContainer_option1">
      <div
        className={`Tab ${activeTab === 'Overview' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('Overview')}
      >
        Overview
      </div>
      <div
        className={`Tab ${activeTab === 'Eligibility' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('Eligibility')} >
        Eligibility
      </div>
      <div
        className={`Tab ${activeTab === 'IncludingFamily' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('IncludingFamily')}
      >
        Including Family
      </div>  
    </div>
    {activeTab === 'Overview' &&
       <div className='Tabs_container_layout'>
        <div className='Tss_Overview_container_option2'>
       <li> TSS Visa holders who have not been working in Australia for three years, or who have changed employers while working in Australia, may still be eligible to apply for Permanent Residency through the Direct Entry Stream. Applicant’s occupation must be listed on the medium long-term skilled occupation list.</li>
       <li>Your occupation must be listed on one of the <u onClick={handleNavigateSkilled} style={{ color: '#B0383D', cursor: 'pointer',fontSize:"24px" }}>  Skilled Occupation Lists.</u></li>
        </div>
        </div>
        }
        {activeTab === 'Eligibility' &&
       <div className='Tabs_container_layout'>
       <div className='Tss_Eligibility_Container'>
       <div className='Tss_Eligibility_fields_end_option2'>
                <div className='VisaFamily_Box_desc'><li>You may be able to apply for Permanent Residency through the direct entry stream if you meet the following requirements</li></div>
              
        </div>
        <div className='Tss_Eligibility_fields'>
            <div className='Tss_Eligibility_fields_container_option2'>
            <div className='AboutUsVisa_box_title'>Work Experience</div>
                <div className='VisaFamily_Box_desc'><li>You must have at least 3 years work experience in your nominated occupation</li>
                 </div>
                 <hr className='Divider' />
                 <div className='AboutUsVisa_box_title'>Skills</div>
                <div className='VisaFamily_Box_desc'><li>You will need to undertake a skill assessment before applying for a 186 or 187 Visa through the direct entry stream unless exempt. <u onClick={handleNavigate186} style={{ color: '#B0383D', cursor: 'pointer',fontSize:"16px" }}>See 186 & 187 Visa skill exemptions</u></li>
                 </div>
            </div>
            <div className='Tss_Eligibility_fields_container_option2'>
            <div className='AboutUsVisa_box_title'>Occupation</div>
                <div className='VisaFamily_Box_desc'><li>186 Visa (ENS) Applicants
Your occupation must be on the <u onClick={handleNavigateMedium} style={{ color: '#B0383D', cursor: 'pointer',fontSize:"16px" }}>  Medium and Long-term Strategic Skills List (MLTSSL)</u></li>
<li>187 Visa (RSMS) Applicants
Your occupation must be on the<u onClick={handleNavigateRegional} style={{ color: '#B0383D', cursor: 'pointer',fontSize:"16px" }}>  Regional Occupation List (ROL)</u></li>
               
<hr className='Divider' />
<div style={{marginBottom:'24px'}}></div>
<div className='Tss_Eligibility_fields_container_option2_TRT'>
    <div className='trt_fields'>
<div className='AboutUsVisa_box_title'>English</div>
<div className='VisaFamily_Box_desc'>You must demonstrate competent English</div>
</div>
<hr className='DividerVertical' />
<div className='trt_fields'>
<div className='AboutUsVisa_box_title'>Health & Character</div>
<div className='VisaFamily_Box_desc'>Meet the health and character requirements (Unless you are exempt)</div>
</div>
</div>

              
              </div>
            </div>
        </div>
        <div className='Tss_Eligibility_fields_end_option2'>
<div className='AboutUsVisa_box_title'>Age</div>
                <div className='VisaFamily_Box_desc'><li>You are under 45 years of age or you can be older than 45 years if;</li>
                <li>You are nominated under an eligible ANZCO</li></div>
              
        </div>
       </div>
      
        </div>
        }
          {activeTab === 'IncludingFamily' &&
        <div className='Tabs_container_layout'>
        <div className='Tss_Eligibility_Container'>
         <div className='Tss_Eligibility_fields'>
             <div className='Tss_Eligibility_fields_container'>
             <div className='AboutUsVisa_box_title'>General</div>
                 <div className='VisaFamily_Box_desc'>Applicants can include the following people in their transitional permanent residency application:
                   <li>Dependent children/step-children</li>
                   <li>Partner</li>
                 </div>
             </div>
             <div className='Tss_Eligibility_fields_container'>
             <div className='AboutUsVisa_box_title'>Health & Character</div>
                 <div className='VisaFamily_Box_desc'>
                 <li>All family members must meet the health and character requirements. All applicants over 16 must provide police clearances.</li>
                  <li>If you have any questions about these requirements or would like more information on who you can include on your 457 to PR application speak to one of our migration experts</li>
               </div>
             </div>
         </div>
         <div className='Tss_Eligibility_fields'>
             <div className='Tss_Eligibility_fields_container'>
             <div className='AboutUsVisa_box_title'>Partner</div>
                 <div className='VisaFamily_Box_desc'>Your married or de facto partner can be the same or opposite sex. Applicants must prove:
                 <li>The relationship is genuine and continuing</li>
                 <li>You live together, or do not live separately on a permanent basis.</li>
                 <li>Your partner is at least 18 years of age when the application is lodged (there are some exceptions)</li>
                 <li>You are not related by family (if you are in a de facto relationship)</li>
                 <li>You and your partner have a mutual commitment to a shared life to the exclusion of all others</li>
                
                 </div>
             </div>
             <div className='Tss_Eligibility_fields_container'>
             <div className='AboutUsVisa_box_title'>English</div>
                 <div className='VisaFamily_Box_desc'>
                 Applicants over 18 must demonstrate Functional English. You can demonstrate Functional English without IELTS if you:
                 <li>Completed all years of primary education and at least three years of secondary education in an educational institution in or outside Australia and all instructions were in English. or</li>
                 <li>Completed at least five years of secondary education in an institution in or outside Australia and all instructions were in English.</li>
                </div>
             </div>
         </div>
         <div className='Tss_Eligibility_fields_end'>
         <div className='AboutUsVisa_box_title'>Children</div>
                 <div className='VisaFamily_Box_desc'>To be eligible to be included or added to a TSS visa, a child must:
                 <li>Be your child or a stepchild from a current or a previous relationship (in certain circumstances);</li>
                 <li>Not be married, engaged to be married, or have a de facto partner; and must be:</li>
                 <li>Under 18 years of age or</li>
                 <li>Over 18 years of age but not yet turned 23, and be dependent on you or your partner or</li>
                 <li>Over 23 years of age and be unable to earn a living to support themselves due to physical or cognitive limitations and be dependent on you or your partner (Note: The child will still need to meet Australia’s health requirement)or</li>
                 <li>A dependent child of a child who is eligible under 1, 2 or 3 above.</li>
</div>
               
         </div>
        </div>
       
         </div>
        }
    </div>
  )
}

export default TssOption2