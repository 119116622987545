import React from 'react'
import "../styles/subNav.css";
import { useNavigate } from 'react-router-dom';

function SubNavFamily() {
    const navigate = useNavigate();

    const handleNavigationPartnerVisa = () => {
      window.scrollTo(0,0);
        navigate("/FamilyPartner");
    };

    const handleNavigation457FamilyVisa = () => {
      window.scrollTo(0,0);
        navigate("/Family457Visa");
    };
 const handleNavigationCitizenFamilyVisa =() =>{
   window.scrollTo(0,0);
    navigate("/Nz")
 }
 const handleNavigationChildVisa = () => {
   window.scrollTo(0,0);
    navigate("/child")
 }
 const handleNavigationTss = () => {
   window.scrollTo(0,0);
    navigate('/Family482')
 }
 const handleNavigationTempParentVisa = () => {
   window.scrollTo(0,0);
    navigate('/TemporaryParentVisa')
 }
 const handleVisaPermanentParentVisa = () => {
   window.scrollTo(0,0);
    navigate('/FamilyParent')
 }
  return (
    <div className='sub-nav-container'>
            <div onClick={handleNavigationPartnerVisa} className='sub_nav_container'>Partner Visa</div>
            <div onClick={handleNavigation457FamilyVisa} className='sub_nav_container'>457 Family Visa</div>
            <div onClick={handleNavigationCitizenFamilyVisa} className='sub_nav_container'>New Zealand Citizen Family</div>
            <div onClick={handleVisaPermanentParentVisa} className='sub_nav_container'>Permanent Parent Visa </div>
            <div onClick={handleNavigationChildVisa} className='sub_nav_container'>Child Visa</div>
            <div onClick={handleNavigationTss} className='sub_nav_container'>TSS Family Visa</div>
            <div onClick={handleNavigationTempParentVisa} className='sub_nav_container'>Temporary Parent Visa</div>
        
     
        </div>
  )
}

export default SubNavFamily