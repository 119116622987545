import React from 'react'
import Heading from '../components/Heading'
import ConsultationComponent from '../components/ConsultationComponent'

function EnglishExemptions() {
  return (
  
          <div>
         <Heading heading="English Exemptions"/>
         <div className='VisaFamily_Box'>
            <li>If you are applying for a 186 or a 187 Visa, you must meet the English requirements, unless you meet one of the below exemptions:</li>
        </div>
        <div className='VisaFamily_Box'>
                <div className='AboutUsVisa_box_title'>DIRECT ENTRY STREAM</div>
                <div className='VisaFamily_Box_desc'><li>You are applying for the 187 RSMS Visa through the Temporary Residence Transition stream and have completed at least five years of full-time study in secondary or higher education where the medium of instruction was delivered in English.</li>
                <li>You are applying through the Labour Agreement Stream and can prove that your english skills are suitable to perform your current occupation. This will be the same level of English as for the other visa streams outlined above unless otherwise specified in the labour agreement.</li>
              </div>
        </div>
        <div className='ConsultationComponent_field'>
            <ConsultationComponent 
            title="View RSMS Post Codes"
            description="View our list of eligible RSMS postcodes for the Regional Sponsored Migration Scheme (RSMS) Subclass 187 Visa. "
            button="View List"
            />
             <ConsultationComponent 
            title="Book A Consultation"
            description="Speak to one of our migration agents and simply book a consultation. We'll assist you in understanding the costs involved"
            button="Consult"
            />
             <ConsultationComponent
            title="Get A Quote"
            description="Have a visa in mind? Contact us for a fixed price quote. We'll assist you in understanding the costs involved.            "
            button="Get a quote"
            />
            </div>
    </div>
    
  )
}

export default EnglishExemptions