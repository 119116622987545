import React from 'react'
import NavBarComponent from '../components/NavBarComponent'
import Family from "../images/VisaExeBanner.mp4"
import { useState } from 'react';
import Footer from '../components/Footer';
import Visa187Screen from '../pages/Visa187Screen';
import DottedLines from '../images/DottedLines.png'
import Visa186Screen from '../pages/Visa186Screen';
import EnglishExemptions from '../pages/EnglishExemptions';
import AgeExemption from './AgeExemption';
import SkillExemptions from './SkillExemptions';
import HeroBanner from '../components/HeroBanner';

function VisaExemptions() {
    const [activeTab, setActiveTab] = useState('187Visa');

    const handleTabClick = tabName => {
      setActiveTab(tabName);
    };
  return (
    <div>
    <NavBarComponent />
    <HeroBanner
       heroHeading='186 & 187 Visa Exemptions'
       heroSubHeading='If you are applying for a Subclass 186 Visa (ENS) or a Subclass 187 Visa (RSMS), you must meet the English, age and skill requirements, unless you are exempt. See all eligibility exemptions below.'
       connectButton='Let’s Connect'
        Banner={[Family]}
        // Banner={['/video1.mp4', 'video2.mp4', 'video3.mp4']}
        DottedLines={DottedLines}
      />
    <div className="TabsContainer">
  <div
    className={`Tab ${activeTab === '187Visa' ? 'isActive' : ''}`}
    onClick={() => handleTabClick('187Visa')}
  >
    187 Visa
  </div>
  <div
    className={`Tab ${activeTab === '186Visa' ? 'isActive' : ''}`}
    onClick={() => handleTabClick('186Visa')}
  >
    186 Visa
  </div>
  <div
    className={`Tab ${activeTab === 'EnglishExemptions' ? 'isActive' : ''}`}
    onClick={() => handleTabClick('EnglishExemptions')}
  >
    English Exemptions
  </div>
  <div
    className={`Tab ${activeTab === 'AgeExemptions' ? 'isActive' : ''}`}
    onClick={() => handleTabClick('AgeExemptions')}
  >
 Age Exemptions
  </div>
  
  <div
    className={`Tab ${activeTab === 'SkillExemptions' ? 'isActive' : ''}`}
    onClick={() => handleTabClick('SkillExemptions')}
  >
  Skill Exemptions
  </div>
  
</div>
{activeTab === '187Visa' &&
   <div className='Tabs_container_layout'>
    <Visa187Screen />
  
    </div>
    }
     {activeTab === '186Visa' &&
   <div className='Tabs_container_layout'>
    <Visa186Screen />
  
    </div>
    }
     {activeTab === 'EnglishExemptions' &&
   <div className='Tabs_container_layout'>
    <EnglishExemptions />
  
    </div>
    }
     {activeTab === 'AgeExemptions' &&
   <div className='Tabs_container_layout'>
  <AgeExemption />
    </div>
    }
     {activeTab === 'SkillExemptions' &&
   <div className='Tabs_container_layout'>
  <SkillExemptions />
    </div>
    }
  
    <Footer/>
</div>
  )
}

export default VisaExemptions   