import React from 'react'
import Heading from './Heading'
import "../styles/family.css"
import ConsultationComponent from './ConsultationComponent'
import FamilyLayoutComp from './FamilyLayoutComp'

function ForEmployers() {
  return (
    <div>
        <Heading heading="For Employers" containerColor="#FFF7F1" textColor="#B0383D" />
        
        <div className='Family_extrayLay'>
                Employers must become a Standard Business Sponsor or have a labour agreement with the department, in order to sponsor overseas workers on a Employer Sponsored visa 482.
       </div>
       <FamilyLayoutComp />
            <div className='ConsultationComponent_field'>
        <ConsultationComponent title="Are You Eligible?" 
        description="Take our online eligibility assessment to find out if you meet the requirements for the 482 Visa"
        button="Check Eligibility"
        />
         <ConsultationComponent title="Book A Consultation" 
        description="Speak to one of our migration agents about the 482 Visa. Simply book a consultation."
        button="Consult"
        />
         <ConsultationComponent title="Book A Consultation" 
        description="Have a visa in mind? Contact us for a fixed price quote. We'll assist you in understanding the costs involved."
        button="Consult"
        />
            </div>
    </div>
  )
}

export default ForEmployers