import React from 'react'
import Navbar from '../components/Navbar'
import DottedLines from '../images/DottedLines.png'
import PermanentEmpBanner from '../components/PermanentEmpBanner'
import Passport from '../images/passport.png'
import Family494Comp from '../components/Family494Comp'
import Footer from '../components/Footer'

const Family494 = () => {
  return (
    <>
      <Navbar />
      <PermanentEmpBanner
        heroHeading='Skilled Employer Sponsored Regional (Provisional) Subclass 494'
        connectButton='Let’s Connect'
        Banner={Passport}
        DottedLines={DottedLines}
      />
      <Family494Comp/>
      <Footer/>
    </>
  )
}

export default Family494