import React from 'react'
import NavBarComponent from '../components/NavBarComponent'
import Footer from '../components/Footer'
import DottedLines from '../images/DottedLines.png'
import EmployerBanner from "../images/EmployerBanner.mp4"
import ContentBoadyOverview from '../components/ContentBoadyOverview'
import SubNav from '../components/SubNav'
import HelpYou from '../components/HelpYou'
import helpYou from '../images/HelpYouVideo.mp4'
import helpYou2 from '../images/FamilyBanner.mp4'
import helpYou3 from '../images/HrAnalysis.mp4'
import helpYou4 from '../images/Business14.mp4'
import HeroBanner from '../components/HeroBanner'

function EmployerSponseredLanding() {
  return (
    <div>
        <NavBarComponent />
         <HeroBanner
        heroHeading='Employer Sponsored Visas'
        heroSubHeading='Employer Sponsored Temporary and permanent Visa'
        connectButton='Let’s Connect'
        Banner={[EmployerBanner]}
        DottedLines={DottedLines}
      />
        <SubNav />
       <HelpYou
        helpYouTitle='Visa Options :'
        helpYou1={helpYou}
        helpYou2={helpYou2}
        helpYou3={helpYou3}
        helpYou4={helpYou4}
        helpDescHeading1='Temporary Skill Visa'
        helpDescHeading2="Family Visas"
        helpDescHeading3="International Recruitment"
        helpDescHeading4="Skilled Migration"
        helpDescContent1='The Employer sponsored Visa 482 allows skilled workers to live and work in Australia for an approved business for up to four years.'
        helpDescContent2="There are a number of family visas that may be suitable, such as Parent visas, Partner visas, Child visas, NZ Family visas, 457 Family visas, TSS Family visas."
        helpDescContent3="We are international recruitment experts and process visas such as the Employer Sponsored 482 TSS Visa, 186 Visa (ENS), 187 Visa (RSMS) & 494 Visa."
        helpDescContent4="We can assist with a number of skilled visa options such the 189 Visa Independent, 190 Visa State Sponsored, 489 Visa or the 491 Visa."
        helpDescLearn='Learn More'
      />
        <ContentBoadyOverview 
        heading="Why Choose Us?"

        />

        <Footer />
    </div>
  )
}

export default EmployerSponseredLanding