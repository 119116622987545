import React from 'react'
import "../styles/TssOption2.css"
import "../styles/TssOption4.css"
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function TssOption4() {
    const [activeTab, setActiveTab] = useState('Overview');

    const handleTabClick = tabName => {
      setActiveTab(tabName);
    };   
    
  const navigate = useNavigate();
    const handleMenuItemClick = (path) => {
      navigate(path);
    };
  return (
    <div className='option3_layout'>
          <div className='TssOption4_heading'>Option 4</div>
          <div className='Tabs_container_layout' style={{marginTop:"24px"}}>
        <div className='Tss_Eligibility_Container'>
        <div className='Tss_Eligibility_fields_end_option4'>
                 <div className='VisaFamily_Box_desc'><li>TSS Visa holders who are in a genuine committed relationship with an Australian citizen, Permanent Resident or eligible New Zealand citizen may be eligible to apply for an <u onClick={() =>{ handleMenuItemClick('/FamilyVisa'); window.scrollTo(0,0);}} style={{ color: '#B0383D', cursor: 'pointer',fontSize:"24px" }}>  Australian Partner Visa. </u></li>
               </div>
               
         </div>
         <div className='Tss_Eligibility_fields'>
             <div className='Tss_Eligibility_fields_container_option4'>
             <div className='AboutUsVisa_box_title'>Are You Considering the TSS Visa Route to Permanent Residency?</div>
                 <div className='VisaFamily_Box_desc'>Many people falsely believe that there is no route to permanent residency through the TSS visa, but this is far from being the truth. The 482 visa to PR route is in fact a viable option for many skilled workers. Whilst the <u onClick={() =>{ handleMenuItemClick('/EmployerSponsered482'); window.scrollTo(0,0);}} style={{ color: '#B0383D', cursor: 'pointer',fontSize:"18px" }}>  482 Temporary Skills Shortage Visa</u> is not particularly designed for those seeking PR but is rather a means for employers to sponsor workers whom they need to help them with particular roles for which there is a temporary shortage of skills, this is not to say that those on a temporary visa cannot find other options for extending their stay or even obtaining permanent residency when that visa comes to an end.
                
                 </div>
             </div>
             <div className='Tss_Eligibility_fields_container_option4'>
             <div className='AboutUsVisa_box_title'>We are the Experts in Helping You Acquire PR in Australia</div>
                 <div className='VisaFamily_Box_desc'>If you are considering the TSS visa to PR route, the team here at Hope can help turn your plans into realities. Hope is proud to be one of Australia’s leading specialists in all things related to skilled migration. All of our migration agents in Adelaide are highly trained and experienced, and we can help with everything from explaining the finer points of your visa requirements to helping you find potential employers to sponsor you. </div>
             </div>
         </div>
         <div className='Tss_Eligibility_fields_end_option4'>
         <div className='AboutUsVisa_box_title'>Contact Us Today to Find Out More Information About 482 Visa to PR</div>
                 <div className='VisaFamily_Box_desc'>To get started with your 482 visa to permanent residency journey, there is plenty of information available on our site which will allow you to see if your skills are currently in demand in Australia. Alternatively, feel free to get in touch with a member of our professional team at any time. You can arrange a personalised quote or a video consultation by filling out one of the online forms available here on our website, or you can contact us via telephone or email with specific enquiries.
                 <div style={{marginTop:"32px"}}><button className='ConsultationComponent_component_button'>LeET'S CONNECT</button></div>
               </div>
               
         </div>
        </div>
       
         </div>
      
    </div>
  )
}

export default TssOption4