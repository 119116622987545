import React from 'react'
import "../styles/PartnerVisaHeadComp.css"

function PartnerVisaHeadComp(props) {
  return (
    <div className='PartnerVisaHeadComp_layout'> 
    <div className='PartnerVisaHeadComp_Title'>{props.Heading}</div>
    <div className='Family_Box'>{props.content1} </div>
    <div className='AboutUsVisa_box_fields'>
        <div className='AboutUsVisa_box_fields_content'>
          <div className='AboutUsVisa_box_title'>{props.Title1}</div>
          <div className='AboutUsVisa_box_desc'>{props.desc1}</div>
        </div>
        <div className='AboutUsVisa_box_fields_content'>
        <div className='AboutUsVisa_box_title'>{props.Title2}</div>
          <div className='AboutUsVisa_box_desc'>{props.desc2}<div>
        </div>
        </div>
        </div>
        </div>
    </div>
  )
}

export default PartnerVisaHeadComp