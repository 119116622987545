import React, { useState } from 'react'
import Herobanner1Family from '../images/TwoPartner.mp4';
import NavBarComponent from '../components/NavBarComponent'
import DottedLines from '../images/DottedLines.png'
import HeroBanner from '../components/HeroBanner';
import { useNavigate } from 'react-router-dom';
import Footer from '../components/Footer';
import "../styles/visa.css"
import ConsultationComponent from '../components/ConsultationComponent';

function VisaPES() {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('Overview');

const handleTabClick = tabName => {
  setActiveTab(tabName);
};
  return (
    <div>
       <NavBarComponent />
    <HeroBanner
    heroHeading='Permanent Employer Sponsored Visas'
    heroSubHeading='The permanent Subclass 186 (ENS) and Subclass 187 (RSMS) visas allow skilled overseas workers to live and work in Australia permanently.'
    connectButton='Let’s Connect'
    Banner={[Herobanner1Family]}
    DottedLines={DottedLines}
  />
    <div className="TabsContainer">
  <div
    className={`Tab ${activeTab === 'Overview' ? 'isActive' : ''}`}
    onClick={() => handleTabClick('Overview')}
  >
   Overview
  </div>
  <div
    className={`Tab ${activeTab === '186Visa' ? 'isActive' : ''}`}
    onClick={() => handleTabClick('186Visa')}
  >
   186 Visa
  </div>
  <div
    className={`Tab ${activeTab === '187Visa' ? 'isActive' : ''}`}
    onClick={() => handleTabClick('187Visa')}
  >
  187 Visa
  </div>
  </div>

  {activeTab === 'Overview' &&
       <div className='Tabs_container_layout'>
          <div className='LabourAgreement_extrayLay'>
      <div className='LabourAgreement_section-container_title'>
      The permanent Subclass 186 (ENS) and Subclass 187 (RSMS) visas allow skilled overseas workers to live and work in Australia permanently.
            </div>
      <div className='LabourAgreement_section-container_desc_extrayLay'>The Subclass and 187 Visa have three streams:

DIRECT ENTRY STREAM: For applicants who are formally qualified or have sufficient experience in an eligible occupation and location.

TRANSITIONAL STREAM: For 457 or TSS 482 Visa holders who have worked for their nominating employer for a minimum of two or three years.

AGREEMENT STREAM: For applicants who are sponsored by an employer under a labour agreement.
      </div>
    </div>
    <div className='VisaPes_Box'>
          <div className='VisaPes_Box_title'>How it works</div>
          <div className='VisaPes_Box_desc'>Contact us to discuss your eligibility or to find an employer.
We assist you to collect the necessary documents.
Our agents submit your application to Immigration.
Our agents liaise with Immigration until your application is finalised.</div>
         </div>
        </div>
  }
  {activeTab === '186Visa' &&
       <div className='Tabs_container_layout'>
          <div className='LabourAgreement_extrayLay'>
      <div className='LabourAgreement_section-container_title'>
      ONSHORE PARTNER or SPOUSE VISAS (Subclass 820/801)
            </div>
      <div className='LabourAgreement_section-container_desc_extrayLay'>This is a permanent visa for skilled foreign workers who have a nominating employer in Australia.
Applicants can be in or outside Australia when they apply for this visa.

DIRECT ENTRY STREAM (DE)
<li>Applicants must have an occupation listed on the MLTSSL</li>
<li>Applicants must be nominated by an Australian employer</li>
<li>Applicants must perform a Skill Assessment through the authorized assessing authority.</li>

TEMPORARY RESIDENCE TRANSITION STREAM (TRT)
<li>Applicants must hold a 457 or 482 TSS Visa</li>
<li>Applicants must be nominated by an Australian Employer</li>
<li>Applicants may not need to perform a Skill Assessment</li>
      </div>
      
    </div>
    <div className='VisaPes_Box'>
          <div className='VisaPes_Box_title'>How it works</div>
          <div className='VisaPes_Box_desc'>Contact us to discuss your eligibility or to find an employer.
We assist you to collect the necessary documents.
Our agents submit your application to Immigration.
Our agents liaise with Immigration until your application is finalised.</div>
         </div>
        </div>
  }
  {activeTab === '187Visa' &&
       <div className='Tabs_container_layout'>
          <div className='LabourAgreement_extrayLay'>
      <div className='LabourAgreement_section-container_title'>
      SUBCLASS 187 VISA (RSMS)
            </div>
      <div className='LabourAgreement_section-container_desc_extrayLay'>This is a permanent visa for skilled foreign workers who have a nominating employer in a regional area of Australia.

Applicants can be in or outside Australia when they apply for this visa.

DIRECT ENTRY STREAM (DE)
This stream is now closed to new applications

TEMPORARY RESIDENCE TRANSITION STREAM (TRT)
<li>Applicants must hold a 457 or 482 TSS Visa</li>
<li>Applicants must be nominated by an Australian Employer</li>
<li>Applicants may not need to perform a Skill Assessment</li>
      </div>
         </div>
         <div className='VisaPes_Box'>
          <div className='VisaPes_Box_title'>How it works</div>
          <div className='VisaPes_Box_desc'>Contact us to discuss your eligibility or to find an employer.
We assist you to collect the necessary documents.
Our agents submit your application to Immigration.
Our agents liaise with Immigration until your application is finalised.</div>
         </div>
        </div>
  }

              <div className='ConsultationComponent_field'>
            <ConsultationComponent
            title="General skilled migration"
            description="There are other skilled migration visa option to consider if the 186 or 187 visas do not suit you. "
            button="Explore"
            />
             <ConsultationComponent 
            title="Book A Consultation"
            description="Not sure where to go from here or if you would even be eligible for an Australian Visa? Speak to an experienced migration agent today."
            button="Consult"
            />
             <ConsultationComponent
            title="Find an employer"
            description="If you are looking for an Australian employer to sponsor you for permanent residency, go to our current vacancies page to see if we have a suitable position for you.  "
            button="View our job boards"
            />
            </div>

  <Footer />
    </div>
  )
}

export default VisaPES