import React from 'react'
import DottedLines from '../images/DottedLines.png'
import HeroBanner from '../components/HeroBanner'
import Privacy from '../images/privacy.png';
import NavBarComponent from '../components/NavBarComponent'
import Footer from '../components/Footer';
import Heading from '../components/Heading';
import PrivacyPolicy from '../components/PrivacyPolicy';
import "../styles/PrivacyPolicy.css"
function PrivacyPage() {
  return (
    <div>
    <NavBarComponent />
    {/* <HeroBanner
        heroHeading='Hope Privacy Policy'
        heroSubHeading=''
        connectButton='Let’s Connect'
        Banner={[Herobanner1]}
        DottedLines={DottedLines}
      /> */}
       <div className='HeroBanner'>
     <div>
      <div className='Banner-image '>
        <img className='HeroBannerImage' src={Privacy} alt='' />
      </div>
      <div className='hero'>
        <div className='hero-styling'>
          <div className='hero-content'>
            <div className='hero-heading' >
            Hope Privacy Policy
            </div>
           
          </div>
        </div>
        <div className='dotted-lines'>
          <img src={DottedLines} alt='dots' />
        </div>
      </div>
    </div>
 
</div>
<div className='privacy_container_css'>
<PrivacyPolicy />
</div>

        <Footer />
    </div>
  )
}

export default PrivacyPage