import React from 'react'
import MediumTermBody from '../components/MediumTermBody'

const VisaMediumTerm = () => {
    return (
        <>
            <MediumTermBody
                shortHeading='Medium Term Stream'
                shortTermDesc='The medium-term TSS (Subclass 482) Visa stream allows applicants to stay for up to 4 years, with the option to apply for another TSS Visa or Permanent Residency.'
                shortGeneralHead1='General'
                shortGeneralDesc1='Applicants must have a minimum of 2 years work experience in the relevant field'
                shortGeneralDesc2='Occupation must be on Medium Long-Term Strategic Skills List (MLTSSL) (For non-regional applications)'
                shortGeneralDesc3='Applicants don’t need to meet genuine temporary entrant requirement'
                shortGeneralDesc4='Applicants have a transitional pathway to Permanent Residency after 3 years'
                shortGeneralDesc5='Immediate family members may be included, with the support of the employer'
                shortGeneralHead2='English'
                shortGeneralDesc6='The primary applicant for the TSS Visa must meet the minimum English requirement.'
                shortGeneralDesc7='You will need to take an English test unless one of the following applies:'
                shortGeneralDesc8='you are a passport holder from Canada, Ireland, UK, USA or NZ'
                shortGeneralDesc9='you have completed at least 5 years of full-time study in at least a secondary level institution and where the medium of instruction was English.'
                shortGeneralDesc10='If you need to take an English test, you can take'
                here='Here'
                shortGeneralHead3='Extending Your Stay'
                shortGeneralDesc11='If your Medium-term 482 (TSS) visa is expiring soon and you would like to continue working for your employer:'
                shortGeneralDesc111='your employer will need to submit a new nomination'
                shortGeneralDesc112='you can apply for a new 482 TSS visa'
                shortGeneralDesc12='You may also be eligible to apply for one of the below permanent visas if your occupation is listed on the Medium Long-Term Strategic Skills List (MLTSSL)'
                subClassHoverContent1='189 Visa'
                subClassHoverContent2='190 Visa'
                subClassHoverContent3='187 Visa (RSMS Visa)'
                subClassHoverContent4='186 Visa (ENS Visa)'
                stayPeriodHead='Stay Period'
                stayPeriodContent4='Medium-term TSS Visa holder can stay in Australia for up to 4 years'
            />
        </>
    )
}

export default VisaMediumTerm