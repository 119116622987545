import React from 'react'
import Heading from '../components/Heading'
import "../styles/Visa187Screen.css"
import { useNavigate } from 'react-router-dom';
import ConsultationComponent from '../components/ConsultationComponent'

function Visa187Screen() {
    const navigate = useNavigate();
    const handleMenuItemClick = (path) => {
      navigate(path);
    };
  return (
    <div>
        <Heading heading="SUBCLASS 187 VISA (RSMS)"/>
        <div className='VisaFamily_Box'>
            <li>This is a permanent visa for skilled foreign workers who have a nominating employer in a regional or low population growth area of Australia.</li>
            <li>Applicants can be in or outside Australia when they apply for this visa.</li>
        </div>
        <div className='Visa187Subclass_fields'>
            <div className='Visa187Subclass_container1'> 
               <div className='AboutUsVisa_box_title'>DIRECT ENTRY STREAM:</div>
                <div className='VisaFamily_Box_desc'>Closed to new applications</div>
            </div>
            <div className='Visa187Subclass_container2'>
                <div className='AboutUsVisa_box_title'>TEMPORARY RESIDENCY TRANSITION STREAM</div>
                <div className='VisaFamily_Box_desc'>
                    <li>457 to PR – 457 Visa holders who have been working for the same employer for two or three years</li>
                    <li>TSS to PR – TSS Visa holders who have been working for the same employer for three years</li>
                     {/* <li><u onClick={() =>{ handleMenuItemClick('/SkilledVisa'); window.scrollTo(0,0);}} style={{ color: '#B0383D', cursor: 'pointer',fontSize:"18px" }}>Learn More about the subclass 187 visa (RSMS)</u></li> */}
               </div>
            </div>
        </div>
        <div className='ConsultationComponent_field'>
            <ConsultationComponent 
            title="View RSMS Post Codes"
            description="View our list of eligible RSMS postcodes for the Regional Sponsored Migration Scheme (RSMS) Subclass 187 Visa. "
            button="View List"
            />
             <ConsultationComponent 
            title="Book A Consultation"
            description="Speak to one of our migration agents and simply book a consultation. We'll assist you in understanding the costs involved"
            button="Consult"
            />
             <ConsultationComponent
            title="Get A Quote"
            description="Have a visa in mind? Contact us for a fixed price quote. We'll assist you in understanding the costs involved.            "
            button="Get a quote"
            />
            </div>
    </div>
  )
}

export default Visa187Screen