import React, { useState } from 'react'
import Herobanner1Family from '../images/House.mp4';
import NavBarComponent from '../components/NavBarComponent'
import DottedLines from '../images/DottedLines.png'
import HeroBanner from '../components/HeroBanner';
import SkilledComp2 from '../components/SkilledComp2';
import VisaYourOption from '../components/VisaYourOption';
import { useNavigate } from 'react-router-dom';
import Footer from '../components/Footer';
import Heading from '../components/Heading';
function VisaPP() {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('Subclass820/801');

const handleTabClick = tabName => {
  setActiveTab(tabName);
};
  const handleButtonClick1 = () => {
    window.scrollTo(0,0);
    navigate("/VisaFinderStudent");

  };

  const handleButtonClick2 = () => {
    window.scrollTo(0,0);
    navigate("/VisaFinderGraduate");
  };
  return (
    <div>
        <NavBarComponent />
    <HeroBanner
    heroHeading='Permanent Residency in Australia'
    heroSubHeading='Below visas will give you the option of permanently migrating to Australia to live and work. Please note that you must meet the respective eligibility criteria.'
    connectButton='Let’s Connect'
    Banner={[Herobanner1Family]}
    DottedLines={DottedLines}
  />
    <div className="TabsContainer">
  <div
    className={`Tab ${activeTab === 'Subclass820/801' ? 'isActive' : ''}`}
    onClick={() => handleTabClick('Subclass820/801')}
  >
   Subclass 820/801
  </div>
  <div
    className={`Tab ${activeTab === 'Subclass309/100' ? 'isActive' : ''}`}
    onClick={() => handleTabClick('Subclass309/100')}
  >
   Subclass 309/100
  </div>
  <div
    className={`Tab ${activeTab === 'Subclass300' ? 'isActive' : ''}`}
    onClick={() => handleTabClick('Subclass300')}
  >
  Subclass 300
  </div>
  <div
    className={`Tab ${activeTab === 'Subclass461' ? 'isActive' : ''}`}
    onClick={() => handleTabClick('Subclass461')}
  >
    Subclass 461
  </div>
  <div
    className={`Tab ${activeTab === '457FamilyVisa' ? 'isActive' : ''}`}
    onClick={() => handleTabClick('457FamilyVisa')}
  >
  457 Family Visa
  </div>
  <div
    className={`Tab ${activeTab === 'TSSFamilyVisa' ? 'isActive' : ''}`}
    onClick={() => handleTabClick('TSSFamilyVisa')}
  >
  TSS Family Visa
  </div>
  </div>
  {activeTab === 'Subclass820/801' &&
       <div className='Tabs_container_layout'>
        <div className='WorkingHoliday_Layout_Student'>
        <Heading heading="SPOUSE VISAS (Subclass 820/801)" containerColor="rgba(255, 247, 241, 1)" textColor="rgba(176, 56, 61, 1)" />
    <div className='LabourAgreement_extrayLay'>
      <div className='LabourAgreement_section-container_title'>
      ONSHORE PARTNER or SPOUSE VISAS (Subclass 820/801)
            </div>
      <div className='LabourAgreement_section-container_desc_extrayLay'>This visa is for the spouse or de facto partner of an Australian citizen, permanent resident or eligible New Zealand citizen who is 
planning to apply while they are inside Australia.

Partner/Spouse Visa (Temporary) Subclass 820
This visa is the first step towards a permanent Partner visa (subclass 801). You will be able to work and study while you hold this visa. You will also have access to Medicare.

Partner/Spouse Visa (Permanent) Subclass 801
This visa lets the applicant live in Australia permanently. It is for people who currently hold or have applied for a temporary Partner (subclass 820) visa.

Applicants who are in a long term relationship will often receive their visa approvals for the 820 and 801 at the same time.
      </div>
    </div>
    <div style={{marginTop:"72px"}}>
    <div className='VisaFinderOtion_content'>
            <div className='VisaFinderOtion_content_box'>
                <div className='VisaFinderOtion_content_box_tite'>Book A Consultation</div>
                <div className='VisaFinderOtion_content_box_desc'>Here at Australian Skilled Migration, our team of Registered Migration Agents are dedicated to helping reunite you with your partner. Let one of our experienced migration agents assess all your visa options. We will look at all visa options available to you and determine which option is best for your individual situation.</div>
                <div className='VisaFinderOtion_content_box_btn' >lET’S DO IT</div>
            </div>
            <div className='VisaFinderOtion_content_box'>
            <div className='VisaFinderOtion_content_box_tite'>Get A Quote</div>
                <div className='VisaFinderOtion_content_box_desc'>If you are in a genuine committed relationship with a person from overseas there are a number of options for you and your spouse or de facto under the partner and spouse visas program.Our registered team of Migration Agents are experts in Australian partner and de facto visas and can help determine your eligibility for a partner or spouse visa.</div>
                <div className='VisaFinderOtion_content_box_btn' >lET’S DO IT</div>
            </div>
        </div>
    </div>
          </div>
          </div>
}
  {activeTab === 'Subclass309/100' &&
       <div className='Tabs_container_layout'>
        <div className='WorkingHoliday_Layout_Student'>
        <Heading heading="SPOUSE VISAS (Subclass 309/100)" containerColor="rgba(255, 247, 241, 1)" textColor="rgba(176, 56, 61, 1)" />
        <div className='LabourAgreement_extrayLay'>
      <div className='LabourAgreement_section-container_title'>
      OFFSHORE PARTNER OR SPOUSE VISAS (Subclass 309/100)
            </div>
      <div className='LabourAgreement_section-container_desc_extrayLay'>This visa is for the spouse or de facto partner of an Australian citizen, permanent resident or eligible New Zealand citizen who is
planning to apply while they are outside Australia.

Partner/Spouse Visa (Provisional) Subclass 309
This visa allows you to remain in Australia temporarily and is the first step towards a permanent Partner visa (subclass 100). You will be able to work and study while you hold this visa. You will also have access to Medicare.

Partner/Spouse Visa (Permanent) Subclass 100
The visa lets the applicant live in Australia permanently. It is for people who hold a temporary Partner (subclass 309) visa.
      </div>
    </div>
    <div style={{marginTop:"72px"}}>
    <div className='VisaFinderOtion_content'>
            <div className='VisaFinderOtion_content_box'>
                <div className='VisaFinderOtion_content_box_tite'>Book A Consultation</div>
                <div className='VisaFinderOtion_content_box_desc'>Here at Australian Skilled Migration, our team of Registered Migration Agents are dedicated to helping reunite you with your partner. Let one of our experienced migration agents assess all your visa options. We will look at all visa options available to you and determine which option is best for your individual situation.</div>
                <div className='VisaFinderOtion_content_box_btn' >lET’S DO IT</div>
            </div>
            <div className='VisaFinderOtion_content_box'>
            <div className='VisaFinderOtion_content_box_tite'>Get A Quote</div>
                <div className='VisaFinderOtion_content_box_desc'>If you are in a genuine committed relationship with a person from overseas there are a number of options for you and your spouse or de facto under the partner and spouse visas program.Our registered team of Migration Agents are experts in Australian partner and de facto visas and can help determine your eligibility for a partner or spouse visa.</div>
                <div className='VisaFinderOtion_content_box_btn' >lET’S DO IT</div>
            </div>
        </div>
        </div>
          </div>
          </div>
}
{activeTab === 'Subclass300' &&
       <div className='Tabs_container_layout'>
        <div className='WorkingHoliday_Layout_Student'>
        <Heading heading="VISA (Subclass 300)" containerColor="rgba(255, 247, 241, 1)" textColor="rgba(176, 56, 61, 1)" />

        <div className='LabourAgreement_extrayLay'>
      <div className='LabourAgreement_section-container_title'>
      PROSPECTIVE MARRIAGE VISA (Subclass 300)
            </div>
      <div className='LabourAgreement_section-container_desc_extrayLay'>This is a temporary visa for people who want to come to Australia to marry their prospective spouse.
To get this visa you must:
<li>Be 18 years old or older</li>
<li>Be sponsored</li>
<li>Intend to marry your prospective spouse before the visa period ends</li>
      </div>
    </div>
    <div style={{marginTop:"72px"}}>
    <div className='VisaFinderOtion_content'>
            <div className='VisaFinderOtion_content_box'>
                <div className='VisaFinderOtion_content_box_tite'>Book A Consultation</div>
                <div className='VisaFinderOtion_content_box_desc'>Here at Australian Skilled Migration, our team of Registered Migration Agents are dedicated to helping reunite you with your partner. Let one of our experienced migration agents assess all your visa options. We will look at all visa options available to you and determine which option is best for your individual situation.</div>
                <div className='VisaFinderOtion_content_box_btn' >lET’S DO IT</div>
            </div>
            <div className='VisaFinderOtion_content_box'>
            <div className='VisaFinderOtion_content_box_tite'>Get A Quote</div>
                <div className='VisaFinderOtion_content_box_desc'>If you are in a genuine committed relationship with a person from overseas there are a number of options for you and your spouse or de facto under the partner and spouse visas program.Our registered team of Migration Agents are experts in Australian partner and de facto visas and can help determine your eligibility for a partner or spouse visa.</div>
                <div className='VisaFinderOtion_content_box_btn' >lET’S DO IT</div>
            </div>
        </div>
        </div>
          </div>
          </div>
}
{activeTab === 'Subclass461' &&
       <div className='Tabs_container_layout'>
        <div className='WorkingHoliday_Layout_Student'>
        <Heading heading=" VISA (Subclass 461)" containerColor="rgba(255, 247, 241, 1)" textColor="rgba(176, 56, 61, 1)" />

        <div className='LabourAgreement_extrayLay'>
      <div className='LabourAgreement_section-container_title'>
      NEW ZEALAND FAMILY VISA (Subclass 461)  
       </div>
      <div className='LabourAgreement_section-container_desc_extrayLay'>This is a temporary visa for the spouse or de facto partner of a New Zealand Citizen who is currently living in Australia on a Special Category Visa (Subclass 444)

To get this visa you must:

<li>Not be a New Zealand citizen</li>
<li>Be one of the Members of the family unit of a New Zealand citizen or their partner</li>
<li>Make sure if the New Zealand citizen is in Australia that they are on a Special Category New Zealand visa (subclass 444) or they will</li>
   travel with you to Australia.
      </div>
    </div>
    <div style={{marginTop:"72px"}}>
    <div className='VisaFinderOtion_content'>
            <div className='VisaFinderOtion_content_box'>
                <div className='VisaFinderOtion_content_box_tite'>Book A Consultation</div>
                <div className='VisaFinderOtion_content_box_desc'>Here at Australian Skilled Migration, our team of Registered Migration Agents are dedicated to helping reunite you with your partner. Let one of our experienced migration agents assess all your visa options. We will look at all visa options available to you and determine which option is best for your individual situation.</div>
                <div className='VisaFinderOtion_content_box_btn' >lET’S DO IT</div>
            </div>
            <div className='VisaFinderOtion_content_box'>
            <div className='VisaFinderOtion_content_box_tite'>Get A Quote</div>
                <div className='VisaFinderOtion_content_box_desc'>If you are in a genuine committed relationship with a person from overseas there are a number of options for you and your spouse or de facto under the partner and spouse visas program.Our registered team of Migration Agents are experts in Australian partner and de facto visas and can help determine your eligibility for a partner or spouse visa.</div>
                <div className='VisaFinderOtion_content_box_btn' >lET’S DO IT</div>
            </div>
        </div>
        </div>
          </div>
          </div>
}
{activeTab === '457FamilyVisa' &&
       <div className='Tabs_container_layout'>
        <div className='WorkingHoliday_Layout_Student'>
        <Heading heading="457 FAMILY VISA" containerColor="rgba(255, 247, 241, 1)" textColor="rgba(176, 56, 61, 1)" />
        <div className='LabourAgreement_extrayLay'>
      <div className='LabourAgreement_section-container_desc_extrayLay'>
        This visa is for the spouse or de facto partner and children of a Temporary Work (Subclass 457) Visa holder.
      </div>
    </div>
    <div style={{marginTop:"72px"}}>
    <div className='VisaFinderOtion_content'>
            <div className='VisaFinderOtion_content_box'>
                <div className='VisaFinderOtion_content_box_tite'>Book A Consultation</div>
                <div className='VisaFinderOtion_content_box_desc'>Here at Australian Skilled Migration, our team of Registered Migration Agents are dedicated to helping reunite you with your partner. Let one of our experienced migration agents assess all your visa options. We will look at all visa options available to you and determine which option is best for your individual situation.</div>
                <div className='VisaFinderOtion_content_box_btn' >lET’S DO IT</div>
            </div>
            <div className='VisaFinderOtion_content_box'>
            <div className='VisaFinderOtion_content_box_tite'>Get A Quote</div>
                <div className='VisaFinderOtion_content_box_desc'>If you are in a genuine committed relationship with a person from overseas there are a number of options for you and your spouse or de facto under the partner and spouse visas program.Our registered team of Migration Agents are experts in Australian partner and de facto visas and can help determine your eligibility for a partner or spouse visa.</div>
                <div className='VisaFinderOtion_content_box_btn' >lET’S DO IT</div>
            </div>
        </div>
        </div>
          </div>
          </div>
}
{activeTab === 'TSSFamilyVisa' &&
       <div className='Tabs_container_layout'>
        <div className='WorkingHoliday_Layout_Student'>
        <Heading heading="457 FAMILY VISA" containerColor="rgba(255, 247, 241, 1)" textColor="rgba(176, 56, 61, 1)" />
        <div className='LabourAgreement_extrayLay'>
      <div className='LabourAgreement_section-container_desc_extrayLay'>
        This visa is for the spouse or de facto partner and children of a TSS Visa holder (Subclass 482).
      </div>
    </div>
    <div style={{marginTop:"72px"}}>
    <div className='VisaFinderOtion_content'>
            <div className='VisaFinderOtion_content_box'>
                <div className='VisaFinderOtion_content_box_tite'>Book A Consultation</div>
                <div className='VisaFinderOtion_content_box_desc'>Here at Australian Skilled Migration, our team of Registered Migration Agents are dedicated to helping reunite you with your partner. Let one of our experienced migration agents assess all your visa options. We will look at all visa options available to you and determine which option is best for your individual situation.</div>
                <div className='VisaFinderOtion_content_box_btn' >lET’S DO IT</div>
            </div>
            <div className='VisaFinderOtion_content_box'>
            <div className='VisaFinderOtion_content_box_tite'>Get A Quote</div>
                <div className='VisaFinderOtion_content_box_desc'>If you are in a genuine committed relationship with a person from overseas there are a number of options for you and your spouse or de facto under the partner and spouse visas program.Our registered team of Migration Agents are experts in Australian partner and de facto visas and can help determine your eligibility for a partner or spouse visa.</div>
                <div className='VisaFinderOtion_content_box_btn' >lET’S DO IT</div>
            </div>
        </div>
        </div>
          </div>
          </div>
}
  <Footer />


    </div>
  )
}

export default VisaPP