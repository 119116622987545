import React from 'react'
import '../styles/PermanentEmpOv.css'
import { useNavigate } from 'react-router-dom';

const PermanentEmpOv = (props) => {
    const navigate = useNavigate();
    const handleNavigate457 = () => {
        window.scrollTo(0,0);
         navigate('/Visa457Emp')
      }
      const handleNavigateTss = () => {
        window.scrollTo(0,0);
         navigate('/EmployerTssVisa')
      }
    
    return (
        <>
            <div className='EmpOverViewLayout'>
                <div className='empOverViewHeadBox'>
                    <div>{props.empOVHeading}</div>
                </div>
                <div className='empOVlayout-two'>
                    <div className='empOVboxone'>
                        <div className='empOVsubHead'>{props.empOVsubHead1}</div>
                        <div className='empOVDesc'>{props.empOVDesc1}</div>
                    </div>
                    <div className='empOVboxone'>
                        <div className='empOVsubHead'>{props.empOVsubHead2}</div>
                        <div className='empOVDesc'>{props.empOVDesc2}</div>
                    </div>
                </div>       
                <div className='empOVlayout-three'>
                    <div className='empOVsubHead'>{props.empOVsubHead3}</div>
                    <div className='empOVDesc'>For <u onClick={handleNavigate457} style={{color:'#B0383D', cursor:'pointer'}}>457 visa</u> or <u onClick={handleNavigateTss}  style={{color:'#B0383D', cursor:'pointer'}}>TSS 482 visa</u> holders who have worked for their nominating employer for a minimum of two or three years. {props.visa186extra}</div>
                </div>
            </div>
        </>
    )
}

export default PermanentEmpOv