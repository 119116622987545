import React from 'react'
import Herobanner1Family from '../images/GrantVisa.mp4';
import NavBarComponent from '../components/NavBarComponent'
import DottedLines from '../images/DottedLines.png'
import HeroBanner from '../components/HeroBanner'
import Footer from '../components/Footer'
import NotSureImg from "../images/NotSureImg.png"
import VisaFinderNotSure from '../components/VisaFinderNotSure';
import VisaYourOption from '../components/VisaYourOption';
import { useNavigate } from 'react-router-dom';
function StudyDevelop() {
  const navigate = useNavigate();
  const handleButtonClick1 = () => {
    window.scrollTo(0,0);
    navigate("/VisaFinderStudent");

  };

  const handleButtonClick2 = () => {
    window.scrollTo(0,0);
    navigate("/VisaFinderGraduate");
  };
  return (
    <div>
    <NavBarComponent />
    <HeroBanner
    heroHeading='Visas to visit Australia for a holiday'
    heroSubHeading='Below visas will give you the option of coming to Australia for leisure so you can embark on tourist adventures around the country. Please note that you must meet the respective eligibility criteria.'
    connectButton='Let’s Connect'
    Banner={[Herobanner1Family]}
    DottedLines={DottedLines}
  />
  <VisaYourOption 
  Head="Your Options"
  title1="Student Visa"
  desc1="Australia’s overseas student program allows foreign students to complete their studies in Australia at a variety of levels such as Higher Education, Vocational Education and Training, Primary and secondary school courses or postgraduate courses. So, if you are looking at getting a degree in Australia, this visa is for you."
  btn1="Learn More"
  onClick1={handleButtonClick1}
  title2="Temporary Graduate Visa"
  desc2="If you are in Australia as a international student, the Temporary Graduate (Subclass 485) Visa allows you to live, study and work after you have finished your studies.This is a great way to extend your stay in Australia to gain work experience in your field and potentially apply for a skilled or employer sponsored visa."
  btn2="Learn More"
  onClick2={handleButtonClick2}
  />
  <VisaFinderNotSure 
  title="Not sure what to do next?"
  desc="Australian visas can be confusing. Often, the obvious choice isn’t the best one. Our registered migration agents are happy to help and answer all you questions."
  btn="Book consultation"
  img={NotSureImg}
  />


  <Footer />
</div>
  )
}

export default StudyDevelop