import React, { useState } from 'react'
import NavBarComponent from '../components/NavBarComponent'
import HeroBanner from '../components/HeroBanner'
import Herobanner1 from '../images/Job.mp4';
import DottedLines from '../images/DottedLines.png'
import ConsultationComponent from '../components/ConsultationComponent';
import Footer from '../components/Footer';
import JobVacancy from '../components/JobVacancy';

function JobVacancies() {
    const [activeTab, setActiveTab] = useState('Vacancies');

    const handleTabClick = tabName => {
      setActiveTab(tabName);
    };
  return (
    <div>
        <NavBarComponent />
        <HeroBanner
        heroHeading='Employer Sponsored Job Vacancies'
        heroSubHeading='Let us make your dreams a reality. On this page you’ll find Australian job vacancies for offshore applicants (482 TSS, 186 & 494 visas) and Australian Residents.'
        connectButton='Let’s Connect'
        Banner={[Herobanner1]}
        DottedLines={DottedLines}
      />
        <div className="TabsContainer">
      <div
        className={`Tab ${activeTab === 'Vacancies' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('Vacancies')}
      >
        All Vacancies
      </div>
      <div
        className={`Tab ${activeTab === 'Automotive' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('Automotive')}
      >
       Mechanics & Automotive
      </div>
      <div
        className={`Tab ${activeTab === 'Fabricatiors' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('Fabricatiors')}
      >
       Welders & Fabricatiors
      </div>
      <div
        className={`Tab ${activeTab === 'Occupations' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('Occupations')}
      >
        Other Occupations
      </div>
      </div>
      {activeTab === 'Vacancies' &&
         <div className='Tabs_container_layout'>
            <JobVacancy
            title="Panel Beaters"
            subTitle="Position Available: 10 Location: Gisborne, Victoria Minimum Requirement:"
            desc11="5 years experience"
            desc12="$85,000 Annual Salary + Super"
            desc13="Relocation Expense"
            btn="Apply Now"
            title2="Panel Beaters"
            subTitle2="Position Available: 10 Location: Gisborne, Victoria Minimum Requirement:"
            desc21="5 years experience"
            desc22="$85,000 Annual Salary + Super"
            desc23="Relocation Expense"
            btn2="Apply Now"
            title3="Panel Beaters"
            subTitle3="Position Available: 10 Location: Gisborne, Victoria Minimum Requirement:"
            desc31="5 years experience"
            desc32="$85,000 Annual Salary + Super"
            desc33="Relocation Expense"
            btn3="Apply Now"
            />
         
         </div>
         }
          {activeTab === 'Automotive' &&
         <div className='Tabs_container_layout'>
           <JobVacancy
            title="Panel Beaters"
            subTitle="Position Available: 10 Location: Gisborne, Victoria Minimum Requirement:"
            desc11="5 years experience"
            desc12="$85,000 Annual Salary + Super"
            desc13="Relocation Expense"
            btn="Apply Now"
            title2="Panel Beaters"
            subTitle2="Position Available: 10 Location: Gisborne, Victoria Minimum Requirement:"
            desc21="5 years experience"
            desc22="$85,000 Annual Salary + Super"
            desc23="Relocation Expense"
            btn2="Apply Now"
            title3="Panel Beaters"
            subTitle3="Position Available: 10 Location: Gisborne, Victoria Minimum Requirement:"
            desc31="5 years experience"
            desc32="$85,000 Annual Salary + Super"
            desc33="Relocation Expense"
            btn3="Apply Now"
            />
         </div>
         }
          {activeTab === 'Fabricatiors' &&
         <div className='Tabs_container_layout'>
           <JobVacancy
            title="Panel Beaters"
            subTitle="Position Available: 10 Location: Gisborne, Victoria Minimum Requirement:"
            desc11="5 years experience"
            desc12="$85,000 Annual Salary + Super"
            desc13="Relocation Expense"
            btn="Apply Now"
            title2="Panel Beaters"
            subTitle2="Position Available: 10 Location: Gisborne, Victoria Minimum Requirement:"
            desc21="5 years experience"
            desc22="$85,000 Annual Salary + Super"
            desc23="Relocation Expense"
            btn2="Apply Now"
            title3="Panel Beaters"
            subTitle3="Position Available: 10 Location: Gisborne, Victoria Minimum Requirement:"
            desc31="5 years experience"
            desc32="$85,000 Annual Salary + Super"
            desc33="Relocation Expense"
            btn3="Apply Now"
            />
         </div>
         }
          {activeTab === 'Occupations' &&
         <div className='Tabs_container_layout'>
           <JobVacancy
            title="Panel Beaters"
            subTitle="Position Available: 10 Location: Gisborne, Victoria Minimum Requirement:"
            desc11="5 years experience"
            desc12="$85,000 Annual Salary + Super"
            desc13="Relocation Expense"
            btn="Apply Now"
            title2="Panel Beaters"
            subTitle2="Position Available: 10 Location: Gisborne, Victoria Minimum Requirement:"
            desc21="5 years experience"
            desc22="$85,000 Annual Salary + Super"
            desc23="Relocation Expense"
            btn2="Apply Now"
            title3="Panel Beaters"
            subTitle3="Position Available: 10 Location: Gisborne, Victoria Minimum Requirement:"
            desc31="5 years experience"
            desc32="$85,000 Annual Salary + Super"
            desc33="Relocation Expense"
            btn3="Apply Now"
            />
         </div>
         }
            <div className='ConsultationComponent_field'>
        <ConsultationComponent containerColor="#FFF7F1"  
             textColor="#014A91" title="Other Sponsored Visas" 
        description="View other similar visa options that could be applicable to you."
        button="Check Eligibility"
        />
         <ConsultationComponent  containerColor="#FFF7F1"  
             textColor="#014A91" title="Book A Consultation" 
        description="Speak to one of our migration agents and simply book a consultation."
        button="Consult"
        />
         <ConsultationComponent  containerColor="#FFF7F1"  
             textColor="#014A91" title="Get A Quote" 
        description="Have a visa in mind? Contact us for a fixed price quote. We'll assist you in understanding the costs involved.        "
        button="Consult"
        />
        </div>
        <Footer />
    </div>
  )
}

export default JobVacancies