import React from 'react'
import "../styles/InternationalTeamComp.css"

function InternationalTeamComp(props) {
    const containerStyle = {
        backgroundColor: props.containerColor || 'rgba(244, 244, 244, 1)', 
        color: props.textColor || 'rgba(4, 85, 144, 1)', 
      };
  return (
    <div className='InternationalTeamComp_layout'>
        <div className='InternationalTeamComp_head' style={containerStyle}> {props.Heading}</div>
        <div className='InternationalTeamComp_Fields' >
            <div className='InternationalTeamComp_contents'>
            <div className='InternationalTeamComp_Fields_set' >
            <div className='InternationalTeamComp_Fields1' style={containerStyle}>
                    <div className='InternationalTeamComp_title'>{props.title1}</div>
                    <div className='InternationalTeamComp_desc'>{props.desc1}</div>
                    </div>
           <div className='InternationalTeamComp_Fields2' style={containerStyle}>
                <div className='InternationalTeamComp_title'>{props.title2}</div>
                <div className='InternationalTeamComp_desc'>{props.desc2}</div>
           </div>
            
           </div>
           <div className='InternationalTeamComp_Fields_set' >
            <div className='InternationalTeamComp_Fields1' style={containerStyle}>
                    <div className='InternationalTeamComp_title'>{props.title12}</div>
                    <div className='InternationalTeamComp_desc'>{props.desc12}</div>
                    </div>
           <div className='InternationalTeamComp_Fields2' style={containerStyle}>
                <div className='InternationalTeamComp_title'>{props.title22}</div>
                <div className='InternationalTeamComp_desc'>{props.desc22}</div>
           </div>
            
           </div>
           </div>
          
            <div > 
               <img src={props.Img} alt="International Team" className='InternationalTeamComp_Img'/>
               </div>

        </div>
    </div>
  )
}

export default InternationalTeamComp