import React, { useState } from 'react'
import '../styles/Option1Overview.css'
import Option1Eligibility from './Option1Eligibility';
import Option1Family from './Option1Family';
import { XCircle } from "@phosphor-icons/react";
const Option1Overview = ({ closeModal }) => {
    const [activeTab, setActiveTab] = useState('Overview');

    const handleTabClick = tabName => {
      setActiveTab(tabName);
    };
    return (
        <>
            <div className='modalLayout'>
            <div className='modalLayoutMain'>
            <div style={{ textAlign: 'right', paddingTop: '16px', paddingRight: '16px' }}>
                        <XCircle onClick={closeModal} size={32} />
                    </div>
                    <div className='OptionOverviewStyling'>
            <div className="TabsContainer">
      <div
        className={`Tab ${activeTab === 'Overview' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('Overview')}
      >
       Overview
      </div>
      <div
        className={`Tab ${activeTab === 'Eligibility' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('Eligibility')}
      >
      Eligibility
      </div>
      <div
        className={`Tab ${activeTab === 'Family' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('Family')}
      >
      Including Family
      </div>
      
                   </div>

                   
                       
                        {activeTab === 'Overview' &&
         <div className='Tabs_container_layout'>
            <div className='OptionOverviewHeading'>Option 1: Temporary Residence Transition Stream (TRT)</div>
                        <div className='OptionOverviewContentBox'>
                            <div className='OptionOverviewContentDesc'>
                                <li>Depending on their location, 457 visa holders will apply through either the Employer Nominated Scheme (ENS) or the Regional <u style={{ color: '#B0383D', cursor: 'pointer' }}>Employer Sponsored Scheme</u> (RSMS).</li>
                                <li>The employer must be willing to sponsor the applicant for permanent residency under the Temporary Residence Transition stream and must lodge a valid nomination with the Department of Home Affairs. This is one of the most common pathways to permanent residency and has many benefits.</li>
                                <li>If you don’t already hold a <u style={{ color: '#B0383D', cursor: 'pointer' }}>457 visa</u> and would like to work in Australia, the <u style={{ color: '#B0383D', cursor: 'pointer' }}>TSS visa (Subclass 482)</u> is the new Australian temporary work visa.</li>
                            </div>
                        </div>
            </div>
            }
             {activeTab === 'Eligibility' &&
         <div className='Tabs_container_layout'>
            <Option1Eligibility />
            </div>
            }
             {activeTab === 'Family' &&
         <div className='Tabs_container_layout'>
            <Option1Family/>
            </div>
            }
                    </div>
                </div>
            </div>
        </>
    )
}

export default Option1Overview