import React, { useState } from 'react'
import "../styles/SkilledComp4.css"
import ContactModal from './ContactModal'

function SkilledComp4(props) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
      setIsModalOpen(true);
  };

  const closeModal = () => {
      setIsModalOpen(false);
  };
  return (
    <div className='SkilledComp4_layout'>
        <div className='skilledComp4_Box_Head'>{props.head}</div>
        <div className='skilledComp4_Box_Fields'>
        <div className='skilledComp4_Box'>
            <div className='skilledComp4_Box_title'>{props.Title}</div>
            <div className='skilledComp4_Box_Desc'>{props.Desc}</div>
            <div className='skilledComp4_Box_btn' onClick={openModal} >{props.btn}</div> 
        </div>
        <div className='skilledComp4_Box'>
        <div className='skilledComp4_Box_title'>{props.Title2}</div>
            <div className='skilledComp4_Box_Desc'>{props.Desc2}</div>
            <div className='skilledComp4_Box_btn' onClick={openModal}>{props.btn2}</div> </div> 
            </div> 
            {isModalOpen && <div className="modal-background">
                <div className='modalFinal'>
                    
                    <ContactModal closeModal={closeModal}/>

                </div>
            </div>}
        
    </div>
  )
}

export default SkilledComp4