import React, { useState } from 'react'
import '../styles/EnglishModal.css'
import * as yup from 'yup';
import { useFormik } from 'formik'; 
import { XCircle } from "@phosphor-icons/react";
import { TextField } from '@mui/material';
function ContactModal({ closeModal }) {
  const phoneRegExp = /^([6789]{1})([\d]{3})[(\D\s)]?[\d]{3}[(\D\s)]?[\d]{3}$/;
  const emailRegExp = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
  const fullNameRegExp = /^(?!.\s{2})(?!.[.'-]{2})[A-Z][A-Za-z' ]{0,48}[A-Za-z]$/;
  const validationSchema = yup.object({
    email: yup
      .string("Enter your email")
      .email("Enter a valid email")
      .matches(emailRegExp, "Email is not valid")
      .trim()
      .required("Email is required"),
    phone: yup
      .string()
      .matches(phoneRegExp, "Phone number is not valid")
      .required("Phone number is required")
      .min(10, "Phone number must consist of 10 digits")
      .trim()
      .max(10, "Phone number must consist of 10 digits"),
    name: yup
      .string("Enter Your First Name")
      .matches(fullNameRegExp, "Name is invalid")
      .trim()
      .required("Name is required"),
 
  });
 
  const [loading, setLoading] = useState(false);
  
  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      query: '',
      phone: '',
    },
    // validationSchema: validationSchema,
    onSubmit: async () => {
      try {
        setLoading(true);
        const response = await fetch(
          `https://prod-17.southindia.logic.azure.com:443/workflows/21b31796c962432a810f908ca44c9922/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=1prHC8t6hpieaBRUmuD0fuaKB1YHTJj_V8d0e3gW97c`,
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-type": "application/json",
            },
            body: JSON.stringify({
              "to" :'bengaluru@hopegroup.com.au',
           "subject":'in this case Hope enquiry ',
              name: formik.values.name.trim(),
              email: formik.values.email,
              phone:  formik.values.phone.trim(),
              message: formik.values.query.trim(),
            }),
          }
        );
        if (response.status === 204) {
          setLoading(false);
          formik.resetForm();
          console.log(response);
          closeModal(); 
        
        } else {
          setLoading(false);
          console.error('Form submission failed:', response.statusText);
        }
      } catch (error) {
        setLoading(false);
        console.error('Error submitting form:', error);
      }
    }
  });
  
  return (
    <div>
     
          <div className='modalLayout_contact'>
                <div className='modalLayoutMain_contact'>
                    <div className='modalStyling' style={{ paddingRight: '30px', paddingTop: '10px !important' }}>
                    <div style={{ textAlign: 'right', paddingTop: '4px', paddingRight: '0px' }}>
                        <XCircle onClick={closeModal} size={32} />
                    </div>

                        <div className='Conatct_title'>Connect With Us</div>
                    <form onSubmit={formik.handleSubmit} className='help-screen-form'>
                        <div className='Form_field'>
                    <div className='Form_name_field'>
          <TextField
            type="text"
          
            name="name"
            className='contact-form-textField'
            value={formik.values.name}
            placeholder='Enter Full Name'
            onChange={formik.handleChange}
            required
          />
           <TextField
            type="text"   
            name="query"
            className='contact-form-textField'
            value={formik.values.query}
            placeholder='Enter Query'
             onChange={formik.handleChange}
            required
          />
        </div>
        <div className='Form_name_field'>
          <TextField
            type="tel"        
            name="email"
            placeholder='Enter Email Address'
            className='contact-form-textField'
            value={formik.values.email}
             onChange={formik.handleChange}
            required
          />
           <TextField
            type="tel"
           
            name="phone"
            placeholder='Enter Phone Number'
            className='contact-form-textField'
            value={formik.values.phone}
             onChange={formik.handleChange}
            required
          />

        
         
</div></div>
          <input type="submit" value="submit" className='submit-button-help'/>
        </form>
                    </div>
                    </div>
                    </div>
    
    </div>
  )
}

export default ContactModal