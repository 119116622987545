import React from 'react'
import '../styles/HeroBanner.css'

const PermanentEmpBanner = (props) => {
    return (
        <>
            <div className='HeroBanner'>
                <div className='Banner-image'>
                    <img className='HeroBannerImage' src={props.Banner} alt='passportimg'/>
                </div>
                <div className='hero'>
                    <div className='hero-styling'>
                        <div className='hero-content'>
                            <div className='hero-heading-Emp'>
                                {props.heroHeading}
                            </div>
                            <div className='heroStyling'>
                                <div className='hero-subheading-Emp'>
                                    {props.heroSubHeading}
                                </div>
                                <div className='connectButton' style={{marginTop:'-35px'}}>
                                    {/* <button type='button' className='connect-buttonEmp'>{props.connectButton}</button> */}
                                    <a target="_blank" href="https://wa.me/9663907648" style={{ textDecoration: 'none' }}>
 <div className='connectButton'>
        <div className='connect-button'>{props.connectButton}</div>
        
      </div>
    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='dotted-lines'>
                        <img src={props.DottedLines} alt='dots' />
                    </div>
                </div>
            </div>
        </>
    )
}

export default PermanentEmpBanner