import React from 'react'
import '../styles/Visa186Holder.css'

const Visa186Holder = (props) => {
    return (
        <>
            <div className='visaHolderMainLayout'>
                <div className='visaHolderHeadBox'>
                    <div className='visaHolderHeading'>{props.visaHolderHeading}</div>
                </div>
                <div className='visaHolderContentLayout'>
                    <div className='visaHolderContentBox'>
                        <li>{props.visaHolderContent1}</li>
                        <li>{props.visaHolderContent2}</li>
                        <li>{props.visaHolderContent3}</li>
                    </div>
                    <div className='visaHolderContentBox'>
                        <li>{props.visaHolderContent4}</li>
                        <li>{props.visaHolderContent5}</li>
                        <li>{props.visaHolderContent6}</li>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Visa186Holder