import React from 'react'
import '../styles/Visa186Eligibility.css'

const Visa186Eligibility = (props) => {
    return (
        <>
            <div className='eligibilityMain-layout'>
                <div className='eligibility-heading'>
                    <div className='visa-eligibility-heading'>{props.whyUsHeadingMain}</div>
                </div>
                <div className='eligibility-two-sublayout2'>
                    <div className='eligibility-subHeading'>{props.eligibilitysubHeading}</div>
                </div>
                <div className='eligibility-second-layout'>
                    <div className='eligibility-layout-one'>
                        <div className='eligibility-layout-exp'>
                            <div className='eligiblity-heading'>{props.whyUsHeading1}</div>
                            <div className='eligiblity-desc'>
                                <li>{props.whyUsDesc1}</li>
                            </div>
                        </div>
                        <div className='eligibility-layout-ext'>
                            <div className='eligiblity-heading1'>{props.whyUsHeading2}</div>
                            <div className='eligiblity-desc1'>
                                <li>{props.whyUsDesc2}</li>
                            </div>
                        </div>
                    </div>
                    <div className='eligiblity-layout-two'>
                        <div className='eligibility-two-sublayout1'>
                            <div className='eligibility-two-service'>
                                <div className='eligibility-two-head'>{props.whyUsHeading3}</div>
                                <div className='eligibility-two-desc'>
                                    <div>{props.whyUsDesc3}</div>
                                    <div style={{ marginTop: '8px' }}>{props.whyUsDesc31}</div>
                                    <li>{props.whyUsDesc32}</li>
                                    <li>{props.whyUsDesc33}</li>
                                </div>
                            </div>
                            <div className='eligibility-two-trans'>
                                <div className='eligibility-two-head'>{props.whyUsHeading4}</div>
                                <div className='eligibility-two-desc'>
                                    <div>{props.whyUsDesc4}</div>
                                    <div style={{ marginTop: '8px' }}>{props.whyUsDesc41}</div>
                                    <li><u style={{ color: '#B0383D', cursor: 'pointer' }}>{props.whyUsDesc42}</u></li>
                                    <li>{props.whyUsDesc43}</li>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='eligibility-two-sublayout2'>
                    <div className='eligibility-two-support'>{props.whyUsHeading5}</div>
                    <div className='eligibility-two-support-desc'>
                        <li>{props.whyUsDesc5}</li>
                        <li>{props.whyUsDesc55}</li>
                    </div>
                </div>
                <div className='eligibility-main-layout2'>
                    <div className='why-two-service'>
                        <div className='eligiblity-heading'>{props.whyUsHeading1}</div>
                        <div className='eligiblity-desc'>
                            <li>{props.whyUsDesc1}</li>
                        </div>
                    </div>
                    <div className='why-two-service'>
                        <div className='eligiblity-heading1'>{props.whyUsHeading2}</div>
                        <div className='eligiblity-desc1'>
                            <li>{props.whyUsDesc2}</li>
                        </div>
                    </div>
                    <div className='why-two-service'>
                        <div className='eligibility-two-head'>{props.whyUsHeading3}</div>
                        <div className='eligibility-two-desc'>
                            <div>{props.whyUsDesc3}</div>
                            <div style={{ marginTop: '8px' }}>{props.whyUsDesc31}</div>
                            <li>{props.whyUsDesc32}</li>
                            <li>{props.whyUsDesc33}</li>
                        </div>
                    </div>
                    <div className='why-two-service'>
                        <div className='eligibility-two-head'>{props.whyUsHeading4}</div>
                        <div className='eligibility-two-desc'>
                            <div>{props.whyUsDesc4}</div>
                            <div style={{ marginTop: '8px' }}>{props.whyUsDesc41}</div>
                            <li><u style={{ color: '#B0383D', cursor: 'pointer' }}>{props.whyUsDesc42}</u></li>
                            <li>{props.whyUsDesc43}</li>
                        </div>
                    </div>
                    <div className='why-two-service'>
                        <div className='eligibility-two-support'>{props.whyUsHeading5}</div>
                        <div className='eligibility-two-support-desc'>
                            <li>{props.whyUsDesc5}</li>
                            <li>{props.whyUsDesc55}</li>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Visa186Eligibility