import React from 'react'
import Navbar from '../components/Navbar'
import PermanentEmpOv from '../components/PermanentEmpOv'
import PermanentEmpHow from '../components/PermanentEmpHow'
import howitworks from '../images/Business14.mp4'
import DottedLines from '../images/DottedLines.png'
import PermanentEmployerBanner from '../images/PermanentEmployer.mp4'
import Footer from '../components/Footer'
import { useState } from 'react'
import Perm186 from './Perm186'
import Perm187 from './Perm187'
import HeroBanner from '../components/HeroBanner'
import PermEmpOverview from './PermEmpOverview'
import ConsultationComponent from '../components/ConsultationComponent'
const PermOverviewLanding = () => {
  const [activeTab, setActiveTab] = useState('Overview');
  const handleTabClick = tabName => {
    setActiveTab(tabName);
  };
  return (
    <>
      <Navbar />
      <HeroBanner
        heroHeading='Permanent Employer Sponsored Visas'
        heroSubHeading='The permanent Subclass 186 (ENS) and Subclass 187 (RSMS) visas allow skilled overseas workers to live and work in Australia permanently.'
        connectButton='Let’s Connect'
        Banner={[PermanentEmployerBanner]}
        DottedLines={DottedLines}
      />
   
        <div className="TabsContainer">
      <div
        className={`Tab ${activeTab === 'Overview' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('Overview')}
      >
        Overview
      </div>
      <div
        className={`Tab ${activeTab === '186Visa' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('186Visa')}
      >
        186 Visa
      </div>
      <div
        className={`Tab ${activeTab === '187Visa' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('187Visa')}
      >
        187 Visa
      </div>
      
    </div>
    {activeTab === 'Overview' &&
       <div className='Tabs_container_layout'>
        <PermEmpOverview />
      
        </div>
        }
         {activeTab === '186Visa' &&
       <div className='Tabs_container_layout'>
        <Perm186 />
      
        </div>
        }
         {activeTab === '187Visa' &&
       <div className='Tabs_container_layout'>
        <Perm187 />
      
        </div>
        }
      {/* <PermanentEmpOv
        empOVHeading='The Subclass 186 visa and 187 Visas have three streams:'
        empOVsubHead1='DIRECT ENTRY STREAM:'
        empOVsubHead2='AGREEMENT STREAM:'
        empOVsubHead3='TRANSITIONAL STREAM:'
        empOVDesc1='For applicants who are formally qualified or have sufficient experience in an eligible occupation and location.'
        empOVDesc2='For applicants who are sponsored by an employer under a labour agreement.'
      /> */}
      <PermanentEmpHow
        EmpHowHeading='How it works'
        EmpHowImage={howitworks}
        EmpHowSubHead1='Step 1'
        EmpHowSubHead2='Step 2'
        EmpHowSubHead3='Step 3'
        EmpHowSubHead4='Step 4'
        EmpHowDesc1='Contact us to discuss your eligibility or to find an employer.'
        EmpHowDesc2='We assist you to collect the necessary documents.'
        EmpHowDesc3='Our agents submit your application to Immigration.'
        EmpHowDesc4='Our agents liaise with Immigration until your application is finalised.'
      />
       <div className='ConsultationComponent_field'>
        <ConsultationComponent 
             title="Are You Eligible?" 
        description="Take our online eligibility assessment to find out if you meet the requirements for the 482 Visa"
        button="Check Eligibility"
        />
         <ConsultationComponent   
             title="Book A Consultation" 
        description="Speak to one of our migration agents and simply book a consultation."
        button="Consult"
        />
         <ConsultationComponent   
             title="Get A Quote" 
        description="Have a visa in mind? Contact us for a fixed price quote. We'll assist you in understanding the costs involved.        "
        button="Consult"
        />
        </div>
      <Footer/>
    </>
  )
}

export default PermOverviewLanding