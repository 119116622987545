import React from 'react'
import '../styles/Option1Overview.css'


import { XCircle } from "@phosphor-icons/react";
const Option4Overview = ({ closeModal }) => {
    return (
        <>
            <div className='modalLayout'>
                <div className='modalLayoutMain'>
                <div style={{ textAlign: 'right', paddingTop: '16px', paddingRight: '16px' }}>
                        <XCircle onClick={closeModal} size={32} />
                    </div>
                    <div className='OptionOverviewStyling'>
                        <div className='OptionOverviewHeading'>Option 4</div>
                        <div className='OptionOverviewContentBox'>
                            <div className='OptionOverviewContentDesc'>
                                <li>457 Visa holders who are in a genuine committed relationship with an Australian citizen, Permanent Resident or eligible New Zealand citizen may be eligible to apply for an <u style={{color:'#B0383D', cursor:'pointer'}}>Australian Partner Visa</u>.</li>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Option4Overview