import React from 'react'
import '../styles/PrivacyPolicy.css'

function PrivacyPolicy() {
  return (
    <div className='Privacy-data'>
        <div className='PrivacyPolicy'>About This Website</div>
      
      <div className='privacy-disclaimer'> There are disclaimers throughout our website stating that Hope Education and Migration  (this website) is an independent entity.</div>

      <div className='privacy-affiliation'>
        <span className='privacy-subheading'>Affiliation :</span>
        <div className='privacy-content'>
        Hope Education and Migration is not affiliated with any government or government  agency for permits. Hope Education and Migration provides immigration guidance, and  concierge services for immigration and charges a service fee. It maintains  www.thehopegroup.com.au, a private publishing website, which offers general  information on issues related to immigration/permits. It is neither a legal firm nor does it  provide any kind of legal advice or suggestions to its users. The information presented  on our website should be used as for reference purposes only and not as a substitute  for professional advice. We do not provide legal advice, opinions or recommendations  to our users about their legal rights, legal remedies, legal defenses, legal options or  legal strategies. Any purchase made using this website is subject to Hope Education  and Migration Terms of Use to which, by using this site and/or making any purchase,  you are agreeing to be bound.
        </div>
      </div>

      <div className='privacy-certification'>
        <span className='privacy-subheading'> Certifications :</span>
        <div className='privacy-content'>
        Hope Education and Migration has passed rigorous background checks that ensure our  validity and overall legitimacy of our business.
        </div>
      </div>
      
      <div className='privacy-rights'>
        <div className='privacyIntel'>
            <span className='privacy-subheading'>Intellectual property rights :</span>
            <div className='privacy-content'>
            Except where stated otherwise, Hope Education and Migration is the copyright holder  of all content, layout, design, data, graphics, trademarks and logos under the Hope  Education and Migration.com domain. The content is protected by India and  international copyright laws. Hope Education and Migration will do its utmost to protect the rights of employees, customers, members and intellectual property rights. We will  not hesitate to take legal action if necessary.
            </div>     
        </div>
        <div className='privacyIntel'>
            <span className='privacy-subheading'>Limitation of liability :</span>
            <div className='privacy-content'>
            Hope Education and Migration shall not be liable for any special or consequential  damages that result from the use of, or the inability to use, the materials on this website  or the performance of the products, even if Hope Education and Migration has been  advised of the possibility of such damages. Applicable law may not allow the limitation  of exclusion of liability, or incidental or consequential damages; thereby the above  limitation or exclusion may not apply to you.
            </div>
        </div>
      </div>
      
      <div className='privacy-affiliation'>
        <span className='privacy-subheading'>Legal Form and Choice of Law :</span>
        <div className='privacy-content'>
        By using our website and purchasing products or services, you have entered into a legal  contract with Hope Education and Migration . You agree that the prevailing party in a  civil lawsuit may be awarded reasonable attorney’s fees.
        </div>
      </div>

      <div className='privacy-affiliation'>
        <span className='privacy-subheading'>Typographical Errors:</span>
        <div className='privacy-content'>
        In the event that a Hope Education and Migration product or service is mistakenly listed
        at an incorrect price, we reserve the right to refuse or cancel any orders listed at the
        incorrect price. Hope Education and Migration reserves the right to refuse or cancel any
        such orders whether or not the order has been confirmed and your credit card charged.
        In the event your credit card has been charged, a full refund will be issued in the
        amount of the incorrect price.
        </div>
      </div>

      <div className='privacy-usenews'>
        <div className='privacyIntel1'>
            <span className='privacy-subheading'> Personal Use Limitation :</span>
            <div className='privacy-content'>
            The information, news, articles, emails, products, and services provided by Hope  Education and Migration are for your personal and non-commercial use. You may not modify, copy, distribute, transmit, display, perform, reproduce, publish, license, create  derivative works from, transfer, or sell any information or other content, products or services obtained from Hope Education and Migration without express written permission from us.
            </div>
            
        </div>
        <div className='privacyIntel1'>
            <span className='privacy-subheading'> Hope Education and Migration Newsletter :</span>
            <div className='privacy-content'>
            Hope Education and Migration.com (this website) provides a free newsletter. This is an  Opt-Out service, which means that the user can remove his or her email address from  the newsletter at any time. An unsubscribe page is available to users to this end. Your email will never be shared with any third party.
            </div>
        </div>
      </div>

      <div className='privacy-affiliation'>
        <span className='privacy-subheading'>Links :</span>
        <div className='privacy-content'>
        This website contains hyperlinks that may take you outside Hope Education and  Migration.com. Links are made available for your convenience. However, the inclusion  of any link does not imply endorsement or approval by Hope Education and Migration. We are not responsible for any links to and from Hope Education and Migration.com. The framing of our website at any level is prohibited.
        </div>
      </div>

      <div className='privacy-limitation1'>
        <span className='privacy-subheading'> Refund Policy:</span>
        <div className='privacy-list'>
        <div className='privacy-content1'>
        A refund policy is applicable for the below criteria:  
        <ul className='ul-list'>
            <li>Evaluations: 100% non-refundable. </li>
            <li>DIYKits: 100% non-refundable.</li>
            <li>Directories: 100% non-refundable.  </li>
            <li>JobSearch Services: 100% non-refundable.</li>
        </ul>
        </div>
        <div className='privacy-content1' >
        Your amount is NONREFUNDABLE in uncertain situations like:  
        <ul className='ul-list'>
            <li>Natural calamities. </li>
            <li>The advent of the pandemic.</li>
        </ul>
        </div>
        </div>
      </div>

      <div className='privacy-limitation2'>
        <span className='privacy-subheading'>Other Services :</span>
        <div className='privacy-content'>
            <ul className='ul-list' >
                <li>100% non-refundable if the agreement is not signed by you and duly returned to  us. </li>
                <li>100% non-refundable if you purchase & change your mind later and decide to  withdraw.  </li>
                <li>100% non-refundable if you do not wish to continue with our services.</li>
            </ul>
            <br/>
            100% non-refundable if:  
            <ul className='ul-list'>
                <li>Failure of the medical examination by the client or his/her family members is included in the permit request. </li>
                <li>Failure to provide a genuine Police Clearance Certificate which is not less than 3 months old. </li>
                <li>Failure to prove sufficient funds for settlement or maintenance by the client or his/her family members included in the permit request.</li>
                <li>Submission of fraudulent papers.</li> 
                    <li>Aprior violation of any immigration law by the client or any of his or her family members is included in the permit request.</li>   
                <li>Late submission of any additional papers requested by the consulate at a later stage.</li>
            </ul>
            <br/>
            <div>
                 You may refer to the applicable refund terms specific to the product in the service agreement  Hope Education and Migration reserves the right to not issue a refund as per our policies and in line with this agreement. 
                 <></><br/>
                Refunds if issued, will be processed within 30 days after you have filled in the refund request form and provided proof of rejection if any.
                <></>
                <br/>
                By agreeing to our terms and conditions, you agree you will not ask for a charge back under any circumstances.
                <></>
                <br/>
                Refunds for payments received will be issued as a company check. The refund check will be made payable to the person on the order form and mailed to the address indicated on the order form.
                <></>  <br/>
                You hereby agree that you will not contact your Credit Card Company or bank to file a dispute as this will only delay the refund process.
                <br/>
            </div>
        </div>
      </div>

      <div className='privacy-copyright'>
        <div className='privacy-copydesc'>
            <span className='privacy-subheading'> Our Products and Services :</span>
            <div className='privacy-content'>
            Hope Education and Migration offers a technical evaluation service which will evaluate your profile for a selected country and let you know how many points have been scored. All reports are sent within 48 hours of sign up if all information has been submitted in the form. The fee for an evaluation report is 100% non-refundable.
            </div>
        </div>
        <div className='privacy-copydesc'>
            <span className='privacy-subheading'>  Full Service :</span>
            <div className='privacy-content'>
            Hope Education and Migration offers only guidance and advice for immigration. All the  services are undertaken at a back office in India and you hereby agree to this  arrangement. The fee for full service is refundable only as per the conditions listed  above.
            </div>
        </div>
        <div className='privacy-copydesc'>
            <span className='privacy-subheading'> Directories :</span>
            <div className='privacy-content'>
            Hope Education and Migration offers downloadable Directories of employers/placement  agencies in a number of cities. All directories available on our website are published by  Hope Education and Migration. These are available for download for a fee forms. The forms provided on our website are published  by various overseas government agencies.
            </div>
        </div>
      </div>

      <div className='privacy-affiliation'>
        <span className='privacy-subheading'>DIY Kits :</span>
        <div className='privacy-content'>
        Hope Education and Migration offers downloadable DIY Kits (do-it-yourself guides). All  DIY Kits available on our website are published by Hope Education and Migration. Kits are available for download for a fee. The fee is 100% non refundable. All kits are  designed for information purposes only, and should not be considered as legal advice.  Copyright Information: DIY Kits are published by Hope Education and Migration and are  protected by all international copyright laws. Legal action will be taken against anyone  who attempts to copy or sell the same.
        </div>
      </div>

      <div className='privacy-copyright1'>
        <div className='privacy-copydesc1'>
            <span className='privacy-subheading'> Copyright Information :</span>
            <div className='privacy-content'>
            Directories are published by Hope Education and Migration and are protected by all  international copyright laws. Legal action will be taken against anyone who attempts to  copy or sell the same.
            </div>
        </div>
        <div className='privacy-copydesc1'>
            <span className='privacy-subheading'> Requisition Forms :</span>
            <div className='privacy-content'>
            Hope Education and Migration offers several types of requisition and inquiry forms for  download as an additional service to our customers. Forms are available for a fee on a  subscription basis.
            </div>
        </div>
        <div className='privacy-copydesc1'>
            <span className='privacy-subheading'> Copyright Information :</span>
            <div className='privacy-content'>
            No copyright is claimed on any forms. The forms provided on our website are published by various overseas government agencies.
            </div>
        </div>
      </div>
      <div className='privacy-affiliation'>
        <span className='privacy-subheading'>Shipping Policy :</span>
        <div className='privacy-content'>
             We do our utmost to ensure that your order is available for immediate download after
            purchase. Delays, although rare, may happen due to technical difficulties or due to
            issues beyond our control. In case of any technical difficulty, the order will be sent to the
            email-id specified by you. Please note, there is no refund or charge back permitted once
            the order has been placed.
        </div>
      </div>
      <div className='privacy-affiliation'>
        <span className='privacy-subheading'>Warranty Disclaimer :</span>
        <div className='privacy-content'>
        This site and the materials and products on this site are provided “as is” and without
        warranties of any kind, whether express or implied. To the fullest extent permissible
        pursuant to applicable law, Hope Education and Migration disclaims all warranties,
        express or implied, including, but not limited to, implied warranties of merchantability
        and fitness for a particular purpose and non-infringement. Hope Education and
        Migration does not represent or warrant that the functions contained in the site will be
        uninterrupted or error-free, that the defects will be corrected, or that this site or the
        server that makes the site available is free of viruses or other harmful components.
        Hope Education and Migration does not make any warranties or representations
        regarding the use of the materials in this site in terms of their correctness, accuracy,
        adequacy, usefulness, timeliness, reliability, or otherwise. Some states do not permit
        limitations or exclusions on warranties, so the above limitations may not apply to you.
        </div>
      </div>

      <div className='privacy-usenews'>
        <div className='privacyIntel4'>
            <span className='privacy-subheading'>  Changes to the Terms and Conditions:</span>
            <div className='privacy-content'>
            Hope Education and Migration retains the right, at its discretion, to change its Terms
            and Conditions at any time. By purchasing any product listed on our website, you
            hereby agree to all terms and conditions listed in this form. You hereby agree not to
            dispute these terms under any circumstances. All disputes are subject to the exclusive
            jurisdiction of Hyderabad Courts only.
            </div>
            
        </div>
        <div className='privacyIntel4'>
            <span className='privacy-subheading'>Data protection principles :</span>
            <div className='privacy-list'>
        <div className='privacy-content1'>
        We will comply with data protection law. This says that the personal information we hold
        about you must be:  
        <ol className='ul-list'>
            <li>Used lawfully, fairly, and in a transparent way. </li>
            <li> Collected only for valid purposes
                that we have clearly explained to you and not used in any way that is
                incompatible with those purposes. </li>
            <li> Relevant to the purposes we have told you
            about and limited only to those purposes. </li>
            <li> Accurate and kept up to date.</li>
            <li>Kept securely.</li>
        </ol>
        </div>
        </div>
        </div>
      </div>

      <div className='privacy-affiliation'>
        <span className='privacy-subheading'>  The kind of information we hold about you </span>
        <div className='privacy-content'>
        Personal data, or personal information, means any information about an individual from
        which that person can be identified. It does not include data where the identity has been
        removed (anonymous data). There are “special categories” of more sensitive personal
        data which require a higher level of protection. We may collect, store, and use the
        following categories of personal information about you: Personal contact details such as
        name, title, addresses, telephone numbers, and personal email addresses. Date of
        birth, gender, Marital status. Next of kin and emergency contact information. National
        Insurance or TAX ID number/PAN card. Bank account details, payroll records and tax
        status information. Driving license. Grievance information. Information about your use of
        our information and communications systems.
        </div>
      </div>

      <div className='privacy-usenews'>
        <div className='privacyIntel6'>
            <span className='privacy-subheading'>How is your personal information collected?</span>
            <div className='privacy-content'>
            We collect personal information about data subjects through various methods such as
        Landing pages, Websites, Registration
            </div>  
        </div>
        <div className='privacyIntel6'>
            <span className='privacy-subheading'>Landing page terms:
            </span>
            <div className='privacy-list'>
        <div className='privacy-content1'>
        We are offering free counseling services via landing pages and websites.
        </div>
        </div>
        </div>
      </div>

      <div className='privacy-limitation1'>
        <span className='privacy-subheading'> How will we use information about you?</span>
        <div className='privacy-list'>
        <div className='privacy-content1'>
        We will only use your personal information when the law allows us to. Most commonly,
        we will use your personal information in the following circumstances:  
        <ol className='ul-list'>
            <li> Where we need to perform the agreement we have entered into with you. </li>
            <li>Where we need to comply with a legal obligation.</li>
            <li>Where it is necessary for our legitimate interests (or those of a third party) and
            your interests and fundamental rights do not override those interests. </li>
            <li>Where you have given us explicit consent to do so. We may also use your
            personal information in the following situations, which are likely to be rare:
            <ul style={{listStyleType:'disc'}}>
            <li> Whereweneedto protect your interests (or someone else’s interests).</li>
            <li>Whereit is needed in the public interest or for official purposes or requested for
            by the CBI, police or governmental authorities.</li>
            </ul>
            </li>
        </ol>
        As a registered member, you will receive important updates and notifications over
        WhatsApp.
        </div>
        </div>
      </div>

<div className='privacy-affiliation'>
        <span className='privacy-subheading'> Situations in which we will use your personal information:</span>
        <div className='privacy-content'>
        We need all the categories of information in the list above primarily to allow us to
        perform our contract with you and to enable us to comply with legal obligations. In some
        cases, we may use your personal information to pursue legitimate interests of our own
        or those of third parties.
        <br/>
        <ul className='ul-list'>
            <div>
            <li>Administering the contract, we have entered into with you.</li>
            <li>Business management and planning, including accounting and auditing.
            </li>
            <li>Making decisions about grievances.</li>
            <li>Making arrangements for the termination of a contract.</li>
            <li>Dealing with legal disputes</li>
            <li>Complying with health and safety obligations.</li>
            <li>To prevent fraud.</li>
            <li>To monitor your use of our information and communication systems to ensure
            compliance with our Global IT policy and laws of the land.</li>
            <li> To ensure network and information security, including preventing unauthorized
            access to our computer and electronic communications systems and preventing
            malicious software distribution.</li>
            <li>To conduct data analytics studies to review and better understand customer
            satisfaction and needs.</li>
            </div>
        </ul>
        Some of the above grounds for processing might overlap. There may be certain
        grounds that justify our use of your personal information. If you fail to provide personal
        information If you fail to provide certain information when requested, we may not be
        able to perform the agreement we have entered into with you or we may be prevented
        from complying with our legal obligations (such as to ensure the health and safety or
        proof of nationality )
        </div>
    </div>

    <div className='privacy-affiliation'>
        <span className='privacy-subheading'>Change of purpose</span>
        <div className='privacy-content'>
        We will only use your personal information for the purposes for which we collected it
        unless we reasonably consider that we need to use it for another reason and that
        reason is compatible with the original purpose. If we need to use your personal
        information for an unrelated purpose, we will notify you and we will explain the legal
        basis which allows us to do so. Please note that we may process your personal
        information without your knowledge or consent, in compliance with the above rules,
        where this is required or permitted by law.
        </div>
      </div>

    <div className='privacy-affiliation'>
        <span className='privacy-subheading'>  How do we use particularly sensitive personal information?</span>
        <div className='privacy-content'>
        “Special categories” of particularly sensitive personal information require higher levels of
        protection. We need to have further justification for collecting, storing, and using this
        type of personal information. We may process special categories of personal
        information in the following circumstances:
        <br/>
        <ol className='ul-list'>
            <div>
            <li>In limited circumstances, with your explicit written consent.</li>
            <li>Where do we need to carry out our legal obligations
            </li>
            <li> Where it is needed in the public interest, such as when requested by CBI, police,
            or governmental authorities. Less commonly, we may process this type of
            information where it is needed in relation to legal claims or where it is needed to
            protect your interests (or someone else’s interests) and you are not capable of
            giving your consent, or where you have already made the information public.
            </li>
            </div>
        </ol>
        Principles of GDPR
        <br/>
        We will use your personal data only in line with the following principles:
        <br/>
        <ol className='ul-list'>
            <div>
            <li>Lawfulness, fairness, and transparency</li>
            <li>Purpose limitation
            </li>
            <li> Data minimization
            </li>
            <li>Accuracy</li>
            <li>Storage limitation</li>
            <li> Integrity and confidentiality This is the only principle that deals explicitly with
                security. The GDPR states that personal data must be “processed in a manner
                that ensures appropriate security of the personal data, including protection
                against unauthorized or unlawful processing and against accidental loss,
                destruction or damage, using appropriate technical or organizational measures”.
                The GDPR is deliberately vague about what measures organizations should take
                because technological and organizational best practices are constantly changing.
                Currently, organizations should encrypt and/or pseudonymize personal data
                wherever possible, but they should also consider whatever other options are
                suitable.</li>
            </div>
        </ol>
        </div>
    </div>

<div className='privacy-copyright2'>
        <div className='privacy-copydesc2'>
            <span className='privacy-subheading'>  Cookies</span>
            <div className='privacy-content'>
            Cookies are small text files stored on your device and used by web browsers to deliver
            personalized content and remember logins and account settings. Hope Education and
            Migration uses cookies and similar technologies, including tracking pixels and web
            beacons, to collect usage and analytic data that helps us provide our Site, Software,
            and/or Services to you, as well as to help deliver ads for relevant Hope Education and
            Migration products and services to you when you visit certain pages on the Site and
            then visit certain third-party sites. Our products currently do not respond to Do Not Track
            requests.
            </div>
        </div>
        <div className='privacy-copydesc2'>
            <span className='privacy-subheading'> Do we need your consent?</span>
            <div className='privacy-content'>
            We do not need your consent if we use special categories of your personal information
            in accordance with our written policy to carry out our legal obligations or exercise
            specific rights. In limited circumstances, we may approach you for your written consent
            to allow us to process certain particularly sensitive data. If we do so, we will provide you
            with full details of the information that we would like and the reason we need it, so that
            you can carefully consider whether you wish to consent. You should be aware that it is
            not a condition of your contract with us that you agree to any request for consent from
            us.
            </div>
        </div>
        <div className='privacy-copydesc2'>
            <span className='privacy-subheading'>Automated decision-making</span>
            <div className='privacy-content'>
            Automated decision-making takes place when an electronic system uses personal
            information to make a decision without human intervention. We do not envisage that
            any decisions will be taken about you using automated means, however, we will notify
            you in writing if this position changes. We will share your personal information with third
            parties where required by law.
            </div>
        </div>
        <div className='privacy-copydesc2'>
            <span className='privacy-subheading'>  Data security:</span>
            <div className='privacy-content'>
            We have put in place measures to protect the security of your information. We have put
            in place appropriate security measures to prevent your personal information from being
            accidentally lost, used, or accessed in an unauthorized way, altered, or disclosed. In
            addition, we limit access to your personal information to those employees, and agents
            who have a business need to know. They will only process your personal information on
            our instructions and they are subject to a duty of confidentiality. We have put in place
            procedures to deal with any suspected data security breach and will notify you and any
            applicable regulator of a suspected breach where we are legally required to do so.

            </div>
        </div>
      </div>
     
      <div className='privacy-affiliation'>
        <span className='privacy-subheading'>  Rights of access, correction, erasure, and restriction:</span>
        <div className='privacy-content'>
        <span style={{fontStyle:'italic'}}>Your duty to inform us of changes</span><br/>
        It is important that the personal information we hold about you is accurate and current.
        Please keep us informed if your personal information changes during your relationship
        with us.<br/>
        <span style={{fontStyle:'italic'}}> Your rights in connection with personal information</span><br/>
        Under certain circumstances, by law you have the right to:
        <br/>
        <ul className='ul-list'>
            
            <li> Request access to your personal information (commonly known as a “data
            subject access request”). This enables you to receive a copy of the personal
            information we hold about you and to check that we are lawfully processing it.</li>
            <li> Request correction of the personal information that we hold about you. This
            enables you to have any incomplete or inaccurate information we hold about you
            corrected.
            </li>
            <li>  Request the erasure of your personal information. This enables you to ask us to
            delete or remove personal information where there is no good reason for us to
            continue to process it. You also have the right to ask us to delete or remove your
            personal information where you have exercised your right to object to processing
            (see below).
            </li>
            <li> Object to processing of your personal information where we are relying on a
            legitimate interest (or those of a third party) and there is something about your
            particular situation which makes you want to object to processing on this ground.
            You also have the right to object where we are processing your personal
            information for direct marketing purposes.</li>
            <li> Request the restriction of processing of your personal information. This enables
            you to ask us to suspend the processing of personal information about you, for
            example, if you want us to establish its accuracy or the reason for processing it.</li>
        </ul>
        If you want to review, verify, correct or request the erasure of your personal information,
            object to the processing of your personal data or request that we transfer a copy of your
            personal information to another party, please contact Bengaluru@hopegroup.com.au in
            writing.
        </div>
    </div>

      <div className='privacy-usenews'>
        <div className='privacyIntel5'>
            <span className='privacy-subheading'> No fee is usually required:</span>
            <div className='privacy-content'>
            You will not have to pay a fee to access your personal information (or to exercise any of
                the other rights)
            </div>  
        </div>
        <div className='privacyIntel5'>
            <span className='privacy-subheading'>What we may need from you?
            </span>
            <div className='privacy-list'>
        <div className='privacy-content'>
        We may need to request specific information from you to help us confirm your identity
        and ensure your right to access the information (or to exercise any of your other rights).
        This is another appropriate security measure to ensure that personal information is not
        disclosed to any person who has no right to receive it.
        </div>
        </div>
        </div>
      </div>

      <div className='privacy-affiliation'>
        <span className='privacy-subheading'>   Right to withdraw consent:</span>
        <div className='privacy-content'>
        In the limited circumstances where you may have provided your consent to the
        collection, processing, and transfer of your personal information for a specific purpose,
        you have the right to withdraw your consent for that specific processing at any time. To
        withdraw your consent, please contact Bengaluru@hopegroup.com.au. Once we have
        received notification that you have withdrawn your consent, we will no longer process
        your information for the purpose or purposes you originally agreed to, unless we have
        another legitimate basis for doing so in law.
        </div>
      </div>

      <div className='privacy-usenews'>
        <div className='privacyIntel5'>
            <span className='privacy-subheading'>Data protection:</span>
            <div className='privacy-content'>
            If you have any questions about this privacy notice or how we handle your personal
            information, please contact us on Bengaluru@hopegroup.com.au
            </div>  
        </div>
        <div className='privacyIntel5'>
            <span className='privacy-subheading'>Changes to this privacy statement:
            </span>
            <div className='privacy-list'>
        <div className='privacy-content1'>
        We reserve the right to update this privacy statement at any time. We may also notify
        you in other ways from time to time about the processing of your personal information.
        For further details, please get in touch or you can e-mail us at
        Bengaluru@hopegroup.com.au. One of our representatives will get back to you at
        the earliest.
        </div>
        </div>
        </div>
      </div>

      <div className='privacy-affiliation'>
        <span className='privacy-subheading'> Information We Collect</span>
        <div className='privacy-content'>
        We collect information that identifies, relates to, describes, references is capable of
        being associated with, or could reasonably be linked, directly or indirectly, with a
        particular consumer or device (“personal information”). In particular, we have collected
        the following categories of personal information from consumers within the last twelve
        (12) months:
        <br/>
        
        <ul className='ul-list'>
            <li> Category A– Identifiers
                <br/>
            Name, phone number, email address</li>
            <li> Category I– Professional or employment-related information <br/>  Occupation, work experience, skill sets</li>
            <li>Category J– Non-public education information (per the Family Educational
                Rights and Privacy Act (20 U.S.C. Section 1232g, 34 C.F.R. Part 99)) <br/> Education level, school or university attended</li>
        </ul>
        <br/>
        We obtain the categories of personal information listed above from the following
        categories of sources:
        <br/>
        <ul className='ul-list'>
            <li> Directly from our clients or their agents. For example, from papers that our clients
            provide to us related to the services for which they engage us.</li>
            <li> Indirectly from our clients or their agents. For example, through information, we
            collect from our clients in the course of providing services to them.</li>
            <li>Directly and indirectly from activity on our website (Hope Education and
                Migration.com). For example, submissions through our website portal or website
                usage details are collected automatically.</li>
        </ul>
        </div>
    </div>

      <div className='privacy-affiliation'>
        <span className='privacy-subheading'>  Use of Personal Information</span>
        <div className='privacy-content'>
        We may use or disclose the personal information we collect for one or more of the
        following business purposes:
        <br/>
        
        <ul className='ul-list'>
            <li>Toprovide you with information, products, or services that you request from us.</li>
            <li> Toprovide you with email alerts, event registrations, and other notices
            concerning our products or services, or events or news, that may be of interest to
            you.</li>
            <li>  Tocarry out our obligations and enforce our rights arising from any contracts
            entered into between you and us, including for billing and collections.</li>
            <li>Toimprove our website and present its contents to you.</li>
            <li>Fortesting, research, analysis, and product development.</li>
        </ul>
        </div>
    </div>

    <div className='privacy-usenews'>
        <div className='privacyIntel2'>
            <span className='privacy-subheading'>Your Rights and Choices</span>
            <div className='privacy-content'>
            The CCPA provides consumers (California residents) with specific rights regarding their
            personal information. This section describes your CCPA rights and explains how to
            exercise those rights.
            </div>  
        </div>
        <div className='privacyIntel2'>
            <span className='privacy-subheading'> Access to Specific Information and Data Portability Rights
            </span>
            <div className='privacy-list'>
        <div className='privacy-content1'>
        You have the right to request that we disclose certain information to you about our
        collection and use of your personal information over the past 12 months. Once we
        receive and confirm your verifiable consumer request, we will disclose to you:
        The categories of personal information we collected about you.
        Our business or commercial purpose for collecting that personal information.
        The categories of third parties with whom we share that personal information.
        The specific pieces of personal information we collected about you (also called a data
        portability request).
        </div>
        </div>
        </div>
      </div>

    <div className='privacy-affiliation'>
        <span className='privacy-subheading'>  Deletion Request Rights</span>
        <div className='privacy-content'>
        You have the right to request that we delete any of the personal information we
        collected from you and retain it, subject to certain exceptions. Once we receive and
        confirm your verifiable consumer request, we will delete (and direct our service
        providers to delete) your personal information from our records, unless an exception
        applies.
        <br/>
        We may deny your deletion request if retaining the information is necessary for us or
        our service providers to:
        <ol className='ol-list'>
            <div>
            <li>Complete the transaction for which we collected the personal information,
            provide a product or service that you requested, take actions reasonably
            anticipated within the context of our ongoing business relationship with you, or
            otherwise perform our contract with you.</li>
            <li>Detect security incidents, protect against malicious, deceptive, fraudulent, or
            illegal activity, or prosecute those responsible for such activities.
            </li>
            <li>Debug products to identify and repair errors that impair existing intended
            functionality.</li>
            <li>Exercise free speech, ensure the right of another consumer to exercise their free
            speech rights, or exercise another right provided for by law.</li>
            
            </div>
            <div>
            <li>Comply with the California Electronic Communications Privacy Act (Cal. Penal
                Code § 1546 ).</li>
            <li>Engage in public or peer-reviewed scientific, historical, or statistical research in
            the public interest that adheres to all other applicable ethics and privacy laws,
            when the information’s deletion may likely render impossible or seriously impair
            the research’s achievement if you previously provided informed consent.</li>
            <li>Enable solely internal uses that are reasonably aligned with consumer
            expectations based on your relationship with us.</li>
            <li>Comply with a legal obligation.</li>
            <li>Make other internal and lawful uses of that information that are compatible with
            the context in which you provided it.</li>
            </div>
           
        </ol>
        </div>
    </div>

    <div className='privacy-affiliation'>
        <span className='privacy-subheading'> Exercising Access, Data Portability, and Deletion Rights</span>
        <div className='privacy-content'>
        To exercise the access, data portability, and deletion rights described above, please
        submit a verifiable consumer request to Bengaluru@hopegroup.com.au.
        <br/>
        Only you or a person registered with the California Secretary of State that you authorize
        to act on your behalf may make a verifiable consumer request related to your personal
        information. You may also make a verifiable consumer request on behalf of your minor
        child.
        <br/>
        You may only make a verifiable consumer request for access or data portability twice
        within a 12-month period. The verifiable consumer request must:
        <ul className='ul-list'>
            <li>Provide sufficient information that allows us to reasonably verify you are the
            person about whom we collected personal information or an authorized
            representative.</li>
            <li>Describe your request with sufficient detail that allows us to properly understand,
            evaluate, and respond to it.</li>
        </ul>
        We cannot respond to your request or provide you with personal information if we
        cannot verify your identity or authority to make the request and confirm the personal
        information relates to you. Making a verifiable consumer request does not require you
        to create an account with us. We will only use personal information provided in a
        verifiable consumer request to verify the requestor’s identity or authority to make the
        request.
        </div>
    </div>

    <div className='privacy-usenews'>
        <div className='privacyIntel3'>
            <span className='privacy-subheading'>Response time :</span>
            <div className='privacy-content'>
            We will respond to your deletion request within 24 to 48 hours.
            </div>  
        </div>
        <div className='privacyIntel3'>
            <span className='privacy-subheading'> Changes to Our Privacy Policy :
            </span>
            <div className='privacy-list'>
        <div className='privacy-content1'>
        We reserve the right to amend this Privacy Policy at our discretion and at any time. The
        date this Privacy Notice was last 
        </div>
        </div>
        </div>
      </div>
    </div>
  )
}

export default PrivacyPolicy
