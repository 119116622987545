import React, { useEffect, useState } from "react";
import { imgDB,txtDB } from "../config/fireBase";
import { v4 } from "uuid";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { addDoc, collection, deleteDoc, doc, getDocs } from "firebase/firestore";
import {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    signInWithPopup,
    signOut,
  } from "firebase/auth";
  import HopeLogo from '../images/HopeLogo.png';
  
  import { auth, googleProvider } from "../config/fireBase";
import BlogBanner from "../images/ManUsingLap.mp4"
import DottedLines from '../images/DottedLines.png'
// import NavBarComponent from '../components/NavBarComponent'
import HeroBanner from '../components/HeroBanner'
// import Familyimg from "../images/Flag.mp4"
// import Familyimg1 from "../images/WomenOnlineWriting.mp4"
// import Familyimg2 from "../images/BlogWomenHandFilling.mp4"
// import BlogHeadComp from '../components/BlogHeadComp'
import "../styles/Blog.css"
// import aish from "../images/Banner.png"
import Footer from '../components/Footer'
import { useNavigate, useParams } from "react-router-dom";
import { CaretLeft, CaretRight ,FileArrowUp,SignOut,XCircle ,CheckCircle} from '@phosphor-icons/react'
import { CircularProgress } from "@mui/material";
// import NavBarAdmin from './NavBarAdmin'
import "../styles/contactUs.css"
import image from "../images/ImgUploader.png"
import SubNavAdmin from "./SubNavAdmin";
import HeroAdmin from "./HeroAdmin";
import Links from "../pages/Links";
import { ToastContainer ,toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Trash } from "@phosphor-icons/react/dist/ssr";

function BlogAdmin(props) {
    const { id } = useParams();
    const navigate = useNavigate();
    const[user,setUser] =useState(null);
    const [display, setDisplay] = useState(false);
    const [loading, setLoading] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [uploadEligible, setUploadEligible] = useState(false);
    const [ upload, setUpload] = useState(0);
    const [activeTab, setActiveTab] = useState('Blog');
    const [txt,setTxt] = useState('')
    const[txtDesVal ,setTxtDesVal]=useState('')
    const [img,setImg] = useState('')
    const [data,setData] = useState([])
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
const [deleteEntryId, setDeleteEntryId] = useState(null);

    const handleTabClick = tabName => {
      setActiveTab(tabName);
    };
    const handleButtonClick1 = () => {
      window.scrollTo(0,0);
      navigate("/DeatiledBlog");
  
    };
    const logout = async () => {
        try {
            setUser();
          await signOut(auth);
          console.log(auth);
          window.scrollTo(0,0);
          navigate("/");
          toast.success('Logged out');
          setDisplay(false);

        } catch (err) {
          console.error(err);
        }
      };
      const handleUpload = async (e) => {
        console.log(e.target.files[0]);
        const storagePath = `img/${v4()}`;
        const imgs = ref(imgDB, storagePath);
        
        try {
            setUploading(true);
            const data = await uploadBytes(imgs, e.target.files[0]);
            const val = await getDownloadURL(data.ref);
            
            console.log('Download URL:', val); // Log the download URL
            setImg(val); // Ensure setImg is updating the state correctly
            
            setUploadEligible(true);
            setUpload(upload + 1);
            setUploadSuccess(true);
        } catch (error) {
            setUploading(false);
            console.error(error);
        } finally {
            setUploading(false);
            
        }
    };
    useEffect(() => {
        console.log('imgUrl after upload:', img);
    }, [img]);
    
      
      const getData = async () => {
        setLoading(true);
        try {
          const valRef = collection(txtDB, 'txtData');
          const dataDb = await getDocs(valRef);
          const allData = dataDb.docs.map((val) => ({ ...val.data(), id: val.id }));
          setData(allData);
          console.log(allData);
           setDisplay(true);
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      };   
      useEffect(() => {
        const fetchData = async () => {
            
          setLoading(true);
          await getData();
          setLoading(false);
        };
      
        fetchData();
      }, []);

      const handleClick = async (e) => {
        e.preventDefault();
        const valRef = collection(txtDB, 'txtData');
        
        if (txt.trim() !== '' && txtDesVal.trim() !== '' && img.trim() !== '') {
          try {
            await addDoc(valRef, { txtVal: txt, txtDesVal: txtDesVal ,imgUrl: img });
            toast.success('Data added successfully' ,{ autoClose: 10 });
            setUploadEligible(false);
            setTxt('');
            setTxtDesVal('');
            setImg('');
            await getData();
          } catch (error) {
            console.error(error);
          }
        } else {
            toast.error('Please fill in all fields before adding data.');
        }
      };
      const openDeleteModal = (id) => {
        setDeleteEntryId(id);
        setShowDeleteModal(true);
      };
      
    const handleDelete = async (id) => {
      try {
        const valRef = doc(txtDB, 'txtData', id);
        await deleteDoc(valRef);
        toast.success("Data deleted successfully", { autoClose: 10 });
        getData();
      } catch (error) {
        console.error(error);
      }
    };
    const handleReadMore  = async (id) => {
        navigate(`/detailed-blog/${id}`);

      };
    const shouldSwap = props.swapImageAndDescription;
    const containerStyle = {
        backgroundColor: props.containerColor || '#F4F4F4', 
        color: props.textColor || '#014A91', 
      };
      const containerStyle1 = {
        backgroundColor: props.containerColor2 || '#F4F4F4', 
        color: props.textColor2 || 'rgba(59, 59, 59, 1)', 
      };
      const handleButtonClick = (onClick) => {
        if (typeof onClick === 'function') {
          onClick();
        } else {
          console.error('Invalid onClick function provided');
        }
      };
      
      const handleNavigateHome = () => {
        window.scrollTo(0,0);
        navigate("/");
      }
      const handleNavigateLink =()=>{
        window.scrollTo(0,0);
        navigate("/links");
      }
      const handleDescriptionChange = (e) => {
        if (e.key === 'Enter' && e.shiftKey) {
          setTxtDesVal((prevValue) => prevValue + '\n');
        }
      };
      
  return (
    <div>
         <ToastContainer position="top-center"/>
       <div className='navbar'>
                <div className='logo'>
                    <img className='HopeLogo' src={HopeLogo} alt='Logo' onClick={handleNavigateHome}/>
                </div>
                <div onClick={logout}  >LogOut <SignOut /></div>
            </div>
        <HeroAdmin
        heroHeading='Blogs'
        heroSubHeading='Our registered migration agents regularly share new, tips and tricks and other interesting information for migrants. '
        // connectButton='Let’s Connect'
        Banner={[BlogBanner]}
        DottedLines={DottedLines}
        />
        
       <div>
        {/* <SubNavAdmin /> */}

        <div className="TabsContainer" >
      <div
        className={`Tab ${activeTab === 'Blog' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('Blog')}
      >
        Add Blog
      </div>
      <div
        className={`Tab ${activeTab === 'Links' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('Links')}
      >
        Add Links
      </div>
   
    </div>
    {activeTab === 'Blog' &&
  
             <div className='Conatct_us_container_admin'>
       <div className='Conatct_us_container_left_admin'>Add New Blogs</div>
       <div className='Conatct_us_container_right_admin'>
       <form className='help-screen-form'>
  <div className="label_head">Title:</div>
  <input type="text" name="title" className='help-form-textField' required onChange={(e) => setTxt(e.target.value)} />

  <div className="label_head">Description:</div>
  {/* <input type="text" 
  name="description" className='help-form-textField'
   required onChange={(e) => setTxtDesVal(e.target.value)} /> */}
  <textarea
        name="description"
        className='help-form-textarea'
        required
        onChange={(e) => setTxtDesVal(e.target.value)}
        onKeyDown={handleDescriptionChange}
        value={txtDesVal}
      />
  <div className="label_head">Image:</div>
  <>
  {!uploading ? (
     <div style={{ display: 'flex', alignItems: 'center' }}>
        
 <input  className='help-form-textField' type="file" name="image" accept="image/*" required onChange={handleUpload} style={{cursor:"pointer"}} />
     {uploadSuccess && (
        <CheckCircle
          style={{ color: 'green', marginLeft: '5px' }}
          size={30}
        />
      )}
     </div>
/* <div style={{ display: 'flex', alignItems: 'center' ,border:"1px solid rgba(0, 0, 0, 0.40)" ,borderRadius:"20px",padding:"52px" }}>
  <label htmlFor="imageInput">
    <img
      src={image}  // Replace with the path to your image
      alt="Choose Image"
      style={{ cursor: 'pointer' }}
    />
    <input
      id="imageInput"
      type="file"
      name="image"
      accept="image/*"
      required
      onChange={handleUpload}
      style={{ display: 'none' }}  // Hide the default file input
    />
  </label>
  
  {uploadSuccess && (
    <CheckCircle
      style={{ color: 'green', marginLeft: '5px' }}
    />
  )}
</div> */

  ) : (
    <div
      style={{
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress style={{ color: "rgba(227, 144, 2, 1)" }} />
    </div>
  )}
</>
  <div className="buttons_field">
    <button className="family_header_desc_btn_admin " type="submit" onClick={handleClick}>
      Add
    </button>
  </div>
</form>
</div>

<div className='Blog_layout' style={{padding:"0px"}}>

        {loading ? (
              <div style={{display:"flex", width:"100%",alignItems:"center",justifyContent:"center"}}><CircularProgress style={{color:"pink" }} /></div>
        ) : (
          <div>
            {data.map((entry) => (
              <div key={entry.id}>
               { console.log('entry:', entry)}
                <div className='Family_header_Box' style={containerStyle}>
        <div className='Family_header_title' style={containerStyle}>{entry.txtVal}</div>
      </div>
      <div className='Family_header_Image'>
        {shouldSwap ? (
          <>
           <div className="admin_blog_content">
            <div className='Blog_header_description_admin' style={containerStyle1}>
            <div className="blog_content_desc"> {entry.txtDesVal}</div>
              </div>
            </div>
            <div className='Family_header_image'>
            <img src={entry.imgUrl} alt="Blog Image" className='Family_header_img' />

            </div>
          </>
        ) : (
          <>
          <div className="admin_blog_content">
            <div className='Blog_header_description_admin' style={containerStyle1}>
            <div className="blog_content_desc">{entry.txtDesVal}</div>
            <div className="read-delete_btn_fields">
            <button onClick={() => handleReadMore(entry.id)} className="family_header_desc_btn">Read More</button>
            {/* <button onClick={() => handleDelete(entry.id)} className="family_header_desc_btn_secondry">Delete</button> */}
            <button onClick={() => openDeleteModal(entry.id)} className="family_header_desc_btn_secondry">Delete</button>

            </div>
            </div>
            
            </div>
            {showDeleteModal && (
  <div className="modal-overlay">
    <div className="delete-modal">
      <p>Are you sure you want to delete this Blog?</p>
      <div className="sure_btns">
        <button className="sure_ok" onClick={() => handleDelete(deleteEntryId)}>Ok</button>
        <button className="sure_cancel" onClick={() => setShowDeleteModal(false)}>Cancel</button>
      </div>
    </div>
  </div>
)}

            <div className='Family_header_image'>
            <img src={entry.imgUrl} alt="Blog Image" className='Family_header_img' />

            </div>
          </>
       )}
      </div>
         </div>
            ))}
          </div>
        )}
      </div>
        </div>
        }
        {activeTab === 'Links' &&
      
           <Links />
     
        }

     
</div>

        <Footer />
    </div>
  )
}
export default BlogAdmin