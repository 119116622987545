import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getAuth ,GoogleAuthProvider } from "firebase/auth";
import { getStorage } from "firebase/storage"
import { getFirestore } from "firebase/firestore"

const firebaseConfig = {
    apiKey: "AIzaSyAInJB-MqIVn1UXlWy8dO1TZSsr7LjpsAU",
    authDomain: "fire-bse-a5094.firebaseapp.com",
    projectId: "fire-bse-a5094",
    storageBucket: "fire-bse-a5094.appspot.com",
    messagingSenderId: "419075744077",
    appId: "1:419075744077:web:a5b0929c2e900aed86d704",
    measurementId: "G-9812GQ1Q7S"
  };

const app = initializeApp(firebaseConfig);
const googleProvider = new GoogleAuthProvider();
// const auth = getAnalytics(app);
export const auth = getAuth(app);
const imgDB = getStorage(app)
const video = getStorage(app)
const txtDB = getFirestore(app)
const linkDB = getFirestore(app)

export {imgDB,txtDB,googleProvider,video ,linkDB};
