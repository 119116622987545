import React from 'react';
import "../styles/subNav.css";
import { useNavigate } from 'react-router-dom';

function SubNavIndividual() {
    const navigate = useNavigate();

    const handleNavigationCitizenship = () => {
        window.scrollTo(0,0);
        navigate("/CitizenScreen");
    };

    const handleNavigationSponsored = () => {
        window.scrollTo(0,0);
        navigate("/VisaExemption");
    };
 const handleNavigationStudent =() =>{
    window.scrollTo(0,0);
    navigate("/EmployerTssVisa")
 }
 const handleNavigationholiday = () => {
   // console.log('handleNavigationEmployerVisa called');
   window.scrollTo(0, 0);
   navigate("/Permoverview");
 }
 

 /* */
 const handleNavigationNZ = () => {
    window.scrollTo(0,0);
    navigate("/EmployerSponsered482")
 }
 const handleNavigationGraduate = () => {
    window.scrollTo(0,0);
    navigate("/Visa494")
 }
 const handleNavigationInnovation = () => {
    window.scrollTo(0,0);
    navigate("/Visa457Emp")
 }
 const handleNavigationSkilled = () => {
    window.scrollTo(0,0);
    navigate("/Employer186")
 }

    return (
        <div className='sub-nav-container'>
            <div onClick={handleNavigationCitizenship} className='sub_nav_container'>Citizenship</div>
            <div onClick={handleNavigationSponsored} className='sub_nav_container'>Sponsored work</div>
            <div onClick={handleNavigationStudent} className='sub_nav_container'>Student </div>
            <div onClick={handleNavigationholiday} className='sub_nav_container'>Working holiday </div>
            <div onClick={handleNavigationNZ} className='sub_nav_container'>NZ Citizen </div>
            <div onClick={handleNavigationSkilled} className='sub_nav_container'>Skilled </div>
            <div onClick={handleNavigationGraduate} className='sub_nav_container'>Temp Graduate  </div>
            <div onClick={handleNavigationInnovation} className='sub_nav_container'>Bus Innovation & Investment </div>
           
        </div>
    );
}
export default SubNavIndividual;
