import React from 'react'
import "../styles/InternationalCantFind.css"

function InternationalCantFind() {
  return (
    <div className='International_CantFind_layout'>
        <div className='International_CantFind_Title'>Can't find what you are looking for?</div>
        <div className='International_CantFind_fields'>
        <div className='International_CantFind_Box'>
            <div className='International_CantFind_Box_title'>Do you need more information on our recruitment services?</div>
            <div className='International_CantFind_Box_desc'>Our specialist international recruitment team will interview dozens of applicants before making a short list for employers to choose from.</div>
            <div className='International_CantFind_Box_btn'>GET IN TOUCH</div>
        </div>
        <div className='International_CantFind_Box'> <div className='International_CantFind_Box_title'>Would you like to become a Standard Business Sponsor?</div>
            <div className='International_CantFind_Box_desc'>Employers have the option to trade test shortlisted applicants to the specifications of your business or workshop.</div>
            <div className='International_CantFind_Box_btn'>CONTACT US</div>
       </div>
        <div className='International_CantFind_Box'>
        <div className='International_CantFind_Box_title'>Are you an applicant looking for a sponsor?</div>
            <div className='International_CantFind_Box_desc'>Once your candidates arrive onshore, our expert team can organise everything from Tax File Numbers to Trade Licensing.</div>
            <div className='International_CantFind_Box_btn'>Applicants</div>
       </div>     
        </div>

    </div>
  )
}

export default InternationalCantFind