import React from 'react'
import '../styles/ShortTerm.css'
import { Info } from "@phosphor-icons/react";
import EnglishModal from './EnglishModal';
import { useState } from 'react';
import { XCircle } from "@phosphor-icons/react";

const ShortTermBody = (props) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    return (
        <div className='shortTermLayout'>
            <div className='shortTermHeading'>
                <div className='shortHeading'>{props.shortHeading}</div>
            </div>
            <div className='shortTermDescBox'>
                <div className='shortTermDesc'>{props.shortTermDesc}</div>
            </div>
            <div className='shortTermContentBox'>
                <div className='shortTermGeneralBox'>
                    <div className='shortGeneralHead'>{props.shortGeneralHead1}</div>
                    <div className='shortGeneralDesc'>
                        <li>{props.shortGeneralDesc1}</li>
                        <li>{props.shortGeneralDesc2} 
                        {/* <u style={{ color: "#B0383D", cursor: 'pointer' }}> */}
                            {props.skills}
                            {/* </u> */}
                        </li>
                        <li>{props.shortGeneralDesc3}</li>
                        <li>{props.shortGeneralDesc4}</li>
                        <li>{props.shortGeneralDesc5}</li>
                    </div>
                </div>
                <div className='shortTermGeneralBox'>
                    <div className='shortGeneralHead'>{props.shortGeneralHead2}</div>
                    <div className='shortGeneralDesc'>
                        <li>{props.shortGeneralDesc6}</li>
                        <li>{props.shortGeneralDesc7}</li>
                        <li>{props.shortGeneralDesc8}</li>
                        <li>{props.shortGeneralDesc9}</li>
                        <li>{props.shortGeneralDesc10} <u onClick={openModal} style={{ color: "#B0383D", cursor: 'pointer' }}>{props.here}</u></li>
                    </div>
                </div>
                <div className='shortTermGeneralBox'>
                    <div className='shortGeneralHead'>{props.shortGeneralHead3}</div>
                    <div className='shortGeneralDesc'>
                        <li>{props.shortGeneralDesc11}</li>
                        <li>{props.shortGeneralDesc12}<span
                                className='mainSubClass'
                                style={{ color: '#B0383D', cursor: 'pointer' }}
                                onMouseEnter={handleMouseEnter}
                                onClick={() => setIsHovered(!isHovered)}
                            >{props.subclass} <Info style={{ position: 'relative', top: '3px' }} size={16} /></span></li>
                    </div>
                </div>
            </div>
            {isHovered && (
                <div className='subClassHoverBoxStyle'>
                    <div className='subClassHoverBox'>
                        <div className='subClassHoverContent'>{props.subClassHoverContent1}</div>
                        <div className='subClassHoverContent'>{props.subClassHoverContent2}</div>
                        <div className='subClassHoverContent'>{props.subClassHoverContent3}</div>
                        <div className='subClassHoverContent'>{props.subClassHoverContent4}</div>
                    </div>
                </div>
            )}
            <div className='stayPeriodBox'>
                <div className='stayPeriodHead'>{props.stayPeriodHead}</div>
                <div className='stayPeriodContent'>
                   {props.stayPeriodContent}
                    <li>{props.stayPeriodContent1}</li>
                    <li>{props.stayPeriodContent2}</li>
                    <li>{props.stayPeriodContent3}</li>
                    <div className='stayPeriodContent'>{props.stayPeriodContent4}</div>
                </div>
            </div>
            {isModalOpen && <div className="modal-background">
                <div className='modalFinal'>
                    {/* <div style={{ textAlign: 'right', paddingTop: '16px', paddingRight: '16px' }}>
                        <XCircle onClick={closeModal} size={32} />
                    </div> */}
                    <EnglishModal  closeModal={closeModal}/>
                </div>
            </div>}
        </div>
    )
}

export default ShortTermBody