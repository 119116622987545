import React, { useState } from 'react'
import DottedLines from '../images/DottedLines.png'
import ParentBanner from "../images/ParentBanner.mp4"
import NavBarComponent from '../components/NavBarComponent';
import ConsultationComponent from '../components/ConsultationComponent';
import Footer from '../components/Footer';
import HeroBanner from '../components/HeroBanner';

function FamilyParent() {
    const [activeTab, setActiveTab] = useState('Overview');

    const handleTabClick = tabName => {
      setActiveTab(tabName);
    };
  return (
    <div>
         <NavBarComponent />
      <HeroBanner
        heroHeading='103 Parent Visa'
        heroSubHeading='Parent Visa (Subclass 103)'
        connectButton='Let’s Connect'
        Banner={[ParentBanner]}
        DottedLines={DottedLines}
      />
          <div className="TabsContainer">
      <div
        className={`Tab ${activeTab === 'Overview' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('Overview')}
      >
        Overview
      </div>
      <div
        className={`Tab ${activeTab === 'Eligibility' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('Eligibility')}
      >
       Eligibility
      </div>
      <div
        className={`Tab ${activeTab === 'ProcessingTimes' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('ProcessingTimes')}
      >
       Processing Times
      </div>
      <div
        className={`Tab ${activeTab === 'Family' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('Family')}
      >
       Including Family
      </div>
    </div>
    {activeTab === 'Overview' &&
       <div className='Tabs_container_layout'>
         <div className='PartnerVisaHeadComp_Title'>Overview</div>
         <div style={{paddingTop:"24px"}}>
         <div className='AboutUsVisa_box_fields_content'>
               <div className='AboutUsVisa_box_title'>The Subclass 103 parent visa allows parents to move to Australia if more of their children live in Australia than any other country</div>
          <div className='AboutUsVisa_box_desc'>
          Parent Visa (Subclass 103) holders can: 
          <li>Live in Australia as a permanent resident  </li>
          <li>  Sponsor eligible relatives to come to Australia</li>
          <li>Get access to Medicare</li>
          <li>Apply for Australian citizenship (if eligible)</li>
          </div>
        </div>
        </div>
            
        </div>
     }
     {activeTab === 'Eligibility' &&
       <div className='Tabs_container_layout'> 
       
       <div className='PartnerVisaHeadComp_Title'>Eligibility</div>
       <div className='AboutUsVisa_box_fields'>
        <div className='AboutUsVisa_box_fields_content'>
          <div className='AboutUsVisa_box_title'>Applying for a Parent (Subclass 103) Visa</div>
          <div className='AboutUsVisa_box_desc'>
          To be eligible to apply for a parent Subclass 103 visa, you must meet the below criteria<li>Partner</li>
<li> Be outside Australia when we decide on your application </li>
<li> Be sponsored by your child or a relative</li>
          </div>
        </div>
        <div className='AboutUsVisa_box_fields_content'>
        <div className='AboutUsVisa_box_title'>SPONSOR</div>
          <div className='AboutUsVisa_box_desc'>You must be sponsored. Usually, this will be your child who must be: 
<li>18 years old or older</li>
<li> A settled Australian citizen, permanent resident or eligible New Zealand citizen</li>
</div>
        </div>
        </div>
        <div className='AboutUsVisa_box_fields'>
        <div className='AboutUsVisa_box_fields_content'>
          <div className='AboutUsVisa_box_title'>Balance of Family Test</div>
          <div className='AboutUsVisa_box_desc'>At least half of your children live permanently in Australia, or More of your children live permanently in Australia than in any other country
          </div>
        </div>
        <div className='AboutUsVisa_box_fields_content'>
        <div className='AboutUsVisa_box_title'>Health And Character</div>
          <div className='AboutUsVisa_box_desc'>You must meet health and character requirements</div>
        </div>
        </div>
        </div>
     }
     {activeTab === 'ProcessingTimes' &&
       <div className='Tabs_container_layout'>
         <div className='PartnerVisaHeadComp_Title'>Processing Times</div>
         <div style={{paddingTop:"24px"}}>
         <div className='AboutUsVisa_box_fields_content'>
        <div className='AboutUsVisa_box_title'>How long will it take to get a decision on your 103 visa?</div>
          <div className='AboutUsVisa_box_desc'><li>The processing times for this visa are not specified.</li>
           <li>Processing will take a number of years</li></div>
        </div>
            </div>
        </div>
     }
     {activeTab === 'Family' &&
          <div className='Tabs_container_layout'>
          <div className='PartnerVisaHeadComp_Title'>Including Family</div>
          <div style={{paddingTop:"24px"}}>
          <div className='AboutUsVisa_box_fields_content'>
         <div className='AboutUsVisa_box_title'>You may be able to include immediate family in your application when you apply</div>
           <div className='AboutUsVisa_box_desc'><li>Family members who are being included in the application must meet the health and character requirements. Family members who are not coming to Australia may also have to meet the health and character requirements.</li></div>
         </div>
             </div>
         </div>
     }
     <div className='ConsultationComponent_field'>
            <ConsultationComponent
            title="Are You Eligible?"
            description="Take our online eligibility assessment to find out if you meet the requirements for the 482 Visa            "
            button="Check Eligibility"
            />
             <ConsultationComponent 
            title="Book A Consultation"
            description="Speak to one of our migration agents and simply book a consultation. We'll assist you in understanding the costs involved"
            button="Consult"
            />
             <ConsultationComponent
            title="Get A Quote"
            description="Have a visa in mind? Contact us for a fixed price quote. We'll assist you in understanding the costs involved.            "
            button="Get a quote"
            />
            </div>
            <Footer />


    </div>
  )
}

export default FamilyParent