import React, { useState } from 'react'
import Herobanner1Family from '../images/Coin.mp4';
import NavBarComponent from '../components/NavBarComponent'
import DottedLines from '../images/DottedLines.png'
import HeroBanner from '../components/HeroBanner'
import Footer from '../components/Footer';
import ConsultComp2 from '../components/ConsultComp2';
function VisaInnovation() {
  const [activeTab, setActiveTab] = useState('Overview');

  const handleTabClick = tabName => {
    setActiveTab(tabName);
  };
  return (
    <div>
       <NavBarComponent/>
    <HeroBanner
    heroHeading='188 Visa'
    heroSubHeading='Business Innovation and Investment Visa'
    connectButton='Let’s Connect'
    Banner={[Herobanner1Family]}
    DottedLines={DottedLines}
  />
   <div className="TabsContainer">
      <div
        className={`Tab ${activeTab === 'Overview' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('Overview')}
      >
        Overview
      </div>
      <div
        className={`Tab ${activeTab === 'Eligibility' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('Eligibility')}
      >
       Eligibility
      </div>
      <div
        className={`Tab ${activeTab === 'ProcessingTimes' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('ProcessingTimes')}
      >
       Processing Times
      </div>
      <div
        className={`Tab ${activeTab === 'Family' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('Family')}
      >
      Including Family
      </div>
      </div>
      {activeTab === 'Overview' &&
       <div className='Tabs_container_layout'>
         <div className='LabourAgreement_extrayLay'>
      <div className='LabourAgreement_section-container_title'>
      The Business Innovation and Investment Visa (subclass 188) is a part of the Business Innovation and Investment Program            </div>
      <div className='LabourAgreement_section-container_desc_extrayLay'>The Business Innovation and Investment Visa Subclass 188 allows successful applicants to do the following:

<li>own and manage a business in Australia (the Business Innovation stream)</li>
<li>conduct business and investment activity in Australia (the Investor stream, the Significant Investor stream and the Premium Investor stream).</li>
<li>undertake an entrepreneurial activity in Australia (Entrepreneur stream).</li>
      </div>
    </div>
        </div>
      }
      {activeTab === 'Eligibility' &&
       <div className='Tabs_container_layout'>
           <div className='LabourAgreement_extrayLay'>
      <div className='LabourAgreement_section-container_title'>
      This visa consists of five visa streams      </div>
      <div className='LabourAgreement_section-container_desc_extrayLay'>The Business Innovation and Investment Visa (subclass 188) is a part of the Business Innovation and Investment Program.

This visa consists of five visa streams. Applicants must submit an Expression of Interest (EOI) through SkillSelect and be invited to apply.

The five business visa streams are:
Business Innovation stream: Applicants must plan to establish, develop and manage a new or existing business in Australia. Applicants must be nominated by a state or territory government.

Investor stream: Applicants must make a designated investment of at least $1.5 million AUD. Applicants must be nominated by a state or territory government.

Significant Investor stream: Applicants must make an investment at least $5 million AUD. Applicants can be nominated by a state or territory government or Austrade on behalf of the Australian government.

Premium Investor stream: Applicants must invest a minimum of $15 million AUD.
Entrepreneur stream: Applicants must have a funding agreement from a third party for at least $200,000 AUD. Applicants must be nominated by a state or territory government.
      </div>
    </div>
        </div>
      }
      {activeTab === 'ProcessingTimes' &&
       <div className='Tabs_container_layout'>
              <div className='LabourAgreement_extrayLay'>
      <div className='LabourAgreement_section-container_title'>
      The Department of Home Affairs current processing times for Business Visas a </div>
      <div className='LabourAgreement_section-container_desc_extrayLay'>BUSINESS INNIVATION STREAM
75% of applications are processed within 15 months90% of applications are processed within 21 months
<li>
SIGNIFICANT INVESTOR STREAM
Unavailable due to low volume of applications Unavailable due to low volume of applications
</li>
<li>
INVESTOR STREAM
75% of applications are processed within 19 months90% of applications are processed within 20 months
</li>
<li>
PREMIUM INVESTOR STREAM
Unavailable due to low volume of applications Unavailable due to low volume of applications
</li>
<li>
ENTREPRENEUR STREAM
Unavailable due to low volume of applications Unavailable due to low volume of applications
Last updated 15 May 2018 (for month ending 30 April 2018) </li>
      </div>
    </div>
        </div>
      }
      {activeTab === 'Family' &&
       <div className='Tabs_container_layout'>
            <div className='LabourAgreement_extrayLay'>
      <div className='LabourAgreement_section-container_title'>
      Bring your family</div>
      <div className='LabourAgreement_section-container_desc_extrayLay'>Applicants can include or add the following family members to their 188 Visa:

<li>Married or de facto partner</li>
<li>Dependent children/step-children</li>
AGE
Children 23 years and over cannot be included in your application. (Unless there are exceptional circumstances)

HEALTH & CHARACTER
All family members must meet the health and character requirements. All applicants over 16 must provide police clearances.
      </div>
    </div>
        </div>
      }
      <ConsultComp2 
      title="Business Innovation & Investment Visa"
      desc="Book a consultation to discuss your Business Innovation Visa application with on of our migration experts.Let one of our migration agents assess all your visa options. We will determine which Business Visa stream you are eligible to apply for.Our team will also assess your eligibility for other visas if necessary."
      btn="book now"
      />
  <Footer />
    </div>
  )
}

export default VisaInnovation