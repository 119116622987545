import React, { useState } from 'react'
import DottedLines from '../images/DottedLines.png'
import NavBarComponent from '../components/NavBarComponent'
import SkilledVisa190Banner from "../images/VolenterHelping.mp4"
import HeroBanner from '../components/HeroBanner'
import howitworks from "../images/WomenOnlineWriting.mp4"
import ConsultationComponent from '../components/ConsultationComponent'
import Footer from '../components/Footer'
import "../styles/SkilledVisa190.css"
import SkilledHowComp from '../components/SkilledHowComp'
import Heading from '../components/Heading'

function SkilledVisa190() {
  const [activeTab, setActiveTab] = useState('Overview');
  const handleTabClick = tabName => {
    setActiveTab(tabName);
  };
  return (
    <div>
        <NavBarComponent />
    <HeroBanner
   heroHeading='491 Visa'
   heroSubHeading='Skilled Work Regional (Provisional) Subclass 491 visa'
   connectButton='Let’s Connect'
    Banner={[SkilledVisa190Banner]}
    DottedLines={DottedLines}
  /> 
    <div className="TabsContainer">
  <div
    className={`Tab ${activeTab === 'Overview' ? 'isActive' : ''}`}
    onClick={() => handleTabClick('Overview')}
  >
    Overview
  </div>
  <div
    className={`Tab ${activeTab === 'About' ? 'isActive' : ''}`}
    onClick={() => handleTabClick('About')}
  >
    About Visa
  </div>
  <div
    className={`Tab ${activeTab === 'Eligibility' ? 'isActive' : ''}`}
    onClick={() => handleTabClick('Eligibility')}
  >
   Eligibility
  </div>
  <div
    className={`Tab ${activeTab === 'family' ? 'isActive' : ''}`}
    onClick={() => handleTabClick('family')}
  >
   Including family
  </div>
  </div>
  {activeTab === 'Overview' &&
         <div className='Tabs_container_layout'>
        
        <Heading heading="190 Visa"/>
        <div className='VisaFamily_Box'>  The 190 visa is a points-tested permanent visa for skilled workers who are nominated by an Australian State or Territory government         </div>
        <div className='Visa190_tri_Field'>
          <div className='Visa190_tri_Container'>
            <div className='Visa190_tri_Container_title'>190 Visa holder can:</div>
            <div className='Visa190_tri_Container_desc'>
            <li>stay in Australia permanently</li>
            <li> work and study in Australia</li>
            <li> travel to and from Australia for 5 years</li>
            <li>Applicants must have:</li>
            <li> an occupation on the relevant occupation list</li>
            <li> a successful skill assessment for the relevant occupation</li>
            <li> at least competent English</li>
            </div>
          </div>
          <div className='Visa190_tri_Container'>
          <div className='Visa190_tri_Container_title'>Applying for a 190 Visa</div>
            <div className='Visa190_tri_Container_desc'>Skilled Nominated Visa (Subclass 190) applicants must obtain a minimum of 65 points on a points assessment and must be nominated by a participating State or Territory government in Australia.
To apply for this visa, you must submit an expression of interest (EOI) and be invited by SkillSelect.
If you receive an invitation, you will have 60 days to apply for the visa.
Click here to take our online points test to find you how many points you score.
</div>
          </div>
          <div className='Visa190_tri_Container'>
          <div className='Visa190_tri_Container_title'>Including family members</div>
            <div className='Visa190_tri_Container_desc'>190 visa applicants may be able to include the below family members to their application:
            <li>dependent child</li>
            <li>partner’s dependent child. Read More</li>
            </div>
          </div>

        </div>
        </div>
    }
    {activeTab === 'About' &&
      <div className='Tabs_container_layout'>
         <div className='Visa190_tri_Field'>
          <div className='Visa190_tri_Container'>
            <div className='Visa190_tri_Container_title'>General</div>
            <div className='Visa190_tri_Container_desc'>
            <li>stay in Australia permanently</li>
            <li> work and study in Australia</li>
            <li> travel to and from Australia for 5 years</li>
            <li> enrol in Medicare (Australia’s public healthcare system)</li>
            <li> sponsor your relatives to come to Australia</li>
            <li> travel to and from Australia for 5 years</li>
            <li> apply for Australian Citizenship (If eligible)</li>
            </div>
          </div>
          <div className='Visa190_tri_Container'>
          <div className='Visa190_tri_Container_title'>Nomination</div>
            <div className='Visa190_tri_Container_desc'>Applicants must be nominated for by an Australian state or territory government agency before applying for the 190 visa.
If you are nominated by a state or territory, the Department of Home Affairs will invite you to apply for the visa.
Each state and territory government agency has its own eligibility criteria.
</div>
          </div>
          <div className='Visa190_tri_Container'>
          <div className='Visa190_tri_Container_title'>Include your family</div>
            <div className='Visa190_tri_Container_desc'>Include your family 190 visa applicants may be able to include the below family members to their application
             <li>partner</li>
            <li>dependent child</li>
            <li>partner’s dependent child</li>
            </div>
          </div>
        </div>
        <div className='Visa190_tri_overlay'>
          <div className='AboutUsVisa_box_title'>Length of stay</div>
          <div className='AboutUsVisa_box_desc'>
        Length of stay ,This is a permanent visa. Applicants can stay in Australia indefinitely. The 190 visa has a travel component that expires 5 years after the date of visa grant. After this time, applicants can apply for one of the below:
            <li>Australian Citizenship</li>
           <li>Resident Return Visa</li>
           </div>
   </div>
     </div>
    }
    {activeTab === 'Eligibility' &&
         <div className='Tabs_container_layout'>
          <Heading heading="You may be eligible for the 190 Visa"/>
          <div className='VisaFamily_Box'>
          You may be able to apply for a Skilled Nominated Subclass Subclass 190 Visa if you meet the following requirements:
         </div>
         <div className='Visa190_tri_Field'>
          <div className='Visa190_tri_Container'>
            <div className='Visa190_tri_Container_title'>State or Territory Nomination</div>
            <div className='Visa190_tri_Container_desc'>
            You must be Nominated by an Australian State or Territory. Each State and Territory Government have their own list of eligible occupations and may have specific requirements for skills and work experience.
            </div>
          </div>
          <div className='Visa190_tri_Container'>
          <div className='Visa190_tri_Container_title'>Occupation & Skills</div>
            <div className='Visa190_tri_Container_desc'>Your occupation must be on the relevant list of eligible skilled occupations.
You must perform a successful skill assessment through the authorised assessing authority to show that you have the skills and qualifications to work in your chosen occupation.
</div>
          </div>
          <div className='Visa190_tri_Container'>
          <div className='Visa190_tri_Container_title'>Points test & Invitation</div>
            <div className='Visa190_tri_Container_desc'>You must lodge an Expression of Interest (EOI) and be invited to apply for this visa
You must score a minimum of 65 points to be invited to apply.
You must meet the points score eligibility at the time you are invited to apply for the visa.
The most recent invitation round gives a breakdown of the number of invitations issued by each state.
            </div>
          </div>
        </div>
        <div className='Visa190_tri_Field'>
          <div className='Visa190_tri_Container_Extended'>
            <div className='Visa190_tri_Container_title'>English</div>
            <div className='Visa190_tri_Container_desc'>
            You must provide evidence of competent English, however, additional points will be awarded for proficient and superior English scores
Improve your English scoreDo you need to increase your English score before you can apply? Click here for details about short English courses for IELTS and PTE.            </div>
          </div>
          <div className='Visa190_tri_Container'>
          <div className='Visa190_tri_Container_title'>Age</div>
            <div className='Visa190_tri_Container_desc'>You are under 45 when you receive an invitation to apply</div>
            <hr className='Divider' />
            <div className='Visa190_tri_Container_title'>Health & Character</div>
            <div className='Visa190_tri_Container_desc'>You must meet the health & character requirements
            </div>
          </div>
         
        </div>
        </div>
    }
    {activeTab === 'family' &&
         <div className='Tabs_container_layout'>
          <Heading heading="Include your family in your Subclass 190 Visa"/>
          <div className='VisaFamily_Box'>
          190 visa applicants may be able to include the below family members to their application
         </div>
         <div className='Visa190_tri_Field'>
          <div className='Visa190_tri_Container'>
            <div className='Visa190_tri_Container_title'>Partner</div>
            <div className='Visa190_tri_Container_desc'>
              <li>the relationship is genuine and continuing</li>
              <li>your partner is at least 18 years of age when the application is lodged (there are some exceptions)</li>
              <li>you are not related by family (if you are in a de facto relationship)</li>
              <li>you and your partner have a mutual commitment to a shared life to the exclusion of all others, and</li>
              <li>you live together, or do not live separately on a permanent basis.</li>
            </div>
          </div>
          <div className='Visa190_tri_Container'>
          <div className='Visa190_tri_Container_title'>English</div>
            <div className='Visa190_tri_Container_desc'>Applicants over 18 must demonstrate Functional English or pay an additional fee.
        You can demonstrate Functional English without IELTS if you:
 completed all years of primary education and at least three years of secondary education in an educational institution in or outside Australia and all instructions were in English.
</div>
          </div>
          <div className='Visa190_tri_Container'>
          <div className='Visa190_tri_Container_title'>Heath & Character</div>
            <div className='Visa190_tri_Container_desc'>All family members must meet health and character requirements. All applicants over 16 must provide police clearances.  </div>
          </div>
        </div>
        <div className='Visa190_tri_Field'>
          <div className='Visa190_tri_Container_Extended'>
            <div className='Visa190_tri_Container_title'>Children</div>
            <div className='Visa190_tri_Container_desc'>
              <li>be your child or a stepchild from a current or a previous relationship (in certain circumstances);</li>
              <li>not be married, engaged to be married, or have a de facto partner; and must be:</li>

              <li>under 18 years of ageor</li>
              <li> over 18 years of age but not yet turned 23, and be dependent on you or your partneror</li>
              <li> over 23 years of age and be unable to earn a living to support themselves due to physical or cognitive limitations and be dependent on you or your partner (Note: The child will still need to meet Australia’s health requirement)or</li>
              <li>a dependent child of a child who is eligible under 1, 2 or 3 above.</li>
           </div>
           </div>
          <div className='Visa190_tri_Container'>
          <div className='Visa190_tri_Container_title'>Age</div>
            <div className='Visa190_tri_Container_desc'>Children 23 years and over cannot be included in your application. (Unless there are exceptional circumstances)</div>
            <hr className='Divider' />
            <div className='Visa190_tri_Container_title'>Health & Character</div>
            <div className='Visa190_tri_Container_desc'>All family members must meet health and character requirements. All applicants over 16 must provide police clearances.
            </div>
          </div>
         
        </div>
        </div>
    }
   
<SkilledHowComp
                EmpHowHeading='How it works'
                containerColor="rgba(255, 247, 241, 1)"
                textColor="rgba(176, 56, 61, 1)"
                EmpHowImage={howitworks}
                EmpHowSubHead1='Step 1'
                EmpHowSubHead2='Step 2'
                EmpHowSubHead3='Step 3'
                EmpHowSubHead4='Step 4'
                EmpHowSubHead5='Step 5'
                EmpHowDesc1='Check if occupation is on the skilled list and you score more than 65+ points'
                EmpHowDesc2='Our migration agents lodge your expression of interest with the Department'
                EmpHowDesc3='We wait to receive an invitation from the Department to apply for your visa.'
                EmpHowDesc4='Our experts lodge your visa and liaise with the department until a decision is made'
                EmpHowDesc5='You and your family migrate to Australia after receiving your visa approval'
            />
<div className='ConsultationComponent_field'>
            <ConsultationComponent
            
            title="View other visa options"
            description="Perhaps there is an alternative visa option you could explore? Use our Visa Finder to conduct your research.    "
            button="Consult"
            />
             <ConsultationComponent 
             
            title="Book A Consultation"
            description="If you would like to discuss your Australian visa eligibility with a registered migration agent, book your consultation now."
            button="Consult"
            />
             <ConsultationComponent
             
            title="Get A Quote"
            description="Know what visa you want to apply for but need some pricing? Get in touch with us and we will get back to you with a fixed price quote.            "
            button="Get a quote"
            />
            </div> 
            <Footer/>
    </div>

  )
}

export default SkilledVisa190