import React from 'react'
import "../styles/familyHeaderComponent.css"
function FamilyHeaderLayout(props) {
    const shouldSwap = props.swapImageAndDescription;
    const handleButtonClick = (onClick) => {
      if (typeof onClick === 'function') {
        onClick();
      } else {
        console.error('Invalid onClick function provided');
      }
    };
  return (
    <div>
         <div className='Family_header_Box'>
        <div className='Family_header_title'>{props.FamilyHeaderTitle}</div>
        </div>
        <div className='Family_header_Image'>
        {shouldSwap ? (
        // Swap the order if shouldSwap is true
        <>
       
          <div className='Family_header_description'>
            {props.familyDescription}
            <button className='family_header_desc_btn' onClick={() => handleButtonClick(props.onClick1)}>{props.btn}</button>
          </div>
          <div className='Family_header_image'>
            <img src={props.FamilyPartnerImg} alt={props.imageAlt} className='Family_header_img'/>
          </div>
          {/* <div className='Family_header_image'>
    <video className='Family_header_video' autoPlay muted loop playsInline>
        <source src={props.FamilyPartnerImg} type='video/mp4' />
    </video>
</div> */}
        
        </>
      ) : (
    
        <>
          
          <div className='Family_header_description'>
            {props.familyDescription}
            <button className='family_header_desc_btn'>{props.btn}</button>
          </div>
          <div className='Family_header_image'>
            <img src={props.FamilyPartnerImg} alt={props.imageAlt} className='Family_header_img'/>
          </div>
        
        </>
      )}
      </div>
  

    </div>
  )
}

export default FamilyHeaderLayout