import React from 'react'
import '../styles/Option1Overview.css'

import { useNavigate } from 'react-router-dom';

const Option1Eligibility = () => {
  const navigate = useNavigate();
  const handleMenuItemClick = (path) => {
    navigate(path);
  };
  return (
    <>
      <div className='EligibilitymodalLayout'>
        <div className='modalLayoutMain'>
          <div className='OptionOverviewStyling'>
            <div className='OptionOverviewHeading'>Option 1: Temporary Residence Transition Stream (TRT)</div>
            <div className='OptionOverviewContentLayout'>
              <div className='OptionOverviewContentBox1'>
                <div className='OptionOverviewContentHead'>General</div>
                <div className='OptionOverviewDesc'>
                  <li>Hold a valid 457 Visa</li>
                  <li>Have been sponsored (as the primary applicant) by your nominating employer on a 457 Visa for at least two years</li>
                </div>
              </div>
              <div className='OptionOverviewContentBox1'>
                <div className='OptionOverviewContentHead'>Work Experience</div>
                <div className='OptionOverviewDesc'>
                  <li>You must have at least 3 years work experience in total (Only two of these years need to be with your current employer)</li>
                </div>
              </div>
              <div className='OptionOverviewContentBox1'>
                <div className='OptionOverviewContentHead'>Skills</div>
                <div className='OptionOverviewDesc'>
                  <li>Generally, when transitioning from 457 to permanent residency, you will not need to perform a skill assessment.</li>
                </div>
              </div>
            </div>
            <div className='OptionOverviewContentLayout'>
              <div className='OptionOverviewContentBox1'>
                <div className='OptionOverviewContentHead'>Occupation</div>
                <div className='OptionOverviewDesc'>
                  <li>If your occupation has been removed from the Medium and Long-term Strategic Skills List MLTSSL, you can still apply for permanent residency through the transitional stream.</li>
                </div>
              </div>
              <div className='OptionOverviewContentBox2'>
                <div className='OptionOverviewContentHead'>English</div>
                <div className='OptionOverviewDesc'>
                  <li>You must demonstrate competent English unless you are exempt. <u onClick={() =>{ handleMenuItemClick('/SkilledVisa'); window.scrollTo(0,0);}} style={{ color: '#B0383D', cursor: 'pointer',fontSize:"18px" }}>See 186 & 187 Visa English exemptions</u></li>
                  <li>A common exemption is the school English exemption, outlines below:</li>
                  <li>You must have completed at least five years of full-time study in secondary or higher education where the medium of instruction was delivered in English. (Example: You have English certificates from your high school and college. You may have used these to apply for your 457 or 482 Visa)</li>
                </div>
              </div>
            </div>
            <div className='OptionOverviewContentLayout'>
              <div className='OptionOverviewContentBox3'>
                <div className='OptionOverviewContentHead'>Nomination</div>
                <div className='OptionOverviewDesc'>
                  <li>You must be nominated by an Australian employer and apply for the 186 or 187 visa within 6 months of the nomination being approved (If the visa and nomination applications were not lodged at the same time)</li>
                  <li>The visa will not be granted if the nomination is withdrawn. The Department does not link new nominations to existing visa applications</li>
                </div>
              </div>
            </div>
            <div className='OptionOverviewContentLayout'>
              <div className='OptionOverviewContentBox4'>
                <div className='OptionOverviewContentHead'>Age</div>
                <div className='OptionOverviewDesc'>
                  <li>You are under 50 years of age unless you are exempt. <u style={{color:'#B0383D', cursor:'pointer'}}>See 186 & 187 Visa age exemptions</u></li>
                </div>
              </div>
              <div className='OptionOverviewContentBox4'>
                <div className='OptionOverviewContentHead'>Health & Character</div>
                <div className='OptionOverviewDesc'>
                  <li>Meet the health and character requirements (Unless you are exempt)</li>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Option1Eligibility