import React from 'react'
import "../styles/subNav.css";
import { useNavigate } from 'react-router-dom';

function SubNavFamilyPartner() {
    const navigate = useNavigate();

    const handleNavigationOnShore = () => {
        navigate("/OnshorePartner");
    };

    const handleNavigationOffShore = () => {
        navigate("/OffShorePartner");
    };
 const handleNavigationMarriage =() =>{
    navigate("/MarriagePartner")
 }

  return (
    <div className='sub-nav-container'>
            <div onClick={handleNavigationOnShore} className='sub_nav_container'>Onshore Partner Visa</div>
            <div onClick={handleNavigationOffShore} className='sub_nav_container'>OffShore Partner Visa</div>
            <div onClick={handleNavigationMarriage} className='sub_nav_container'>Prospective Marriage Visa</div>
           
        
     
        </div>
  )
}

export default SubNavFamilyPartner