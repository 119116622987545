import React from 'react'
import Heading from '../components/Heading'
import { useNavigate } from 'react-router-dom';
import ConsultationComponent from '../components/ConsultationComponent'

function Visa186Screen() {
  const navigate = useNavigate();
  const handleMenuItemClick = (path) => {
    navigate(path);
  };
  return (
    <div>
         <Heading heading="SUBCLASS 186 VISA (ENS)"/>
         <div className='VisaFamily_Box'>
            <li>This is a permanent visa for skilled foreign workers who have a nominating employer in a regional or low population growth area of Australia.</li>
            <li>Applicants can be in or outside Australia when they apply for this visa.</li>
        </div>
        <div className='VisaFamily_Box'>
                <div className='AboutUsVisa_box_title'>DIRECT ENTRY STREAM</div>
                <div className='VisaFamily_Box_desc'><li>Applicants must have an occupation listed on the MLTSSL </li>
                <li>Applicants must by nominated by an Australian employer </li>
                <li>TEMPORARY RESIDENCY TRANSITION STREAM </li>
                <li>457 to PR – 457 Visa holders who have been working for the same employer for two or three years </li>
                <li>TSS to PR – TSS Visa holders who have been working for the same employer for three years </li>
                <li><u onClick={() =>{ handleMenuItemClick('/Employer186'); window.scrollTo(0,0);}} style={{ color: '#B0383D', cursor: 'pointer',fontSize:"18px" }}>  Learn More about the subclass 186 visa (ens)</u> </li></div>
        </div>
        <div className='ConsultationComponent_field'>
            <ConsultationComponent 
            title="View RSMS Post Codes"
            description="View our list of eligible RSMS postcodes for the Regional Sponsored Migration Scheme (RSMS) Subclass 187 Visa. "
            button="View List"
            />
             <ConsultationComponent 
            title="Book A Consultation"
            description="Speak to one of our migration agents and simply book a consultation. We'll assist you in understanding the costs involved"
            button="Consult"
            />
             <ConsultationComponent
            title="Get A Quote"
            description="Have a visa in mind? Contact us for a fixed price quote. We'll assist you in understanding the costs involved.            "
            button="Get a quote"
            />
            </div>
    </div>
  )
}

export default Visa186Screen