import React, { useState } from 'react'
import NavBarComponent from '../components/NavBarComponent'
import DottedLines from '../images/DottedLines.png'
import Herobanner1 from '../images/Stamps.mp4';
import Footer from '../components/Footer'
import "../styles/Working.css"
import WorkingImg from "../images/WorkingImg.png"
import HeroBanner from '../components/HeroBanner';
import VisaBookNow from '../components/VisaBookNow';

function WorkinHoliday() {
    const [activeTab, setActiveTab] = useState('Overview');

    const handleTabClick = tabName => {
      setActiveTab(tabName);
    };
  return (
    <div>
        <NavBarComponent />
        <HeroBanner
        heroHeading='Working Holiday Visa'
        heroSubHeading='Working Holiday Visa (Subclass 417)'
        connectButton='Let’s Connect'
        Banner={[Herobanner1]}
        DottedLines={DottedLines}
      />
          <div className="TabsContainer">
      <div
        className={`Tab ${activeTab === 'Overview' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('Overview')}
      >
        Overview
      </div>
      <div
        className={`Tab ${activeTab === 'Eligibility' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('Eligibility')}
      >
        Eligibility
      </div>
      <div
        className={`Tab ${activeTab === 'Countries' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('Countries')}
      >
        Eligibility Countries
      </div>
      <div
        className={`Tab ${activeTab === 'IncludingFamily' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('IncludingFamily')}
      >
        Including Family
      </div>
      </div>
      {activeTab === 'Overview' &&
       <div className='Tabs_container_layout'>
        <div className='WorkingHoliday_Layout'>
     <div className='Working_Title'>
        <div className='Working_Sub_Title'>The Working Holiday Visa allows citizens from eligible countries to come to Australia to work and travel for 12 months, with the possibility to extend their stay</div>
        <div className='Working_desc'>Working Holiday Visa holders can:
<li>do short-term work in Australia for up to 6 months per employer</li>
<li>study for up to 4 months</li>
<li>travel to and from Australia as many times as you want</li>
<li>do specified work to become eligible for a second and soon to be third Working Holiday visa</li>

You may be able to apply for a second and third working holiday (417) visa if you have completed the specified work.

You may also be eligible to apply for another more permanent visa if you meet the requirements:
<li>189 visa</li>
<li>190 visa</li>
<li>187 visa (RSMS visa)</li>
<li>186 visa (ENS visa)</li>
<li>Student visa</li>
<li>Partner visa</li>
<li>Employer Sponsored Visas</li>
Changes coming soon
The Australian Government has announced changes to the Working Holiday Maker (WHM) visa program in an effort to support regional areas of Australia. 
Applicants may now be eligible to apply for a third working holiday visa, extending their stay for a total of three years.
    </div>
     </div>
     </div>
      
        </div>
        }
         {activeTab === 'Eligibility' &&
       <div className='Tabs_container_layout'>
        <div className='WorkingHoliday_Layout'>
      <div className='Working_Title'>
        <div className='Working_Sub_Title'>To apply for a Working Holiday Visa you must meet the following criteria</div>
        <div className='Working_desc'>You are aged 18 – 30 years – Unless you are a Canadian or Irish citizen (You can apply up to 35 years)
If you are applying for your second working holiday visa, you must have completed 3 months of specified work on your first Working Holiday visa
    </div>
     </div>
      </div>
        </div>
        }
         {activeTab === 'Countries' &&
       <div className='Tabs_container_layout'>
       <div className='WorkingHoliday_Layout'>
       <div className='Working_Title'>
        <div className='Working_Sub_Title'>You may be able to apply for a Working Holiday Visa if you are a citizen of the following countries</div>
        <div className='Working_desc'>
<li>Belgium</li>
<li>Canada</li>
<li>Republic of Cyprus</li>
<li>Denmark</li>
<li>Estonia</li>
<li>Finland</li>
<li>France</li>
<li>Germany</li>
<li>Hong Kong Special Administrative Region of the People’s Republic of China (including British National Overseas passport holders)</li>
<li>Republic of Ireland</li>
<li>Italy</li>
<li>Japan</li>
<li>Republic of Korea</li>
<li>Malta</li>
<li>Netherlands</li>
<li>Norway</li>
<li>Sweden</li>
<li>Taiwan (other than an official or diplomatic passport)</li>
<li>The United Kingdom of Great Britain and Northern Ireland</li>
    </div>
     </div>
     </div>
        </div>
        }
         {activeTab === 'IncludingFamily' &&
       <div className='Tabs_container_layout'>
        <div className='WorkingHoliday_Layout'>
       <div className='Working_Title'>
        <div className='Working_desc'>You cannot include any family members in your application.
You cannot be accompanied by dependent children while you are in Australia
    </div>
     </div>
     </div>
        </div>
        }
        <VisaBookNow 
        img={WorkingImg}
        Title="Not sure how to proceed or have questions about the Temporary Graduate Visa?"
        Desc="If you aren’t quite sure how to proceed, book a consultation with one of our registered migration agents today."
        Btn="Book now" 
        />
      <Footer />
    </div>
  )
}

export default WorkinHoliday