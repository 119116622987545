import React from 'react'
import Navbar from '../components/Navbar'
import DottedLines from '../images/DottedLines.png'
import PermanentEmpBanner from '../components/PermanentEmpBanner'
import Passport from '../images/passport.png'
import Overview494Comp from '../components/Overview494Comp'
import Footer from '../components/Footer'

const Overview494 = () => {
  return (
    <>
      <Navbar />
      <PermanentEmpBanner
        heroHeading='Skilled Employer Sponsored Regional (Provisional) Subclass 494'
        connectButton='Let’s Connect'
        Banner={Passport}
        DottedLines={DottedLines}
      />
      <Overview494Comp
        overview494Heading1='SUBCLASS 494'
        overview494Heading2='Applying for a 494 Visa'
        overview494Heading3='More about the 494 Visa'
        overview494Content1='The 494 Visa allows skilled workers to be sponsored by an Australian employer in a regional area for up to five years'
        overview494Content2='494 Visa holders can:'
        overview494Content3='live and work in Australia for up to five years'
        overview494Content4='Include immediate family on 494 visa'
        overview494Content5='Transition to permanent residency (if eligible) via the 191 Regional Skilled Visa'
        overview494Content6='The Subclass 494 visa will open to applications in November 2019. To be eligible to apply for this visa, applicants must'
        overview494Content7='Be under 45 years'
        overview494Content8='Have at least 3 years work experience'
        overview494Content9='Have a valid'
        overview494Content10='Demonstrate '
        overview494Content11='Meet the health and character requirements'
        overview494Content12='Regional areas include everywhere except the metropolitan areas of; Sydney, Melbourne, Brisbane, Gold Coast and Perth. Increased number of eligible occupations. Provisional visa with option to transition to permanent residency after three years. '
        overview494Content13='If you would like to apply for another employer sponsored visa, you may be eligible to apply for one of the below employer sponsored visas instead: '
        overview494Content14='DE CLOSED '
      />
      <Footer/>
    </>
  )
}

export default Overview494