import React, { useState } from 'react'
import IndividualVisaBanner from "../images/Interview.mp4"
import DottedLines from '../images/DottedLines.png'
import NavBarComponent from '../components/NavBarComponent'
import HeroBanner from '../components/HeroBanner'
import Footer from '../components/Footer'
import ConsultationComponent from '../components/ConsultationComponent'
import Heading from '../components/Heading'
import SubNavIndividual from '../components/SubNavIndividual'
import IndividualWhat from '../components/IndividualWhat'

function IndividualVisa() {
    const [activeTab, setActiveTab] = useState('Visit');
    const handleTabClick = tabName => {
      setActiveTab(tabName);
    };
  return (
    <div>
        <NavBarComponent />
        <HeroBanner
        heroHeading='Visas for Individuals'
        heroSubHeading='Our registered migration agents can help you with a range of different visas.'
        connectButton='Let’s Connect'
        Banner={[IndividualVisaBanner]}
        DottedLines={DottedLines}
        />
        <SubNavIndividual />
        <div className="TabsContainer">
  <div
    className={`Tab ${activeTab === 'Visit' ? 'isActive' : ''}`}
    onClick={() => handleTabClick('Visit')}
  >
    Visit
  </div>
  <div
    className={`Tab ${activeTab === 'Study' ? 'isActive' : ''}`}
    onClick={() => handleTabClick('Study')}
  >
    Study
  </div>
  <div
    className={`Tab ${activeTab === 'Work' ? 'isActive' : ''}`}
    onClick={() => handleTabClick('Work')}
  >
   Work & Travel
  </div>
  <div
    className={`Tab ${activeTab === 'Business' ? 'isActive' : ''}`}
    onClick={() => handleTabClick('Business')}
  >
   Business
  </div>
  <div
    className={`Tab ${activeTab === 'Citizenship' ? 'isActive' : ''}`}
    onClick={() => handleTabClick('Citizenship')}
  >
   Citizenship
  </div>
  </div>        
  {activeTab === 'Visit' &&
         <div className='Tabs_container_layout'>
             <div className='Visa489_Single_Box_layout'>
          <Heading heading="Getting a Visitor Visa is simple" containerColor="rgba(255, 247, 241, 1)" textColor="rgba(176, 56, 61, 1)"/>
          <div className='Visa489_Single_Box'> 
          Our migration agents have compiled tips and tricks for you to increase your chance of visa approval. Everything you need to know about evidence, processing times and documents in one place.
          </div>
          </div>
          <IndividualWhat />

        </div>
}

{activeTab === 'Study' &&
         <div className='Tabs_container_layout'>
             <div className='Visa489_Single_Box_layout'>
          <Heading heading="International students make up 22% of Australia’s students" containerColor="rgba(255, 247, 241, 1)" textColor="rgba(176, 56, 61, 1)"/>
          <div className='Visa489_Single_Box'> 
          For many people studying abroad is a dream come true. Many students choose Australia as their dream destination. Getting a Student visa is easier than you think and our agents can help you through the process.          </div>
          </div>
          <IndividualWhat />

        </div>
}
{activeTab === 'Work' &&
         <div className='Tabs_container_layout'>
             <div className='Visa489_Single_Box_layout'>
          <Heading heading="The infamous Australian gap year" containerColor="rgba(255, 247, 241, 1)" textColor="rgba(176, 56, 61, 1)"/>
          <div className='Visa489_Single_Box'> 
          If you are wanting to stay in Australia for 12 – 24 months and you are under 30 years of age, you might be able to qualify for a 417 Working Holiday Visa.          </div>
          </div>
          <IndividualWhat />

        </div>
}
{activeTab === 'Business' &&
         <div className='Tabs_container_layout'>
             <div className='Visa489_Single_Box_layout'>
          <Heading heading="Helping you achieve your goals in Australia" containerColor="rgba(255, 247, 241, 1)" textColor="rgba(176, 56, 61, 1)"/>
          <div className='Visa489_Single_Box'> 
          The Business Innovation and Investment Visa allows you to own and manage a business, conduct business and investment activity and undertake an entrepreneurial activity in Australia.          </div>
          </div>
          <IndividualWhat />

        </div>
}
{activeTab === 'Citizenship' &&
         <div className='Tabs_container_layout'>
             <div className='Visa489_Single_Box_layout'>
          <Heading heading="In 2019 we welcomed 127,674 new Australian citizens" containerColor="rgba(255, 247, 241, 1)" textColor="rgba(176, 56, 61, 1)"/>
          <div className='Visa489_Single_Box'> 
          To become an Australian Citizen, there are a number of requirements you need to meet in order to be eligible to apply. Our team of migration experts can guide you through the entire Citizenship process.          </div>
          </div>
          <IndividualWhat />

        </div>
}


       <div className='Consult_layout'>
       <Heading heading="Can’t find what you are looking for?" containerColor="rgba(255, 247, 241, 1)" textColor="rgba(176, 56, 61, 1)"/>
        <div className='ConsultationComponent_field' style={{marginTop:"24px"}}>
            <ConsultationComponent
            containerColor="rgba(255, 247, 241, 1)"
            title="Not sure what visa you could be eligible for?"
            description="Australian visas can be confusing, especially if you might have more than one option. Use our Visa Finder to view your visa options. "
            button="Research"
            />
             <ConsultationComponent 
             containerColor="rgba(255, 247, 241, 1)"
            title="Want to speak to someone about your case?"
            description="Not sure where to go from here or if you would even be eligible for an Australian Visa? Speak to an experienced migration agent today."
            button="Consult"
            />
             <ConsultationComponent
             containerColor="rgba(255, 247, 241, 1)"
            title="Are you a New Zealand Citizen visiting Australia?"
            description="If you are a NZ Citizen residing or visiting Australia you may be granted a Special Category Visa (Subclass 444) upon entry if you meet the certain criteria.            "
            button="Learn More"
            />
            </div> 
            </div>
        <Footer />
    </div>
  )
}

export default IndividualVisa