import React from 'react'
import Internatinal from '../images/InternationBanner.mp4'
import DottedLines from '../images/DottedLines.png'
import HeroBanner from '../components/HeroBanner'
import NavBarComponent from '../components/NavBarComponent'
import Footer from '../components/Footer'
import InternationalTeam1 from "../images/InternationalImg.png"
import InternationalTeam2 from "../images/InternationImg2.png"
import InternationalWhy from '../components/InternationalWhy'
import InternationalTeamComp from '../components/InternationalTeamComp'
import InternationalCantFind from '../components/InternationalCantFind'


function InternationalRecruitment() {
  return (
    <div>
      <NavBarComponent/>
        {/* <HeroBanner
        heroHeading='International Recruitment'
        heroSubHeading='With over 15 years of combined experience, our recruitment team members know what they are doing.'
        connectButton='Let’s Connect'
        Banner={Herobanner}
        DottedLines={DottedLines}
      /> */}
       <HeroBanner
        heroHeading='International Recruitment'
        heroSubHeading='With over 15 years of combined experience, our recruitment team members know what they are doing.'
        connectButton='Let’s Connect'
        Banner={[Internatinal]}
        DottedLines={DottedLines}
        />
      <InternationalWhy />
      <InternationalTeamComp 
      Heading="Our international recruitment team can help with"
      title1="Interviewing & Selection"
      desc1="Our specialist international recruitment team will interview dozens of applicants before making a short list for employers to choose from."
      title2="Trade Testing"
      desc2="Employers have the option to trade test shortlisted applicants to the specifications of your business or workshop."
      title12="Sponsorship of employees"
      desc12="Our registered migration agents will process all sponsorship, nomination and visa applications in a timely and professional matter."
      title22="Continuous Support"
      desc22="Once your candidates arrive onshore, our expert team can organise everything from Tax File Numbers to Trade Licensing."
      Img={InternationalTeam1}
      />
       <InternationalTeamComp 
      Heading="How does the recruitment process work?"
      textColor="rgba(176, 56, 61, 1)"
      containerColor="rgba(255, 247, 241, 1)"
      title1="Selection Process"
      desc1="We help determine your needs and customise your recruitment plan. We will then source, screen and shortlist applicants. You have the option to interview, trade test applicants to your exact requirements, ensuring only the most skilled are selected."
      title2="Visa Application"
      desc2="Once you have selected your preferred applicant(s), we collect and check candidates requirements and business documents. We arrange all visa, sponsorship, nomination, skills assessment and country of origin departure documents."
      title12="Preparing for arrival"
      desc12="Upon approval of your applicant’s visas, our office organises international and domestic flights. Prior to your candidate’s arrival in Australia, our team has already arranged private health insurance, tax file numbers and can also facilitate trade licensing."
      title22="Support after arrival"
      desc22="Australian Skilled Migration offers continued support to both you and your new employee. Our team can assist your new employees to bring their family to Australia, apply for permanent residency and take care of Citizenship applications."
      Img={InternationalTeam2}
      />
      <InternationalCantFind />

      <Footer/>
      
     
    </div>
  )
}

export default InternationalRecruitment