import React, { useState } from 'react'
import Herobanner1Family from '../images/WomenHandFilling.mp4';
import NavBarComponent from '../components/NavBarComponent'
import DottedLines from '../images/DottedLines.png'
import HeroBanner from '../components/HeroBanner'
import Footer from '../components/Footer';
import ConsultationComponent from '../components/ConsultationComponent';
import ConsultComp3 from '../components/ConsultComp3';
import Heading from '../components/Heading';

function VisaNz() {
  const [activeTab, setActiveTab] = useState('Overview');

  const handleTabClick = tabName => {
    setActiveTab(tabName);
  };
  return (
    <div>
       <NavBarComponent/>
    <HeroBanner
    heroHeading='189 Visa'
    heroSubHeading='Skilled Independent Visa (New Zealand Stream) 189.'
    connectButton='Let’s Connect'
    Banner={[Herobanner1Family]}
    DottedLines={DottedLines}
  />
    <div className="TabsContainer">
      <div
        className={`Tab ${activeTab === 'Overview' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('Overview')}
      >
        Overview
      </div>
      <div
        className={`Tab ${activeTab === 'Eligibility' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('Eligibility')}
      >
       Eligibility
      </div>
      <div
        className={`Tab ${activeTab === 'ProcessingTimes' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('ProcessingTimes')}
      >
       Processing Times
      </div>
      <div
        className={`Tab ${activeTab === 'Family' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('Family')}
      >
      Including Family
      </div>
      </div>
      {activeTab === 'Overview' &&
       <div className='Tabs_container_layout'>
        <Heading heading="Overview" containerColor="rgba(255, 247, 241, 1)" textColor="rgba(186, 81, 85, 1)"/>
        <div className='LabourAgreement_extrayLay'>
      <div className='LabourAgreement_section-container_title'>
      The 189 Visa is for 444 Visa holders who have been contributing to the Australian economy to apply for Permanent Residency
       </div>
      <div className='LabourAgreement_section-container_desc_extrayLay'>
      New Zealand Citizens who are granted this visa will be eligible to apply for Australian Citizenship after 1 year.
This visa lets you live, work, study and enrol in Medicare while in Australia.
If you are not a New Zealand Citizen, you may be eligible to apply for the Skilled Independent 189 Visa.
      </div>
    </div>
    <div className='LabourAgreement_extrayLay'>
      <div className='LabourAgreement_section-container_title'>
      Eligibility
       </div>
      <div className='LabourAgreement_section-container_desc_extrayLay'>
       <li>Applicants must be New Zealand Citizen</li>
 <li>Applicants must hold a Special Category Subclass 444 Visa</li>
 <li>Applicants must have been residing in Australia for 5 years</li>
 <li>Applicants started residency before 16th February 2016</li>
 <li>Applicants have been earning above the income threshold for each of the four years prior to lodging the application</li>
      </div>
    </div>
        </div>
      }
      {activeTab === 'Eligibility' &&
       <div className='Tabs_container_layout'>
                <Heading heading="Eligibility" containerColor="rgba(255, 247, 241, 1)" textColor="rgba(186, 81, 85, 1)"/>
                <div className='LabourAgreement_extrayLay'>
      <div className='LabourAgreement_section-container_title'>
      You may be eligible to apply for this visa if you meet the following requirements
       </div>
      <div className='LabourAgreement_section-container_desc_extrayLay'>
        You may be able to apply for a New Zealand Stream 189 Visa if you meet the below criteria:
      </div>
    </div>
    <div className='LabourAgreement_extrayLay'>
      <div className='LabourAgreement_section-container_title'>
      General
       </div>
      <div className='LabourAgreement_section-container_desc_extrayLay'>
       <li>Applicants must be New Zealand Citizen</li>
 <li>You hold a Special Category Subclass 444 Visa</li>
 <li>You must have been a resident of Australia for five years immediately prior to your application</li>
 <li>You must have commenced residence prior to February 19, 2016</li>
      </div>
    </div>
    <div className='student_tri_field'>
          <div className='student_tri_box_Visa'>
          <div className='LabourAgreement_section-container_title'>Income</div>
         <div className='LabourAgreement_section-container_desc'>Your taxable income must have been at, or above the income threshold for each of the four years prior to applying (Currently $53,900) </div>
          </div>
          <div className='student_tri_box_Visa'> 
          <div className='LabourAgreement_section-container_title'>Age</div>
         <div className='LabourAgreement_section-container_desc'>There is no age restriction on this visa stream.</div>
        </div>
          <div className='student_tri_box_Visa'>
          <div className='LabourAgreement_section-container_title'>Heath & Character</div>
          <div className='LabourAgreement_section-container_desc'>Meet the health and character requirements (Unless you are exempt)</div>
          </div>
        </div>
        </div>
      }
      {activeTab === 'ProcessingTimes' &&
       <div className='Tabs_container_layout'>
                <Heading heading="When will you receive a decision on your 189 Visa?" containerColor="rgba(255, 247, 241, 1)" textColor="rgba(186, 81, 85, 1)"/>
                <div className='LabourAgreement_extrayLay'>
      <div className='LabourAgreement_section-container_desc_extrayLay'>
      Due to the recent introduction of the New Zealand pathway stream of the subclass 189 visa global processing times are not yet available.
      </div>
    </div>
        </div>
      }
      {activeTab === 'Family' &&
       <div className='Tabs_container_layout'>
                <Heading heading="You can include family in your visa application" containerColor="rgba(255, 247, 241, 1)" textColor="rgba(186, 81, 85, 1)"/>
                <div className='LabourAgreement_extrayLay'>
      <div className='LabourAgreement_section-container_title'>
      Applicants can include the following people in their transitional permanent residency application:
       </div>
      <div className='LabourAgreement_section-container_desc_extrayLay'>
       <li>Married or de facto partner</li>
       <li>Dependent children/step-children</li>
      </div>
    </div>
    <div className='Visa489_Double_Box_field'>
          <div className='Visa489_Double_Box'>
          <div className='Visa489_Content_box_title'>Health & Character</div>
          <div className='Visa489_Content_box_desc'>Meet the health and character requirements (Unless exempt)</div>
          </div>
          <div className='Visa489_Double_Box'>
          <div className='Visa489_Content_box_title'>Age</div>
          <div className='Visa489_Content_box_desc'> There is no age restriction on this visa stream
           </div>
          </div>
         </div>
        </div>
      }
       <div className='ConsultationComponent_field'>
             <ConsultComp3
            title="Book A Consultation"
            desc="Not sure where to go from here or if you would even be eligible for an Australian Visa? Speak to an experienced migration agent today."
            btn="Consult"
            title2="Get A Quote"
            desc2="Know what visa you want to apply for but need some pricing? Get in touch with us and we will get back to you with a fixed price quote."
            btn2="Consult"
            />
            
            </div>
  <Footer />
    </div>
  )
}

export default VisaNz