import React from 'react'
import "../styles/JobVacancy.css"
function JobVacancy(props) {
  return (
    <div className='JobVacancy_layout'>
        <div className='JobVacancy_container_fields'>
            <div className='JobVacancy_container'>
                <div className='JobVacancy_container_title'>{props.title}</div>
                <div className='JobVacancy_container_subTitle'>{props.subTitle}</div>
                <div className='JobVacancy_container_desc'>
                    <li>{props.desc11}</li>
                    <li>{props.desc12}</li>
                    <li>{props.desc13}</li>
                    </div>
                <div className='JobVacancy_container_btn'>{props.btn}</div>
            </div>
             <div className='JobVacancy_container'>
             <div className='JobVacancy_container_title'>{props.title2}</div>
                <div className='JobVacancy_container_subTitle'>{props.subTitle2}</div>
                <div className='JobVacancy_container_desc'>
                    <li>{props.desc21}</li>
                    <li>{props.desc22}</li>
                    <li>{props.desc23}</li>
                </div>
                <div className='JobVacancy_container_btn'>{props.btn2}</div>
            </div>
             <div className='JobVacancy_container'>
             <div className='JobVacancy_container_title'>{props.title3}</div>
                <div className='JobVacancy_container_subTitle'>{props.subTitle3}</div>
                <div className='JobVacancy_container_desc'>
                   
                    <li> {props.desc31}</li>
                    <li> {props.desc32}</li>
                    <li> {props.desc33}</li>
                    </div>
                <div className='JobVacancy_container_btn'>{props.btn3}</div>
            </div>
        </div>
        
    </div>
  )
}

export default JobVacancy