import React, { useState } from 'react'
import NavBarComponent from '../components/NavBarComponent'
import DottedLines from '../images/DottedLines.png'
import Family from "../images/FamilyPartnerLanding.png"
import PermanentEmpBanner from '../components/PermanentEmpBanner'
import SubNavFamilyPartner from '../components/SubNavFamilyPartner'
import PartnerVisaHeadComp from '../components/PartnerVisaHeadComp'
import ConsultationComponent from '../components/ConsultationComponent'
import Footer from '../components/Footer'

function FamilyPartnerVisaLanding() {
    const [activeTab, setActiveTab] = useState('Subclass820/801');

    const handleTabClick = tabName => {
      setActiveTab(tabName);
    };
  return (
    <div>
        <NavBarComponent />
        <PermanentEmpBanner
        heroHeading='Partner Visa'
        heroSubHeading='An Australian Partner Visa allows married or de facto (same or opposite sex) couples to enter and remain in Australia with their partner'
        connectButton='Let’s Connect'
        Banner={Family}
        DottedLines={DottedLines}
      />
      <SubNavFamilyPartner/>
      <div className="TabsContainer">
      <div
        className={`Tab ${activeTab === 'Subclass820/801' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('Subclass820/801')}
      >
     Subclass 820/801
      </div>
      <div
        className={`Tab ${activeTab === 'Subclass309/100' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('Subclass309/100')}
      >
        Subclass 309/100 
      </div>
      <div
        className={`Tab ${activeTab === 'Subclass300' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('Subclass300')}
      >
      Subclass 300
      </div>
    </div>

    {activeTab === 'Subclass820/801' &&
       <div className='Tabs_container_layout'>
    <PartnerVisaHeadComp 
    Heading="ONSHORE PARTNER or SPOUSE VISAS (Subclass 820/801)"
    content1="This visa is for the spouse or de facto partner of an Australian citizen, permanent resident or eligible New Zealand citizen who is planning to apply while they are inside Australia."
    Title1="Partner/Spouse Visa (Temporary) Subclass 820"
    desc1="This visa is the first step towards a permanent Partner visa (subclass 801). You will be able to work and study while you hold this visa. You will also have access to Medicare."
    Title2="Partner/Spouse Visa (Permanent) Subclass 801"
    desc2="This visa lets the applicant live in Australia permanently. It is for people who currently hold or have applied for a temporary Partner (subclass 820) visa. Applicants who are in a long term relationship will often receive their visa approvals for the 820 and 801 at the same time. Learn more"
    
    />
  
        </div>
        }
      {activeTab === 'Subclass309/100' &&
       <div className='Tabs_container_layout'>
    <PartnerVisaHeadComp 
    Heading="OFFSHORE PARTNER OR SPOUSE VISAS (Subclass 309/100)"
    content1="This visa is for the spouse or de facto partner of an Australian citizen, permanent resident or eligible New Zealand citizen who is planning to apply while they are outside Australia."
    Title1="Partner/Spouse Visa (Provisional) Subclass 309"
    desc1="This visa allows you to remain in Australia temporarily and is the first step towards a permanent Partner visa (subclass 100). You will be able to work and study while you hold this visa. You will also have access to Medicare."
    Title2="Partner/Spouse Visa (Permanent) Subclass 100"
    desc2="The visa lets the applicant live in Australia permanently. It is for people who hold a temporary Partner (subclass 309) visa. 
    Learn more"
    
    />
        </div>
        }
        {activeTab === 'Subclass300' &&
       <div className='Tabs_container_layout'>
      <div className='PartnerVisaHeadComp_layout'> 
    <div className='PartnerVisaHeadComp_Title'>PROSPECTIVE MARRIAGE VISA (Subclass 300)</div>
    <div className='Family_Box'>This is a temporary visa for people who want to come to Australia to marry their prospective spouse. </div>
    <div className='Family_Box'>To get this visa you must:
    <li>be 18 years old or older</li>
    <li>be sponsored</li>
    <li>intend to marry your prospective spouse before the visa period ends Learn More</li>
    </div>
    </div>
        </div>
        }
  <div className='ConsultationComponent_field'>
            <ConsultationComponent
            title="Are You Eligible?"
            description="Take our online eligibility assessment to find out if you meet the requirements for the 482 Visa            "
            button="Check Eligibility"
            />
             <ConsultationComponent 
            title="Book A Consultation"
            description="Speak to one of our migration agents and simply book a consultation. We'll assist you in understanding the costs involved"
            button="Consult"
            />
             <ConsultationComponent
            title="Get A Quote"
            description="Have a visa in mind? Contact us for a fixed price quote. We'll assist you in understanding the costs involved.            "
            button="Get a quote"
            />
            </div>
            <Footer />

    </div>
  )
}

export default FamilyPartnerVisaLanding