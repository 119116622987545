import React from 'react'
import NavBarComponent from '../components/NavBarComponent'
import PermanentEmpBanner from '../components/PermanentEmpBanner'
import DottedLines from '../images/DottedLines.png'
import Passport from '../images/FamilyVisaBanner.mp4'
import SubNavFamily from '../components/SubNavFamily'
import { useState } from 'react'
import Familyimg from "../images/FamilyPartnerImg.png"
import Familyimg2 from "../images/FamilyChildImg.png"
import Familyimg3 from "../images/FamilParentVisa.png"
import Familyimg4 from "../images/CoupleVisa.png"
import Familyimg5 from "../images/NzVisa.png"
import FamilyPartner from './FamilyPartner'
import Footer from '../components/Footer'
import ConsultationComponent from '../components/ConsultationComponent'
import FamilyHeaderLayout from '../components/FamilyHeaderLayout'
import HeroBanner from '../components/HeroBanner'
import { useNavigate } from 'react-router-dom';

import Herobanner1Family from '../images/FamilyBanner.mp4';
function FamilyVisaPartner() {
  
  const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState('Partner');

    const handleTabClick = tabName => {
      setActiveTab(tabName);
    };
    const handleButtonClick1 = () => {
      window.scrollTo(0,0);
      navigate("/FamilyPartner");
  
    };
    const handleButtonClick2 = () => {
      window.scrollTo(0,0);
      navigate("/child");
  
    };
    const handleButtonClick3 = () => {
      window.scrollTo(0,0);
      navigate("/FamilyParent");
  
    };
    const handleButtonClick4 = () => {
      window.scrollTo(0,0);
      navigate("/Family457Visa");
  
    };
    const handleButtonClick5 = () => {
      window.scrollTo(0,0);
      navigate("/Nz");
  
    };
  return (
    <div>
        <NavBarComponent />
        <HeroBanner
        heroHeading='Family Visa'
        heroSubHeading='Our registered migration agents can help assess your family visa eligibility in a professional and timely manner. We have assisted hundreds of clients with the preparation and lodgement of successful visa applications.'
        connectButton='Let’s Connect'
        Banner={[Herobanner1Family]}
        DottedLines={DottedLines}
      />
      <SubNavFamily />
      <div className="TabsContainer">
        
      <div
        className={`Tab ${activeTab === 'Partner' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('Partner')}
      >
        Partner
      </div>
      <div
        className={`Tab ${activeTab === 'Child' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('Child')}
      >
       Child
      </div>
      <div
        className={`Tab ${activeTab === 'Parent' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('Parent')}
      >
       Parent
      </div>
      <div
        className={`Tab ${activeTab === '4820r457Family' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('4820r457Family')}
      >
       482 0r 457 Family
      </div>
      <div
        className={`Tab ${activeTab === 'NZ' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('NZ')}
      >
        NZ
      </div>
      
    
    </div>

      {activeTab === 'Partner' &&
       <div className='Tabs_container_layout'>
          <FamilyHeaderLayout 
        FamilyHeaderTitle="Helping our partner to join you in Australia"
        familyDescription="Australian Partner Visas allow married or de facto (same or opposite sex) couples to enter and remain in Australia with their partner. If you are in a genuine committed relationship with a person from overseas there are a number of options for you and your spouse or de facto under the partner and spouse visas program, such as the Onshore Partner Visa, Offshore Partner Visa or the Prospective Marriage Visa."
        FamilyPartnerImg= {Familyimg}
        imageAlt="Alt text for your image"
        swapImageAndDescription={true}
        onClick1={handleButtonClick1}
         btn="Partner Visa"
        />
        <FamilyPartner 
        FamilyHeading="Who is the visa for?"
        FamilyPartnerHeading1="Partner or Spouse"
        FamilyPartnerDesc1="I am the partner of an Australian resident or citizen and we want to live together in Australia. Learn More"
        FamilyPartnerHeading2="Child or Children"
        FamilyPartnerDesc2="I am the child of a permanent resident or citizen and want to come to Australia. Learn More"
        FamilyPartnerHeading3="Parent or Parents"
        FamilyPartnerDesc3="I am the parent of an Australian Citizen, permanent resident or visa holder. Learn More"
        FamilyPartnerHeading31="457 Visa Holder Family"
        FamilyPartnerDesc31="I am a family member of someone who holds a 457 visa. Learn More"
        FamilyPartnerHeading4="482 Visa Holder Family"
        FamilyPartnerDesc4="One of my family members holds a 482 visa. Learn More"
        FamilyPartnerHeading5="NZ Citizen Family"
        FamilyPartnerDesc5="I am not a New Zealand citizen but a family member living in Australia is. Learn More"
        />
      

      </div>
        }
         {activeTab === 'Child' &&
       <div className='Tabs_container_layout'>
        <FamilyHeaderLayout 
        FamilyHeaderTitle="Bring your children to Australia"
        familyDescription="If you are a parent or step-parent and an Australian permanent resident or citizen, and your child or step child is outside of Australia, you might be eligible to apply for a Child Visa."
        FamilyPartnerImg= {Familyimg2}
        imageAlt="Alt text for your image"
        swapImageAndDescription={true}
         btn="Child Visa"
         onClick1={handleButtonClick2}
        />
         <FamilyPartner 
        FamilyHeading="Who is the visa for?"
        FamilyPartnerHeading1="Partner or Spouse"
        FamilyPartnerDesc1="I am the partner of an Australian resident or citizen and we want to live together in Australia. Learn More"
        FamilyPartnerHeading2="Child or Children"
        FamilyPartnerDesc2="I am the child of a permanent resident or citizen and want to come to Australia. Learn More"
        FamilyPartnerHeading3="Parent or Parents"
        FamilyPartnerDesc3="I am the parent of an Australian Citizen, permanent resident or visa holder. Learn More"
        FamilyPartnerHeading31="457 Visa Holder Family"
        FamilyPartnerDesc31="I am a family member of someone who holds a 457 visa. Learn More"
        FamilyPartnerHeading4="482 Visa Holder Family"
        FamilyPartnerDesc4="One of my family members holds a 482 visa. Learn More"
        FamilyPartnerHeading5="NZ Citizen Family"
        FamilyPartnerDesc5="I am not a New Zealand citizen but a family member living in Australia is. Learn More"
        />
      </div>
        }
         {activeTab === 'Parent' &&
       <div className='Tabs_container_layout'>
        <FamilyHeaderLayout 
        FamilyHeaderTitle="Want your parents to live with you in Australia?"
        familyDescription="Regardless if you are a permanent resident, Australian citizen or a visa holder, there is a range of options available to you to help your family join you in Australia. Such as the Contributory Parent Visa (temporary) which allows allows parents of an Australian citizen, permanent resident or eligible New Zealand citizen to live in Australia temporarily or the Contributory Parent Visa (permanent) which allows parents of an Australian citizen, permanent resident or eligible New Zealand citizen to live in Australia permanently."
        FamilyPartnerImg= {Familyimg3}
        imageAlt="Alt text for your image"
        swapImageAndDescription={true}
         btn="Partner Visa"
         onClick1={handleButtonClick3}
        />
         <FamilyPartner 
        FamilyHeading="Who is the visa for?"
        FamilyPartnerHeading1="Partner or Spouse"
        FamilyPartnerDesc1="I am the partner of an Australian resident or citizen and we want to live together in Australia. Learn More"
        FamilyPartnerHeading2="Child or Children"
        FamilyPartnerDesc2="I am the child of a permanent resident or citizen and want to come to Australia. Learn More"
        FamilyPartnerHeading3="Parent or Parents"
        FamilyPartnerDesc3="I am the parent of an Australian Citizen, permanent resident or visa holder. Learn More"
        FamilyPartnerHeading31="457 Visa Holder Family"
        FamilyPartnerDesc31="I am a family member of someone who holds a 457 visa. Learn More"
        FamilyPartnerHeading4="482 Visa Holder Family"
        FamilyPartnerDesc4="One of my family members holds a 482 visa. Learn More"
        FamilyPartnerHeading5="NZ Citizen Family"
        FamilyPartnerDesc5="I am not a New Zealand citizen but a family member living in Australia is. Learn More"
        />
      </div>
        }
          {activeTab === '4820r457Family' &&
       <div className='Tabs_container_layout'>
        <FamilyHeaderLayout 
        FamilyHeaderTitle="Are you a 482 or 457 visa holder?"
        familyDescription="There are family visas avaliable for the immediate family (spouse/partner) of Temporary Work (Subclass 457) Visa holders or Temporary Skill Shortage (TSS) Subclass 482 Visa holders."
        FamilyPartnerImg= {Familyimg4}
        imageAlt="Alt text for your image"
        swapImageAndDescription={true}
         btn="Learn More"
         onClick1={handleButtonClick4}
        
        />
         <FamilyPartner 
        FamilyHeading="Who is the visa for?"
        FamilyPartnerHeading1="Partner or Spouse"
        FamilyPartnerDesc1="I am the partner of an Australian resident or citizen and we want to live together in Australia. Learn More"
        FamilyPartnerHeading2="Child or Children"
        FamilyPartnerDesc2="I am the child of a permanent resident or citizen and want to come to Australia. Learn More"
        FamilyPartnerHeading3="Parent or Parents"
        FamilyPartnerDesc3="I am the parent of an Australian Citizen, permanent resident or visa holder. Learn More"
        FamilyPartnerHeading31="457 Visa Holder Family"
        FamilyPartnerDesc31="I am a family member of someone who holds a 457 visa. Learn More"
        FamilyPartnerHeading4="482 Visa Holder Family"
        FamilyPartnerDesc4="One of my family members holds a 482 visa. Learn More"
        FamilyPartnerHeading5="NZ Citizen Family"
        FamilyPartnerDesc5="I am not a New Zealand citizen but a family member living in Australia is. Learn More"
        />
      </div>
        }
          {activeTab === 'NZ' &&
       <div className='Tabs_container_layout'>
        <FamilyHeaderLayout 
        FamilyHeaderTitle="Are you a New Zealand citizen and you want a non-New Zealand family member to join you in Australia?"
        familyDescription="The New Zealand Citizen Family visa (Subclass 461) allows non-New Zealand family members of a New Zealand Citizen who currently is on a Special Category Visa (Subclass 444) to live and work in Australia for up to five years."
        FamilyPartnerImg= {Familyimg5}
        imageAlt="Alt text for your image"
        swapImageAndDescription={true}
         btn="Learn More"
        />
         <FamilyPartner 
        FamilyHeading="Who is the visa for?"
        FamilyPartnerHeading1="Partner or Spouse"
        FamilyPartnerDesc1="I am the partner of an Australian resident or citizen and we want to live together in Australia. Learn More"
        FamilyPartnerHeading2="Child or Children"
        FamilyPartnerDesc2="I am the child of a permanent resident or citizen and want to come to Australia. Learn More"
        FamilyPartnerHeading3="Parent or Parents"
        FamilyPartnerDesc3="I am the parent of an Australian Citizen, permanent resident or visa holder. Learn More"
        FamilyPartnerHeading31="457 Visa Holder Family"
        FamilyPartnerDesc31="I am a family member of someone who holds a 457 visa. Learn More"
        FamilyPartnerHeading4="482 Visa Holder Family"
        FamilyPartnerDesc4="One of my family members holds a 482 visa. Learn More"
        FamilyPartnerHeading5="NZ Citizen Family"
        FamilyPartnerDesc5="I am not a New Zealand citizen but a family member living in Australia is. Learn More"
        />
      </div>
        }

<div className='ConsultationComponent_field'>
            <ConsultationComponent 
            title="Are You Eligible?"
            description="Take our online eligibility assessment to find out if you meet the requirements for Visa            "
            button="View List"
            />
             <ConsultationComponent 
            title="Book A Consultation"
            description="Speak to one of our migration agents and simply book a consultation. We'll assist you in understanding the costs involved"
            button="Consult"
            />
             <ConsultationComponent
            title="Get A Quote"
            description="Have a visa in mind? Contact us for a fixed price quote. We'll assist you in understanding the costs involved.            "
            button="Get a quote"
            />
            </div>
            <Footer />
    </div>
    
  )
}

export default FamilyVisaPartner