import React, { useState } from 'react'
import DottedLines from '../images/DottedLines.png'
import ChildVisaBanner from "../images/ChildVisaBanner.mp4"
import NavBarComponent from '../components/NavBarComponent'
import ConsultationComponent from '../components/ConsultationComponent'
import Footer from '../components/Footer'
import HeroBanner from '../components/HeroBanner'

function FamilyChild() {
    const [activeTab, setActiveTab] = useState('Overview');

    const handleTabClick = tabName => {
      setActiveTab(tabName);
    };
  return (
    <div>
        <NavBarComponent />
        <HeroBanner
        heroHeading='Child Visa'
        heroSubHeading='Permanent Child Visa (subclass 101)'
        connectButton='Let’s Connect'
        Banner={[ChildVisaBanner]}
        DottedLines={DottedLines}
      />
           <div className="TabsContainer">
      <div
        className={`Tab ${activeTab === 'Overview' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('Overview')}
      >
        Overview
      </div>
      <div
        className={`Tab ${activeTab === 'Eligibility' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('Eligibility')}
      >
       Eligibility
      </div>
      <div
        className={`Tab ${activeTab === 'ProcessingTimes' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('ProcessingTimes')}
      >
       Processing Times
      </div>
      </div>
      {activeTab === 'Overview' &&
       <div className='Tabs_container_layout'>
          <div className='PartnerVisaHeadComp_Title'>Overview</div>
          <div style={{paddingTop:"24px"}}>
         <div className='AboutUsVisa_box_fields_content'>
               <div className='AboutUsVisa_box_title'>The Subclass 101 Child visa allows children from outside Australia to live with their parents permanently in Australia</div>
          <div className='AboutUsVisa_box_desc'>Child Visa holders can:
          <li>Migrate to Australia as a permanent resident</li>
          <li>Study• access Medicare</li>
          <li>Get access to Medicare</li>
          <li>Apply for Australian citizenship (if eligible)</li>
          </div>
        </div>
        </div>
       </div>
      }
       {activeTab === 'Eligibility' &&
       <div className='Tabs_container_layout'>
          <div className='PartnerVisaHeadComp_Title'>Eligibility</div>
          <div style={{paddingTop:"24px"}}>
         <div className='AboutUsVisa_box_fields_content'>
               <div className='AboutUsVisa_box_title'>Applying for a Child Visa</div>
          <div className='AboutUsVisa_box_desc'>To be eligible to apply for a Child Subclass 101 visa, a child must meet the below requirements:
          <li>Be outside Australia at the time of application and the time of decision</li>
          <li> Have a parent or step-parent who is an Australian permanent resident or citizen</li>
          <li>Be under 18, a full-time student between 18 to 25 years, or over 18 and unable to work due to a disability</li>
          <li>Is financially dependent on the parent</li>
          Each child will submit a separate application. If the child has siblings, each sibling will be the primary applicant of their own application. Applicants must meet the Australian health and character requirements
          </div>
        </div>
        </div>
       </div>
      }
       {activeTab === 'ProcessingTimes' &&
       <div className='Tabs_container_layout'>
          <div className='PartnerVisaHeadComp_Title'>ProcessingTimes</div>
          <div style={{paddingTop:"24px"}}>
         <div className='AboutUsVisa_box_fields_content'>
               <div className='AboutUsVisa_box_title'>How long does it take to get a decision on the 101 visa application?</div>
          <div className='AboutUsVisa_box_desc'>The department does not specify the processing times for this vasa due to the low volume of applications.     </div>
        </div>
        </div>
       </div>
      }
 <div className='ConsultationComponent_field'>
            <ConsultationComponent
            title="Are You Eligible?"
            description="Take our online eligibility assessment to find out if you meet the requirements for the 482 Visa            "
            button="Check Eligibility"
            />
             <ConsultationComponent 
            title="Book A Consultation"
            description="Speak to one of our migration agents and simply book a consultation. We'll assist you in understanding the costs involved"
            button="Consult"
            />
             <ConsultationComponent
            title="Get A Quote"
            description="Have a visa in mind? Contact us for a fixed price quote. We'll assist you in understanding the costs involved.            "
            button="Get a quote"
            />
            </div>
            <Footer/>
    </div>
  )
}

export default FamilyChild