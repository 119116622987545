import React from 'react'
import Footer from '../components/Footer'
import HelpYou from '../components/HelpYou'
import Clients from '../components/Clients'
import HeadOffice from '../components/HeadOffice'
import Pathway from '../components/Pathway'
import WhyUs from '../components/WhyUs'
import OurTeam from '../components/OurTeam'
import Country from '../components/Country'
import Navbar from '../components/Navbar'
import HeroBanner from '../components/HeroBanner'
import DottedLines from '../images/DottedLines.png'
import helpYou from '../images/HelpYouVideo.mp4'
import helpYou2 from '../images/FamilyBanner.mp4'
import helpYou3 from '../images/HrAnalysis.mp4'
import helpYou4 from '../images/Business14.mp4'
import ourTeam from '../images/ourteam.png'
import raj from '../images/raj.png'
import ajit from '../images/ajit.png'
import rahul from '../images/rahul.png'
import kriti from '../images/kriti.png'
import santosh from '../images/santosh.png'
import preeti from '../images/preeti.png'
import Herobanner1 from '../images/CountryBanner.mp4';
import Herobanner2 from '../images/CountryBanner.mp4';
import pathwaymapImage from '../images/Pathway.mp4'
import { Navigate } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';
import Country2 from '../components/Country2'

const LangingPage = () => {
  
  const navigate = useNavigate();
  const handleButtonClick1 = () => {
    window.scrollTo(0,0);
    navigate("/VisaFinderLandingScreen");

  };

  const handleButtonClick2 = () => {
    window.scrollTo(0,0);
    navigate("/SkilledVisa");
  };
  const handleButtonClick3 = () => {
    window.scrollTo(0,0);
    navigate("/FamilyVisa");
  };
  const handleButtonClick4 = () => {
    window.scrollTo(0,0);
    navigate("/tools");
  };
  const handleButtonClick5 = () => {
    window.scrollTo(0,0);
    navigate("/employerLanding");
  };
  return (
    <>
    
      <Navbar />
      

      <HeroBanner
        heroHeading='YOUR ONE-STOP DESTINATION FOR EDUCATION AND VISA SERVICES.'
        heroSubHeading='We offer expert guidance and support to help you achieve your study and migration goals.'
        connectButton='Let’s Connect'
        Banner={[Herobanner1, Herobanner2]}
        DottedLines={DottedLines}
      />
      
      <Country2
        countryName1='Australia'
        countryName2='USA'
        countryName3='Canada'
        countryName4='Germany'
        countryName5='United Kingdom'
        infoButton31="PR visa-Express Entry"
        infoButton32="PR visa- PNP"
        infoButton33="Work visa"
        infoButton34="Work visa-FSTP"
        infoButton355="Work visa-FSWP"
        infoButton36="Digital Nomad visa"
        infoButton37="Temporary work visa"
        infoButton38="Open work permit for US H-1B holders"
        infoButton39=" Caregiver visa"
        infoButton310="Visitor visa"
        infoButton311="Student visa"
        infoButton312="Dependent visa"
        infoButton313="Parent Migration"
        infoButton314="Super visa"
        infoButton315="Invest visa"
    
        infoButton1='Visa Finder'
        onClick1={handleButtonClick1}
        onClick2={handleButtonClick2}
        onClick3={handleButtonClick3}
        onClick4={handleButtonClick4}
        onClick5={handleButtonClick5}
        routePath1='/visa-finder'
        infoButton2='Skilled Migration'
        infoButton3='Family Visa'
        infoButton4='Tools'
        infoButton5='Employer Sponsored Visa'
        infoButtonSec1="Business visa-B1"
        infoButtonSec2="Visitor/Tourist -B2"
        infoButtonSec3="Student visa-F1"
        infoButtonSec4="Work Visa-H1B"
        infoButtonSec5="Exchange-J1 Visa"
        infoButtonSec6="Dependant visa"
        infoButton15="Uk"
        infoButton25="Uk"
        infoButton35="Uk"
        infoButton45="Uk"
        infoButton55="Uk"
        infoButton65="Uk"
        infoButton41="Visitor visa"
        infoButton42="Student visa"
        infoButton43="Job Seeker visa"
        infoButton44="Work visa"
        infoButton454="Business visa"
        infoButton46="Dependant visa"
        infoButton47="Self Employment visa"

        // infoButton6='Visit Visa'
        // infoButton7='TSS Visa 482'
      />
      <OurTeam
        teamHeading='Our Team Includes:'
        ourTeam={ourTeam}
        teamSubHeading1='Customer Service Representatives'
        teamSubHeading2='Education Consultants'
        teamSubHeading3='Migration Consultants'
        teamSubDesc1='Our customer service representatives are friendly, knowledgeable, and always willing to help. They are your first point of contact with our company and are able to answer any questions you may have about our services.'
        teamSubDesc2='They have extensive experience in the education sector and are able to provide you with the guidance and support you need to choose the right course and university for your needs. '
        teamSubDesc3='Our migration consultants have a deep understanding of the Australian migration system and are able to provide you with the advice and support you need to navigate the complex process of migrating to Australia. '
      />
      <WhyUs
        whyUsHeadingMain='Why Choose Hope?'
        whyUsHeading1='Experience'
        whyUsHeading2='Expertise'
        whyUsHeading3='Personalized Service'
        whyUsHeading4='Transparency'
        whyUsHeading5='Support'
        whyUsDesc1='We have helped countless clients navigate the complex processes involved in studying abroad and migrating to a new country, and we are committed to using our experience to help you succeed.'
        whyUsDesc2='Our team of consultants has a wealth of knowledge and expertise in navigating the complex processes involved in studying abroad and migrating to a new country.'
        whyUsDesc3='We offer personalized service and support to every client, tailored to their individual needs. We take the time to understand your goals and preferences, and we work with you to develop a customized plan that meets your needs.'
        whyUsDesc4='At Hope Education & Migration, we believe in transparency and honesty. We will always provide you with clear and concise information about the services we offer, as well as our fees and charges.'
        whyUsDesc5='We offer ongoing support to every client, providing guidance and assistance every step of the way. We are always available to answer your questions and provide you with the support you need to succeed.'
      />
      <HelpYou
        helpYouTitle='We Will Help You With:'
        helpYou1={helpYou}
        helpYou2={helpYou2}
        helpYou3={helpYou3}
        helpYou4={helpYou4}
        helpDescHeading1='Individual Visas'
        helpDescHeading2="Family Visas"
        helpDescHeading3="International Recruitment"
        helpDescHeading4="Skilled Migration"
        helpDescContent1='We can help with visitor visas or working holiday visas and can offer guidance if you are looking to come to Australia to study. If you need a visa to visit Australia for business, this section is also for you'
        helpDescContent2="There are a number of family visas that may be suitable, such as Parent visas, Partner visas, Child visas, NZ Family visas, 457 Family visas, TSS Family visas."
        helpDescContent3="We are international recruitment experts and process visas such as the Employer Sponsored 482 TSS Visa, 186 Visa (ENS), 187 Visa (RSMS) & 494 Visa."
        helpDescContent4="We can assist with a number of skilled visa options such the 189 Visa Independent, 190 Visa State Sponsored, 489 Visa or the 491 Visa."
        helpDescLearn='Learn More'
      />
      <Pathway
        pathwayHeadingMain='Start Your Pathway to Migration Here'
        pathwayQues1='Not sure what visa you could be eligible for?'
        pathwayQues2='Want to speak to someone about your case?'
        pathwayQues3='Know what you need and want some pricing?'
        pathwayDesc1='Australian visas can be confusing, especially if you might have more than one option. Use our Visa Finder to view your visa options.'
        pathwayDesc2='Australian visas can be confusing, especially if you might have more than one option. Use our Visa Finder to view your visa options.'
        pathwayDesc3='Australian visas can be confusing, especially if you might have more than one option. Use our Visa Finder to view your visa options.'
        pathwayImage={pathwaymapImage}
        visaFinderButton='Visa Finder'
        visaFinderButton2="Let’s Connect"
        visaFinderButton3="Get A Quote"
      />
      <HeadOffice />
      {/* <Clients
        clientMainHeading='Our Happy Clients'
        clientImage1={rahul}
        clientImage2={kriti}
        clientImage3={santosh}
        clientImage4={preeti}
        clientImage5={ajit}
        clientImage6={raj}
        clientDesc='Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris nec augue augue. Suspendisse elementum orci sed lacus vehicula, a accumsan mauris sollicitudin.'
        clientName1='Rahul Vyas'
        clientName2='Kirti Menon'
        clientName3='Santosh Vyas'
        clientName4='Preeti Mittal'
        clientName5='Ajit Singh'
        clientName6='Raj Shukla'
      /> */}
      <Footer />
      
    </>
  )
}

export default LangingPage