import React from 'react'
import Family from "../images/TssBannner.mp4"
import NavBarComponent from '../components/NavBarComponent'
import Option3Tss from '../components/Option3Tss'
import DottedLines from '../images/DottedLines.png'
import TssHeadComponent from '../components/TssHeadComponent'
import TssOption1 from '../components/TssOption1'
import TssOption2 from '../components/TssOption2'
import TssOption4 from '../components/TssOption4'
import ConsultationComponent from '../components/ConsultationComponent'
import Footer from '../components/Footer'
import HeroBanner from '../components/HeroBanner'
import PermanentEmpBanner from '../components/PermanentEmpBanner'

function EmployerTssVisa() {
  return (
    <div>
        <NavBarComponent />
        {/* <PermanentEmpBanner
        heroHeading='TSS to Permanent Residency'
        heroSubHeading='There are a number of ways a TSS visa holder can transition to permanent residency'
        connectButton='Let’s Connect'
        Banner={Family}
        DottedLines={DottedLines}
      /> */}
       <HeroBanner
       heroHeading='TSS to Permanent Residency'
       heroSubHeading='There are a number of ways a TSS visa holder can transition to permanent residency'
       connectButton='Let’s Connect'
        Banner={[Family]}
        // Banner={['/video1.mp4', 'video2.mp4', 'video3.mp4']}
        DottedLines={DottedLines}
      />
        <TssHeadComponent/>
        <TssOption1 />
        <TssOption2 />
        <Option3Tss />
        <TssOption4 />
        
        <div className='ConsultationComponent_field'>
            <ConsultationComponent 
             containerColor="#FFF7F1"  // Use containerColor instead of backgroundColor
             textColor="#014A91" 
            title="Are You Eligible?"
            description="Take our online eligibility assessment to find out if you meet the requirements for the 482 Visa            "
            button="Check Eligibility"
            />
             <ConsultationComponent 
             containerColor="#FFF7F1"  // Use containerColor instead of backgroundColor
             textColor="#014A91" 
            title="Book A Consultation"
            description="Speak to one of our migration agents and simply book a consultation. We'll assist you in understanding the costs involved"
            button="Consult"
            />
             <ConsultationComponent
             containerColor="#FFF7F1"  // Use containerColor instead of backgroundColor
             textColor="#014A91" 
            title="Get A Quote"
            description="Have a visa in mind? Contact us for a fixed price quote. We'll assist you in understanding the costs involved.            "
            button="Get a quote"
            />
            </div>
        <Footer />
    </div>
  )
}

export default EmployerTssVisa