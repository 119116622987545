import React from 'react'
import '../styles/Whyus.css'

const ContentBoadyOverview = (props) => {
    return (
        <>
            <div className='whyus-layout'>
                <div className='whyus-heading'>
                    <div className='why-us-heading'>{props.heading}</div>
                </div>
                <div className='why-main-layout'>
                    <div className='why-layout-one'>
                        <div className='why-layout-exp'>
                            <div className='why-heading'>Experience</div>
                            <div className='why-desc'>We have helped countless clients navigate the complex processes involved in studying abroad and migrating to a new country, and we are committed to using our experience to help you succeed.</div>
                        </div>
                        <div className='why-layout-ext'>
                            <div className='why-heading1'>Expertise</div>
                            <div className='why-desc1'>Our team of consultants has a wealth of knowledge and expertise in navigating the complex processes involved in studying abroad and migrating to a new country.</div>
                        </div>
                    </div>
                    <div className='why-layout-two'>
                        <div className='why-two-sublayout1'>
                            <div className='why-two-service'>
                                <div className='why-two-head'>Personalized Service</div>
                                <div className='why-two-desc'>We offer personalized service and support to every client, tailored to their individual needs. We take the time to understand your goals and preferences, and we work with you to develop a customized plan that meets your needs.</div>
                            </div>
                            <div className='why-two-trans'>
                                <div className='why-two-head'>Transparency</div>
                                <div className='why-two-desc'>At Hope Education & Migration, we believe in transparency and honesty. We will always provide you with clear and concise information about the services we offer, as well as our fees and charges.</div>
                            </div>
                        </div>
                        <div className='why-two-sublayout2'>
                            <div className='why-two-support'>Support</div>
                            <div className='why-two-support-desc'>We offer ongoing support to every client, providing guidance and assistance every step of the way. We are always available to answer your questions and provide you with the support you need to succeed.</div>
                        </div>
                    </div>
                </div>
                <div className='why-main-layout2'>
                    <div className='why-two-service'>
                        <div className='why-two-head'>Experience</div>
                        <div className='why-two-desc'>We have helped countless clients navigate the complex processes involved in studying abroad and migrating to a new country, and we are committed to using our experience to help you succeed.</div>
                    </div>
                    <div className='why-two-service'>
                        <div className='why-two-head'>Expertise</div>
                        <div className='why-two-desc'>Our team of consultants has a wealth of knowledge and expertise in navigating the complex processes involved in studying abroad and migrating to a new country.</div>
                    </div>
                    <div className='why-two-service'>
                        <div className='why-two-head'>Personalized Service</div>
                        <div className='why-two-desc'>We offer personalized service and support to every client, tailored to their individual needs. We take the time to understand your goals and preferences, and we work with you to develop a customized plan that meets your needs.</div>
                    </div>  
                    <div className='why-two-service'>
                        <div className='why-two-head'>Transparency</div>
                        <div className='why-two-desc'>At Hope Education & Migration, we believe in transparency and honesty. We will always provide you with clear and concise information about the services we offer, as well as our fees and charges.</div>
                    </div>
                    <div className='why-two-service'>
                        <div className='why-two-head'>Support</div>
                        <div className='why-two-desc'>We offer ongoing support to every client, providing guidance and assistance every step of the way. We are always available to answer your questions and provide you with the support you need to succeed.</div>
                    </div>
                </div>
                <div className='contentBoadyOverview_end'>
                <div className='why-two-head'>Experience</div>
               <div className='why-two-desc'>We have helped countless clients navigate the complex processes involved in studying abroad and migrating to a new country, and we are committed to using our experience to help you succeed.</div>
                </div>
            </div>
        </>
    )
}

export default ContentBoadyOverview


