import React from 'react'
import Herobanner1Family from '../images/Stamps.mp4';
import NavBarComponent from '../components/NavBarComponent'
import DottedLines from '../images/DottedLines.png'
import HeroBanner from '../components/HeroBanner'
import Footer from '../components/Footer'
import NotSureImg from "../images/NotSureImg.png"
import VisaFinderNotSure from '../components/VisaFinderNotSure';
import VisaYourOption from '../components/VisaYourOption';
import { useNavigate } from 'react-router-dom';

function VisitWork() {
    const navigate = useNavigate();
  const handleButtonClick1 = () => {
    window.scrollTo(0,0);
    navigate("/WorkinHoliday");

  };

  const handleButtonClick2 = () => {
    window.scrollTo(0,0);
    navigate("/VisitorVisa");
  };
  return (
    <div>
        <NavBarComponent />
        <HeroBanner
        heroHeading='Visas to visit Australia for a holiday'
        heroSubHeading='Below visas will give you the option of coming to Australia for leisure so you can embark on tourist adventures around the country. Please note that you must meet the respective eligibility criteria.'
        connectButton='Let’s Connect'
        Banner={[Herobanner1Family]}
        DottedLines={DottedLines}
      />
      <VisaYourOption 
      Head="Your Options"
      title1="Working Holiday"
      desc1="The Subclass 417 Working Holiday Visa allows you to come to Australia to work and travel for 12 months, with the possibility to extend your stay by 12 months. You must meet the age requirements and be a citizen from an eligible country to be able to apply for this visa."
      btn1="Learn More"
      onClick1={handleButtonClick1}
      title2="Visitor Visa"
      desc2="If you are want to visit Australia for the sole purpose of having a holiday, with no intention to partake in business activity, this is the right visa choice for you. Please not that you must meet the identity and financial requirements to be grated a tourist visa."
      btn2="Learn More"
      onClick2={handleButtonClick2}
      />
      <VisaFinderNotSure 
      title="Not sure what to do next?"
      desc="Australian visas can be confusing. Often, the obvious choice isn’t the best one. Our registered migration agents are happy to help and answer all you questions."
      btn="Book consultation"
      img={NotSureImg}
      />


      <Footer />
    </div>
  )
}

export default VisitWork