import React from 'react'
import Navbar from '../components/Navbar'
import PermanentEmpHow from '../components/PermanentEmpHow'
import howitworks from '../images/howitworks.png'
import PermSecondContent from '../components/PermSecondContent'
import Footer from '../components/Footer'
// import PermanentEmpBanner from '../components/PermanentEmpBanner'
// import DottedLines from '../images/DottedLines.png'
// import Passport from '../images/passport.png'

const Perm187 = () => {
  return (
    <>
      {/* <Navbar />
      <PermanentEmpBanner
        heroHeading='Permanent Employer Sponsored Visas'
        heroSubHeading='The permanent Subclass 186 (ENS) and Subclass 187 (RSMS) visas allow skilled overseas workers to live and work in Australia permanently.'
        connectButton='Let’s Connect'
        Banner={Passport}
        DottedLines={DottedLines}
      /> */}
      <PermSecondContent
        empOVHeading='SUBCLASS 187 VISA (RSMS)'
        empOVsubHead1='DIRECT ENTRY STREAM:'
        empOVsubHead2='TEMPORARY RESIDENCE TRANSITION STREAM (TRT)'
        empOVDesc1='This stream is now closed to new applications'
        empOVDesc2='Applicants must hold a 457 or 482 TSS Visa'
        empOVDesc22='Applicants must be nominated by an Australian Employer'
        empOVDesc222='Applicants may not need to perform a Skill Assessment'
        visaSubHeading='This is a permanent visa for skilled foreign workers who have a nominating employer in a regional area of Australia. Applicants can be in or outside Australia when they apply for this visa.'
      />
      {/* <PermanentEmpHow
        EmpHowHeading='How it works'
        EmpHowImage={howitworks}
        EmpHowSubHead1='Step 1'
        EmpHowSubHead2='Step 2'
        EmpHowSubHead3='Step 3'
        EmpHowSubHead4='Step 4'
        EmpHowDesc1='Contact us to discuss your eligibility or to find an employer.'
        EmpHowDesc2='We assist you to collect the necessary documents.'
        EmpHowDesc3='Our agents submit your application to Immigration.'
        EmpHowDesc4='Our agents liaise with Immigration until your application is finalised.'
      /> */}
      {/* <Footer /> */}
    </>
  )
}

export default Perm187