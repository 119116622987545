import React from 'react'
import '../styles/Pathway.css'
import { CaretDown, CaretUp } from "@phosphor-icons/react";
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ContactModal from './ContactModal';
import { WhatsappLogo } from '@phosphor-icons/react/dist/ssr';
const ToggleableCaret = ({ isOpen }) => {
    return isOpen ? <CaretUp size={28} /> : <CaretDown size={28} />;
};
const Pathway = (props) => {
    const [openAccordion, setOpenAccordion] = useState(1);

    const toggleAccordion = (accordionNumber) => {
        setOpenAccordion(openAccordion === accordionNumber ? null : accordionNumber);
    };
    const navigate = useNavigate();
    const handleMenuItemClick = (path) => {
      navigate(path);
    };
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };
  
    const closeModal = () => {
        setIsModalOpen(false);
    };
    return (
        <>
            <div className='pathway-layout'>
                <div className='patway-heading-main'>
                    <div className='patway-heading'>{props.pathwayHeadingMain}</div>
                </div>
                <div className='pathway-layout-one'>
                    <div className='pathway-sublayout-one'>
                        {[1].map((accordionNumber) => (
                            <div className='pathway-questions' key={accordionNumber}>
                                <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }} onClick={() => toggleAccordion(accordionNumber)}>
                                    <div className='pathway-sublayout-ques'>{props.pathwayQues1}</div>
                                    <div><ToggleableCaret isOpen={openAccordion === accordionNumber} /></div>
                                </div>
                                {openAccordion === accordionNumber && (
                                    <>
                                        <div className='pathway-desc'>{props.pathwayDesc1}</div>
                                        <button className='pathway-button' type='button' onClick={() =>{ handleMenuItemClick('/VisaFinderLandingScreen'); window.scrollTo(0,0);}}>{props.visaFinderButton}</button>
                                    </>
                                )}
                            </div>
                        ))}
                        {[2].map((accordionNumber) => (
                            <div className='pathway-questions' key={accordionNumber}>
                                <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }} onClick={() => toggleAccordion(accordionNumber)}>
                                    <div className='pathway-sublayout-ques'>{props.pathwayQues2}</div>
                                    <div><ToggleableCaret isOpen={openAccordion === accordionNumber} /></div>
                                </div>
                                {openAccordion === accordionNumber && (
                                    <>
                                        <div className='pathway-desc'>{props.pathwayDesc2}</div>
                                        <button className='pathway-button'>
                                        <WhatsappLogo size={24}/>
  <a target="_blank" href="https://wa.me/9663907648" style={{ textDecoration: 'none', color: 'inherit' }}>
    {props.visaFinderButton2}
  </a>
</button>
       

                                        {/* <button className='pathway-button' type='button' onClick={() =>{ handleMenuItemClick('/CitizenScreen'); window.scrollTo(0,0);}}>{props.visaFinderButton2}</button> */}
                                    </>
                                )}
                            </div>
                        ))}
                        {[3].map((accordionNumber) => (
                            <div className='pathway-questions' key={accordionNumber}>
                                <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }} onClick={() => toggleAccordion(accordionNumber)}>
                                    <div className='pathway-sublayout-ques'>{props.pathwayQues3}</div>
                                    <div><ToggleableCaret isOpen={openAccordion === accordionNumber} /></div>
                                </div>
                                {openAccordion === accordionNumber && (
                                    <>
                                        <div className='pathway-desc'>{props.pathwayDesc3}</div>
                                        <button className='pathway-button' type='button'  onClick={openModal}>{props.visaFinderButton3}</button>
                                        {isModalOpen && <div className="modal-background">
                <div className='modalFinal'>
                  
                    <ContactModal closeModal={closeModal}/>

                </div>
            </div>}
                                    </>
                                )}
                            </div>
                        ))}
                    </div>
                    <div className='pathway-image'>
   <video className='pathwayMapImage' autoPlay muted loop playsInline>
      <source className='pathway-map-image' src={props.pathwayImage} type='video/mp4' />
   </video>
</div>
                </div>
            </div>
        </>
    )
}

export default Pathway