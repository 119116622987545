import React from 'react'
import NavBarComponent from '../components/NavBarComponent'
// import Hero from '../components/VisaBannerHero'
import Service from '../components/Service'
import DottedLines from '../images/DottedLines.png'
import Passport from '../images/Aust.mp4'
import Footer from '../components/Footer'
import PermanentEmpBanner from '../components/PermanentEmpBanner'
import Country from '../components/Country'
import HeroBanner from '../components/HeroBanner'
import { useNavigate } from 'react-router-dom';
function CountryLandingPage() {
  const navigate = useNavigate();
  const handleButtonClick1 = () => {
    window.scrollTo(0,0);
    navigate("/LwTemporarily");

  };

  const handleButtonClick2 = () => {
    window.scrollTo(0,0);
    navigate("/LwPermanently");
  };
  const handleButtonClick3 = () => {
    window.scrollTo(0,0);
    navigate("/JoinFamily");
  };
  const handleButtonClick4 = () => {
    window.scrollTo(0,0);
    navigate("/StudyDevelop");
  };
  const handleButtonClick5 = () => {
    window.scrollTo(0,0);
    navigate("/VisitWork");
  };
  const handleButtonClick12 = () => {
    window.scrollTo(0,0);
    navigate("/FamilyPartner");
  };
  const handleButtonClick13 = () => {
    window.scrollTo(0,0);
    navigate("/Nz");
  };
  const handleButtonClick14 = () => {
    window.scrollTo(0,0);
    navigate("/FamilyParent");
  };
  const handleButtonClick15 = () => {
    window.scrollTo(0,0);
    navigate("/child");
  };
  const handleButtonClick16 = () => {
    window.scrollTo(0,0);
    navigate("/Family457Visa");
  };
  const handleButtonClick31 = () => {
    window.scrollTo(0,0);
    navigate("/SkilledVisa189");
  };
  const handleButtonClick32 = () => {
    window.scrollTo(0,0);
    navigate("/Visa491");
  };
  const handleButtonClick33 = () => {
    window.scrollTo(0,0);
    navigate("/Visa191");
  };
  const handleButtonClick34 = () => {
    window.scrollTo(0,0);
    navigate("/Visa190");
  };
  const handleButtonClick355 = () => {
    window.scrollTo(0,0);
    navigate("/Visa489");
  };
  const handleButtonClick41 = () => {
    window.scrollTo(0,0);
    navigate("/Gms");
  };
  const handleButtonClick42 = () => {
    window.scrollTo(0,0);
    navigate("/blog");
  };
  const handleButtonClick43 = () => {
    window.scrollTo(0,0);
    navigate("/RmsPostCode");
  };
  return (
    <div>
        <NavBarComponent />
        {/* <PermanentEmpBanner
        heroHeading='Explore Hope Education & Migrations Migration and Immigration Services to Australia'
        heroSubHeading='Our expert consultants guide you through the intricate Australian immigration system, turning your dream of a better life, world-class education, and healthcare into reality.'
        connectButton='Let’s Connect'
        Banner={Passport}
        DottedLines={DottedLines}
      /> */}
       <HeroBanner
        heroHeading="Explore Hope's Services to Australia."
        heroSubHeading='Our expert consultants guide you through the intricate Australian immigration system, turning your dream of a better life, world-class education, and healthcare into reality.'
        connectButton='Let’s Connect'
        Banner={[Passport]}
        // Banner={['/video1.mp4', 'video2.mp4', 'video3.mp4']}
        DottedLines={DottedLines}
      />
        <Country
        countryName1='Visa Finder'
        countryName2='Family Visa'
        countryName3='Skilled Migration'
        countryName4='Tools'
        countryName5='Employer Sponsered'
        infoButton1='Work Temporarily'
        onClick1={handleButtonClick1}
        onClick2={handleButtonClick2}
        onClick3={handleButtonClick3}
        onClick4={handleButtonClick4}
        onClick5={handleButtonClick5}
        infoButton2='Work Permanently'
        infoButton3='Join Family'
        infoButton4='Study Develop'
        infoButton5='Visit Work'
        infoButton6='Visit Visa'
        infoButton7='TSS Visa 482'
        infoButtonSec1='Partner Visa'
        infoButtonSec2='NZ Citizen'
        infoButtonSec3='Parmenent Parent Visa'
        infoButtonSec4='Child Visa'
        infoButtonSec5='457 Visa'
        onClick12={handleButtonClick12}
        onClick13={handleButtonClick13}
        onClick14={handleButtonClick14}
        onClick15={handleButtonClick15}
        onClick16={handleButtonClick16}
        infoButton31="189 Visa"
        infoButton32="491 Visa"
        infoButton33="191 Visa"
        infoButton34="190 Visa"
        infoButton355="489 Visa"
        infoButton41="GSM Point Test"
        infoButton42="Blogs"
        infoButton43="RSMS Post Codes"
        onClick41={handleButtonClick41}
        onClick42={handleButtonClick42}
        onClick43={handleButtonClick43}
        onClick31={handleButtonClick31}
        onClick32={handleButtonClick32}
        onClick33={handleButtonClick33}
        onClick34={handleButtonClick34}
        onClick355={handleButtonClick355}
        infoButton15='482 Visa'
        infoButton25='494 Visa'
        infoButton35='457 to PR Visa'
        infoButton45='186 Visa'
        infoButton55='187 Visa'
        infoButton56='TSS Visa To PR'
        infoButton57='186 & 187 Visa Exemptions'

      />
        {/* <Hero /> */}
        <Service />
        <Footer />
    </div>
  )
}

export default CountryLandingPage