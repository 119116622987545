import React from 'react'
import Heading from './Heading'
import HowComponent187 from './HowComponent187'
import Visa187Img from "../images/Visa187Img.png"
import { useNavigate } from 'react-router-dom';
import ConsultationComponent from './ConsultationComponent'

function VisaStream187() {
  const navigate = useNavigate();
  const handleMenuItemClick = (path) => {
    navigate(path);
  };
  return (
    <div className='visaStream_container'>
      <Heading heading="Subclass 187 Visa streams & requirements" />
      <div className='container_layout'>
      <div className='VisaFamily_Box'>
      This is a permanent visa for skilled foreign workers who have a nominating employer in a regional or low population growth area of Australia.
Applicants can be in or outside Australia when they apply for this visa.
        </div>
        <div className='VisaFamily_Box'>
        <div className='AboutUsVisa_box_title'>DIRECT ENTRY STREAM</div>
        <div className='VisaFamily_Box_desc'><li>In November 2019, the Direct Entry Stream of the 187 Visa was replaced by the <u onClick={() =>{ handleMenuItemClick('/SkilledVisa'); window.scrollTo(0,0);}} style={{ color: '#B0383D', cursor: 'pointer',fontSize:"18px" }}> Skilled Employer Sponsored Regional (Provisional) – Subclass 494 Visa </u>Applicants can still apply for the 187 visa through the Transition Stream</li></div>

        </div>
        <div className='VisaFamily_Box'>
        <div className='AboutUsVisa_box_title'>TEMPORARY RESIDENCE TRANSITION STREAM (TRT)</div>
        <div className='VisaFamily_Box_desc'><li>Applicants must hold a <u onClick={() =>{ handleMenuItemClick('/Visa457Emp'); window.scrollTo(0,0);}} style={{ color: '#B0383D', cursor: 'pointer',fontSize:"18px" }}> 457 visa </u> or a <u onClick={() =>{ handleMenuItemClick('/EmployerTssVisa'); window.scrollTo(0,0);}} style={{ color: '#B0383D', cursor: 'pointer',fontSize:"18px" }}>TSS visa</u></li>
<li>Applicants must have worked for their employer full-time for two or three years (depending on when your visa was lodged)</li>
<li>Applicants must be nominated by an employer</li>
<li>If your <u onClick={() =>{ handleMenuItemClick('/Visa457Emp'); window.scrollTo(0,0);}} style={{ color: '#B0383D', cursor: 'pointer',fontSize:"18px" }}>457 visa </u> was lodged before 18th of April 2017, see detailed 457 to permanent residency requirements.</li>
<li> If your 457 visa was lodged after 18th of April 2017, check the <u onClick={() =>{ handleMenuItemClick('/EmployerTssVisa'); window.scrollTo(0,0);}} style={{ color: '#B0383D', cursor: 'pointer',fontSize:"18px" }}>TSS to permanent residency </u> detailed requirements.</li>
</div>

        </div>
      </div>
      <HowComponent187
                EmpHowHeading='How it works'

                EmpHowImage={Visa187Img}

                EmpHowSubHead1='Step 1'

                EmpHowSubHead2='Step 2'

                EmpHowSubHead3='Step 3'

                EmpHowSubHead4='Step 4'

                EmpHowSubHead5='Step 5'

                EmpHowDesc1='You have an Australian employer who is willing to sponsor you.'

                EmpHowDesc2='Our migration agents liaise with you and your employer to collect documents.'

                EmpHowDesc3='We lodge your nomination and visa application with the Department.'

                EmpHowDesc4='We wait until a decision is made on your nomination and visa application.'

                EmpHowDesc5='You are expected to work for your employer for 2 years after approval.'

            />
              <div className='ConsultationComponent_field'>
            <ConsultationComponent 
            title="Are You Eligible?"
            description="Take our online eligibility assessment to find out if you meet the requirements for the 482 Visa            "
            button="Check Eligibility"
            />
             <ConsultationComponent 
            title="Book A Consultation"
            description="Speak to one of our migration agents and simply book a consultation. We'll assist you in understanding the costs involved"
            button="Consult"
            />
             <ConsultationComponent
            title="Get A Quote"
            description="Have a visa in mind? Contact us for a fixed price quote. We'll assist you in understanding the costs involved.            "
            button="Get a quote"
            />
            </div>
    </div>
  )
}

export default VisaStream187