import React from 'react'
import Navbar from '../components/Navbar'
import DottedLines from '../images/DottedLines.png'
// import Herobanner2 from '../images/BannerVideo2.mp4';
import Herobanner1 from '../images/OverSeaMeeting.mp4';
import HeroBanner from '../components/HeroBanner'
import Footer from '../components/Footer';
import WhyUs from '../components/WhyUs';
import OverSeaHead from '../components/OverSeaHead';
import OverSeaWhyComp from '../components/OverSeaWhyComp';
import OurTeam from '../components/OurTeam';
import pathwaymapImage from '../images/OverSea.jpg'
import Pathway from '../components/Pathway';
import WhyHope from '../components/WhyHope';
import Hero2 from '../components/Hero2';
function OverSeaStudy() {
  return (
    <div>
        <Navbar />
        <Hero2
        heroHeading='Overseas Study'
        heroSubHeading='Welcome to the Study Abroad page of Hope Education & Migration.'
        connectButton='Let’s Connect'
        Banner={[Herobanner1]}
        DottedLines={DottedLines}
      />
      <OverSeaHead />
      {/* <OverSeaWhyComp /> */}
      {/* <OurTeam
        teamHeading='Why Choose Hope?'
        ourTeam={ourTeam}
        teamSubHeading1=''
        teamSubHeading2=''
        teamSubHeading3=''
        teamSubDesc1='At Hope Education & Migration, we are committed to providing personalized and professional services to help students achieve their goals of studying abroad. Our experienced team of consultants has a deep understanding of the education systems in Canada, Australia, and the USA and can provide tailored guidance and support based on each student’s unique needs.'
        teamSubDesc2='We also offer a wide range of resources and support services, including language testing and training, job placement services, and cultural orientation. Our goal is to provide students with a comprehensive and seamless experience from start to finish.'
        teamSubDesc3='Contact Us Today If you are interested in studying in Canada, Australia, or the USA and would like more information about our services, please contact us today. Our team of consultants would be happy to answer any questions you may have and help you get started on your journey to study in abroad.'
      /> */}
       <WhyHope
        pathwayHeadingMain='Why Choose Hope?'
        pathwayQues1=''
        pathwayQues2=''
        pathwayQues3=''
        pathwayDesc1='At Hope Education & Migration, we are committed to providing personalized and professional services to help students achieve their goals of studying abroad. Our experienced team of consultants has a deep understanding of the education systems in Canada, Australia, and the USA and can provide tailored guidance and support based on each student’s unique needs.'
        pathwayDesc2='We also offer a wide range of resources and support services, including language testing and training, job placement services, and cultural orientation. Our goal is to provide students with a comprehensive and seamless experience from start to finish.'
        pathwayDesc3='Contact Us Today If you are interested in studying in Canada, Australia, or the USA and would like more information about our services, please contact us today. Our team of consultants would be happy to answer any questions you may have and help you get started on your journey to study in abroad.'
        pathwayImage={pathwaymapImage}
        visaFinderButton=''
      />
       <WhyUs
        whyUsHeadingMain='Some of the services we offer include'
        whyUsHeading3='Program and Institution Selection'
        whyUsHeading4='Post-Arrival Support'
        whyUsHeading1='Student Visa Application'
        whyUsHeading2='Pre-Departure Support'
        whyUsHeading5='Application Assistance'
        whyUsDesc3='We help students choose the right program and institution based on their academic background, career goals, and personal preferences.'
        whyUsDesc4='Our team provides ongoing support to students after they arrive in their study abroad destination, helping them with any issues or challenges they may face.'
        whyUsDesc1='We assist with student visa applications, providing guidance on the requirements and supporting documents needed.'
        whyUsDesc2='We provide information and support for students preparing to depart for their study abroad destination, including travel arrangements and accommodation.'
        whyUsDesc5='Our team provides guidance and support for the entire application process, including preparing application materials and submitting them to the relevant institutions'
      />
      <Footer />
    </div>
  )
}

export default OverSeaStudy