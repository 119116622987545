import React from 'react'
import Heading from '../components/Heading'
import "../styles/SkillExemptions.css"
import ConsultationComponent from '../components/ConsultationComponent'
import { useNavigate } from 'react-router-dom';
function SkillExemptions() {
  const navigate = useNavigate();
  const handleMenuItemClick = (path) => {
    navigate(path);
  };
  return (
    <div>
        <Heading heading="Skill Exemptions"/>
        <div className='VisaFamily_Box'>
            <li>If you are applying for a 186 or a 187 Visa, you must meet the skill requirements, unless you meet any of the below exemptions:</li>
        </div>
        <div className='Skill_Exemptions_detail_fields'>
            <div className='Skill_Exemptions_detail_container'>
            <div className='AboutUsVisa_box_title'>186 Visa (ENS)</div>
            <div className='VisaFamily_Box_desc'> 
           <li> You don’t need a skills assessment if:</li>
           <li>You currently hold a Special Category NZ visa (subclass 444) or New Zealand Citizen Family visa (subclass 461) visa, and have worked for the employer who nominated you, in the nominated occupation, for at least 2 years in the 3 years immediately before you apply</li>
           <li>If you are nominated as:</li>
           <li>An academic by a university in Australia or</li>
           <li>A scientist, researcher, or technical specialist by an Australian government scientific agency, including State and Territory government scientific agencies</li>
             </div>

            </div>
            <div className='Skill_Exemptions_detail_container'>
            <div className='AboutUsVisa_box_title'>187 Visa (RSMS)</div>
            <div className='VisaFamily_Box_desc'>  <li>You don’t need a skills assessment if:</li>
            <li>You currently hold a <u onClick={() =>{ handleMenuItemClick('/FamilyVisa'); window.scrollTo(0,0);}} style={{ color: '#B0383D', cursor: 'pointer',fontSize:"18px" }}>  Special Category NZ visa (subclass 444) </u><u onClick={() =>{ handleMenuItemClick('/Nz'); window.scrollTo(0,0);}} style={{ color: '#B0383D', cursor: 'pointer',fontSize:"18px" }}>  or New Zealand Citizen Family visa (subclass 461) visa,</u> and have worked for the employer who nominated you, in the nominated occupation, for at least 2 years in the 3 years immediately before you apply</li>
            <li>Your occupation was not listed on IMMI 12/096 Specification of Occupations and Assessing Authorities, or</li>
            <li>You obtained your qualification in Australia</li></div>

            </div>
        </div>


        <div className='VisaFamily_Box'>
        <div className='AboutUsVisa_box_title'>Transitional Stream</div>
        <div className='VisaFamily_Box_desc'>   <li>If you are applying for a Subclass 186 visa (ENS) or a Subclass 187 visa (RSMS) through the Temporary Residence Transition Stream, you will generally not need to have your skills assessed.</li></div>
        </div>
        <div className='ConsultationComponent_field'>
       <ConsultationComponent 
       title="View RSMS Post Codes"
       description="View our list of eligible RSMS postcodes for the Regional Sponsored Migration Scheme (RSMS) Subclass 187 Visa. "
       button="View List"
       />
        <ConsultationComponent 
       title="Book A Consultation"
       description="Speak to one of our migration agents and simply book a consultation. We'll assist you in understanding the costs involved"
       button="Consult"
       />
        <ConsultationComponent
       title="Get A Quote"
       description="Have a visa in mind? Contact us for a fixed price quote. We'll assist you in understanding the costs involved.            "
       button="Get a quote"
       />
       </div>
    </div>
  )
}

export default SkillExemptions