import React from 'react'
import Navbar from '../components/Navbar'
import PermanentEmpHow from '../components/PermanentEmpHow'
import howitworks from '../images/howitworks.png'
import Perm186Content from '../components/Perm186Content'
import Footer from '../components/Footer'
// import PermanentEmpBanner from '../components/PermanentEmpBanner'
// import DottedLines from '../images/DottedLines.png'
// import Passport from '../images/passport.png'
import ConsultationComponent from '../components/ConsultationComponent'

const Perm186 = () => {
 
  
  return (
    <>
      {/* <Navbar />
      <PermanentEmpBanner
        heroHeading='Permanent Employer Sponsored Visas'
        heroSubHeading='The permanent Subclass 186 (ENS) and Subclass 187 (RSMS) visas allow skilled overseas workers to live and work in Australia permanently.'
        connectButton='Let’s Connect'
        Banner={Passport}
        DottedLines={DottedLines}
      /> */}
      <Perm186Content
        empOVHeading='SUBCLASS 186 VISA (ENS)'
        empOVsubHead1='DIRECT ENTRY STREAM:'
        empOVsubHead2='TEMPORARY RESIDENCE TRANSITION STREAM (TRT)'
        empOVDesc1='Applicants must have an occupation listed on the MLTSSL'
        empOVDesc11='Applicants must be nominated by an Australian employer'
        empOVDesc111='Applicants must perform a Skill Assessment through the authorized assessing authority.'
        empOVDesc2='Applicants must hold a 457 or 482 TSS Visa'
        empOVDesc22='Applicants must be nominated by an Australian Employer'
        empOVDesc222='Applicants may not need to perform a Skill Assessment'
        visaSubHeading='This is a permanent visa for skilled foreign workers who have a nominating employer in Australia.Applicants can be in or outside Australia when they apply for this visa.'
      />
      {/* <PermanentEmpHow
        EmpHowHeading='How it works'
        EmpHowImage={howitworks}
        EmpHowSubHead1='Step 1'
        EmpHowSubHead2='Step 2'
        EmpHowSubHead3='Step 3'
        EmpHowSubHead4='Step 4'
        EmpHowDesc1='Contact us to discuss your eligibility or to find an employer.'
        EmpHowDesc2='We assist you to collect the necessary documents.'
        EmpHowDesc3='Our agents submit your application to Immigration.'
        EmpHowDesc4='Our agents liaise with Immigration until your application is finalised.'
      />
        <div className='ConsultationComponent_field'>
            <ConsultationComponent
            
            title="Are You Eligible?"
            description="Take our online eligibility assessment to find out if you meet the requirements for the 482 Visa            "
            button="Check Eligibility"
            />
             <ConsultationComponent 
            
            title="Book A Consultation"
            description="Speak to one of our migration agents and simply book a consultation. We'll assist you in understanding the costs involved"
            button="Consult"
            />
             <ConsultationComponent
            
            title="Get A Quote"
            description="Have a visa in mind? Contact us for a fixed price quote. We'll assist you in understanding the costs involved.            "
            button="Get a quote"
            />
            </div> */}
      {/* <Footer /> */}
    </>
  )
}

export default Perm186