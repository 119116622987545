import React from 'react'
import '../styles/Applicant494.css'
import { useNavigate } from 'react-router-dom';
const Applicant494Comp = () => {
    const navigate = useNavigate();
    const handleNavigate482 = () => {
        window.scrollTo(0,0);
         navigate('/Family482')
      }
      const handleNavigate457 = () => {
        window.scrollTo(0,0);
         navigate('/Family457Visa')
      }
      const handleNavigateNzFamily = () => {
        window.scrollTo(0,0);
         navigate('/Nz')
      }
    return (
        <>
            <div className='applicant494-layout'>
                <div className='applicant494-headbox'>
                    <div className='applicant494-heading'>Eligibility to apply for a 494 visa</div>
                </div>
                <div className='applicant494-contentbox'>
                    <div className='applicants494-content'>
                        <div className='applicant-box-two'>
                            <div className='applicant-content-head'>Nomination</div>
                            <div className='applicant-content-desc'>You must be nominated by an Australian employer, who is a Stanard Business Sponsor operating lawfully in Australia.</div>
                            <div className='applicant-content-desc'>You must be employed in a regional area and working under an eligible occupation.</div>
                            <div className='applicant-content-desc'>The nomination application must be lodged before your visa application.</div>
                        </div>
                        <div className='applicant-box-two'>
                            <div className='applicant-content-head'>Age</div>
                            <div className='applicant-content-desc'>You must be under 45 at the time of application unless you are exempt.</div>
                        </div>
                    </div>
                    <div className='applicants494-content'>
                        <div className='applicant-box-two'>
                            <div className='applicant-content-head'>Experience</div>
                            <div className='applicant-content-desc'>You must have at least 3 years of full-time work experience, within the last 5 years, before applying for this visa.</div>
                        </div>
                        <div className='applicant-box-two'>
                            <div className='applicant-content-head'>English</div>
                            <div className='applicant-content-desc'>You must have at least Competent English to apply for this visa</div>
                        </div>
                        <div className='applicant-box-two'>
                            <div className='applicant-content-head'>Health & Care</div>
                            <div className='applicant-content-desc'>You must meet the health and character requirements (Unless you are exempt)</div>
                        </div>
                    </div>
                    <div className='applicants494-content'>
                        <div className='applicant-box-three'>
                            <div className='applicant-content-head'>Skill Assessment</div>
                            <div className='applicant-content-desc'>
                                You must have a positive <u style={{ color: '#B0383D', cursor: 'pointer' }}>Skill Assessment</u>, in your nominated occupation, from the relevant assessing authority unless you are exempt. Some applicants may be exempt if;
                                <li>You hold a <u onClick={handleNavigate457} style={{ color: '#B0383D', cursor: 'pointer' }}>457 Visa</u> or <u style={{ color: '#B0383D', cursor: 'pointer' }} onClick={handleNavigate482}>482 Visa</u> and you were required to provide a suitable skills assessment for this application.</li>
                                <li>Are nominated as an academic (University Lecturer or Faculty Head at academic Level A, B, C, D or E) by an Australian university</li>
                                <li>You have held a <u style={{ color: '#B0383D', cursor: 'pointer' }}>New Zealand Subclass 444</u> or <u  onClick={handleNavigateNzFamily} style={{ color: '#B0383D', cursor: 'pointer' }}>New Zealand Citizen Family Relationship visa (subclass 461) </u> and have been working for your nominating employer for two years*</li>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Applicant494Comp