import React from 'react'
import NavBarComponent from '../components/NavBarComponent'
import Herobanner1 from '../images/glob.mp4';
import DottedLines from '../images/DottedLines.png'
import HeroBanner from '../components/HeroBanner';
import "../styles/Tools.css"
import "../styles/VisaFinder.css"
import "../styles/ConsultationComponent.css"
import { useNavigate } from 'react-router-dom';
import Heading from '../components/Heading';
import Footer from '../components/Footer';
import ConsultationComponent from '../components/ConsultationComponent';
import ConsultationComponentTools from "../components/ConsultationComponentTools"

function Tools() {
    const navigate = useNavigate();
    const handleButtonClick1 = () => {
        window.scrollTo(0,0);
        navigate("/PointTest");
    
      };
      const handleButtonClick2 = () => {
        window.scrollTo(0,0);
        navigate("/VisaFinderLandingScreen");
    
      };
      const handleButtonClick3 = () => {
        window.scrollTo(0,0);
        navigate("/RmsPostCode");
    
      };
      const handleButtonClick4 = () => {
        window.scrollTo(0,0);
        navigate("/blog");
    
      };
      const handleButtonClick5 = () => {
        window.scrollTo(0,0);
        navigate("/VisitWork");
    
      };
    
  return (
    <div>
         <NavBarComponent />
        <HeroBanner
        heroHeading='Tools'
        heroSubHeading='We have developed a range of helpful resources and tools for you.'
        connectButton='Let’s Connect'
        Banner={[Herobanner1]}
        DottedLines={DottedLines}
      />
          <div className='Visa_finder_layout'>
      <div className='Consult_layout'>
      <Heading heading="Tools" containerColor="rgba(255, 247, 241, 1)" textColor="rgba(176, 56, 61, 1)"/>
      
        <div className='ConsultationComponent_field' style={{marginTop:"24px"}}>
            <ConsultationComponentTools
            containerColor="rgba(255, 247, 241, 1)"
            title="GSM Point Test"
            description="Take our free online points test to find out if you may be eligible to apply for General Skilled Migration"
            button="Let’s Do It"
            onClick1={handleButtonClick1}
            />
             <ConsultationComponentTools
             containerColor="rgba(255, 247, 241, 1)"
            title="Visa Finder"
            description="Australian visas can be confusing. Use our Visa Finder to view your visa options for better understanding"
            button="View options"
            onClick1={handleButtonClick2}
            />
              <ConsultationComponentTools
             containerColor="rgba(255, 247, 241, 1)"
            title="RSMS Post Codes"
            description="Have a visa in mind? Contact us for a fixed price quote. We'll assist you in understanding the costs involved  ."
            button="View Post Codes"
            onClick1={handleButtonClick3}
            />
            </div> 
            <div className='ConsultationComponent_field' style={{marginTop:"24px"}}>
             <ConsultationComponentTools
            containerColor="rgba(255, 247, 241, 1)"
            title="Blogs"
            description="Our registered migration agents regularly share new, tips and tricks and other interesting information for migrants."
            button="Learn More"
            onClick1={handleButtonClick4}
            />
             <ConsultationComponentTools
             containerColor="rgba(255, 247, 241, 1)"
            title="Visa Eligibility"
            description="Take our online eligibility assessment to find out if you meet the requirements for the 482 Visa"
            button="Check Eligibility"
            onClick1={handleButtonClick5}
            />
              {/* <ConsultationComponentTools
             containerColor="rgba(255, 247, 241, 1)"
            title="Skilled Occupation List"
            description="Our skilled occupation list can help you determine which visas you may be eligible to apply for."
            button="View options"
            onClick1={handleButtonClick6}
            /> */}
            </div> 
        
            </div>
        <Footer />
      </div>

    </div>
  )
}

export default Tools