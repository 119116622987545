import React, { useState } from 'react'
import Option1Overview from './Option1Overview';
import Option1Eligibility from './Option1Eligibility';
import Option1Family from './Option1Family';

function ModalComp() {
    const [activeTab, setActiveTab] = useState('Vacancies');

    const handleTabClick = tabName => {
      setActiveTab(tabName);
    };
  return (
    <div>
         <div className="TabsContainer">
      <div
        className={`Tab ${activeTab === 'Overview' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('Overview')}
      >
       Overview
      </div>
      <div
        className={`Tab ${activeTab === 'Eligibility' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('Eligibility')}
      >
      Eligibility
      </div>
      <div
        className={`Tab ${activeTab === 'Family' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('Family')}
      >
      Including Family
      </div>
      
                   </div>
         {activeTab === 'Overview' &&
         <div className='Tabs_container_layout'>
            <Option1Overview />
            </div>
            }
             {activeTab === 'Eligibility' &&
         <div className='Tabs_container_layout'>
            <Option1Eligibility />
            </div>
            }
             {activeTab === 'Family' &&
         <div className='Tabs_container_layout'>
            <Option1Family/>
            </div>
            }
    </div>
  )
}

export default ModalComp