import React, { useState } from 'react'
import NavBarComponent from '../components/NavBarComponent'
import HeroBanner from '../components/HeroBanner'
import DottedLines from '../images/DottedLines.png'
import Herobanner1 from '../images/GrantVisa.mp4';
import WorkingImg from "../images/StudentStudy.png"
import VisaBookNow from '../components/VisaBookNow';
import Footer from '../components/Footer';
import "../styles/Working.css"
import Heading from '../components/Heading';

function VisaFinderStudent() {
  const [activeTab, setActiveTab] = useState('Overview');

  const handleTabClick = tabName => {
    setActiveTab(tabName);
  };
  return (
    <div>
        <NavBarComponent />
        <HeroBanner
        heroHeading='Working Holiday Visa'
        heroSubHeading='Working Holiday Visa (Subclass 417)'
        connectButton='Let’s Connect'
        Banner={[Herobanner1]}
        DottedLines={DottedLines}
      />
           <div className="TabsContainer">
      <div
        className={`Tab ${activeTab === 'Overview' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('Overview')}
      >
        Overview
      </div>
      <div
        className={`Tab ${activeTab === 'Requirements' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('Requirements')}
      >
        Requirements
      </div>
      <div
        className={`Tab ${activeTab === 'Benefits' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('Benefits')}
      >
        Benefits
      </div>
      <div
        className={`Tab ${activeTab === 'FAQ' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('FAQ')}
      >
        FAQ’s
      </div>
      </div>
      {activeTab === 'Overview' &&
       <div className='Tabs_container_layout'>
        <div className='WorkingHoliday_Layout'>
     <div className='Working_Title'>
        <div className='Working_Sub_Title'>Australia’s overseas student program allows foreign students to complete their studies in Australia at a variety of levels</div>
        <div className='Working_desc'>These levels include:

<li> Higher Education such as Bachelor and Associate Degrees, Graduate Certificates, Graduate Diploma, Higher Education Diploma to name just a few</li>
<li> Vocational Education and Training such as: Certificate I, II, III, IV, VET Diploma, VET Advanced Diploma and more Primary and secondary school course</li>
<li> Postgraduate courses</li>
<li> English Language Intensive Courses for Overseas Students (ELICOS)</li>
Living expenses and tuition costs are quite low in Australia compared to other countries. Many student visas will allow you to work up to 40 hours per fortnight.
    </div>
     </div>
     </div>
      
        </div>
        }
        {activeTab === 'Requirements' &&
       <div className='Tabs_container_layout'>
        <div className='WorkingHoliday_Layout'>
          <Heading heading="To be granted a Student Visa you must meet the following requirements" containerColor="rgba(255, 247, 241, 1)" textColor="rgba(176, 56, 61, 1)"/>
          <div className='Tss_Eligibility_fields'>
            <div className='StudentVisa_fields_container1'>
            <div className='AboutUsVisa_box_title'>Enrolment</div>
                <div className='VisaFamily_Box_desc'>
                  International students must be enrolled in a course that is registered with the Commonwealth Register of Institutions and Courses for Overseas Students (CRICOS) unless they are exempt.
                 Students applying from outside Australia must include a Confirmation of Enrolment (CoE) letter with their visa application for each course they intend to study.
              </div>
            </div>
            <div className='StudentVisa_fields_container2'>
            <div className='AboutUsVisa_box_title'>ENGLISH REQUIREMENT</div>
                <div className='VisaFamily_Box_desc'>Students must demonstrate Functional English to be eligible to apply for a Student Visa in Australia unless they are exempt.
Students will not need to provide evidence of an english test score with their visa application if any of the following apply:
<li>the applicant is enrolled full-time in one of the following courses: a secondary exchange  programme, a postgraduate research course, a standalone</li>
 <li>English Language Intensive Course for Overseas Students (ELICOS), and Foreign Affairs or Defence sponsored students</li>
<li>the applicant has completed five or more years’ study in one of the following countries: Australia, UK, USA, Canada, New Zealand, South Africa, or the Republic of Ireland</li>
<li>the applicant must hold a passport from UK, USA, Canada, NZ or Republic of Ireland</li>
              </div>
            </div>
        </div>
        <div className='visaFinder_extrayLay'>
                <div className='LabourAgreement_section-container_title'>Financial Capacity</div>
                <div className='LabourAgreement_section-container_desc_extrayLay'>International students must have enough money available to them, to pay for their course fees, and all travel and living costs for them and any accompanying family members while in Australia. Students may need to provide evidence of these funds with heir visa application.</div>
            </div>
            <div className='visaFinder_extrayLay'>
                <div className='LabourAgreement_section-container_title'>Health Insurance</div>
                <div className='LabourAgreement_section-container_desc_extrayLay'>International students and all accompanying family members must have adequate health insurance for the duration of their stay in Australia.</div>
            </div>

     </div>
        </div>
        }
         {activeTab === 'Benefits' &&
       <div className='Tabs_container_layout'>
        <div className='WorkingHoliday_Layout_Student'>
        <Heading heading="Benefits of a student visa" containerColor="rgba(255, 247, 241, 1)" textColor="rgba(176, 56, 61, 1)"/>
        <div className='LabourAgreement_section'>
                <div className='LabourAgreement_section-container'>
                    <div className='LabourAgreement_section-container_title'>Work And Study </div>
                    <div className='LabourAgreement_section-container_desc'>
                    An Australian Student Visa allows you to work throughout the duration of your course. Student visa holders are entitled to work 40 hours per fortnight while studying and unlimited hours during school breaks and holidays.
                       </div>
                </div>
                <div className='LabourAgreement_section-container'>
                <div className='LabourAgreement_section-container_title'>Internationally Recognized </div>
                    <div className='LabourAgreement_section-container_desc'>Once you have finished your study, you will have an Internationally Recognized Qualification. With this, you may be able to apply for an employer sponsored visa and migrate to Australia permanently.
                       </div>
                </div>
                <div className='LabourAgreement_section-container'>
                <div className='LabourAgreement_section-container_title'>Post Graduate </div>
                    <div className='LabourAgreement_section-container_desc'>
                    Once you have completed your studies, you may be eligible to apply for a Post Graduate Visa. This will allow you to get two years work experience in Australia, making it easier for you to seek employer sponsorship.                         
                </div>
                </div>
            </div>
       </div>
        </div>
        }
         {activeTab === 'FAQ' &&
       <div className='Tabs_container_layout'>
        <div className='WorkingHoliday_Layout_Student'>
        <Heading heading="Answers to frequently asked questions" containerColor="rgba(255, 247, 241, 1)" textColor="rgba(176, 56, 61, 1)"/>
        <div className='student_tri_field'>
          <div className='student_tri_box'>
          <div className='LabourAgreement_section-container_title'>Can ASM help me choose the right course? </div>
         <div className='LabourAgreement_section-container_desc'>Absolutely, our migration agents will assist you to decide on which courses are most suitable for you, based on your academic qualifications, learning abilities, interests, financial requirements and long-term goals. We will advise you on what courses are available, the locations, the costs, course duration and your options for employment in Australia, after you graduate. </div>
          </div>
          <div className='student_tri_box'> 
          <div className='LabourAgreement_section-container_title'>Can I enrol for packaged courses?</div>
         <div className='LabourAgreement_section-container_desc'>International Students can choose to study two or more courses while in Australia to upskill or give them the head start they need in their chosen work industry. Australian Skilled Migration offers a number of packaged courses in almost any industry.</div>
        </div>
          <div className='student_tri_box'>
          <div className='LabourAgreement_section-container_title'>Can I add family members to my student visa?</div>
          <div className='LabourAgreement_section-container_desc'>Applicants can include eligible family members to their student visa application when lodging their visa. Eligible family members may also be added after the student visa has been granted, Applicants must have sufficient funds to support themselves and all accompanying family members while in Australia.</div>
          </div>
        </div>
        <div className='student_double_field'>
        <div className='student_double_box'>
        <div className='LabourAgreement_section-container_title'>Can I work while studying?</div>
          <div className='LabourAgreement_section-container_desc'>International Students in Australia can generally work up to 40 hours per fortnight while they are studying. During scheduled course breaks/school holidays, International Students can work unlimited hours.
Students who are studying for a Masters Degree by research or a PhD do not have any work restrictions.
Accompanying family members have the same work rights as the student. They are entitled to work 40 hours per fortnight while the primary visa applicant is studying, and unlimited hours during scheduled breaks and holidays. If the primary applicant is studying for a Masters Degree or PhD, the secondary applicants can work unlimited hours.</div>
        </div>
        <div className='student_double_box'>
        <div className='LabourAgreement_section-container_title'>Can I get a student visa if I am not yet 18 years old?</div>
          <div className='LabourAgreement_section-container_desc'>A parent or legal guardian of an international student studying in Australia may be eligible to apply for a Student Guardian Visa (Subclass 509). This visa allows a parent or guardian to live in Australia for the duration of the course their child is studying, or until their child turns 18.
The following requirements apply:

<li>the student must be under 18 years of age unless exceptional circumstances apply</li>
<li>the student guardian must be at least 21 years of age</li>
<li>the student guardian must be able to provide accommodation, general welfare and other support to the student</li>
<li>the student guardian must meet health and character requirements</li></div>
        </div>
        </div>

         </div>      
        </div>
        }

<VisaBookNow
        img={WorkingImg}
        Title="Student Visa Australia"
        Desc="Here at Australian Skilled Migration, our team of Registered Migration Agents are dedicated to helping you reach your full potential. Let one of our experienced migration agents assist with your student visa application. We will discuss all course options available to you and determine which option will help you achieve your long-term career goals."
        Btn="Book now" 
        />
      <Footer/>
    </div>
  )
}

export default VisaFinderStudent