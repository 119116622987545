import React from 'react'
import "../styles/Internationalwhy.css"

export default function InternationalWhy(props) {
  return (
    <div className='InternationalWhy_layout' >
        <div className='InternationalWhy_head'>Why Trust Us?</div>
        <div className='InternationalWhy_desc'>We understand that high-quality employees are the best resource any business can have and that your company needs to be sure that it is hiring the most talented and suitable individual for the position. Our Registered Migration Agents collect and lodge all sponsorship, visa, nomination and country of departure documents. We have successfully managed the overseas recruitment for a large number of Australian businesses, across dozens of industries.</div>
        <div className='InternationalWhy_container1'>
        <div className='InternationalWhy_container1_fields'>
            <div className='InternationalWhy_container1_title'>Highly Qualified</div>
            <div className='InternationalWhy_container1_desc'>With over 15 years of recruitment experience, our thorough screening process ensures that all applicants are shortlisted to YOUR specifications. We guarantee high-quality staff because we have access to an international labour pool. We select the only most skilled people in their field.</div>
        </div>
        <div className='InternationalWhy_container1_fields'>
        <div className='InternationalWhy_container1_title'>Extensive Selection</div>
        <div className='InternationalWhy_container1_desc'>When businesses advertise, all they are hoping to find great employees but most often just settle for what’s available in their small local labour pool. We offer a customised recruitment service to find you exactly the skills and attitude you need. Don’t just settle.</div>
        </div>
        <div className='InternationalWhy_container1_fields'>
        <div className='InternationalWhy_container1_title'>Professional Support</div>
        <div className='InternationalWhy_container1_desc'>Our experienced team of Registered Migration Agents and recruitment experts will provide ongoing support throughout your sponsoring journey. They will give you personalised advice and information based on the most current legislation and policy requirements.</div>
        </div>
        </div>

    </div>
  )
}
