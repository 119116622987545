import React, { useState } from 'react'
import '../styles/Visa186Eligibility.css'
import { XCircle } from "@phosphor-icons/react";
import EnglishModal from './EnglishModal';

const Visa186Requirements = (props) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };
    return (
        <>
            <div className='eligibilityMain-layout'>
                <div className='eligibility-heading'>
                    <div className='visa-eligibility-heading'>{props.whyUsHeadingMain}</div>
                </div>
                <div className='eligibility-second-layout'>
                    <div className='eligibility-layout-one-186'>
                        <div className='eligibility186-layout-exp'>
                            <div className='eligiblity-heading-186'>{props.whyUsHeading1}</div>
                            <div className='eligiblity-desc'>
                                <li>{props.whyUsDesc1}</li>
                            </div>
                        </div>
                        <div className='eligibility-layout-ext'>
                            <div className='eligiblity-heading-186'>{props.whyUsHeading2}</div>
                            <div className='eligiblity-desc1'>
                                <li>{props.whyUsDesc2}</li>
                            </div>
                        </div>
                    </div>
                    <div className='eligiblity-layout-two'>
                        <div className='eligibility-two-sublayout1'>
                            <div className='eligibility-two-service-186'>
                                <div className='eligibility-two-head'>{props.whyUsHeading3}</div>
                                <div className='eligibility-two-desc'>
                                    <div>{props.whyUsDesc3} <span> 
                                    <u onClick={openModal} style={{ color: "#B0383D", cursor: 'pointer' }}>{props.here}</u>
                                        </span></div>
                                </div>
                            </div>
                            <div className='eligibility-two-trans'>
                                <div className='eligibility-two-head'>{props.whyUsHeading4}</div>
                                <div className='eligibility-two-desc'>
                                    <li>{props.whyUsDesc42}</li>
                                    <li>{props.whyUsDesc43}</li>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='eligibility-two-sublayout2'>
                    <div className='eligibility-two-support'>{props.whyUsHeading5}</div>
                    <div className='eligibility-two-support-desc'>
                        <div>{props.whyUsDesc5}</div>
                        <div>{props.whyUsDesc51}</div>
                        <li>{props.whyUsDesc52}</li>
                        <li>{props.whyUsDesc53}</li>
                        <li>{props.whyUsDesc54}</li>
                    </div>
                </div>
                <div className='eligibility-main-layout2'>
                    <div className='why-two-service'>
                        <div className='eligiblity-heading'>{props.whyUsHeading1}</div>
                        <div className='eligiblity-desc'>
                            <li>{props.whyUsDesc1}</li>
                        </div>
                    </div>
                    <div className='why-two-service'>
                        <div className='eligiblity-heading1'>{props.whyUsHeading2}</div>
                        <div className='eligiblity-desc1'>
                            <li>{props.whyUsDesc2}</li>
                        </div>
                    </div>
                    <div className='why-two-service'>
                        <div className='eligibility-two-head'>{props.whyUsHeading3}</div>
                        <div className='eligibility-two-desc'>
                            <div>{props.whyUsDesc3}</div>
                            <div style={{ marginTop: '8px' }}>{props.whyUsDesc31}</div>
                            <li>{props.whyUsDesc32}</li>
                            <li>{props.whyUsDesc33}</li>
                        </div>
                    </div>
                    <div className='why-two-service'>
                        <div className='eligibility-two-head'>{props.whyUsHeading4}</div>
                        <div className='eligibility-two-desc'>
                            <div>{props.whyUsDesc4}</div>
                            <div style={{ marginTop: '8px' }}>{props.whyUsDesc41}</div>
                            <li><u style={{ color: '#B0383D', cursor: 'pointer' }}>{props.whyUsDesc42}</u></li>
                            <li>{props.whyUsDesc43}</li>
                        </div>
                    </div>
                    <div className='why-two-service'>
                        <div className='eligibility-two-support'>{props.whyUsHeading5}</div>
                        <div className='eligibility-two-support-desc'>
                            <li>{props.whyUsDesc5}</li>
                            <li>{props.whyUsDesc55}</li>
                        </div>
                    </div>
                </div>
                {isModalOpen && <div className="modal-background">
                <div className='modalFinal'>
                    {/* <div style={{ textAlign: 'right', paddingTop: '16px', paddingRight: '16px' }}>
                        <XCircle onClick={closeModal} size={32} />
                    </div> */}
                    <EnglishModal closeModal={closeModal}/>
                </div>
            </div>}
            </div>
        </>
    )
}

export default Visa186Requirements