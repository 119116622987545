import React from 'react'
import "../styles/OverSea.css"

function OverSeaHead() {
  return (
    <div className='OverSeaLayout'>
        <div className='oversea_title'>Overseas Study</div>
        <div className='Oversea_Box'>We are a leading education and migration consultancy that helps students pursue their dreams of studying abroad. Our team of experienced consultants is committed to providing personalized guidance and support to help students achieve their goals of studying in Canada, Australia, and the USA.</div>
        <div className='Oversea_Box'>Why Study Abroad? Studying abroad is a unique and rewarding experience that offers numerous benefits. Students who study abroad have the opportunity to immerse themselves in a new culture, learn a new language, and make lifelong connections with people from around the world. They also gain valuable skills and experience that can help them succeed in their future careers.

Canada, Australia, and the USA are all top destinations for international students looking to pursue their education abroad. Each country offers a unique and high-quality education system, a welcoming environment, and a wide range of programs and degrees to choose from.

Services We Offer At Hope Education & Migration, we offer a wide range of services to help students achieve their goals of studying abroad. Our experienced team of consultants provides personalized guidance and support throughout the entire process, from choosing the right program and institution to applying for a student visa.</div>
        
    </div>
  )
}

export default OverSeaHead