import React from 'react'
import Heading from '../components/Heading'
import ConsultationComponent from '../components/ConsultationComponent'

function AgeExemption() {
  return (
 
    <div>
    <Heading heading="Age Exemptions"/>
    <div className='VisaFamily_Box'>
       <li>If you are applying for a 186 or a 187 Visa, you must meet the age requirements, unless, you meet any of the below exemptions:</li>
   </div>
   <div className='VisaFamily_Box'>
           <div className='AboutUsVisa_box_title'>DIRECT ENTRY STREAM</div>
           <div className='VisaFamily_Box_desc'><li>You are applying through the Temporary Residence Transition stream and have been working for your nominating employer as the holder of a TSS or subclass 457 visa for the last three years and your earnings were at least $153,600 for each year over that period.</li>
           <li>You hold a Special Category NZ visa (subclass 444) or New Zealand Citizen Family visa (subclass 461) and you have been working for the employer who nominated you for at least two years (excluding any periods of unpaid leave) in the last three years immediately before applying (Direct Entry stream only)</li>
           <li> You are applying through the Labour Agreement stream and the relevant labour agreement allows for a person who has turned 45 to be employed</li>
         </div>
   </div>
   <div className='VisaFamily_Box'>
           <div className='AboutUsVisa_box_title'>OCCUPATION AGE EXEMPTIONS</div>
           <div className='VisaFamily_Box_desc'><li>you have been working in your nominated occupation as the holder of a TSS or subclass 457 visa for at least three years immediately before applying for two of those years, you were employed in regional Australia</li>
           <li>The nominated position is located in regional Australia</li>
           <li> You are nominated as a senior academic by a university in Australia (Applies to Temporary Residence Transition and Direct Entry streams)</li>
           <li> You are nominated as a scientist, researcher, or technical (scientific) specialist at ANZSCO skill level one or two by an Australian government scientific agency (Applies to Temporary Residence Transition and Direct Entry streams)</li>
         </div>
   </div>
   <div className='ConsultationComponent_field'>
       <ConsultationComponent 
       title="View RSMS Post Codes"
       description="View our list of eligible RSMS postcodes for the Regional Sponsored Migration Scheme (RSMS) Subclass 187 Visa. "
       button="View List"
       />
        <ConsultationComponent 
       title="Book A Consultation"
       description="Speak to one of our migration agents and simply book a consultation. We'll assist you in understanding the costs involved"
       button="Consult"
       />
        <ConsultationComponent
       title="Get A Quote"
       description="Have a visa in mind? Contact us for a fixed price quote. We'll assist you in understanding the costs involved.            "
       button="Get a quote"
       />
       </div>
</div>
  )
}

export default AgeExemption