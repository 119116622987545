import React, { useState } from 'react'
import NavBarComponent from '../components/NavBarComponent'
import Heading from '../components/Heading'
import "../styles/Points.css"
import Footer from '../components/Footer'
import { WhatsappLogo } from '@phosphor-icons/react'

function PointTest() {
    const [points, setPoints] = useState(0);
    const [answers, setAnswers] = useState({});
    const surveyData = {
        "Q-1": {
          "title": "Your age group",
          "options": [
            { "label": "18-24", "points": 25 },
            { "label": "25-32", "points": 30 },
            { "label": "33-39", "points": 25 },
            { "label": "40-44", "points": 15 }
          ]
        },
        "Q-2": {
          "title": "Your highest level of education",
          "options": [
            { "label": "PHD", "points": 20 },
            { "label": "Masters", "points": 15 },
            { "label": "Bachelors", "points": 15 },
            { "label": "Diploma after secondary", "points": 10 }
          ]
        },
        "Q-3": {
          "title": "Your total work experience(excluding Australian experience)",
          "options": [
            { "label": "0-2 years", "points": 0 },
            { "label": "3 to 4 years", "points": 5 },
            { "label": "5 to 7 years", "points": 10 },
            { "label": "8 plus", "points": 15 }
          ]
        },
        "Q-4": {
          "title": "Your English proficiency",
          "options": [
            { "label": "Basic Proficiency", "points": 0 },
            { "label": "High Proficiency", "points": 10 },
            { "label": "Very High Proficiency", "points": 20 }
          ]
        },
        "Q-5": {
            "title": "Are you married?",
            "options": [
              { "label": "Yes", "points": 0 },
              { "label": "No", "points": 10 }
            ],
            "subQuestions": {
              "Sub Q-1": {
                "title": "your spouse/partner's English proficiency",
                "options": [
                  { "label": "No proficiency", "points": 0 },
                  { "label": "Basic proficiency", "points": 5 },
                  { "label": "High proficiency", "points": 5 }
                ]
              },
              "Sub Q-2": {
                "title": "your spouse has experience in any of the skilled occupations and moderate English?",
                "options": [
                  { "label": "Yes", "points": 5 },
                  { "label": "No", "points": 0 }
                ]
              }
            }
          },
          
        "Q-6": {
          "title": "Understanding your adaptability",
          "subQuestions": {
            "Sub Q-3": {
              "title": "Do you have experience in Australia?",
              "options": [
                { "label": "0 year", "points": 0 },
                { "label": "1 year", "points": 5 },
                { "label": "3 years", "points": 10 },
                { "label": "5 years", "points": 15 },
                { "label": "8 or more years", "points": 20 }
              ]
            },
            "Sub Q-4": {
              "title": "Did you study in Australia(2 academic years)?",
              "options": [
                { "label": "Yes", "points": 5 },
                { "label": "No", "points": 0 }
              ]
            },
            "Sub Q-5": {
              "title": "Do you hold a Masters by research or a Doctorate degree from an Australian educational institution in the STEM fields?",
              "options": [
                { "label": "Yes", "points": 10 },
                { "label": "No", "points": 0 }
              ]
            },
            "Sub Q-6": {
              "title": "Your Australian education is from regional Australia?",
              "options": [
                { "label": "Yes", "points": 5 },
                { "label": "No", "points": 0 }
              ]
            },
            "Sub Q-7": {
              "title": "Did you obtain credential community language qualifications in the following languages Punjabi/ Telugu/ Urdu/ Tamil/ Malayalam/ Hindi/ Bangla/ Gujarati?",
              "options": [
                { "label": "Yes", "points": 5 },
                { "label": "No", "points": 0 }
              ]
            }
          }
        }
      };
     

      const handleRadioChange = (questionKey, optionLabel, optionPoints) => {
        // Update answers state
        setAnswers((prevAnswers) => ({
          ...prevAnswers,
          [questionKey]: { optionLabel, optionPoints },
        }));
      
        // Calculate total points
        let totalPoints = 0;
      
        // Iterate over all answers and add their points
        Object.values({...answers, [questionKey]: { optionLabel, optionPoints }}).forEach((answer) => {
          if (answer && typeof answer.optionPoints === 'number') {
            totalPoints += answer.optionPoints;
          }
        });
      
        // Check for subquestions and add their points to the total
        const subQuestions = surveyData[questionKey]?.subQuestions;
        if (subQuestions) {
          Object.values(subQuestions).forEach((subQuestionData) => {
            const subQAnswer = answers[subQuestionData];
            if (subQAnswer && typeof subQAnswer.optionPoints === 'number') {
              totalPoints += subQAnswer.optionPoints;
            }
          });
        }
      
        // Update the total points state
        setPoints(totalPoints);
      };
      
      
      
      
      
      const renderOptions = (questionKey, options) => {
        return options.map((option) => (
          <div key={option.label} className='radio_fields'>
            <input
              type="radio"
              className='radio'
              id={`${questionKey}-${option.label}`}
              name={questionKey}
              value={option.label}
              onChange={() => handleRadioChange(questionKey, option.label, option.points)}
            />
            <label htmlFor={`${questionKey}-${option.label}`} className='radio_labels'>{option.label}</label>
          </div>
        ));
      };
    
      const renderSubQuestions = (subQuestions) => {
        return Object.entries(subQuestions).map(([subQKey, subQData]) => (
          <div key={subQKey}>
            <p>{subQData.title}</p>
            {renderOptions(subQKey, subQData.options)}
          </div>
        ));
      };
    // const renderQuestions = () => {
    //     return Object.entries(surveyData).map(([questionKey, questionData]) => (
    //       <div key={questionKey} className='points_question'>
    //         <p>{questionData.title}</p>
    //         {questionData.options ? renderOptions(questionKey, questionData.options) : null}
    //         {questionData.subQuestions ? (
    //           <div className="subquestions-container">
    //             {renderSubQuestions(questionData.subQuestions)}
    //           </div>
    //         ) : null}
    //       </div>
    //     ));
    //   };
    const renderQuestions = () => {
        return Object.entries(surveyData).map(([questionKey, questionData]) => (
          <div key={questionKey} className='points_question'>
            <p>{questionData.title}</p>
            {questionData.options ? (
              <div>
                {renderOptions(questionKey, questionData.options)}
                {/* Check if it's Q-5 and answer is Yes to render sub-questions */}
                {questionKey === 'Q-5' && answers[questionKey]?.optionLabel === 'Yes' ? (
                  <div className="subquestions-container">
                    {renderSubQuestions(questionData.subQuestions)}
                  </div>
                ) : null}
              </div>
            ) : null}
            {/* Explicitly render Q-6 sub-questions here */}
            {questionKey === 'Q-6' ? (
              <div className="subquestions-container">
                {renderSubQuestions(questionData.subQuestions)}
              </div>
            ) : null}
          </div>
        ));
      };
      
      
        const handleReset = () => {
        setAnswers({});
        setPoints(0);
      
        // Reset radio buttons for main questions
        const radioGroups = Object.keys(surveyData);
        radioGroups.forEach((questionKey) => {
          const radioButtons = document.getElementsByName(questionKey);
          radioButtons.forEach((radioButton) => {
            radioButton.checked = false;
          });
        });
      
        // Reset radio buttons for subquestions
        radioGroups.forEach((questionKey) => {
          const subQuestions = surveyData[questionKey]?.subQuestions;
          if (subQuestions) {
            Object.keys(subQuestions).forEach((subQKey) => {
              const subRadioButtons = document.getElementsByName(subQKey);
              subRadioButtons.forEach((subRadioButton) => {
                subRadioButton.checked = false;
              });
            });
          }
        });
      };
      
    const handleConnectButtonClick = () => {
        const phoneNumber = "+91 9663961038";
        const whatsappWebLink = `https://web.whatsapp.com/send?phone=${phoneNumber}`;
      
        const isUserLoggedIn = localStorage.getItem("WABrowserId") !== null;
        window.open(isUserLoggedIn ? whatsappWebLink : "https://web.whatsapp.com/", '_blank');
      };
      
  return (
    <div>
        <NavBarComponent />
        <div className='Points_layout'>
            <div className='points_cotainer1'>
        <div className='points_head_box'>
            <div className='heading_points'>Points Calculator</div>
            <div className='subhead_point'>Skilled Independent Visa ( Subclass 189 )</div>
        </div>
        <div className='points_form_container'>
            {renderQuestions()}
          </div>

            </div>
            <div className='points_cotainer2'>
        <Heading heading="Points Calculator" containerColor="rgba(255, 247, 241, 1)"  textColor="rgba(176, 56, 61, 1)"/>
        <div className='points_form_summary'>
        <div className='points_form'>
  {Object.entries(surveyData).map(([questionKey, questionData]) => (
    <div key={questionKey} className={`points_form_fields ${questionData.subQuestions ? 'has_sub_questions' : ''}`}>
      <div className='summary_label'>{questionData.title}</div>
      <div className='summary_label'>
  {questionKey === 'Q-5' && answers['Q-5']?.optionLabel === 'No' ? (
    // If Q-5 is answered with 'No', display the main question and points with justify-content: space-between
    <div className='points_form_fields no_answer' style={{marginTop: "-40px"}}>
      <div></div>
      <div>{questionData.options.find(option => option.label === 'No')?.points || ''}</div>
    </div>
  ) : (
    // Render subquestions or main question's points if there are no subquestions
    questionData.subQuestions ? (
      // Render subquestions only if they exist and if the answer to Q-5 is not 'No'
      Object.entries(questionData.subQuestions).map(([subQuestionKey, subQuestionData]) => (
        <div key={subQuestionKey} className='points_form_fields sub_question'>
          <div className='summary_label' style={{fontWeight: 400, fontSize: "20px", color: "#848484", width: "70%"}}>{subQuestionData.title}</div>
          <div className='summary_label'>
            {answers[subQuestionKey] ? answers[subQuestionKey].optionPoints : ''}
          </div>
        </div>
      ))
    ) : (
      // Render the main question's points if there are no subquestions
      answers[questionKey] ? answers[questionKey].optionPoints : ''
    )
  )}
</div>


    </div>
  ))}
  <div className='points_form_fields'>
    <div className='summary_label'>Your Total Points</div>
    <div className='summary_label'>{points}</div>
  </div>
</div>

            <div className='points_btn'>
                <div><button className='reset_btn' onClick={handleReset}>Reset Calculator</button></div>
                {/* <div><button className='lets_conncet' onClick={handleConnectButtonClick}>Let’s Connect</button></div> */}
            </div>
            

        </div>
        </div>
        <div style={{marginTop:"24px"}}>
        <div className='points_form_summary'>
            <div className='point_connect'>
            <div>If your score is above 50  </div>
            {/* <div>
              <button className='lets_conncet' onClick={handleConnectButtonClick}><WhatsappLogo size={20} />Let’s Connect</button>
              </div> */}
              <div className='lets_conncet'>
     <a target="_blank" href="https://wa.me/9663907648" style={{ textDecoration: 'none' }} className='lets_conncet'>
     <WhatsappLogo size={24} /> Let’s Connect  </a>

</div>

            </div>

            </div>

        </div>
        </div>
    <Footer />
    </div>
  )
}

export default PointTest