import React, { useState } from 'react'
import HeroBanner from '../components/HeroBanner'
import DottedLines from '../images/DottedLines.png'
import Herobanner from '../images/InsidePassport.mp4';
import NavBarComponent from '../components/NavBarComponent'
import Footer from '../components/Footer'
import "../styles/Working.css"
import WorkingImg from "../images/WorkingImg.png"
import VisaBookNow from '../components/VisaBookNow';

function VisitorVisa() {
    const [activeTab, setActiveTab] = useState('Identification');

    const handleTabClick = tabName => {
      setActiveTab(tabName);
    };
  return (
    <div>
         <NavBarComponent />
        <HeroBanner
        heroHeading='Visitor Visa 600'
        heroSubHeading='Everything you need to know about applying for your own visitor visa.'
        connectButton='Let’s Connect'
        Banner={[Herobanner]}
        DottedLines={DottedLines}
      />
            <div className="TabsContainer">
      <div
        className={`Tab ${activeTab === 'Identification' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('Identification')}
      >
        Identification
      </div>
      <div
        className={`Tab ${activeTab === 'Evidence' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('Evidence')}
      >
      Evidence of funds
      </div>
      <div
        className={`Tab ${activeTab === 'Genuine' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('Genuine')}
      >
        Genuine tourist intention
      </div>
      <div
        className={`Tab ${activeTab === 'Intentions' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('Intentions')}
      >
        Intentions to return home
      </div>
      </div>
      {activeTab === 'Identification' &&
       <div className='Tabs_container_layout'>
       <div className='WorkingHoliday_Layout'>
     <div className='Working_Title'>
        <div className='Working_desc'>Applicants must provide a coloured, certified copy of the biographical pages in their passport. It is recommended that passport is valid for more than 6 months at the time of application.
Applicants must provide a certified copy of their birth certificate.
Applicants must also provide one recent passport sized photo (45 mm x 35 mm).</div>
     </div>
     <div className='Working_Things_Avoid_box'>
        <div className='Working_Sub_Title'>Things to avoid</div>
        <div className='Working_desc'>
            <li>Lodge a Visitor Visa if the applicant already has a Visa pending in with the Department of Immigration.</li>
            <li>Lodge a Visitor Visa if the applicant has recently had a visa refusal (Seek professional advice before lodging).</li>
            <li>Book flights before your Visitor Visa is approved! (Most airlines will not refund your ticket if you visa isn’t approved).</li>
            <li>Lodge your application without attaching all required documents. (Evidence, bank statements, passport, etc)</li>
    </div>
     </div>
     </div>
      
        </div>
        }
         {activeTab === 'Evidence' &&
       <div className='Tabs_container_layout'>
         <div className='WorkingHoliday_Layout'>
     <div className='Working_Title'>
        <div className='Working_desc'>Applicant must show that they have sufficient funds to support their stay in Australia (minimum $1000 per month) The onus is on the applicant to demonstrate with bank statements that they have personal funds to support themselves. An applicant may show that a family member is offering financial support however this evidence bears less weight.
Type of evidence to show financial support from a family member:
<li>Family Member’s Bank Statement</li>
<li>Evidence of family relationship (ie Birth Certificate, Family Composition Book, Statement from Family)</li>
</div>
     </div>
     <div className='Working_Things_Avoid_box'>
        <div className='Working_Sub_Title'>Things to avoid</div>
        <div className='Working_desc'>
            <li>Lodge a Visitor Visa if the applicant already has a Visa pending in with the Department of Immigration.</li>
            <li>Lodge a Visitor Visa if the applicant has recently had a visa refusal (Seek professional advice before lodging).</li>
            <li>Book flights before your Visitor Visa is approved! (Most airlines will not refund your ticket if you visa isn’t approved).</li>
            <li>Lodge your application without attaching all required documents. (Evidence, bank statements, passport, etc)</li>
    </div>
     </div>
     </div>
      
        </div>
        }
         {activeTab === 'Genuine' &&
       <div className='Tabs_container_layout'>
        <div className='WorkingHoliday_Layout'>
     <div className='Working_Title'>
        <div className='Working_desc'>The applicant must demonstrate that they are genuinely traveling to Australia for tourism/vacation.
        <li> Invitations received to attend ceremony/special occasions/ seminar (if applying under business visitor stream)</li>
<li> Invitation Letter from family members/ friends living in Australia</li>
<li> Tentative booking for tourist destinations/ activities/ accommodation</li>
<li> Tentative itineraries</li>
<li> Medical reports if visiting an ill family member/ friend living in Australia</li>

</div>
     </div>
     <div className='Working_Things_Avoid_box'>
        <div className='Working_Sub_Title'>Things to avoid</div>
        <div className='Working_desc'>
            <li>Lodge a Visitor Visa if the applicant already has a Visa pending in with the Department of Immigration.</li>
            <li>Lodge a Visitor Visa if the applicant has recently had a visa refusal (Seek professional advice before lodging).</li>
            <li>Book flights before your Visitor Visa is approved! (Most airlines will not refund your ticket if you visa isn’t approved).</li>
            <li>Lodge your application without attaching all required documents. (Evidence, bank statements, passport, etc)</li>
    </div>
     </div>
     </div>
   </div>
        }
         {activeTab === 'Intentions' &&
       <div className='Tabs_container_layout'>
        <div className='WorkingHoliday_Layout'>
     <div className='Working_Title'>
        <div className='Working_desc'>The applicant must demonstrate that they have substantial ties with their home country and will depart Australia after their vacation:
<li>Evidence of current employment for example letter from employer confirming leave approval/ employment contracts/ payslips</li>
<li>Evidence of enrollment in school and information about holiday period</li>
<li>Evidence of ownership of property/land in home country</li>
<li>Evidence of dependent family members for example Family composition book</li>
<li>Evidence of any memberships held in home country</li>

</div>
     </div>
     <div className='Working_Things_Avoid_box'>
        <div className='Working_Sub_Title'>Things to avoid</div>
        <div className='Working_desc'>
            <li>Lodge a Visitor Visa if the applicant already has a Visa pending in with the Department of Immigration.</li>
            <li>Lodge a Visitor Visa if the applicant has recently had a visa refusal (Seek professional advice before lodging).</li>
            <li>Book flights before your Visitor Visa is approved! (Most airlines will not refund your ticket if you visa isn’t approved).</li>
            <li>Lodge your application without attaching all required documents. (Evidence, bank statements, passport, etc)</li>
    </div>
     </div>
     </div>
        </div>
        }
 <VisaBookNow 
        img={WorkingImg}
        Title="Not sure how to proceed or have questions about the Temporary Graduate Visa?"
        Desc="If you aren’t quite sure how to proceed, book a consultation with one of our registered migration agents today."
        Btn="Book now" 
        />
      <Footer />
    </div>
  )
}

export default VisitorVisa