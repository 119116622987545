import { auth } from "../config/fireBase";
import React, { useEffect, useState } from "react";
import { txtDB } from "../config/fireBase";
import {  collection,  getDocs } from "firebase/firestore";
import {
    signOut,
  } from "firebase/auth";
  
import { useNavigate } from "react-router-dom";

function DisplayPage(props) 
{
    const {user, setUser} = props;
    const navigate = useNavigate();
    const [ , setDisplay] = useState(false);
    const [loading, setLoading] = useState(false);
  
    const [popupData, setPopupData] = useState({
        isOpen: false,
        index: 0,
      });
  const openImageModal = (index) => {
    setPopupData({
      isOpen: true,
      index,
    });
  };
  
  const closeImageModal = () => {
    setPopupData({
      isOpen: false,
      index: 0,
    });
  };
 
    const logout = async () => {
        try {
            setUser();
          await signOut(auth);
          console.log(auth);
          setDisplay(false);
  
        } catch (err) {
          console.error(err);
        }
      };
      const [data,setData] = useState([])
      
      const getData = async () => {
        setLoading(true);
        try {
          const valRef = collection(txtDB, 'txtData');
          const dataDb = await getDocs(valRef);
          const allData = dataDb.docs.map((val) => ({ ...val.data(), id: val.id }));
          setData(allData);
          console.log(allData);
           setDisplay(true);
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      };
      
      useEffect(() => {
        const fetchData = async () => {
          setLoading(true);
          await getData();
          setLoading(false);
        };
      
        fetchData();
      }, []);
  
  return (
    <div>
          
          {data.map((value ,index) => (
                 <div key={value.id} className="project-detail-container-content">
                   <div> <img src={value.imgUrl} className="project-detail-img"  alt="Uploaded" /></div>
                <div>{data.title}</div>
                <div className="project-detail-desc"> {value.txtVal}</div>

                 </div>
               ))}
    </div>
  )
}

export default DisplayPage