import React, { useState } from 'react'
import DottedLines from '../images/DottedLines.png'
import NavBarComponent from '../components/NavBarComponent';
import HeroBanner from '../components/HeroBanner';
import SkilledVisa491Banner from "../images/WomenHoldingApplication.mp4"
import ConsultationComponent from '../components/ConsultationComponent';
import Footer from '../components/Footer';
import Heading from '../components/Heading';
import "../styles/FamilyVisa.css"
import "../styles/Family491.css"

function SkilledVisa491() {
  const [activeTab, setActiveTab] = useState('Overview');
  const handleTabClick = tabName => {
    setActiveTab(tabName);
  };
  return (
    <div>
       <NavBarComponent />
    <HeroBanner
   heroHeading='491 Visa'
   heroSubHeading='Skilled Work Regional (Provisional) Subclass 491 visa'
   connectButton='Let’s Connect'
    Banner={[SkilledVisa491Banner]}
    DottedLines={DottedLines}
  /> 
  <div className="TabsContainer">
  <div
    className={`Tab ${activeTab === 'Overview' ? 'isActive' : ''}`}
    onClick={() => handleTabClick('Overview')}
  >
    Overview
  </div>
  <div
    className={`Tab ${activeTab === 'Eligibility' ? 'isActive' : ''}`}
    onClick={() => handleTabClick('Eligibility')}
  >
    Eligibility
  </div>
  <div
    className={`Tab ${activeTab === 'ProcessingTime' ? 'isActive' : ''}`}
    onClick={() => handleTabClick('ProcessingTime')}
  >
   Processing Time
  </div>
  <div
    className={`Tab ${activeTab === 'family' ? 'isActive' : ''}`}
    onClick={() => handleTabClick('family')}
  >
   Including family
  </div>
  <div
    className={`Tab ${activeTab === 'Similar' ? 'isActive' : ''}`}
    onClick={() => handleTabClick('Similar')}
  >
   Similar Visas
  </div>
  
</div>
{activeTab === 'Overview' &&
       <div className='Tabs_container_layout'>
           <Heading heading="What is the 491 Visa" />
        <div className='VisaFamily_Box'>
        The 491 visa allows skilled workers live in a regional area of Australia for up to five years.
You must be invited to apply for this visa. To do so, you must submit an Expression of Interest (EOI)          
                  </div>
                  <div className='AboutUsVisa_box_fields'>
        <div className='AboutUsVisa_box_fields_content_Skilled'>
        <div className='AboutUsVisa_box_title'>Health & Character</div>
          <div className='AboutUsVisa_box_desc'>You must meet the health and character requirements (Unless you are exempt). Read More</div>
        </div>
        <div className='AboutUsVisa_box_fields_content_Skilled'>
        <div className='AboutUsVisa_box_title'>Nomination or Sponsorship </div>
          <div className='AboutUsVisa_box_desc'>You must be nominated by an Australian state or territory government agency or be sponsored by an eligible relative.</div>
        </div>
        <div className='AboutUsVisa_box_fields_content_Skilled'>
          <div className='AboutUsVisa_box_title'>Score 65 pointsr</div>
          <div className='AboutUsVisa_box_desc'>You must score 65 points in order to receive an invitation to apply for this visa. Calculate your points</div>
          </div>
                </div>
                <div className='AboutUsVisa_box_fields'>
                <div className='AboutUsVisa_box_fields_content_Skilled'>
        <div className='AboutUsVisa_box_title'>Score 65 points</div>
          <div className='AboutUsVisa_box_desc'>You must score 65 points in order to receive an invitation to apply for this visa. Calculate your points</div>
        </div>
        <div className='AboutUsVisa_box_fields_content_Skilled'>
        <div className='AboutUsVisa_box_title'>Skill Assessment </div>
          <div className='AboutUsVisa_box_desc'>You must have a positive Skill Assessment, in your nominated occupation, from the relevant assessing authority unless you are exempt.</div>
        </div>
        <div className='AboutUsVisa_box_fields_content_Skilled'>
          <div className='AboutUsVisa_box_title'>English</div>
          <div className='AboutUsVisa_box_desc'>You must have at least Competent English to apply for this visa.</div>
          </div>
                </div>
                
                <div className='AboutUsVisa_box_fields_content'>
          <div className='AboutUsVisa_box_title'>Age</div>
          <div className='AboutUsVisa_box_desc'>You must be aged under 45 to be invited to apply for the visa, meaning, you can still apply for the visa if you turn 45 after receiving the invitation.If you turn 45 after submitting your EOI and you haven’t received an invitation, you will not one.
          </div>
        </div>
        </div>
}
{activeTab === 'Eligibility' &&
       <div className='Tabs_container_layout'>
           <Heading heading="Applying for a 491 (Provisional) Visa" />
         <div className='VisaFamily_Box'>
         You may be able to apply for a 491 Visa if you meet the following requirements:
                            </div>

        <div className='AboutUsVisa_box_fields'>
        <div className='AboutUsVisa_box_fields_content'>
        <div className='AboutUsVisa_box_title'>English</div>
          <div className='AboutUsVisa_box_desc'>You can provide evidence of at least competent English, however, additional points will be awarded for proficient and superior English scores</div>
        </div>
        <div className='AboutUsVisa_box_fields_content'>
        <div className='AboutUsVisa_box_title'>Age </div>
          <div className='AboutUsVisa_box_desc'>You must be aged under 45 to be invited to apply for the visa, meaning, you can still apply for the visa if you turn 45 after receiving the invitation</div>
        </div>
        <div className='AboutUsVisa_box_fields_content'>
          <div className='AboutUsVisa_box_title'>Health & Character</div>
          <div className='AboutUsVisa_box_desc'>You must meet the health & character requirements</div>
          </div>
        </div>
        <div className='Visa491_Eligibility_Box_field'>
<div className='AboutUsVisa_box_fields_content'>
          <div className='AboutUsVisa_box_title'>OCCUPATION & SKILLS</div>
          <div className='AboutUsVisa_box_desc'>
          Your occupation must be on the relevant list of eligible skilled occupations.
You must perform a successful skill assessment through the authorised assessing authority to show that you have the skills and qualifications to work in your chosen occupation.
          </div>
        </div>
        <div className='AboutUsVisa_box_fields_content'>
          <div className='AboutUsVisa_box_title'>POINTS TEST & INVITATION</div>
          <div className='AboutUsVisa_box_desc'>You must lodge an Expression of Interest (EOI) and be invited to apply for this visa
You must score a minimum of 65 points to be invited to apply, however, invitations are often issued to applicants with more than 65 points.
The most recent invitation round outlines the point scores which attracted invitations. There are also some occupations which require more than 65 points to be considered.
You must meet the points score eligibility at the time you are invited to apply for the visa.
          </div>
        </div>
        </div>
        </div>
}
{activeTab === 'ProcessingTime' &&
       <div className='Tabs_container_layout'>
          <Heading heading="How long will it take to get a decision on your 491 visa?" />
         <div className='SkilledVisa189_part2_fields'>
           <div className='SkilledVisa189_part2_Box'>
           <div className='AboutUsVisa_box_title'>Skilled Work Regional (provisional) visa (subclass 491) – State/Territory Government Nominated Regional:</div>
           <div className='AboutUsVisa_box_desc'>75% of visas are processed within 58 days
90% of visas are processed within 76 days.
</div>
              </div>
             <div className='SkilledVisa189_part2_Box'>
           <div className='AboutUsVisa_box_title'>Skilled Work Regional (provisional) visa (subclass 491)– Family Sponsored Regional:</div>
           <div className='VisaFamily_Box_desc'>75% of visas are processed within 57 days
90% of visas are processed within 71 days </div>
              </div>
        </div>
        </div>
}
{activeTab === 'family' &&
       <div className='Tabs_container_layout'>
        <Heading heading="Include your family in the Subclass 491 Visa" />
          <div className='VisaFamily_Box'>
          Applicants can include the following people in their 491 visa application:
                   </div>
        <div className='SkilledVisa189_part2_fields'>
           <div className='SkilledVisa189_part2_Box'>
           <div className='AboutUsVisa_box_title'>PARTNERS</div>
           <div className='AboutUsVisa_box_desc'>Your married or de facto partner can be the same or opposite sex. Applicants must prove:
           <li> the relationship is genuine and continuing</li>
 <li>you live together, or do not live separately on a permanent basis.</li>
<li> you partner is at least 18 years of age when the application is  lodged (there are some exceptions)</li>
 <li>you are not related by family (if you are in a de facto relationship)</li>
 <li>you and your partner have a mutual commitment to a shared life to the exclusion of all others</li>
                 </div>
              </div>
             <div className='SkilledVisa189_part2_Box'>
           <div className='AboutUsVisa_box_title'>CHILDREN</div>
           <div className='VisaFamily_Box_desc'>To be eligible to be included or added to this visa, a child must:

<li>be your child or a stepchild from a current or a previous relationship (in certain circumstances);</li> 
<li>not be married, engaged to be married, or have a de facto partner; and must be:</li> 
<li> under 18 years of ageor</li> 
<li>over 18 years of age but not yet turned 23, and be dependent on you or your partneror</li> 
<li>over 23 years of age and be unable to earn a living to support themselves due to physical or cognitive limitations and be dependent on you or your partner (Note: The child will still need to meet Australia’s health requirement)or</li> 
<li>a dependent child of a child who is eligible under 1, 2 or 3 above.</li> </div>
              </div>
        </div>
        <div className='AboutUsVisa_box_fields_content'>
        <div className='AboutUsVisa_box_title'>HEALTH & CHARACTER:</div>
          <div className='AboutUsVisa_box_desc'>All family members must meet health and character requirements. All applicants over 16 must provide police clearances.
             </div>
        </div>
      
        </div>
}
{activeTab === 'Similar' &&
    <div className='Tabs_container_layout'>
        <div className='VisaFamily_Box'>If you would like to apply for another employer-sponsored or skilled visa, you may be eligible to apply for one of the below visas:
        <span style={{fontFamily:"Mulish",fontSize:"16px", color:"rgba(132, 132, 132, 1)"}}><li>482 TSS Visa</li>
        <li>186 Visa (ENS)</li>
        <li>189 Visa</li>
        <li>190 Visa</li>
        <li>494 Visa</li>
        </span>
        </div>
       <div className='VisaFamily_Box'>If you would like more information about the 491 visa subclass or to discuss your Australian visa eligibility with a registered migration agent, click here to book a consultation. </div>
    </div>
}
<div className='ConsultationComponent_field'>
            <ConsultationComponent
            containerColor="rgba(255, 247, 241, 1)"
            title="View other visa options"
            description="Perhaps there is an alternative visa option you could explore? Use our Visa Finder to conduct your research.   "
            button="Consult"
            />
             <ConsultationComponent 
             containerColor="rgba(255, 247, 241, 1)"
            title="Book A Consultation"
            description="If you would like to discuss your Australian visa eligibility with a registered migration agent, book your consultation now."
            button="Consult"
            />
             <ConsultationComponent
             containerColor="rgba(255, 247, 241, 1)"
            title="Get A Quote"
            description="Know what visa you want to apply for but need some pricing? Get in touch with us and we will get back to you with a fixed price quote.            "
            button="Get a quote"
            />
            </div> 
            <Footer />
      
    </div>
  )
}

export default SkilledVisa491