import React from 'react'
import Navbar from '../components/Navbar'
import PermanentEmpBanner from '../components/PermanentEmpBanner'
import DottedLines from '../images/DottedLines.png'
import Passport from '../images/passport.png'
import Footer from '../components/Footer'
import Visa186FamComp from '../components/Visa186FamComp'
import howitworks from '../images/howitworks.png'
import Visa186How from '../components/Visa186How'

const Visa186Family = () => {
  return (
    <>
      <Navbar />
      <PermanentEmpBanner
        heroHeading='Employer Nomination Scheme ENS (Subclass 186 Visa)'
        connectButton='Let’s Connect'
        Banner={Passport}
        DottedLines={DottedLines}
      />
      <Visa186FamComp
        whyUsHeadingMain='Include your family in your 186 Visa'
        eligibilitysubHeading='186 visa allows holders to be able to include the below family members to their application'
        eligibilitysubHeading1='Dependent children/step-children'
        eligibilitysubHeading2='Partner’s dependent child or children'
        eligibilitysubHeading3='Partner'
        whyUsHeading5='Children'
        whyUsDesc5='To be eligible to be included or added to a 186 visa, a child must:'
        whyUsDesc51='Be your child or a stepchild from a current or a previous relationship (in certain circumstances)'
        whyUsDesc52='Not be married, engaged to be married, or have a de facto partner; and must be:'
        whyUsDesc53='Under 18 years of ageor'
        whyUsDesc54='Over 18 years of age but not yet turned 23, and be dependent on you or your partneror'
        whyUsDesc55='Over 23 years of age and be unable to earn a living to support themselves due to physical or cognitive limitations and be dependent on you or your partner (Note: The child will still need to meet Australia’s health requirement)or'
        whyUsDesc56='A dependent child of a child who is eligible under 1, 2 or 3 above.'
        whyUsHeading1='English'
        whyUsHeading2='Age'
        whyUsDesc1='Applicants over 18 must demonstrate Functional English. However, you can choose to pay an additional application fee to the department for family members who have less than functional English.'
        whyUsDesc2='Children 23 years and over cannot be included in your application. (Unless there are exceptional circumstances)'
        whyUsHeading3='Partners'
        whyUsHeading4='Health & Character'
        whyUsDesc31='Your married or de facto partner can be the same or opposite sex. Applicants must prove:'
        whyUsDesc32='The relationship is genuine and continuing'
        whyUsDesc33='You live together, or do not live separately on a permanent basis.'
        whyUsDesc34='Your partner is at least 18 years of age when the application is lodged (there are some exceptions)'
        whyUsDesc35='You are not related by family (if you are in a de facto relationship)'
        whyUsDesc36='You and your partner have a mutual commitment to a shared life to the exclusion of all others'
        whyUsDesc41='All family members must meet the health and character requirements. All applicants over 16 must provide police clearances.'
        whyUsDesc42='If you already hold a permanent 186 (ENS) Visa and you would like to bring your family to Australia, you will need to apply for one of the family visas.'
      />
      <Visa186How
        EmpHowHeading='How it works'
        EmpHowImage={howitworks}
        EmpHowSubHead1='Step 1'
        EmpHowSubHead2='Step 2'
        EmpHowSubHead3='Step 3'
        EmpHowSubHead4='Step 4'
        EmpHowSubHead5='Step 5'
        EmpHowDesc1='You have an Australian employer who is willing to sponsor you.'
        EmpHowDesc2='Our migration agents liaise with you and your employer to collect documents.'
        EmpHowDesc3='We lodge your nomination and visa application with the Department.'
        EmpHowDesc4='We wait until a decision is made on your nomination and visa application.'
        EmpHowDesc5='You are expected to work for your employer for 2 years after approval.'
      />
      <Footer />
    </>
  )
}

export default Visa186Family