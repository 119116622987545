import React from 'react'
import "../styles/FamilyComp.css"
import { Divider } from '@mui/material'
function FamilyLayoutComp() {
  return (
    <div className='family_layout_comp_container'>
        <div className='family_layout_comp_container_left'>
            <div className='family_comp_left_fields'>
            <div className='family_comp_left_box' >
            <div className='family_comp_title'>Operations</div>
            <div className='family_comp_desc'>
                <li>To become an approved sponsor, the business must be legally established in Australia and currently operating. The business can be in or outside Australia.</li>
                </div>
                <Divider />
                <div className='family_comp_title'>TSS Nomination</div>
            <div className='family_comp_desc'>
                <li>An approved Business Sponsor can nominate a position within their business to be filled by an overseas skilled worker. There are strict criteria which must be met in order to receive a successful Nomination outcome.</li>
                </div>
            </div>
       
            <div className='family_comp_left_box' >
            <div className='family_comp_title'>Operations</div>
            <div className='family_comp_desc'>
                <li>To become an approved sponsor, the business must be legally established in Australia and currently operating. The business can be in or outside Australia.</li>
                </div>
                <Divider />
                <div className='family_comp_title'>TSS Nomination</div>
            <div className='family_comp_desc'>
                <li>An approved Business Sponsor can nominate a position within their business to be filled by an overseas skilled worker. There are strict criteria which must be met in order to receive a successful Nomination outcome.</li>
                </div>
            </div>
            </div>
            <div className='family_comp_left_fields_extraly'>
            <div className='family_comp_title'>Labour Market Testing (LMT)</div>
            <div className='family_comp_desc'><li>The business must demonstrate that they have tested the labour market by advertising locally for the nominated position.</li></div>
            </div>

        </div>
        <div className='family_layout_comp_container_right'>
            <div className='family_comp_title'>Training Levy</div>
            <div className='family_comp_desc'>
                <li>The new Skilling Australians Fund (SAF) levy has been introduced with the TSS Visa.</li>
                <li>The payable levy is determined by the turnover of the nominating business:</li>
                <li>Small Businesses (Turnover less than $10 million) will pay $1,200 per year, per nominated TSS Visa holderOther Businesses (Turnover more than $10 million) will pay $1,800 per year, per nominated TSS Visa holderFor example, a business with a turn over $10M nominates an applicant for a four year TSS Visa would pay $7,200 upfront at the nomination stage.</li>
                <li>What is the Training Levy (SAF)?The Department states that the SAF levy provides a tangible demonstration of how the TSS visa supports training opportunities for Australians. The business does not receive any training by paying the SAF.</li>
            </div>

        </div>
    </div>
  )
}

export default FamilyLayoutComp