import React from 'react'
import '../styles/HeadOffice.css'
import worldMap from '../images/LandingMap.png'
import australia from '../images/australia.png'
import india from '../images/india.png'
import hydrabadh from "../images/Hydrabadh.png"
import indiaVjay from "../images/vijaywada.png"
import { MapPin, EnvelopeSimple, Phone, WhatsappLogo } from "@phosphor-icons/react";

const HeadOffice = () => {
    return (
        <>
            <div className='headoffice-layout'>
                <div className='head-layout-one'>
                    <div className='headoffice-heading-box'>
                        <div className='headoffice-heading'> Our Offices :</div>
                    </div>
                    <div className='headoffice-heading-box1'>
                        <div className='headoffice-heading'>Our Presence</div>
                    </div>
                    <div className='headoffice-image'>
                        <img className='headoffice-map' src={worldMap} alt='' />
                    </div>
                </div>
                <div className='head-layout-two'>
                    <div className='head-cities'>
                        <div className='head-city-name'>
                            <div className='head-city-image'>
                                <img className='australia' src={australia} alt='' />
                            </div>
                            <div className='head-city-details'>
                                <div className='cityName'><b>Adelaide</b>, Australia</div>
                                <div className='address-style'>
                                    <div style={{ color: '#848484' }}><MapPin size={24} /></div>
                                    <div className='cityAddress'>Level 2, 18-20,Grenfell Street,Adelaide SA 5000</div>
                                </div>
                                <div className='cityAddress'><EnvelopeSimple size={24} />info@hopegroup.com.au</div>
                                <div className='cityContactDetails'>
                                    <div className='contact-style'>
                                        <div style={{ color: '#848484' }}><Phone size={24} /></div>
                                        <div className='cityAddress11'>0870816999</div>
                                    </div>
                                    <div className='contact-style'>
                                        {/* <div className='whatsapplogo'><WhatsappLogo size={24} /></div> */}
                                        <div className='cityAddress22'> +61 404597999</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='head-city-name1'>
                            <div className='head-city-image'>
                                <img className='india' src={india} alt='' />
                            </div>
                            <div className='head-city-details'>
                                <div className='cityName'><b>Bengaluru </b>, India</div>
                                <div className='address-style'>
                                    <div style={{ color: '#848484' }}><MapPin size={24} /></div>
                                    <div className='cityAddress'>143, 5th Main Rd, Binnamangala, Stage 1, Indiranagar, Bengaluru, Karnataka 560038</div>
                                </div>
                                <div className='cityAddress'><EnvelopeSimple size={24} />Bengaluru@hopegroup.com.au.</div>
                                <div className='cityContactDetails1'>
                                    <div className='contact-style'>
                                        <div style={{ color: '#848484' }}><Phone size={24} /></div>
                                        <div className='cityAddress1'>+91 96639 61038</div>
                                    </div>
                                    {/* <div className='contact-style'>
                                    <div className='whatsapplogo'><WhatsappLogo size={24} /></div>
                                        <div className='cityAddress2'>040-40158546</div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='head-cities1'>
                        <div className='head-city-name'>
                            <div className='head-city-image'>
                                <img className='india' src={india} alt='' />
                            </div>
                            <div className='head-city-details'>
                                <div className='cityName'><b>Bengaluru </b>, India</div>
                                <div className='address-style'>
                                    <div style={{ color: '#848484' }}><MapPin size={24} /></div>
                                    <div className='cityAddress'>143, 5th Main Rd, Binnamangala, Stage 1, Indiranagar, Bengaluru, Karnataka 560038</div>
                                </div>
                                <div className='cityAddress'><EnvelopeSimple size={24} />Bengaluru@hopegroup.com.au.</div>
                                <div className='cityContactDetails1'>
                                    <div className='contact-style'>
                                        <div style={{ color: '#848484' }}><Phone size={24} /></div>
                                        <div className='cityAddress1'>+91 96639 61038</div>
                                    </div>
                                    {/* <div className='contact-style'>
                                    <div className='whatsapplogo'><WhatsappLogo size={24} /></div>
                                        <div className='cityAddress2'>040-40158546</div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='head-cities'>
                        <div className='head-city-name'>
                            <div className='head-city-image'>
                                <img className='australia' src={hydrabadh} alt='' />
                            </div>
                            <div className='head-city-details'>
                                <div className='cityName'><b>Hyderabad</b>, India</div>
                                <div className='address-style'>
                                    <div style={{ color: '#848484' }}><MapPin size={24} /></div>
                                    <div className='cityAddress'>1210/1, floor 11th,Vasavi MPM grand, Yellareddy Guda Road,Ameerpet, Hyderabad.</div>
                                </div>
                                <div className='cityAddress'><EnvelopeSimple size={24} />hyderabad@hopegroup.com.au </div>
                                <div className='cityContactDetails'>
                                    <div className='contact-style'>
                                        <div style={{ color: '#848484' }}><Phone size={24} /></div>
                                        <div className='cityAddress11'>8259994999 | 040-40158546</div>
                                    </div>
                                    {/* <div className='contact-style'>
                                        <div className='whatsapplogo'><WhatsappLogo size={24} /></div>
                                        <div className='cityAddress22'> +61 404597999</div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        <div className='head-city-name1'>
                            <div className='head-city-image'>
                                <img className='india' src={indiaVjay} alt='' />
                            </div>
                            <div className='head-city-details'>
                                <div className='cityName'><b>Vijayawada </b>, India</div>
                                <div className='address-style'>
                                    <div style={{ color: '#848484' }}><MapPin size={24} /></div>
                                    <div className='cityAddress'>Sri Sal Nilayam, #8-137, Endowments Colony M.G Road, Kamaiahthopu, Kanuru Vijayawada 920007</div>
                                </div>
                                <div className='cityAddress'><EnvelopeSimple size={24} />vijayawada@hopegroup.com.au</div>
                                <div className='cityContactDetails1'>
                                    <div className='contact-style'>
                                        <div style={{ color: '#848484' }}><Phone size={24} /></div>
                                        <div className='cityAddress1'>+91 9642616755</div>
                                    </div>
                                    {/* <div className='contact-style'>
                                    <div className='whatsapplogo'><WhatsappLogo size={24} /></div>
                                        <div className='cityAddress2'>040-40158546</div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='head-cities1'>
                        <div className='head-city-name'>
                            <div className='head-city-image'>
                                <img className='india' src={indiaVjay} alt='' />
                            </div>
                            <div className='head-city-details'>
                                <div className='cityName'><b>Vijayawada</b>, India</div>
                                <div className='address-style'>
                                    <div style={{ color: '#848484' }}><MapPin size={24} /></div>
                                    <div className='cityAddress'>Sri Sal Nilayam, #8-137, Endowments Colony M.G Road, Kamaiahthopu, Kanuru Vijayawada 920007</div>
                                </div>
                                <div className='cityAddress'><EnvelopeSimple size={24} />vijayawada@hopegroup.com.au</div>
                                <div className='cityContactDetails'>
                                    <div className='contact-style'>
                                        <div style={{ color: '#848484' }}><Phone size={24} /></div>
                                        <div className='cityAddress1'>+91 9642616755</div>
                                    </div>
                                    {/* <div className='contact-style'>
                                        <div className='cityAddress2'>040-40158546</div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HeadOffice