import React, { useState } from 'react'
import '../styles/EnglishModal.css'

import { XCircle } from "@phosphor-icons/react";
const EnglishModal = ({closeModal} ) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };

    // const closeModal = () => {
    //     setIsModalOpen(false);
    // };

    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };
    return (
        <>
            <div className='modalLayout'>
                <div className='modalLayoutMain'>
                <div style={{ textAlign: 'right', paddingTop: '16px', paddingRight: '16px' }}>
                        <XCircle onClick={closeModal} size={32} />
                    </div>
                    <div className='modalStyling'>
                        <table class="simple-table">
                            <tr>
                                <td className='tableTitle1'>Test</td>
                                <td className='tableTitle2'>Required Results</td>
                            </tr>
                            <tr>
                                <td className='tableSubTitle'>IELTS</td>
                                <td className='tableSubDesc'>Overall band score of at least 5.0 with a score of at least 4.5 in each of the test components.</td>
                            </tr>
                            <tr>
                                <td className='tableSubTitle'>OET</td>
                                <td className='tableSubDesc'>Score of at least ‘B’ in each of the four components</td>
                            </tr>
                            <tr>
                                <td className='tableSubTitle'>PTE Academic</td>
                                <td className='tableSubDesc'>Academic overall test score of at least 36 with a score of at least 30 in each of the test components</td>
                            </tr>
                            <tr>
                                <td className='tableSubTitle'>Cambridge</td>
                                <td className='tableSubDesc'>Overall test score of at least 154 with a score of at least 147 in each of the test components.</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EnglishModal