import React from 'react'
import '../styles/PermanentEmpOv.css'
import { useNavigate } from 'react-router-dom';

const PermSecondContent = (props) => {
    const navigate = useNavigate();
    const handleNavigate187 = () => {
        window.scrollTo(0,0);
         navigate('/Visa187')
      }
    return (
        <div className='EmpOverViewLayout'>
            <div className='empOverViewHeadBox'>
                <div>{props.empOVHeading}</div>
            </div>
            <div className='empOVlayout-three' style={{ marginTop: '24px' }}>
                <div className='empOVDesc'>{props.visaSubHeading}</div>
            </div>
            <div className='empOVlayout-two'>
                <div className='empOVboxone'>
                    <div className='empOVsubHead'>{props.empOVsubHead1}</div>
                    <div className='empOVDesc'>
                        <li>{props.empOVDesc1}</li>
                    </div>
                </div>
                <div className='empOVboxone' style={{ height: 'auto' }}>
                    <div className='empOVsubHead'>{props.empOVsubHead2}</div>
                    <div className='empOVDesc'>
                        <li>{props.empOVDesc2}</li>
                        <li>{props.empOVDesc22}</li>
                        <li>{props.empOVDesc222}</li>
                    </div>
                </div>
            </div>
            <div className='empOVlayout-three'>
            <div className='empOVDesc'><u onClick={handleNavigate187} style={{ color: '#B0383D', cursor: 'pointer',fontSize:"24px" }}>Learn More 187 Visa (ENS)</u></div>
            </div>
        </div>
    )
}

export default PermSecondContent