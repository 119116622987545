import React from 'react'
import "../styles/howComponent187.css"

function HowComponent187(props) {
  return (
    <div>  <div className='VisaHow187Layout'>
    <div className='VisaHowHeadBox'>
        <div className='VisaHowHeading'>{props.EmpHowHeading}</div>
    </div>
    <div className='VisaHowImage'>
        <img className='VisaHow-Image' src={props.EmpHowImage} alt='howImage' />
    </div>
    <div className='VisaHowSubLayout'>
        <div className='VisaHow-SubLayout'>
            <div className='VisaHowSubHead'>{props.EmpHowSubHead1}</div>
            <div className='VisaHowDesc'>{props.EmpHowDesc1}</div>
        </div>
        <div className='VisaHow-SubLayout'>
            <div className='VisaHowSubHead'>{props.EmpHowSubHead2}</div>
            <div className='VisaHowDesc'>{props.EmpHowDesc2}</div>
        </div>
        <div className='VisaHow-SubLayout'>
            <div className='VisaHowSubHead'>{props.EmpHowSubHead3}</div>
            <div className='VisaHowDesc'>{props.EmpHowDesc3}</div>
        </div>
    </div>
    <div className='VisaHowSubLayout2'>
        <div className='VisaHow-SubLayout'>
            <div className='VisaHowSubHead'>{props.EmpHowSubHead4}</div>
            <div className='VisaHowDesc'>{props.EmpHowDesc4}</div>
        </div>
        <div className='VisaHow-SubLayout'>
            <div className='VisaHowSubHead'>{props.EmpHowSubHead5}</div>
            <div className='VisaHowDesc'>{props.EmpHowDesc5}</div>
        </div>
    </div>
    {/* <div className='VisaHowSubLayout3'>
        <div className='VisaFindEmpHead'>Find an Employer</div>
        <div className='VisaFindEmpDesc'>If you are looking for an Australian employer in a regional area to sponsor you for permanent residency, go to our current vacancies page to see if we have a suitable position for you.</div>
        <div className='VisaFindEmpButton'><button className='VisaFindView' type='button'>View job board</button></div>
    </div> */}
</div></div>
  )
}

export default HowComponent187