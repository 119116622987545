import React from 'react'
import "../styles/ConsultationComponent.css"
import { useNavigate } from 'react-router-dom';
function ConsultationComponentTools(props) {
  const navigate = useNavigate();
  const handleButtonClick = (onClick) => {
    if (typeof onClick === 'function') {
      onClick();
    } else {
      console.error('Invalid onClick function provided');
    }
  };
  const containerStyle = {
    backgroundColor: props.containerColor || '#F4F4F4', 
    color: props.textColor || '#014A91', 
  };
  return (
    <div className='ConsultationComponent_component_Tools'  style={containerStyle}>
        <div className='ConsultationComponent_component_title'>{props.title}</div>
        <div  className='ConsultationComponent_component_description'>{props.description}</div>
        <div className='ConsultationComponent_component_button' onClick={() => handleButtonClick(props.onClick1)}>{props.button}</div>

    </div>
  )
}

export default ConsultationComponentTools