import React, { useState } from 'react'
import DottedLines from '../images/DottedLines.png'
import House from "../images/House.mp4"
import NavBarComponent from '../components/NavBarComponent'
import HeroBanner from '../components/HeroBanner';
import "../styles/SkilledVisa191.css"
import Visa191HeadComp from '../components/Visa191HeadComp';
import ConsultationComponent from '../components/ConsultationComponent';
import Footer from '../components/Footer';

function SkilledVisa191() {
  const [activeTab, setActiveTab] = useState('Overview');
  const handleTabClick = tabName => {
    setActiveTab(tabName);
  };
  return (
    <div>
      <NavBarComponent />
      <HeroBanner
     heroHeading='491 Visa'
   heroSubHeading='Skilled Work Regional (Provisional) Subclass 491 visa'
   connectButton='Let’s Connect'
    Banner={[House]}
    DottedLines={DottedLines}
  /> 
    <div className="TabsContainer">
  <div
    className={`Tab ${activeTab === 'Overview' ? 'isActive' : ''}`}
    onClick={() => handleTabClick('Overview')}
  >
    Overview
  </div>
  <div
    className={`Tab ${activeTab === 'Eligibility' ? 'isActive' : ''}`}
    onClick={() => handleTabClick('Eligibility')}
  >
    Eligibility
  </div>
  <div
    className={`Tab ${activeTab === 'Similar' ? 'isActive' : ''}`}
    onClick={() => handleTabClick('Similar')}
  >
   Similar Visas
  </div>
  <div
    className={`Tab ${activeTab === 'family' ? 'isActive' : ''}`}
    onClick={() => handleTabClick('family')}
  >
   Including family
  </div>
   </div>
   {activeTab === 'Overview' &&
    <div className='Tabs_container_layout'>
      <div className='Skilled_visa_component_field'>
     <Visa191HeadComp
     Title="The subclass 191 skilled regional visa will be open to new applications in November 2022"
     Desc1="Live and work in Australia indefinitely"
     Desc2="Include immediate family on their 191 Visa"
     Desc3="Live, work and study only in regional areas"
     Desc4="Sponsor eligible family members"
     />
      <Visa191HeadComp
     Title="Important:"
     containerColor="rgba(255, 247, 241, 1)"
     textColor="rgba(176, 56, 61, 1)"
     textColor2="rgba(59, 59, 59, 1)"
     containerColor2="rgba(255, 247, 241, 1)"
     Desc1="Applicants who earn $53,900 and claim deductions through their tax return will no longer be eligible to apply, as their taxable income will fall before the threshold."

     />
     </div>
     </div>
      }
      {activeTab === 'Eligibility' && 
         <div className='Tabs_container_layout'>
          
           <Visa191HeadComp
     Title="We will provide further information about the specific requirements of this new visa"
     Desc1="Held a 491 or 494 visa for at least three years"
     Desc2="Complied with the conditions of the regional provisional visas"
     Desc3="Be inside Australia to apply"
     Desc4="Applicants are required to have a taxable income of at least the minimum amount specified in the legislative instrument (Currently $53,900)."
     />
        </div>
        }
        {activeTab === 'Similar' && 
         <div className='Tabs_container_layout'>
           <Visa191HeadComp
     Title="The Subclass 191 Permanent Residence (Skilled Regional) Visa will be open to applications in November 2022."
     Desc1="If you would like to apply for a permanent visa sooner, you may be eligible to apply for one of the below permanent visas instead:"
     Desc2="186 Visa(ENS) , 187 VISA(RSMS)DE CLOSED"
     Desc3="189 Visa"
     Desc4="190 Visa"
     />
        </div>
        }
        {activeTab === 'family' && 
         <div className='Tabs_container_layout'>
           <Visa191HeadComp
     Title="Include your family"
     Desc1="At this time, we know that you will be able to sponsor eligible family members. We will update this section with relevant information closer to application commencement date."
   
     />
        </div>
        }


<div className='ConsultationComponent_field'>
            <ConsultationComponent
            containerColor="rgba(255, 247, 241, 1)"
            title="View other visa options"
            description="Perhaps there is an alternative visa option you could explore? Use our Visa Finder to conduct your research.  "
            button="Consult"
            />
             <ConsultationComponent 
             containerColor="rgba(255, 247, 241, 1)"
            title="Book A Consultation"
            description="If you would like to discuss your Australian visa eligibility with a registered migration agent, book your consultation now."
            button="Consult"
            />
             <ConsultationComponent
             containerColor="rgba(255, 247, 241, 1)"
            title="Get A Quote"
            description="Know what visa you want to apply for but need some pricing? Get in touch with us and we will get back to you with a fixed price quote.            "
            button="Get a quote"
            />
            </div> 
            <Footer />
    </div>
  
  )
}

export default SkilledVisa191