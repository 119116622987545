import React from 'react'
import "../styles/IndividualVisa.css"

function IndividualWhat() {
  return (
    <div className='IndividualWhat_layout'>
        <div className='Individual_title'>What do you want to do in Australia?</div>
        <div className='Individual_Box_field'>
            <div className='Individual_Box'>
                <div className='Individual_Box_title'>Just travel around</div>
                <div className='Individual_Box_desc'>For those who want to visit without conducting business or working.Visitor Visa</div>
            </div>
            <div className='Individual_Box'>
                <div className='Individual_Box_title'>Get a degree</div>
                <div className='Individual_Box_desc'>For individuals who are looking to get a diploma or degree in Australia.Student Visa</div>
            </div>
            <div className='Individual_Box'>
                <div className='Individual_Box_title'>Work after studies</div>
                <div className='Individual_Box_desc'>For Post-Grads who would like to aquire some work experience.Temp Grad Visa</div>
            </div>
        </div>
        <div className='Individual_Box_field'>
            <div className='Individual_Box'>
                <div className='Individual_Box_title'>Mix work & travel</div>
                <div className='Individual_Box_desc'>For young individuals who want to earn money while travelling.417 Visa</div>
            </div>
            <div className='Individual_Box'>
                <div className='Individual_Box_title'>Do business</div>
                <div className='Individual_Box_desc'>For professionals who conduct business during their stay.188 Visa</div>
            </div>
            <div className='Individual_Box'>
                <div className='Individual_Box_title'>Become a citizen</div>
                <div className='Individual_Box_desc'>For everyone whose goal it is to hold an Australian passport.Citizenship</div>
            </div>
        </div>
        
        
    </div>
  )
}

export default IndividualWhat