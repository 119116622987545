import React, { useState } from 'react'
import Herobanner1 from '../images/WomenHandFilling.mp4';
import DottedLines from '../images/DottedLines.png'
import HeroBanner from '../components/HeroBanner';
import NavBarComponent from '../components/NavBarComponent';
import Footer from '../components/Footer';
import "../styles/Working.css"
import Heading from '../components/Heading';
function TempGraduateVisa() {
    const [activeTab, setActiveTab] = useState('Overview');

    const handleTabClick = tabName => {
      setActiveTab(tabName);
    };
  return (
    <div>
         <NavBarComponent />
        <HeroBanner
        heroHeading='Working Holiday Visa (Subclass 417)'
        heroSubHeading='Below visas will give you the option of temporarily migrating to Australia to live and work. Please note that you must meet the respective eligibility criteria.'
        connectButton='Let’s Connect'
        Banner={[Herobanner1]}
        DottedLines={DottedLines}
      />
         <div className="TabsContainer">
      <div
        className={`Tab ${activeTab === 'Overview' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('Overview')}
      >
        Overview
      </div>
      <div
        className={`Tab ${activeTab === 'Eligibility' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('Eligibility')}
      >
        Eligibility
      </div>
      <div
        className={`Tab ${activeTab === 'Stream' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('Stream')}
      >
       Visa Stream
      </div>
      <div
        className={`Tab ${activeTab === 'Times' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('Times')}
      >
       Processing Times
      </div>
      <div
        className={`Tab ${activeTab === 'Family' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('Family')}
      >
        Including Family
      </div>
      </div>
      {activeTab === 'Overview' &&
       <div className='Tabs_container_layout'>
      
        <div className='WorkingHoliday_Layout'>
        <Heading heading="Temporary Graduate Visa" containerColor="rgba(255, 247, 241, 1)" textColor="rgba(186, 81, 85, 1)"/>
     <div className='Working_Title'>
        <div className='Working_Sub_Title'>The Temporary Graduate (Subclass 485) Visa allows international students to live, study and work after their studies.</div>
        <div className='Working_desc'>485 Visa holders can:
<li>live and work in Australia for up to four years</li>
<li>include immediate family on 485 visa application</li>
<li>study in Australia</li>

This visa is a great way to extend your stay in Australia to gain work experience in your field and potentially apply for a skilled or employer sponsored visa.

There are two streams you may be eligible to apply for:</div>
      </div>
      <div className='Working_Title'>
      <div className='Working_Sub_Title'>  The Graduate Work Stream , The Post-Study Work Stream</div>
        <div className='Working_desc'>If you would like to apply for a Temporary Graduate (Subclass 485) Visa, speak to one of our registered migration agents about your eligibility and let us assist you with your application.

If you aren’t sure if you meet the requirements for this visa, book a consultation to discuss your eligibility and possible visa options or to start planning your pathway to permanent residency.

    </div>
     </div>
     </div>
        </div>
        }
         {activeTab === 'Eligibility' &&
       <div className='Tabs_container_layout'>
      
        <div className='WorkingHoliday_Layout'>
        <Heading heading="Temporary Graduate Visa" containerColor="rgba(255, 247, 241, 1)" textColor="rgba(186, 81, 85, 1)"/>
     <div className='Working_Title'>
        <div className='Working_Sub_Title'>International students who have recently graduated may be eligible to apply for a Temporary Graduate (485) Visa</div>
      </div>
      <div className='Working_Title'>
        <div className='Working_desc'>You must meet the following requirements:
<li>you are under 50 years of age</li>
<li>you hold an eligible visa</li>
<li>you have a recent qualification in a CRICOS-registered course or;</li>
<li>you have a qualification relevant to an occupation on the Skilled occupation list.</li>
    </div>
     </div>
     </div>
        </div>
        }
        {activeTab === 'Stream' &&
       <div className='Tabs_container_layout'>
      
        <div className='WorkingHoliday_Layout'>
        <Heading heading="Temporary Graduate Visa" containerColor="rgba(255, 247, 241, 1)" textColor="rgba(186, 81, 85, 1)"/>
     <div className='Working_Title'>
        <div className='Working_Sub_Title'>There are two visa stream for the 485 visa: Graduate Work Stream </div>
        <div className='Working_desc'>This temporary graduate visa stream is for international students who have recently graduated and have qualifications relevant to occupations on the Australian skilled occupation lists. This visa lets you live, study and work in Australia temporarily.
       <li>You can work in Australia</li>
<li>You can study in Australia</li>
<li>Have a qualification relevant to an occupation on the skilled occupation list</li>
<li>You can bring your family with you</li>
<li>You can stay for up to 18 months</li>


</div>
      </div>
      <div className='Working_Title'>
      <div className='Working_Sub_Title'>Post-Study Work Stream</div>
        <div className='Working_desc'>This visa is for international students who have recently graduated with a degree or higher from an Australian institution. This visa lets you live, work and study in Australia, temporarily.
        <li>You can work in Australia</li>
        <li>You can study in Australia</li>
        <li>You can bring your family with you</li>
        <li> You need a recent degree a CRICOS-registered course</li>
        <li>You can stay between 2 and 4 years, depending on your qualification</li>
Masters by Coursework or Bachelor Degree: 2 years
Masters by Research: 3 years
PhD: 4 years
    </div>
     </div>
     </div>
        </div>
        }
          {activeTab === 'Times' &&
       <div className='Tabs_container_layout'>
      
        <div className='WorkingHoliday_Layout'>
        <Heading heading="Temporary Graduate Visa" containerColor="rgba(255, 247, 241, 1)" textColor="rgba(186, 81, 85, 1)"/>
     <div className='Working_Title'>
        <div className='Working_Sub_Title'>How long will it take to get a decision on your 485 visa?</div>
        <div className='Working_desc'><h4>GRADUATE WORK </h4>
<li>75% of visas are processed within 5 months</li>
<li>90% of visas are processed within 5 months</li>

<h4>POST-STUDY WORK </h4>
<li>75% of visas are processed within 59 days</li>
<li>90% of visas are processed within 3 months</li>
</div>
      </div>
    
     </div>
        </div>
        }
          {activeTab === 'Family' &&
       <div className='Tabs_container_layout'>
      
        <div className='WorkingHoliday_Layout'>
        <Heading heading="Temporary Graduate Visa" containerColor="rgba(255, 247, 241, 1)" textColor="rgba(186, 81, 85, 1)"/>
     <div className='Working_Title'>
        <div className='Working_Sub_Title'>Including family members</div>
        <div className='Working_desc'>You must meet the following requirements:
<li>Spouse or partner</li>
<li>Dependent children</li>
<li>Dependent children must be under 18, or between 18 and 23 years and financially dependent on the main applicant</li>

Family members must meet the health and character requirements.
You may also add family members to your application after approval
</div>
      </div>
    
     </div>
        </div>
        }
        <Footer />
    </div>
  )
}

export default TempGraduateVisa