import React from 'react'
import '../styles/VisaOption457.css'

const VisaOption457Comp = () => {
  return (
    <>
        <div className='VisaOptionLayoutMain'>
            <div className='VisaOptionHeadBox'>
                <div className='VisaOptionHeading'>457 to PR Visa Options</div>
            </div>
            <div className='VisaOptionSubLayout'>
              <div className='VisaOptionDesc'>
                  <u style={{color:'#B0383D', cursor:'pointer'}}>
                  <li>Employer Sponsored Temporary Transition Stream (ENS/RSMS)</li>
                  <li>Employer Sponsored Direct Entry Stream (ENS/RSMS)</li>
                  <li>General Skilled Migration (189/190/489)</li>
                  <li>Partner Migration (As the partner of an Australian Permanent Resident or Citizen)</li>
                  </u>
                  <li>These options apply to applicants who held or applied for their 457 Visa before 18th of April 2017.</li>
                  <li>If your 457 visa was lodged after 18th of April 2017, different rules apply.</li>
              </div>
            </div>
        </div>
    </>
  )
}

export default VisaOption457Comp