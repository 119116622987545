import React from 'react'
import "../styles/SkilledComp1.css"


function SkilledComp1(props) {
  const containerStyle = {
    backgroundColor: props.containerColor || ' rgba(255, 247, 241, 1)', 
    color: props.textColor || 'rgba(176, 56, 61, 1)', 
  };
  const containerStyle2 = {
    backgroundColor: props.containerColor2 || ' rgba(255, 247, 241, 1)', 
    color: props.textColor2 || 'rgba(59, 59, 59, 1)', 
  };
  return (
    <div className='SkilledComp1_Layout'>
        <div className='SkilledComp1_Title' style={containerStyle}>{props.Title}</div>
        <div className='SkilledComp1_Content1' style={containerStyle2}>{props.content1}</div>
        <div className='SkilledComp1_Content2' style={containerStyle}>
            <div className='skilledComp1_Title' >{props.Title2}</div>
            <div className='skilledComp1_Desc'>
              {props.content2}
              
            </div>
       </div>
    
    </div>
  )
}

export default SkilledComp1