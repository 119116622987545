import React, { useState } from 'react';
import HopeLogo from '../images/HopeHd.png';
import { MagnifyingGlass, CaretDown, List } from "@phosphor-icons/react";
import { useNavigate } from 'react-router-dom';
import { PhoneCall,ChatsCircle} from '@phosphor-icons/react'
import '../styles/Navbar.css';
import ToggleableCaret from './ToggleableCaret';

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isHelpMenuOpen, setIsHelpMenuOpen] = useState(false);
    const [isImmigrationMenuOpen,setIsImmigrationMenuOpen] =useState(false);
    const navigate = useNavigate();
    const handleHelpClick = () => {
        setIsHelpMenuOpen(!isHelpMenuOpen);
      };
      const handleServiceClick = () => {
        window.scrollTo(0,0);
        navigate("/Services");
      };
      const handleImmigrationClick = () => {
        setIsImmigrationMenuOpen(!isImmigrationMenuOpen);
      };
    
      const handleMenuItemClick = (path) => {
        window.scrollTo(0,0);
        navigate(path);
        setIsOpen(false);
      };
  
    const [formData, setFormData] = useState({
        text: '',
    });

    const handleChange = (event) => {
        setFormData({
            ...formData,
            text: event.target.value,
        });
    };

    const handleNavigateHome = () => {
      window.scrollTo(0,0);
      navigate("/");
    }
    const [isNavVisible, setNavVisibility] = useState(false);

    const toggleNavVisibility = () => {
        setNavVisibility(!isNavVisible);
    }; 
    const handleNavigateOverSea = () => {
        window.scrollTo(0,0);
        navigate("/OverSeaStudy");
    
      };
      const handleBlogClick = () => {
        window.scrollTo(0,0);
        navigate("/blog");
    
      };
      const handleToolsClick = () => {
        window.scrollTo(0,0);
        navigate("/tools");
    
      };
      const handleNavigateAustralia = () => {
        window.scrollTo(0,0);
        navigate("/countryLanding");
    
      };
    
    return (
        <>
            <div className='navbar' >
                <div className='logo'>
                    <List className='list-icon' size={32} onClick={toggleNavVisibility} />
                    <img className='HopeLogo' src={HopeLogo} alt='Logo' onClick={handleNavigateHome} style={{width:"120px"}} />
                </div>
                <div className='nav-content'>
                    <div className={`navbar-content ${isNavVisible ? 'visible' : ''}`}>
                        
                        <nav className='nav-buttons' onClick={handleNavigateOverSea}>Overseas Study</nav>
                        <nav className='nav-buttons' onClick={handleImmigrationClick}>Immigration 
                        <ToggleableCaret
                isOpen={isImmigrationMenuOpen}
                onClick={handleImmigrationClick}
                size={22}
            />
                        {isImmigrationMenuOpen && (
                    <div className="menu_layout_Immigration">
                <div
                  className="menu_items"
                  onClick={() => handleMenuItemClick('/countryLanding')}
                >
                 Australia
                </div>
                {/* <div
                  className="menu_items"
                  onClick={() => handleMenuItemClick('/countryLanding')}
                >
                 USA
                </div>
                <div
                  className="menu_items"
                  onClick={() => handleMenuItemClick('/countryLanding')}
                >
                 Canada
                </div>
                <div
                  className="menu_items"
                  onClick={() => handleMenuItemClick('/countryLanding')}
                >
                 United Kingdom
                </div> */}
              </div>
            )}
                        </nav>
                        <nav className='nav-buttons' onClick={handleServiceClick}>Our Services</nav>
                        <nav className='nav-buttons' onClick={handleBlogClick}>Blog</nav>
                        <nav className='nav-buttons'  onClick={handleHelpClick}>Help  
                        <ToggleableCaret
                isOpen={isHelpMenuOpen}
                onClick={handleHelpClick}
                size={22}
            /></nav>
                        {isHelpMenuOpen && (  
              <div className="menu_layout">
                <div
                  className="menu_items"
                  onClick={() => handleMenuItemClick('/ContactUs')}
                >
                  
                  Contact
                </div>
                <div
                  className="menu_items"
                  onClick={() => handleMenuItemClick('/faq')}
                >    
                  FAQ
                </div>
              </div>
            )}
                  <nav className='nav-buttons' onClick={handleToolsClick}>Tools</nav>
                    </div>
                    <div className='search-bar'>
                        <input
                            type="text"
                            className='search-field'
                            name="text"
                            placeholder='Search'
                            value={formData.text}
                            onChange={handleChange}
                        />
                        <div className='icon-search'>
                            {/* <MagnifyingGlass className='search-icon' size={26} /> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className={`nav ${isNavVisible ? 'navcontentButtons' : ''}`} >
                {/* <nav className='nav-buttons'>About Us</nav> */}
                <nav className='nav-buttons' onClick={handleNavigateOverSea}>Overseas Study</nav>
                <nav className='nav-buttons' onClick={handleNavigateAustralia}>Australia</nav>
                {/* <nav className='nav-buttons'>Immigration <CaretDown className='expand-icon' size={22} /></nav> */}
                <nav className='nav-buttons'>Immigration 
                        <ToggleableCaret
                isOpen={isImmigrationMenuOpen}
                onClick={handleImmigrationClick}
                size={22}
            />
                        {isImmigrationMenuOpen && (
                    <div className="menu_layout_Immigration">
                <div
                  className="menu_items"
                  onClick={() => handleMenuItemClick('/countryLanding')}
                >
                 Australia
                </div>
                
              </div>
            )}
                        </nav>
                <nav className='nav-buttons' onClick={handleServiceClick}>Our Services</nav>
                <nav className='nav-buttons'onClick={handleBlogClick}>Blog</nav>
                {/* <nav className='nav-buttons'>Help <CaretDown className='expand-icon' size={22} /></nav> */}
                 {/* <nav className='nav-buttons'>Help    <ToggleableCaret
                isOpen={isHelpMenuOpen}
                onClick={handleHelpClick}
                size={22}
            /></nav> */}
           <nav className='nav-buttons' onClick={() => handleMenuItemClick('/ContactUs')}>Contact</nav>
           <nav className='nav-buttons' onClick={() =>handleMenuItemClick('/faq')}>FAQ</nav>
           

            </div>
        </>
    );
};

export default Navbar;
