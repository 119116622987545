import React, { useState } from 'react'
import DottedLines from '../images/DottedLines.png'
import Family482Banner from "../images/482FamilyBanner.mp4"
import NavBarComponent from '../components/NavBarComponent'
import Footer from '../components/Footer'
import "../styles/Family482.css"
import ConsultationComponent from '../components/ConsultationComponent'
import HeroBanner from '../components/HeroBanner'

import { useNavigate } from 'react-router-dom';
function Family482Visa() {
    const [activeTab, setActiveTab] = useState('Overview');

    const handleTabClick = tabName => {
      setActiveTab(tabName);
    };
    const navigate = useNavigate();
    const handleMenuItemClick = (path) => {
      navigate(path);
    };
  return (
    <div>
            <NavBarComponent />
        <HeroBanner
        heroHeading='482 Family Visa'
        heroSubHeading='For the family members of Subclass 482 TSS Visa holders'
        connectButton='Let’s Connect'
        Banner={[Family482Banner]}
        DottedLines={DottedLines}
      />
         <div className="TabsContainer">
      <div
        className={`Tab ${activeTab === 'Overview' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('Overview')}
      >
        Overview
      </div>
      <div
        className={`Tab ${activeTab === 'Eligibility' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('Eligibility')}
      >
       Eligibility
      </div>
      </div>
      {activeTab === 'Overview' &&
       <div className='Tabs_container_layout'>
           <div className='PartnerVisaHeadComp_Title'>Overview</div>
         <div style={{paddingTop:"24px"}}>
         <div className='AboutUsVisa_box_fields_content'>
               <div className='AboutUsVisa_box_title'>A TSS Family Visa is for the immediate family of a Subclass 482 Visa holder</div>
          <div className='AboutUsVisa_box_desc'>
          A TSS visa holder can add their spouse or de facto partner and children to their visa as secondary applicants.
Choose the option that applies to you:
<li>I want to add my family to my TSS Visa</li>
<li>I want to apply for a TSS Visa with my family</li>
<li>I need an employer to sponsor me and my family on a TSS Visa</li>
TSS Visa secondary applicants are able to enter and remain in Australia for the duration of the main applicant’s TSS Visa (Subclass 482).
Secondary applicants can live, work and study while residing in Australia.
Our team of migration agents specialize in Employer Sponsored Visas, let our experts help you bring your family to Australia. If you don’t hold a TSS Visa and would like to apply for one <u onClick={() =>{ handleMenuItemClick('/employerSponsered'); window.scrollTo(0,0);}} style={{ color: '#B0383D', cursor: 'pointer',fontSize:"16px" }}>Skilled Shortage Visa (TSS Visa) Subclass 482</u> We have assisted hundreds of TSS Visa clients to bring their partners and families to join them in Australia.
          </div>
        </div>
        <div style={{paddingTop:"24px"}}>
        <div className='AboutUsVisa_box_fields_content'>
               <div className='AboutUsVisa_box_title'>Adding family members</div>
          <div className='AboutUsVisa_box_desc'>
          <li> TSS Family members can be included on original <u onClick={() =>{ handleMenuItemClick('/Family482'); window.scrollTo(0,0);}} style={{ color: '#B0383D', cursor: 'pointer',fontSize:"16px" }}>482 Visa </u> application prior to lodgement</li>
          <li>Family members can be added to your TSS visa after approval.</li>
          <li>Employers must support visa applications or additional family members</li>
          <li>TSS Family Visa application charges depend on the main applicant’s occupation. (Whether on the Short-Term Skilled Occupation List or the Medium Long-Term Strategic Skilled List)  </li></div>
        </div>
         </div>
         </div>
        </div>
       } 
        {activeTab === 'Eligibility' &&
       <div className='Tabs_container_layout'>
         <div className='PartnerVisaHeadComp_Title'>Eligibility</div>
        
         <div className='AboutUsVisa_box_fields'>
        <div className='AboutUsVisa_box_fields_content_482'>
          <div className='NzFamily_content'>
          <div className='AboutUsVisa_box_title'>Including family members</div>
          <div className='AboutUsVisa_box_desc'>
          Applicants can include or add the following family members to their TSS Visa (Subclass 482) Visa:
<li>Dependent children/step-children</li>
<li>Partner</li>
 </div></div>
        </div>
        <div className='AboutUsVisa_box_fields_content_482'>
        <div className='NzFamily_content'>
          <div className='AboutUsVisa_box_title'>English</div>
          <div className='AboutUsVisa_box_desc'> Family members are not required to show evidence of English level</div>
        </div>
        </div>
        <div className='AboutUsVisa_box_fields_content_482'>
        <div className='NzFamily_content'>
        <div className='AboutUsVisa_box_title'>Health & Character</div>
          <div className='AboutUsVisa_box_desc'>All family members must meet the health and character requirements. All applicants over 16 must provide police clearances.</div>
        </div>
        </div>
        </div>
       
         <div className='AboutUsVisa_box_fields_content'>
               <div className='AboutUsVisa_box_title'>Children</div>
          <div className='AboutUsVisa_box_desc'>
          To be eligible to be included or added to a 186 visa, a child must:
          <li>Be your child or a stepchild from a current or a previous relationship (in certain circumstances);</li>
          <li>Not be married, engaged to be married, or have a de facto partner; and must be:</li>
          <li>Under 18 years of ageor</li>
          <li>Over 18 years of age but not yet turned 23, and be dependent on you or your partner or</li>
          <li>Over 23 years of age and be unable to earn a living to support themselves due to physical or cognitive limitations and be dependent on you or your partner (Note: The child will still need to meet Australia’s health requirement)or</li>
          <li>A dependent child of a child who is eligible under 1, 2 or 3 above</li> </div>
        </div>
        
        <div style={{paddingTop:"24px"}}>
        <div className='AboutUsVisa_box_fields_content'>
               <div className='AboutUsVisa_box_title'>Partners</div>
          <div className='AboutUsVisa_box_desc'>
          Your married or de facto partner can be the same or opposite sex. Applicants must prove:
          <li>The relationship is genuine and continuing</li>
          <li>You live together or do not live separately on a permanent basis.</li>
          <li>Your partner is at least 18 years of age when the application is lodged (there are some exceptions)</li>
          <li>You are not related by family (if you are in a de facto relationship)</li>
<li>You and your partner have a mutual commitment to a shared life to the exclusion of all others </li></div>
         </div>
         </div>
       
         
        </div>
       } 
        <div className='ConsultationComponent_field'>
            <ConsultationComponent
            title="Are You Eligible?"
            description="Take our online eligibility assessment to find out if you meet the requirements for the 482 Visa            "
            button="Check Eligibility"
            />
             <ConsultationComponent 
            title="Book A Consultation"
            description="Speak to one of our migration agents and simply book a consultation. We'll assist you in understanding the costs involved"
            button="Consult"
            />
             <ConsultationComponent
            title="Get A Quote"
            description="Have a visa in mind? Contact us for a fixed price quote. We'll assist you in understanding the costs involved.            "
            button="Get a quote"
            />
            </div>
       <Footer/>
    </div>
  
  )
}

export default Family482Visa