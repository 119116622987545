import React, { useState } from 'react'
import NavBarComponent from '../components/NavBarComponent'
import HeroBanner from '../components/HeroBanner'
import Business14 from "../images/Applicant.mp4"
import DottedLines from '../images/DottedLines.png'
import howitworks from "../images/WomenOnlineWriting.mp4"
import Visa186How from '../components/Visa186How'
import Footer from '../components/Footer'
import "../styles/SkilledVisa189.css"
import SkilledHowComp from '../components/SkilledHowComp'
import ConsultationComponent from '../components/ConsultationComponent'
import SkilledComp1 from '../components/SkilledComp1'
import Heading from '../components/Heading'
import SkilledComponent2 from '../components/SkilledComponent2'

function SkilledVisa189() {
  const [activeTab, setActiveTab] = useState('Overview');
  const handleTabClick = tabName => {
    setActiveTab(tabName);
  };
  return (
    <div> 
      <NavBarComponent />
    <HeroBanner
   heroHeading='189 Visa'
   heroSubHeading='Skilled Independent Subclass 189 visa'
   connectButton='Let’s Connect'
    Banner={[Business14]}
    DottedLines={DottedLines}
  />
   <div className="TabsContainer">
      <div
        className={`Tab ${activeTab === 'Overview' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('Overview')}
      >
        Overview
      </div>
      <div
        className={`Tab ${activeTab === 'About' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('About')}
      >
       About
      </div>
      <div
        className={`Tab ${activeTab === 'Eligibility' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('Eligibility')}
      >
        Eligibility
      </div>
      <div
        className={`Tab ${activeTab === 'family' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('family')}
      >
       Including family
      </div>
      
    </div>
    {activeTab === 'Overview' &&
       <div className='Tabs_container_layout'>
       <SkilledComponent2
       Title="189 visa"
       textColor="rgba(1, 74, 145, 1)"
       containerColor="rgba(244, 244, 244, 1)"
       containerColor2="rgba(244, 244, 244, 1)"
       content1="The 189 visa is a points-tested permanent visa for skilled workers who are not sponsored by an Australian employer"
       Title2="189 Visa holders can:"
       content2="be in or outside Australia when making the application"
       content3="work and study anywhere in Australia"
       content4="sponsor eligible relatives for permanent residence"
       content5="apply for Australian Citizenship (If eligible)"
       />
      
        </div>
        }
         {activeTab === 'About' &&
       <div className='Tabs_container_layout'>
        <Heading heading="189 visa"/>
        <div className='VisaFamily_Box'>
        The Skilled Independent Subclass 189 visa is a points-tested permanent visa for skilled workers who are not sponsored by an Australian employer, family member or nominated by a state or territory.   </div>
        <div className='SkilledVisa189_part2_fields'>
           <div className='SkilledVisa189_part2_Box'>
           <div className='AboutUsVisa_box_title'>Skilled Independent visa (Subclass 189)</div>
           <div className='AboutUsVisa_box_desc'>applicants must obtain a minimum of 65 points on the point assessment.

To apply for this visa, you must submit an expression of interest (EOI) and be invited by SkillSelect.

Upon receiving an invitation to apply for this visa, you have 60 days to submit your application.

Once the 189 Visa (Skilled Independent) Visa has been granted, the visa holder and accompanying family members are are able to live, work and study in Australia as permanent residents.</div>
              </div>
             <div className='SkilledVisa189_part2_Box'>
           <div className='AboutUsVisa_box_title'>Applying for a 189 Visa</div>
           <div className='VisaFamily_Box_desc'>To be eligible to apply for a 189 Skilled Independent Visa, applicants must:

<li>have an occupation on the relevant skilled occupation list</li> 
<li>complete a successful skill assessment for the relevant  occupation</li> 
<li>have at least competent English</li> 
<li>score at least 65 points. Calculate your points</li> 
<li>be invited to apply</li>  </div>
              </div>
        </div>
      
        </div>
        }
         {activeTab === 'Eligibility' &&
       <div className='Tabs_container_layout'>
         <Heading heading="189 Visa Eligibility"/>
        <div className='VisaFamily_Box'>
        You may be able to apply for a 189 Skilled Independent Visaif you meet the following requirements:
        </div>   
        <div style={{marginTop:"24px"}}>
        <div className='AboutUsVisa_box_fields_content'>
          <div className='AboutUsVisa_box_title'>Points Test & Invitation</div>
          <div className='AboutUsVisa_box_desc'>You must lodge an Expression of Interest (EOI) and be invited to apply for this visa

You must score a minimum of 65 points to be invited to apply, however, invitations are often issued to applicants with more than 65 points.

The most recent invitation round outlines the point scores which attracted invitations. There are also some occupations which require more than 65 points to be considered.

You must meet the points score eligibility at the time you are invited to apply for the visa.
        </div>
        </div> 
        <div className='SkilledVisa189_part2_fields'>
           <div className='SkilledVisa189_part2_Box'>
           <div className='AboutUsVisa_box_title'>Occupation & Skills</div>
           <div className='AboutUsVisa_box_desc'>Your occupation must be on the relevant list of eligible skilled occupations.
You must perform a successful skill assessment through the authorised assessing authority to show that you have the skills and qualifications to work in your chosen occupation.</div>
              </div>
             <div className='SkilledVisa189_part2_Box'>
           <div className='AboutUsVisa_box_title'>English</div>
           <div className='VisaFamily_Box_desc'>You can provide evidence of at least competent English, however, additional points will be awarded for proficient and superior English scores
Improve your English score through an Online English Academy </div>
              </div>

        </div>  
        <div className='SkilledVisa189_part2_fields'>
           <div className='SkilledVisa189_part2_Box'>
           <div className='AboutUsVisa_box_title'>Age</div>
           <div className='AboutUsVisa_box_desc'>You are under 45 when you receive an invitation to apply.
            </div>
          </div>
             <div className='SkilledVisa189_part2_Box'>
           <div className='AboutUsVisa_box_title'>Health & Character</div>
           <div className='VisaFamily_Box_desc'>You must meet the health & character requirements
If you are a New Zealand Citizen, you may be eligible to apply for the New Zealand Stream 189 Visa instead. </div>
              </div>
          </div></div>
    
        </div>
        }
         {activeTab === 'family' &&
       <div className='Tabs_container_layout'>
        <div className='VisaFamily_Box'>
        189 visa applicants may be able to include the below family members to their application
        </div>      
          <div className='SkilledVisa189_part2_fields'>
           <div className='SkilledVisa189_part2_Box'>
           <div className='AboutUsVisa_box_title'>Partner</div>
           <div className='AboutUsVisa_box_desc'>
            Your married or de facto partner can be the same or opposite sex. Applicants must prove:
            <li>the relationship is genuine and continuing</li>
            <li> your partner is at least 18 years of age when the application is lodged (there are some exceptions)</li>
            <li>you are not related by family (if you are in a de facto relationship)</li>
            <li> you and your partner have a mutual commitment to a shared life to the exclusion of all others, and</li>
            <li> you live together, or do not live separately on a permanent basis.</li>
            </div>
          </div>
             <div className='SkilledVisa189_part2_Box'>
           <div className='AboutUsVisa_box_title'>Children</div>
           <div className='VisaFamily_Box_desc'>To be eligible to apply for a 189 Skilled Independent Visa, applicants must:
 <li> be your child or a stepchild from a current or a previous relationship (in certain circumstances);</li>
 <li> not be married, engaged to be married, or have a de facto partner; and must be:</li>
 <li> under 18 years of ageor</li>
 <li> over 18 years of age but not yet turned 23, and be dependent on you or your partneror</li>
 <li> over 23 years of age and be unable to earn a living to support themselves due to physical or cognitive limitations and be dependent on you or your partner (Note: The child will still need to meet Australia’s health requirement)or</li>
 <li> a dependent child of a child who is eligible under 1, 2 or 3 above.</li> </div>
              </div>
          </div>
          <div className='SkilledVisa189_part2_fields'>
           <div className='SkilledVisa189_part2_Box'>
           <div className='AboutUsVisa_box_title'>English Exemptions</div>
           <div className='AboutUsVisa_box_desc'>You can choose to pay an additional application fee for family members who have less than functional English.
            </div>
          </div>
             <div className='SkilledVisa189_part2_Box'>
           <div className='AboutUsVisa_box_title'>Health & Character</div>
           <div className='VisaFamily_Box_desc'>All family members must meet health and character requirements. All applicants over 16 must provide police clearances. </div>
              </div>
          </div>
          <div className='AboutUsVisa_box_fields_content'>
          <div className='AboutUsVisa_box_title'>English</div>
          <div className='AboutUsVisa_box_desc'>
          Applicants over 18 must demonstrate Functional English. You can demonstrate Functional English without taking an English test if you:
<li>hold a valid passport issued by the United Kingdom, the United States of America, Canada, New Zealand or the Republic of Ireland</li>
<li>have completed all years of primary education and at least three years of secondary education in an educational institution in or outside Australia and all instructions were in English.</li>
<li>have completed at least five years of secondary education in an institution in or outside Australia and all instructions were in English.</li>
<li>have completed at least one year of full-time study or equivalent part-time study towards a degree, higher degree, diploma, or associate diploma in an institution in Australia and all instructions were in English.</li>
<li>have completed a degree, a higher degree, a diploma or a trade certificate that required at least two years of full-time study or training in an institution in or outside Australia and all instructions were in English.</li>
        </div></div>
      
        </div>
        }
        <SkilledHowComp
                EmpHowHeading='How it works'
                containerColor="rgba(255, 247, 241, 1)"
                textColor="rgba(176, 56, 61, 1)"
                EmpHowImage={howitworks}
                EmpHowSubHead1='Step 1'
                EmpHowSubHead2='Step 2'
                EmpHowSubHead3='Step 3'
                EmpHowSubHead4='Step 4'
                EmpHowSubHead5='Step 5'
                EmpHowDesc1='Check if occupation is on the skilled list and you score more than 65+ points'
                EmpHowDesc2='Our migration agents lodge your expression of interest with the Department'
                EmpHowDesc3='We wait to receive an invitation from the Department to apply for your visa.'
                EmpHowDesc4='Our experts lodge your visa and liaise with the department until a decision is made'
                EmpHowDesc5='You and your family migrate to Australia after receiving your visa approval'
            />
            <div className='ConsultationComponent_field'>
            <ConsultationComponent
            
            title="Calculate your points"
            description="If you would like to know if you are eligible to apply for a Skilled Independent Visa, you can calculate your points online.            "
            button="Let’s do it"
            />
             <ConsultationComponent 
            
            title="Book A Consultation"
            description="If you would like to discuss your Australian visa eligibility with a registered migration agent, book your consultation now."
            button="Consult"
            />
             <ConsultationComponent
            
            title="Get A Quote"
            description="Know what visa you want to apply for but need some pricing? Get in touch with us and we will get back to you with a fixed price quote.            "
            button="Get a quote"
            />
            </div> 
        <Footer/>
      
    </div>
  )
}

export default SkilledVisa189