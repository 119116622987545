import React from 'react'
import { useNavigate } from 'react-router-dom';
import "../styles/ConsultComp2.css"

function ConsultComp2(props) {
    
  const navigate = useNavigate();
    const containerStyle = {
        backgroundColor: props.containerColor || '#F4F4F4', 
        color: props.textColor || '#014A91', 
      };
     
      const handleclick = () => {
        window.scrollTo(0,0);
        navigate("/PointTest");
      };
  return (
    <div className='Consult_Comp_Layout'>
        <div className='Consult_Comp_container' style={containerStyle}>
            <div className='Consult_Comp_title'>{props.title}</div>
            <div className='Consult_Comp_desc'>{props.desc}</div>
            <div className='Consult_Comp_btn' onClick={handleclick}>{props.btn}</div>
        </div>
    </div>
  )
}

export default ConsultComp2