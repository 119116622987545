import React from 'react'
import "../styles/option3.css"
import { useNavigate } from 'react-router-dom';

function Option3Tss(props) {
  
  const navigate = useNavigate();
  const handleMenuItemClick = (path) => {
    navigate(path);
  };
  return (
    <div className='option3_layout'>
     <div className='option3_head'>Option 3 </div>
     <div className='VisaFamily_Box'>
              <li>TSS Visa holders who do not have an Australian employer willing to sponsor them for permanent residency may be able to apply through the <u onClick={() =>{ handleMenuItemClick('/SkilledVisa'); window.scrollTo(0,0);}} style={{ color: '#B0383D', cursor: 'pointer',fontSize:"24px" }}> General Skilled Migration Program.</u></li>
        </div>
    </div>
  )
}

export default Option3Tss