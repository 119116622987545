import React, { useState } from 'react'
import Employer186Banner from "../images/Emp186Banner.mp4"
import DottedLines from '../images/DottedLines.png'
import howitworks from '../images/Reading.mp4'
import NavBarComponent from '../components/NavBarComponent'
import HeroBanner from '../components/HeroBanner'
import PermanentEmpHow from '../components/PermanentEmpHow'
import Footer from '../components/Footer'
import ConsultationComponent from '../components/ConsultationComponent'
import PermEmpOverview from './PermEmpOverview'
import PermanentEmpOv from '../components/PermanentEmpOv'
import Visa186Requirements from '../components/Visa186Requirements'
import Visa186TransComp from '../components/Visa186TransComp'
import Visa186FamComp from '../components/Visa186FamComp'

function Employer186() {
    const [activeTab, setActiveTab] = useState('Overview');
    const handleTabClick = tabName => {
      setActiveTab(tabName);
    };
  return (
    <div>
        <NavBarComponent/>
        <HeroBanner
        heroHeading='Employer Nomination Scheme ENS (Subclass 186 Visa)'
        heroSubHeading=''
        connectButton='Let’s Connect'
        Banner={[Employer186Banner]}
        DottedLines={DottedLines}
      />
     <div className="TabsContainer">
      <div
        className={`Tab ${activeTab === 'Overview' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('Overview')}
      >
        Overview
      </div>
      <div
        className={`Tab ${activeTab === '186Visa' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('186Visa')}
      >
        Direct Entry Stream
      </div>
      <div
        className={`Tab ${activeTab === 'Transition' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('Transition')}
      >
        Transition Stream
      </div>
      <div
        className={`Tab ${activeTab === 'Family' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('Family')}
      >
        Including Family
      </div>
      
    </div>
    {activeTab === 'Overview' &&
       <div className='Tabs_container_layout'>
        <PermanentEmpOv
         empOVHeading='The Subclass 186 Visa has 3 streams:'
         empOVsubHead1='DIRECT ENTRY STREAM:'
         empOVsubHead2='AGREEMENT STREAM:'
         empOVsubHead3='TRANSITIONAL STREAM:'
         empOVDesc1='For applicants who are qualified or have sufficient experience in an eligible occupation. See the Direct Entry Stream tab for specific requirements.'
         empOVDesc2='For applicants who are sponsored by an employer under a labour agreement.'
         empOVDesc3="For 457 visa or 482 TSS Visa holders who have worked for their nominating employer for a minimum of two or three years. See the Transition Steam tab for specific requirements."
        
        />
      
        </div>
        }
         {activeTab === '186Visa' &&
       <div className='Tabs_container_layout'>
         <Visa186Requirements
        whyUsHeadingMain='Meet the following requirements:'
        whyUsHeading5='Age'
        whyUsDesc5='You must be under 45 years of age when you apply for this visa.'
        whyUsDesc51='You may be older than 45 years if you are;'
        whyUsDesc52='You are nominated as an academic by an Australian University'
        whyUsDesc53='You are nominated as a scientist, researcher or technical specialist by a Scientific Government Agency'
        whyUsDesc54='You hold a New Zealand Subclass 444 or 461 Visa and have been working for your nominated employer for two years.'
        whyUsHeading1='Experience'
        here='Here'
        whyUsHeading2='Health & Character'
        whyUsDesc1='You must have at least 3 years of relevant work experience'
        whyUsDesc2='Meet the health and character requirements (Unless you are exempt). This requires police and medical checks.'
        whyUsHeading3='English'
        whyUsHeading4='Nomination'
        whyUsDesc3='The primary applicant for the 186 Visa must have Competent English.
        You will need to take an English test unless one of the following applies:
        You are a passport holder from Canada, Ireland, UK, USA or NZ
        If you need to take an English test, you can take one of the tests:'
        whyUsDesc42='You must be nominated by an Australian employer and apply for the 186 visa within 6 months of the nomination being approved (If the visa and nomination applications were not lodged at the same time)'
        whyUsDesc43='The visa will not be granted if the nomination is withdrawn. The Department does not link new nominations to existing visa applications.'
      />
      
        </div>
        }
         {activeTab === 'Transition' &&
       <div className='Tabs_container_layout'>
        {/* <Perm187 /> */}
        <Visa186TransComp
        whyUsHeadingMain='Meet the following requirements:'
        whyUsHeading5='Age'
        here='Here'
        whyUsDesc5='You must be under 45 years of age when you apply for this visa.'
        whyUsDesc51='You may be older than 45 years if you are;'
        whyUsDesc52='you held (or had applied for) a subclass 457 visa on 18 April 2017. In this case, you can apply up to 50 years of age'
        whyUsDesc53='you are nominated as an academic by an Australian University'
        whyUsDesc54='you are nominated as a scientist, researcher or technical specialist by a Scientific Government Agency'
        whyUsDesc55='you are a medical practitioner who has worked in your position for at least 3 years as a 457 or 482 visa holder, and 2 of those years were in a regional area.'
        whyUsDesc56='you have been working for your employer for 3 years on a 457 or 482 visa and you were earning at least the Fair Work High Income Threshold for each year of that period'
        whyUsHeading1='Current Visa'
        whyUsHeading2='Health & Character'
        whyUsDesc1='You must hold one of the below visas to apply for the 186 Visa through the Transition Stream:'
        whyUsDesc11='457 Visa'
        whyUsDesc111='482 Visa'
        whyUsDesc2='Meet the health and character requirements (Unless you are exempt). This requires police and medical checks.'
        whyUsHeading3='English'
        whyUsHeading4='Experience'
        whyUsDesc3='The primary applicant for the Employer Nomination Scheme visa must have Competent English.'
        whyUsDesc31='You will need to take an English test unless one of the following applies: You are a passport holder from Canada, Ireland, UK, USA or NZ'
        whyUsDesc32='If you need to take an English test, you can take one of the tests from here:'
        whyUsDesc42='You must have been working full-time for your employer on your subclass 457 or subclass 482 visa for at least 3 years. Unless you held (or had applied for) a subclass 457 visa on 18 April 2017, then you only need to have been working for your employer for at least 2 years.'
        visaTransacHead1='COVID Update'
        visaTransacHead2='Nomination'
        visaTransacDesc1='If you were temporarily stood down, had to take unpaid leave or had your hours reduced due to COVID-19, this time can still be counted towards the time worked for your employer.'
        visaTransacDesc2='You must be nominated by an Australian employer and apply for the 186 visa within 6 months of the nomination being approved (If the visa and nomination applications were not lodged at the same time) '
        visaTransacDesc3='The visa will not be granted if the nomination is withdrawn. The Department does not link new nominations to existing visa applications.'
      />
      
        </div>
        }
         {activeTab === 'Family' &&
          <div className='Tabs_container_layout'>
  <Visa186FamComp
        whyUsHeadingMain='Include your family in your 186 Visa'
        eligibilitysubHeading='186 visa allows holders to be able to include the below family members to their application'
        eligibilitysubHeading1='Dependent children/step-children'
        eligibilitysubHeading2='Partner’s dependent child or children'
        eligibilitysubHeading3='Partner'
        whyUsHeading5='Children'
        whyUsDesc5='To be eligible to be included or added to a 186 visa, a child must:'
        whyUsDesc51='Be your child or a stepchild from a current or a previous relationship (in certain circumstances)'
        whyUsDesc52='Not be married, engaged to be married, or have a de facto partner; and must be:'
        whyUsDesc53='Under 18 years of ageor'
        whyUsDesc54='Over 18 years of age but not yet turned 23, and be dependent on you or your partneror'
        whyUsDesc55='Over 23 years of age and be unable to earn a living to support themselves due to physical or cognitive limitations and be dependent on you or your partner (Note: The child will still need to meet Australia’s health requirement)or'
        whyUsDesc56='A dependent child of a child who is eligible under 1, 2 or 3 above.'
        whyUsHeading1='English'
        whyUsHeading2='Age'
        whyUsDesc1='Applicants over 18 must demonstrate Functional English. However, you can choose to pay an additional application fee to the department for family members who have less than functional English.'
        whyUsDesc2='Children 23 years and over cannot be included in your application. (Unless there are exceptional circumstances)'
        whyUsHeading3='Partners'
        whyUsHeading4='Health & Character'
        whyUsDesc31='Your married or de facto partner can be the same or opposite sex. Applicants must prove:'
        whyUsDesc32='The relationship is genuine and continuing'
        whyUsDesc33='You live together, or do not live separately on a permanent basis.'
        whyUsDesc34='Your partner is at least 18 years of age when the application is lodged (there are some exceptions)'
        whyUsDesc35='You are not related by family (if you are in a de facto relationship)'
        whyUsDesc36='You and your partner have a mutual commitment to a shared life to the exclusion of all others'
        whyUsDesc41='All family members must meet the health and character requirements. All applicants over 16 must provide police clearances.'
        whyUsDesc42='If you already hold a permanent 186 (ENS) Visa and you would like to bring your family to Australia, you will need to apply for one of the family visas.'
      />
          </div>
         }
        <PermanentEmpHow
        EmpHowHeading='How it works'
        EmpHowImage={howitworks}
        EmpHowSubHead1='Step 1'
        EmpHowSubHead2='Step 2'
        EmpHowSubHead3='Step 3'
        EmpHowSubHead4='Step 4'
        EmpHowDesc1='Contact us to discuss your eligibility or to find an employer.'
        EmpHowDesc2='We assist you to collect the necessary documents.'
        EmpHowDesc3='Our agents submit your application to Immigration.'
        EmpHowDesc4='Our agents liaise with Immigration until your application is finalised.'
      />

<div className='ConsultationComponent_field'>
            <ConsultationComponent 
            title="Are You Eligible?"
            description="Take our online eligibility assessment to find out if you meet the requirements for the 482 Visa            "
            button="Check Eligibility"
            />
             <ConsultationComponent 
            title="Book A Consultation"
            description="Speak to one of our migration agents and simply book a consultation. We'll assist you in understanding the costs involved"
            button="Consult"
            />
             <ConsultationComponent
            title="Get A Quote"
            description="Have a visa in mind? Contact us for a fixed price quote. We'll assist you in understanding the costs involved.            "
            button="Get a quote"
            />
            </div>
      <Footer/>

    </div>
  )
}

export default Employer186