import React, { useState } from 'react'
import HeroBanner from '../components/HeroBanner'
import DottedLines from '../images/DottedLines.png'
import Family from "../images/Visa187Banner3.mp4"
import NavBarComponent from '../components/NavBarComponent'
import Overview494Comp from '../components/Overview494Comp'
import Applicant494Comp from '../components/Applicant494Comp'
import Family494Comp from '../components/Family494Comp'
import Footer from '../components/Footer'
import ConsultationComponent from '../components/ConsultationComponent'

function Visa494() {
    const [activeTab, setActiveTab] = useState('Overview');

    const handleTabClick = tabName => {
      setActiveTab(tabName);
    };
  return (
    <div>
        <NavBarComponent />
        <HeroBanner
       heroHeading='Skilled Employer Sponsored Regional (Provisional) Subclass 494'
       heroSubHeading=''
       connectButton='Let’s Connect'
        Banner={[Family]}
        DottedLines={DottedLines}
      />
  <div className="TabsContainer">
      <div
        className={`Tab ${activeTab === 'Overview' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('Overview')}
      >
        Overview
      </div>
      <div
        className={`Tab ${activeTab === 'Applicant' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('Applicant')}
      >
        Main Applicant
      </div>
      <div
        className={`Tab ${activeTab === 'IncludingFamily' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('IncludingFamily')}
      >
        Including Family
      </div>
    </div>
    {activeTab === 'Overview' &&
       <div className='Tabs_container_layout'>
         <Overview494Comp
        overview494Heading1='SUBCLASS 494'
        overview494Heading2='Applying for a 494 Visa'
        overview494Heading3='More about the 494 Visa'
        overview494Content1='The 494 Visa allows skilled workers to be sponsored by an Australian employer in a regional area for up to five years'
        overview494Content2='494 Visa holders can:'
        overview494Content3='live and work in Australia for up to five years'
        overview494Content4='Include immediate family on 494 visa'
        overview494Content5='Transition to permanent residency (if eligible) via the 191 Regional Skilled Visa'
        overview494Content6='The Subclass 494 visa will open to applications in November 2019. To be eligible to apply for this visa, applicants must'
        overview494Content7='Be under 45 years'
        overview494Content8='Have at least 3 years work experience'
        overview494Content9='Have a valid'
        overview494Content10='Demonstrate '
        overview494Content11='Meet the health and character requirements'
        overview494Content12='Regional areas include everywhere except the metropolitan areas of; Sydney, Melbourne, Brisbane, Gold Coast and Perth. Increased number of eligible occupations. Provisional visa with option to transition to permanent residency after three years. '
        overview494Content13='If you would like to apply for another employer sponsored visa, you may be eligible to apply for one of the below employer sponsored visas instead: '
        overview494Content14='DE CLOSED '
      />
      
        </div>
        }

{activeTab === 'Applicant' &&
 <div className='Tabs_container_layout'>
  <Applicant494Comp/>
 </div>
    
    }
    {activeTab === 'IncludingFamily' &&
 <div className='Tabs_container_layout'>
    <Family494Comp/>
    </div>
}
<div className='ConsultationComponent_field'>
        <ConsultationComponent  containerColor="#FFF7F1"  // Use containerColor instead of backgroundColor
             textColor="#014A91" title="Other Sponsored Visas" 
        description="View other similar visa options that could be applicable to you."
        button="Check Eligibility"
        />
         <ConsultationComponent  containerColor="#FFF7F1"  // Use containerColor instead of backgroundColor
             textColor="#014A91" title="Book A Consultation" 
        description="Speak to one of our migration agents and simply book a consultation."
        button="Consult"
        />
         <ConsultationComponent  containerColor="#FFF7F1"  // Use containerColor instead of backgroundColor
             textColor="#014A91" title="Get A Quote" 
        description="Have a visa in mind? Contact us for a fixed price quote. We'll assist you in understanding the costs involved.        "
        button="Consult"
        />
        </div>
<Footer />
        
    </div>
  )
}

export default Visa494