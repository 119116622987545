import React from 'react'
import '../styles/Visa186FamComp.css'

const Visa186FamComp = (props) => {
    return (
        <>
            <div className='FamComp-layout'>
                <div className='FamComp-heading'>
                    <div className='FamCompHeading'>{props.whyUsHeadingMain}</div>
                </div>
                <div className='FamComp-sublayout2'>
                    <div className='FamComp-subHeading'>
                        <div>{props.eligibilitysubHeading}</div>
                        <li>{props.eligibilitysubHeading1}</li>
                        <li>{props.eligibilitysubHeading2}</li>
                        <li>{props.eligibilitysubHeading3}</li>
                    </div>
                </div>
                <div className='FamComp-second-layout'>
                    <div className='FamComp-layout-one'>
                        <div className='FamComp-layout-exp'>
                            <div className='FamComp-heading'>{props.whyUsHeading1}</div>
                            <div className='FamComp-desc'>
                                {props.whyUsDesc1}
                            </div>
                        </div>
                        <div className='FamComp-layout-ext'>
                            <div className='FamComp-heading1'>{props.whyUsHeading2}</div>
                            <div className='FamComp-desc1'>
                                {props.whyUsDesc2}
                            </div>
                        </div>
                    </div>
                    <div className='FamComp-layout-two'>
                        <div className='FamComp-two-sublayout1'>
                            <div className='FamComp-two-service'>
                                <div className='FamComp-two-head'>{props.whyUsHeading3}</div>
                                <div className='FamComp-two-desc'>
                                    <li>{props.whyUsDesc31}</li>
                                    <li>{props.whyUsDesc32}</li>
                                    <li>{props.whyUsDesc33}</li>
                                    <li>{props.whyUsDesc34}</li>
                                    <li>{props.whyUsDesc35}</li>
                                    <li>{props.whyUsDesc36}</li>
                                </div>
                            </div>
                            <div className='FamComp-two-trans'>
                                <div className='FamComp-two-head'>{props.whyUsHeading4}</div>
                                <div className='FamComp-two-desc'>
                                    <li>{props.whyUsDesc41}</li>
                                    <li>{props.whyUsDesc42}</li>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='FamComp-two-sublayout2'>
                    <div className='FamComp-two-support'>{props.whyUsHeading5}</div>
                    <div className='FamComp-two-support-desc'>
                        <div>{props.whyUsDesc5}</div>
                        <li>{props.whyUsDesc51}</li>
                        <li>{props.whyUsDesc52}</li>
                        <li>{props.whyUsDesc53}</li>
                        <li>{props.whyUsDesc54}</li>
                        <li>{props.whyUsDesc55}</li>
                        <li>{props.whyUsDesc56}</li>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Visa186FamComp