import React, { useEffect, useState } from "react";
import "../styles/Blog.css";
import { useNavigate } from "react-router-dom";
import { txtDB } from "../config/fireBase";
import {  collection,  getDocs } from "firebase/firestore";

function BlogHeadComp(props) {
  const [ , setDisplay] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const shouldSwap = props.swapImageAndDescription;
  const containerStyle = {
    backgroundColor: props.containerColor || '#F4F4F4', 
    color: props.textColor || '#014A91', 
  };
  const containerStyle1 = {
    backgroundColor: props.containerColor2 || '#F4F4F4', 
    color: props.textColor2 || 'rgba(59, 59, 59, 1)', 
  };const handleButtonClick = (onClick) => {
    if (typeof onClick === 'function') {
      onClick();
    } else {
      console.error('Invalid onClick function provided');
    }
  };
  const [data,setData] = useState([])
  const [link, setLink] = useState('');
  const [links, setLinks] = useState([]);
  const getData = async () => {
    setLoading(true);
    try {
      const valRef = collection(txtDB, 'txtData');
      const dataDb = await getDocs(valRef);
      const allData = dataDb.docs.map((val) => ({ ...val.data(), id: val.id }));
      setData(allData);
      console.log(allData);
       setDisplay(true);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await getData();
      setLoading(false);
    };
  
    fetchData();
  }, []);

  const handleReadMore  = async (id) => {
    // navigate("/DeatiledBlog")
    navigate(`/DeatiledBlogUser/${id}`);
  };
  const fetchLinks = async () => {
    try {
      const linkRef = collection(txtDB, 'linkDB');
      const snapshot = await getDocs(linkRef);

      const linksData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      setLinks(linksData);
    } catch (error) {
      console.error('Error fetching links:', error);
    }
  };

  useEffect(() => {
    fetchLinks();
  }, []);
  return (
    <div className='Blog_layout'>
       {data.map((value ,index) => (
        <div key={value.id}>
          {console.log('Image URL:', value.imgUrl)}
      <div className='Family_header_Box' style={containerStyle} >
        <div className='Family_header_title' style={containerStyle}>{value.txtVal}</div>
      </div>
      <div className='Family_header_Image'>
        {shouldSwap ? (
          <>
            <div className='Blog_header_description' style={containerStyle1}>
              <div className="blog_content_desc">{value.txtDesVal}</div>
              <button className='family_header_desc_btn' onClick={() => handleReadMore(value.id)}>{props.btn}</button>
            </div>
            <div className='Family_header_image'>
              <img src={value.imgUrl} 
              alt="img"
              // type="video/mp4" autoPlay muted loop 
              className='Family_header_img' />
              {/* </video> */}
            </div>
          </>
        ) : (
          <>
            <div className='Blog_header_description' style={containerStyle1}>
            {value.txtDesVal}
              <button className='family_header_desc_btn'  onClick={() => handleReadMore(value.id)}>{props.btn}</button>
            </div>
            <div className='Family_header_image'>
              <img src={value.imgUrl} 
              alt="img"
              // type="video/mp4" autoPlay muted loop 
              className='Family_header_img' />
              {/* </video> */}
            </div>
          </>
          
        )}
      </div>
      </div>))}

      <div className='Conatct_us_container_link' style={{padding:"0px"}}>
      <div className='Link_container_left'>Read more</div>
      <div className='Link_container_right'>
      <div style={{display:"flex",flexDirection:"column",gap:"24px"}}>
        {links.map(link => (
          <>
          <li key={link.id} className="Links_conatiner_fields">
          <div className="admin_links_head">
            
          <div style={{display:"flex" ,flexDirection:"column",gap:"24px"}}>
          <div className="label_head"><span>{link.title}</span></div>
          <div className="label_head">  <a href={link.linkUrl} target="_blank" rel="noopener noreferrer" style={{fontSize:"20px" ,color:"#848484", fontWeight: 500}}>
                      {link.linkUrl}
                    </a></div>
          </div>
       </div>
       </li>
       <hr className='Divider' />
      </>
        ))}
      </div>
      </div>
      </div>
    </div>
  );
}

export default BlogHeadComp;
