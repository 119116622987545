import React, { useState } from 'react';
import "../styles/faq.css";
// import HerobannerFaq from "../images/FaqBanner.png"
import DottedLines from "../images/DottedLines.png"
import Herobanner from '../images/selective-focus.mp4'
// import FaqVideo from "../images/FaqVideo.mp4";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Footer from '../components/Footer';
import NavBarComponent from '../components/NavBarComponent';
import HeroBanner from '../components/HeroBanner';
const faqData = [
  {
    id: 1,
    question: "What services does Hope Migration & Education Offers?",
    answer: "We offer a wide range of education and migration services, including study abroad programs, student visa applications, migration and immigration services, language testing, and professional development training. Our experienced team of consultants can provide personalized and professional guidance throughout the process."
  },
  {
    id: 2,
    question: "How is Hope Migration & Education benefits our clients?",
    answer: "Our clients benefit from our years of experience, in-depth knowledge of the education and migration industry, and personalized approach. We take the time to understand each client’s unique needs and goals and provide tailored solutions to help them achieve their objectives. Our clients also have access to a wide range of resources, including visa application assistance, language training, and job placement services."
  },
  {
    id: 3,
    question: "How long does it take to process a visa application?",
    answer: "The processing time for a visa application can vary depending on the type of visa and the country of application. Generally, the process can take several weeks to several months. Our team of consultants will provide you with an estimated timeline based on your specific circumstances and keep you updated throughout the process."
  },
  {
    id: 4,
    question: "Do i need to have certain level of profeciency in English to study or work in another country?",
    answer: "Yes, English proficiency is often a requirement for studying or working in another country. Depending on the program or job, you may need to provide proof of your English proficiency through standardized language tests like TOEFL or IELTS. Our team can provide guidance and support for language testing and training."
  },
  {
    id: 5,
    question: "Do i need to have certain level of profeciency in English to study or work in another country?",
    answer: "Yes, English proficiency is often a requirement for studying or working in another country. Depending on the program or job, you may need to provide proof of your English proficiency through standardized language tests like TOEFL or IELTS. Our team can provide guidance and support for language testing and training."
  },
];
function HopeFaq() {  
  const [openAccordionIndex, setOpenAccordionIndex] = useState([1, 2, 3,4,5]);

  const handleAccordionToggle = (index) => {
    setOpenAccordionIndex((prevOpenAccordionIndex) => {
      if (prevOpenAccordionIndex.includes(index)) {
        return prevOpenAccordionIndex.filter((item) => item !== index);
      } else {
        return [...prevOpenAccordionIndex, index];
      }
    });
  };
  

  return (
    <>
      <NavBarComponent />
      <HeroBanner
        heroHeading='Frequently Asked Questions'
        heroSubHeading='We have provided answers to some of the most common questions that our clients ask us about our services. If you have any additional questions or concerns, please do not hesitate to contact us.'
        connectButton='Let’s Connect'
        Banner={[Herobanner]}
        DottedLines={DottedLines}
      />
      <div className='faq_container'>
        <div className='faq_container_head'>
          Frequently Asked Questions
        </div>
        <div className='faq_accordian_video_contaner'>
          <div className='accordian_container'>
            {faqData.map((item) => (
              <div className='accordian-submain_end' key={item.id} onClick={() => handleAccordionToggle(item.id)}>
                <div className='accordian-heading'>
                  {item.question}
                </div>
                {openAccordionIndex.includes(item.id) && (
                  <div className='accordian-details'>
                    {item.answer}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
     
    </>
  );
}

export default HopeFaq;

{/* <div className='faq_container'>
        <div className='faq_container_head'>
          Frequently Asked Questions
        </div>
        <div className='faq_accordian_video_contaner'>
          <div className='accordian_container'>
            {[1, 2, 3].map((index) => (
              <div className='accordian-submain_end' key={index} onClick={() => handleAccordionToggle(index)}>
                <div className='accordian-heading'>
                What services does Hope Migration & Education Offers?
                  <span>{openAccordionIndex.includes(index) ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}</span>
                </div>
                {openAccordionIndex.includes(index) && (
                  <div className='accordian-details'>
    We offer a wide range of education and migration services, including study abroad programs, student visa applications, migration and immigration services, language testing, and professional development training. Our experienced team of consultants can provide personalized and professional guidance throughout the process.                  </div>
                )}
              </div>
            ))}
          </div>
          <div className='faq_container_image'>
          <video
      autoPlay
      loop
      muted
      playsInline
      style={{
        width: '100%',
      
        flexShrink: 0,
        borderRadius: '20px',
       
      }}
      src={FaqVideo} 
    />
           
          </div>
        </div>
        {[4, 5].map((index) => (
          <div className='accordian-submain_end' key={index} onClick={() => handleAccordionToggle(index)}>
            <div className='accordian-heading'>
            Do i need to have certain level of profeciency in English to study or work in another country?
              <span>{openAccordionIndex.includes(index) ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}</span>
            </div>
            {openAccordionIndex.includes(index) && (
              <div className='accordian-details'>
Yes, English proficiency is often a requirement for studying or working in another country. Depending on the program or job, you may need to provide proof of your English proficiency through standardized language tests like TOEFL or IELTS. Our team can provide guidance and support for language testing and training.              </div>
            )}
          </div>
        ))}
      </div> */}