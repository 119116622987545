import React, { useEffect, useState } from "react";
import { imgDB,txtDB } from "../config/fireBase";
import { v4 } from "uuid";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { addDoc, collection, deleteDoc, doc, getDocs } from "firebase/firestore";
import BlogBanner from "../images/WomenOnlineWriting.mp4"
import DottedLines from '../images/DottedLines.png'
import NavBarComponent from '../components/NavBarComponent'
import HeroBanner from '../components/HeroBanner'
import HelpYou from '../components/HelpYou'
import "../styles/Blog.css";
import { useNavigate, useParams } from "react-router-dom";
import helpYou1 from '../images/Flag.mp4'
import helpYou from '../images/Flag.mp4'
import helpYou2 from '../images/WomenOnlineWriting.mp4'
import helpYou3 from '../images/BlogWomenHandFilling.mp4'
import helpYou4 from '../images/Business14.mp4'
import "../styles/DeatiledBlog.css"
import Footer from '../components/Footer'
import HelpYouBlog from '../components/HelpYouBlog'
import { CaretLeft } from "@phosphor-icons/react";
import { CircularProgress } from "@mui/material";

function DeatiledBlog(props) {
  const [ , setDisplay] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [entry, setEntry] = useState(null);
  const shouldSwap = props.swapImageAndDescription;
  const containerStyle = {
    backgroundColor: props.containerColor || '#F4F4F4', 
    color: props.textColor || '#014A91', 
  };
  const containerStyle1 = {
    backgroundColor: props.containerColor2 || '#F4F4F4', 
    color: props.textColor2 || 'rgba(59, 59, 59, 1)', 
  };const handleButtonClick = (onClick) => {
    if (typeof onClick === 'function') {
      onClick();
    } else {
      console.error('Invalid onClick function provided');
    }
  };
  const [data,setData] = useState([])

  
  const getData = async () => {
    setLoading(true);
    try {
      const valRef = collection(txtDB, 'txtData');
      const dataDb = await getDocs(valRef);
      const allData = dataDb.docs.map((val) => ({ ...val.data(), id: val.id }));
      setData(allData);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await getData();
      setLoading(false);
    };

    fetchData();
  }, []);

  // Find the entry with the matching ID
  const detailedEntry = data.find((entry) => entry.id === id);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await getData(id); // Pass the id parameter to getData
      setLoading(false);
    };
  
    fetchData();
  }, [id]);

  return (
    <div>
        <NavBarComponent />
        <div className='HeroBanner'>
    
    {detailedEntry && (
    
    <div  key={detailedEntry.id}>
      <div className='Banner-image '>
        <img className='HeroBannerImage' src={detailedEntry.imgUrl} alt='' />
      </div>
      <div className='hero'>
        <div className='hero-styling'>
          <div className='hero-content'>
            <div className='hero-heading'>
              {detailedEntry.txtVal}
            </div>
           
          </div>
        </div>
        <div className='dotted-lines'>
          <img src={DottedLines} alt='dots' />
        </div>
      </div>
    </div>
  )}
</div>
         {/* {data.map((entry) => (
        <div className='Deatiled_blog_layout' key={entry.id} >
          
          <div className='VisaFamily_Box'>
          {entry.txtDesVal}
                </div>
 
         </div>))} */}
          <div onClick={() => navigate("/BlogAdmin")} className="back_btn">
          <CaretLeft onClick={() => navigate("/BlogAdmin")} /> Back
          </div>
          <div>
      {/* Display other components or UI elements */}
      {loading &&        
      <div style={{display:"flex", width:"100%",alignItems:"center",justifyContent:"center"}}><CircularProgress style={{color:"pink" }} /></div>}

      {/* {detailedEntry && (
        <div className='Deatiled_blog_layout' key={detailedEntry.id}>
          <div className='Blog_Box'>
            {detailedEntry.txtDesVal}
          </div>
        </div>
      )} */}
   {detailedEntry && (
  <div className='Deatiled_blog_layout' key={detailedEntry.id}>
    <div className='Blog_Box'>
      
      {detailedEntry.txtDesVal.split('\n\n').map((paragraph, index) => (
        <p key={index}>{paragraph}</p>
      ))}
    </div>
  </div>
)}
    </div>
        <HelpYouBlog
        containerColor2="rgba(255, 247, 241, 1)"
        containerColor="rgba(255, 247, 241, 1)"
        textColor2="rgba(59, 59, 59, 1)"
        textColor="rgba(176, 56, 61, 1)"
        helpYouTitle='Similar Blogs'
        helpYou1={helpYou}
        helpYou2={helpYou2}
        helpYou3={helpYou3}
      //   helpYou4={helpYou4}
        helpDescContent1='The Top 5 Reasons Why People choose to Migrate To Australia'
        helpDescContent2='The Benefits Of Studying In Australia'
        helpDescContent3='Understanding The Different Types Of Australian Visas'
      //   helpDescContent='Australia is a popular destination for people from all over the world who are seeking a new life abroad. In this article,…'
        helpDescLearn='Read More'
      />
      <Footer />
    </div>
  )
}

export default DeatiledBlog