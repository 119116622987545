import React from 'react'
import "../styles/FamilyPartner.css"
import ConsultationComponent from '../components/ConsultationComponent'
import HowComponent187 from '../components/HowComponent187'

function FamilyPartner(props) {
  return (
    <div className='Family_Layout'>
          <div className='VisaHowHeadBox'>
        <div className='VisaHowHeading'>{props.FamilyHeading}</div>
    </div>
  <div className='Family_Layout_sub_container'>
    <div className='Family_Layout_Divider_box'>
    <div className='VisaHowSubHead'>{props.FamilyPartnerHeading1}</div>
            <div className='VisaHowDesc'>{props.FamilyPartnerDesc1}</div>
            <hr className='Divider' />
            <div className='VisaHowSubHead'>{props.FamilyPartnerHeading2}</div>
            <div className='VisaHowDesc'>{props.FamilyPartnerDesc2}</div>
    </div>
    <div className='Family_Layout_sub_container2'>
    <div className='Family_Layout_sub_container2_box_fields'>
        <div className='Family_Layout_sub_container2_box'>
        <div className='VisaHowSubHead'>  {props.FamilyPartnerHeading3}</div>
            <div className='VisaHowDesc'>{props.FamilyPartnerDesc3}</div>
          
            </div>
        <div className='Family_Layout_sub_container2_box'>
        <div className='VisaHowSubHead'>  {props.FamilyPartnerHeading31}</div>
            <div className='VisaHowDesc'>{props.FamilyPartnerDesc31}</div>
        </div>
    </div>
    
    <div className='Family_Layout_sub_container3'>
        <div className='Family_Layout_sub_container3_Box'>
    <div className='VisaHowSubHead'>{props.FamilyPartnerHeading4}</div>
            <div className='VisaHowDesc'>{props.FamilyPartnerDesc4}</div>
            </div>
            <hr className='DividerVertical' />
            <div className='Family_Layout_sub_container3_Box'>
            <div className='VisaHowSubHead'>{props.FamilyPartnerHeading5}</div>
            <div className='VisaHowDesc'>{props.FamilyPartnerDesc5}</div>
            </div>

    </div>
    </div>
  </div>



    </div>
  )
}

export default FamilyPartner