import React, { useState } from 'react'
import NavBarComponent from '../components/NavBarComponent'
import PermanentEmpBanner from '../components/PermanentEmpBanner'
import DottedLines from '../images/DottedLines.png'
import Family from "../images/Movie.mp4"
import ConsultationComponent from '../components/ConsultationComponent'
import Footer from '../components/Footer'
import Heading from '../components/Heading'
import "../styles/AboutUs187Visa.css"
import HeroBanner from '../components/HeroBanner'
function OnshorePartner() {
    const [activeTab, setActiveTab] = useState('Overview');

    const handleTabClick = tabName => {
      setActiveTab(tabName);
    };
  return (
    <div>
        <NavBarComponent />
        <HeroBanner
        heroHeading='Onshore Partner Visa'
        heroSubHeading='Our registered migration agents can help assess your family visa eligibility in a professional and timely manner. We have assisted hundreds of clients with the preparation and lodgement of successful visa applications.'
        connectButton='Let’s Connect'
        Banner={[Family]}
        DottedLines={DottedLines}
      />
            <div className="TabsContainer">
      <div
        className={`Tab ${activeTab === 'Overview' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('Overview')}
      >
   Overview
      </div>
      <div
        className={`Tab ${activeTab === 'VisaStage' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('VisaStage')}
      >Visa Stage
      </div>
      <div
        className={`Tab ${activeTab === 'Eligibility' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('Eligibility')}
      >
     Eligibility
      </div>
      <div
        className={`Tab ${activeTab === 'ProcessingTimes' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('ProcessingTimes')}
      >
     Processing Times
      </div>
    </div>

    {activeTab === 'Overview' &&
       <div className='Tabs_container_layout'>
         <div className='PartnerVisaHeadComp_Title'>Overview</div>
         <div style={{paddingTop:"24px"}}>
 <div className='AboutUsVisa_box_fields_content'>
  <div className='AboutUsVisa_box_title'>These visas are for the spouse or de facto partner of an Australian citizen, Australian permanent resident or eligible New Zealand citizen</div>
          <div className='AboutUsVisa_box_desc'>
            <li>Heterosexual and same-sex couples may apply for an Australian partner visa.</li>
            <li>Partner visas are granted in two stages.</li>
            <li> The first is a two year provisional visa. After this period, couples are reassessed against the criteria of the permanent visa.</li>
            <li>Depending on whether the applicant is inside or outside Australia at the time of application will determine which visa they are eligible to apply for.</li>
      </div>
        </div>
        </div> 
        </div>
        }
         {activeTab === 'VisaStage' &&
       <div className='Tabs_container_layout'>
   <div className='PartnerVisaHeadComp_Title'>Partner Visas are processed in two stages</div>
   <div className='AboutUsVisa_box_fields'>
        <div className='AboutUsVisa_box_fields_content'>
          <div className='AboutUsVisa_box_title'>PROVISIONAL PARTNER VISA</div>
          <div className='AboutUsVisa_box_desc'>
          The provisional partner and spouse visas (Subclass 820) are granted for an indefinite period (until superseded by the permanent partner/spouse visa) and are conditional that the relationship of the applicants meets the criteria of the permanent visa. </div>
        </div>
        <div className='AboutUsVisa_box_fields_content'>
        <div className='AboutUsVisa_box_title'>PERMANENT PARTNER VISA</div>
          <div className='AboutUsVisa_box_desc'>
<li>After the two year waiting period of the Provisional Partner Visa, the Department of Immigration will assess applicants against the Permanent Partner Visa criteria.</li>
<li> Applicants must be able to prove they are still in a relationship with their partner or spouse.</li>
<li>Once approved, the Permanent De Facto Partner or Spouse Visa is unconditional and the visa holder may live in Australia indefinitely whether or not the relationship continues.</li></div>
        </div>
        </div>
        </div>
        }
         {activeTab === 'Eligibility' &&
       <div className='Tabs_container_layout'>
   <div className='PartnerVisaHeadComp_Title'>Eligibility</div>
   <div style={{paddingTop:"24px"}}>
   <div className='AboutUsVisa_box_fields_content'>
        <div className='AboutUsVisa_box_title'>PROVISIONAL PARTNER VISA</div>
          <div className='AboutUsVisa_box_desc'>
<li>The applicant must be sponsored by an Australian citizen, permanent resident or eligible New Zealand citizen.</li>
<li> The applicant must have been in a relationship with their sponsoring spouse or de facto partner for at least 12 months.</li>
<li>The applicant and sponsor must be at least 18 years of age. </li>
<li>The applicant and sponsor must have proof of being in a genuine relationship</li>
<li>The applicant and sponsor must remain in a relationship for two years before the permanent part of this visa is granted (Unless exempt)</li>
</div>
        </div>
        <div className='AboutUsVisa_box_fields'>
        <div className='AboutUsVisa_box_fields_content'>
          <div className='AboutUsVisa_box_title'>Age</div>
          <div className='AboutUsVisa_box_desc'><li>There is no age limit for this visa</li> </div>
          </div>
        <div className='AboutUsVisa_box_fields_content'>
        <div className='AboutUsVisa_box_title'>Health & Character</div>
          <div className='AboutUsVisa_box_desc'><li>All family members must meet the health and character requirements. All applicants over 16 must provide police clearances.</li></div>
        </div>
        
        </div>
        </div>
        </div>
        }
         {activeTab === 'ProcessingTimes' &&
       <div className='Tabs_container_layout'>
    <div className='PartnerVisaHeadComp_Title'>Processing Times</div>
    <div style={{paddingTop:"24px"}}>
    <div className='AboutUsVisa_box_fields_content'>
          <div className='AboutUsVisa_box_title'>How long will it take to get a decision on your visa?</div>
          <div className='AboutUsVisa_box_desc'>
           <span> 801 – Partner (subclass 801):</span>
          <li>75% of visas are processed within 10 months</li>
          <li>90% of visas are processed within 19 months</li>
          <li>Processing time for subclass 801 Partner (permanent) visa and 100 Partner (permanent) visa is from date of eligibility (2 years after the 820/801 or 309/100 application is lodged) to finalisation.</li> 
          <span> 309 – Partner (Provisional) (subclass 309):</span>
          <li>75% of visas are processed within 21 months</li>
          <li>90% of visas are processed within 28 months</li>
          </div>
          </div>
          </div>
        </div>
        }
          <div className='ConsultationComponent_field'>
            <ConsultationComponent
            title="Are You Eligible?"
            description="Take our online eligibility assessment to find out if you meet the requirements for the 482 Visa            "
            button="Check Eligibility"
            />
             <ConsultationComponent 
            title="Book A Consultation"
            description="Speak to one of our migration agents and simply book a consultation. We'll assist you in understanding the costs involved"
            button="Consult"
            />
             <ConsultationComponent
            title="Get A Quote"
            description="Have a visa in mind? Contact us for a fixed price quote. We'll assist you in understanding the costs involved.            "
            button="Get a quote"
            />
            </div>
            <Footer />

   
    </div>
  )
}

export default OnshorePartner