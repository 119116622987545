import React from 'react'
import "../styles/VisaFinderNotSure.css"
import Heading from './Heading'

function VisaFinderNotSure(props) {
  return (
    <div className='VisaFinderNotSure_layout'>
        <div className='VisaFinderNotSure_layout_content'>
            <div className='VisaFinderNotSure_layout_title'>{props.title}</div>
            <div className='VisaFinderNotSure_layout_desc'>{props.desc}</div>
            {/* <div className='VisaFinderNotSure_layout_btn'>{props.btn}</div> */}
        </div>
        <div className='VisaFinderNotSure_layout_img_container'>
            <img src={props.img} alt="" className='VisaFinderNotSure_layout_img'/>
            </div> 
    </div>
  )
}

export default VisaFinderNotSure