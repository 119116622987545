import React, { useState } from 'react'
import "../styles/footer.css"
import logo from "../images/HopeHd.png"
import { FacebookLogo ,LinkedinLogo} from '@phosphor-icons/react/dist/ssr'
import { InstagramLogo } from '@phosphor-icons/react'
import { XCircle } from "@phosphor-icons/react";
import { useNavigate } from 'react-router-dom';
import ContactModal from './ContactModal'

function Footer() {
  const navigate = useNavigate();
  const handleMenuItemClick = (path) => {
    navigate(path);
  };
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
      setIsModalOpen(true);
  };

  const closeModal = () => {
      setIsModalOpen(false);
  };
  const handleNavigateHome = () => {
    window.scrollTo(0,0);
    navigate("/");
  }
  
  return (
    <div className='footer_container'>
  <div className='footer_container_logo'>
    <img src={logo} alt="" className='footer_logo' onClick={handleNavigateHome} style={{width:"120px"}}/>
    <div>© 2023 Hope Education & Migration</div>
    <div className='Social_links'>
    <a href="https://www.facebook.com/HopeBengaluru/" target="_blank">
  <FacebookLogo color={"rgba(9, 111, 185, 1)"} weight='fill' size={36}/>
</a>

<a href="https://www.linkedin.com/company/hopeeducationnadmigration/" target="_blank">
  <LinkedinLogo color={"rgba(9, 111, 185, 1)"} weight='fill' size={36}/>
</a>

<a href="https://www.instagram.com/hope_education_and_migration?igsh=OHBpc3RkaDluNnAy&utm_source=qr" target="_blank">
  <InstagramLogo color={"rgba(9, 111, 185, 1)"} weight='fill' size={36}/>
</a>

    </div>
    <div>

    </div>
  </div>
  <div className='footer_about_container'> 
  <div className='Footer_contents_field'> 
  <span className='footer-span'>Help </span> 
  <div className="footer_links_bottom" style={{cursor:"pointer",borderRight:"1px solid rgba(32, 32, 32, 1)",paddingRight:"4px"}} onClick={() =>{ handleMenuItemClick('/ContactUs'); window.scrollTo(0,0);}}> Contact us </div>
  <div className="footer_links_bottom" style={{cursor:"pointer" ,borderRight:"1px solid rgba(32, 32, 32, 1)" ,paddingRight:"4px"}} onClick={() =>{ handleMenuItemClick('/faq'); window.scrollTo(0,0);}}> FAQ's </div>
  <div className="footer_links_bottom" style={{cursor:"pointer"}} onClick={() =>{ handleMenuItemClick('/PrivacyPage'); window.scrollTo(0,0);}}>Privacy Policy</div>

  {/* <div>Popular Searches</div> */}
    </div>
  <div className='Footer_contents_field'> 
  <span className='footer-span'>Tools & Resources</span> 
  <div className="footer_links_bottom" style={{cursor:"pointer" ,borderRight:"1px solid rgba(32, 32, 32, 1)",paddingRight:"4px"}} onClick={() =>{ handleMenuItemClick('/blog'); window.scrollTo(0,0);}}>Blogs </div> 
  <div className="footer_links_bottom" style={{cursor:"pointer" ,borderRight:"1px solid rgba(32, 32, 32, 1)" ,paddingRight:"4px"}} onClick={() =>{ handleMenuItemClick('/tools'); window.scrollTo(0,0);}}>Tools </div>
  <div className="footer_links_bottom" style={{cursor:"pointer" ,borderRight:"1px solid rgba(32, 32, 32, 1)" ,paddingRight:"4px"}} onClick={() =>{ handleMenuItemClick('/Services'); window.scrollTo(0,0);}}>Our Services </div>  
  <div className="footer_links_bottom" style={{cursor:"pointer" }} onClick={() =>{ handleMenuItemClick('/VisaFinderLandingScreen'); window.scrollTo(0,0);}}>Visa Finder</div>

  {/* <div>View All Visas</div> */}
  </div>

  </div>
  <div className='footer_about_container'> 
  <div>
    <p className='footer-span'>Connect With Us</p>
    {/* <div className='input-container'>
  <input
    type='text'
    placeholder='Email address'
    className='email-address-field'
  />
  <button type='submit' className='submit-btn-footer'>
    Submit
  </button>
</div> */}
<button type='submit' className='submit-btn-footer_Latest' onClick={openModal}>
Click Here
  </button>


  </div>
  {isModalOpen && <div className="modal-background">
                <div className='modalFinal'>
                  
                    <ContactModal closeModal={closeModal}/>

                </div>
            </div>}

  </div>
    </div>
  )
}

export default Footer