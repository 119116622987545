import React from 'react'
import "../styles/Visa191HeadComp.css"

function Visa191HeadComp(props) {
    const containerStyle = {
        backgroundColor: props.containerColor || '#F4F4F4', 
        color: props.textColor || '#014A91', 
      };
      const containerStyle2 = {
        backgroundColor: props.containerColor2 || '#F4F4F4', 
        color: props.textColor2 || 'rgba(59, 59, 59, 1)', 
      };
  return (
    <div className='Visa191HeadComp_Layout'>
        <div className='Visa191HeadComp_Title' style={containerStyle}>{props.Title}</div>
        <div className='Visa191HeadComp_Desc' style={containerStyle2}>
        {props.Desc1 && <li>{props.Desc1}</li>}
        {props.Desc2 && <li>{props.Desc2}</li>}
        {props.Desc3 && <li>{props.Desc3}</li>}
        {props.Desc4 && <li>{props.Desc4}</li>}
      </div>
    </div>
  )
}

export default Visa191HeadComp