import React from 'react';
import { CaretDown, CaretUp }  from "@phosphor-icons/react"; 

const ToggleableCaret = ({ isOpen, onClick, size }) => {
    return isOpen ? (
        <CaretUp className="expand-icon" size={size} onClick={onClick} />
    ) : (
        <CaretDown className="expand-icon" size={size} onClick={onClick} />
    );
};

export default ToggleableCaret;
