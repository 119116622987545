import React, { useState } from 'react';

const SurveyForm = () => {
  const [ageGroup, setAgeGroup] = useState('');
  const [educationLevel, setEducationLevel] = useState('');
  const [workExperience, setWorkExperience] = useState('');
  const [englishProficiency, setEnglishProficiency] = useState('');
  const [isMarried, setIsMarried] = useState('');
  const [experienceInAustralia, setExperienceInAustralia] = useState('');
  const [studiedInAustralia, setStudiedInAustralia] = useState('');
  const [stemDegree, setStemDegree] = useState('');
  const [regionalEducation, setRegionalEducation] = useState('');
  const [communityLanguageQualification, setCommunityLanguageQualification] = useState('');
  const [selectedOptions, setSelectedOptions] = useState({});
  const [totalPoints, setTotalPoints] = useState(0);

  const surveyData = {
    "Q-1": {
      "title": "Your age group",
      "options": [
        { "label": "18-24", "points": 25 },
        { "label": "25-32", "points": 30 },
        { "label": "33-39", "points": 25 },
        { "label": "40-44", "points": 15 }
      ]
    },
    "Q-2": {
      "title": "Your highest level of education",
      "options": [
        { "label": "PHD", "points": 20 },
        { "label": "Masters", "points": 15 },
        { "label": "Bachelors", "points": 15 },
        { "label": "Diploma after secondary", "points": 10 }
      ]
    },
    "Q-3": {
      "title": "Your total work experience(excluding Australian experience)",
      "options": [
        { "label": "0-2 years", "points": 0 },
        { "label": "3 to 4 years", "points": 5 },
        { "label": "5 to 7 years", "points": 10 },
        { "label": "8 plus", "points": 15 }
      ]
    },
    "Q-4": {
      "title": "Your English proficiency",
      "options": [
        { "label": "Basic Proficiency", "points": 0 },
        { "label": "High Proficiency", "points": 10 },
        { "label": "Very High Proficiency", "points": 20 }
      ]
    },
    "Q-5": {
      "title": "Are you married?",
      "options": [
        { "label": "Yes", "points": 0 },
        { "label": "No", "points": 10 }
      ]
      
    },
    "Q-6": {
      "title": "Understanding your adaptability",
      "subQuestions": {
        "Sub Q-1": {
          "title": "Do you have experience in Australia?",
          "options": [
            { "label": "0 year", "points": 0 },
            { "label": "1 year", "points": 5 },
            { "label": "3 years", "points": 10 },
            { "label": "5 years", "points": 15 },
            { "label": "8 or more years", "points": 20 }
          ]
        },
        "Sub Q-2": {
          "title": "Did you study in Australia(2 academic years)?",
          "options": [
            { "label": "Yes", "points": 5 },
            { "label": "No", "points": 0 }
          ]
        },
        
        "Sub Q-3": {
          "title": "Do you hold a Masters by research or a Doctorate degree from an Australian educational institution in the STEM fields?",
          "options": [
            { "label": "Yes", "points": 10 },
            { "label": "No", "points": 0 }
          ]
        },
        "Sub Q-4": {
          "title": "Your Australian education is from regional Australia?",
          "options": [
            { "label": "Yes", "points": 5 },
            { "label": "No", "points": 0 }
          ]
        },
        "Sub Q-5": {
          "title": "Did you obtain credential community language qualifications in the following languages Punjabi/Telugu/Urdu/Tamil/Malayalam/Hindi/Bangla/Gujarati?",
          "options": [
            { "label": "Yes", "points": 5 },
            { "label": "No", "points": 0 }
          ]
        }
        
      }
    }
  };
   const handleOptionChange = (questionKey, selectedOption) => {
    setSelectedOptions((prevOptions) => ({ ...prevOptions, [questionKey]: selectedOption }));
    calculateTotalPoints();
  };
  const calculateTotalPoints = () => {
    let calculatedTotalPoints = 0;
  
    for (const [questionKey, selectedOption] of Object.entries(selectedOptions)) {
      const question = surveyData[questionKey];
      if (question) {
        const selectedOptionData = question.options.find((option) => option.label === selectedOption);
        if (selectedOptionData) {
          calculatedTotalPoints += selectedOptionData.points;
        } else if (question.subQuestions) {
          // Handle subquestions
          for (const [subQuestionKey, subQuestionData] of Object.entries(selectedOptions[questionKey])) {
            const subQuestion = question.subQuestions[subQuestionKey];
            const subSelectedOption = subQuestionData.selectedOption;
            const subSelectedOptionData = subQuestion.options.find((option) => option.label === subSelectedOption);
            if (subSelectedOptionData) {
              calculatedTotalPoints += subSelectedOptionData.points;
            }
          }
        }
      }
    }
  
    setTotalPoints(calculatedTotalPoints);
  };
  

  const renderQuestion = (questionKey, questionData) => {
    return (
      <div key={questionKey}>
        <h3>{questionData.title}</h3>
        <label>
          {questionData.title}:
          <select value={questionData.selectedOption} onChange={(e) => handleOptionChange(questionKey, e.target.value)}>
            <option value="">Select</option>
            {questionData.options.map((option) => (
              <option key={option.label} value={option.label}>
                {option.label}
              </option>
            ))}
          </select>
        </label>
        {questionData.subQuestions && (
          <div>
            {Object.entries(questionData.subQuestions).map(([subQuestionKey, subQuestionData]) => (
              renderQuestion(subQuestionKey, subQuestionData)
            ))}
          </div>
        )}
      </div>
    );
  };

  return (
    <div>
      {Object.entries(surveyData).map(([questionKey, questionData]) => (
        <div key={questionKey}>
          <h3>{questionData.title}</h3>
          <label>
            {questionData.title}:
            <select value={questionData.selectedOption} onChange={(e) => handleOptionChange(questionKey, e.target.value)}>
              <option value="">Select</option>
              {questionData.options && questionData.options.map((option) => (
                <option key={option.label} value={option.label}>
                  {option.label}
                </option>
              ))}
            </select>
          </label>
          {questionData.subQuestions && (
            <div>
              {Object.entries(questionData.subQuestions).map(([subQuestionKey, subQuestionData]) => (
                <div key={subQuestionKey}>
                  <h4>{subQuestionData.title}</h4>
                  <label>
                    {subQuestionData.title}:
                    <select
                      value={subQuestionData.selectedOption || ''}
                      onChange={(e) => handleOptionChange(subQuestionKey, e.target.value)}
                    >
                      <option value="">Select</option>
                      {subQuestionData.options && subQuestionData.options.map((option) => (
                        <option key={option.label} value={option.label}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </label>
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
      
    </div>
  );
  
                      }
  export default SurveyForm