import React, { useState } from 'react';
import '../styles/empoloyerSponsered.css';
import EmployerSponseredOverview from '../components/EmployerSponseredOverview';
import Footer from '../components/Footer';
import NavBarComponent from '../components/NavBarComponent';
import Employer482Banner from '../images/Employer482Banner.mp4';
import LabourAgreement from './LabourAgreement';
import DottedLines from '../images/DottedLines.png'
import ForEmployers from '../components/ForEmployers';
import IncludingFamily from '../components/IncludingFamily';
import HeroBanner from '../components/HeroBanner';
import ShortTermBody from '../components/ShortTermBody';
import MediumTermBody from '../components/MediumTermBody';


const EmployerSponsered = () => {
    const [activeTab, setActiveTab] = useState('Overview');

    const handleTabClick = tabName => {
      setActiveTab(tabName);
    };
  return (
    <div>
          <NavBarComponent />
          <HeroBanner
        heroHeading='Skilled Shortage Visa (Tss Visa) Subclass 482'
        heroSubHeading='The TSS Visa (Temporary Skill Shortage) Subclass 482 has replaced the Subclass 457 Visa. The TSS visa program assists Australian businesses who experience genuine skill shortages and cannot find Australian workers to fill vacant positions.'
        connectButton='Let’s Connect'
        Banner={[Employer482Banner]}
        DottedLines={DottedLines}
      />
     <div className="TabsContainer">
      <div
        className={`Tab ${activeTab === 'Overview' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('Overview')}
      >
        Overview
      </div>
      <div
        className={`Tab ${activeTab === 'ShortTermStream' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('ShortTermStream')}
      >
        Short-term Stream
      </div>
      <div
        className={`Tab ${activeTab === 'MediumTermStream' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('MediumTermStream')}
      >
       Medium-term Stream
      </div>
      <div
        className={`Tab ${activeTab === 'LabourAgreementStream' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('LabourAgreementStream')}
      >
       Labour Agreement
      </div>
      <div
        className={`Tab ${activeTab === 'IncludingFamily' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('IncludingFamily')}
      >
        Including Family
      </div>
      
      <div
        className={`Tab ${activeTab === 'ForEmployers' ? 'isActive' : ''}`}
        onClick={() => handleTabClick('ForEmployers')}
      >
       For Employers
      </div>
    </div>

      {activeTab === 'Overview' &&
       <div className='Tabs_container_layout'>
   
        <EmployerSponseredOverview />
     
       
        </div>
        }
      {activeTab === 'ShortTermStream' && 
      <div className='Tabs_container_layout'>
        <ShortTermBody
                shortHeading='Short Term Stream'
                shortTermDesc='The short-term TSS (Subclass 482) Visa stream allows applicants to stay for up to 2 years or up to 4 if an International Trade Obligation (ITO) applies.'
                shortGeneralHead1='General'
                shortGeneralDesc1='Applicants must have a minimum of 2 years of work experience in the relevant field'
                shortGeneralDesc2='Occupation must be listed on the Short-Term (STSOL)'
                skills='Skilled Occupations List'
                shortGeneralDesc3='Applicants must meet genuine temporary entrant requirement'
                shortGeneralDesc4='This visa does not provide a transitional pathway to Permanent Residency'
                shortGeneralDesc5='Immediate family members may be included, with the support of the employer'
                shortGeneralHead2='English'
                shortGeneralDesc6='The primary applicant for the TSS Visa must meet the minimum English requirement.'
                shortGeneralDesc7='You will need to take an English test unless one of the following applies:'
                shortGeneralDesc8='you are a passport holder from Canada, Ireland, UK, USA or NZ'
                shortGeneralDesc9='you have completed at least 5 years of full-time study in at least a secondary level institution and where the medium of instruction was English.'
                shortGeneralDesc10='If you need to take an English test, you can take'
                here='Here'
                shortGeneralHead3='Extending Your Stay'
                shortGeneralDesc11='If your Short-term 482 (TSS) visa is expiring soon and you would like to continue working for your employer, you may be eligible to renew your visa for another 2 years.'
                // shortGeneralDesc111='If your Short-term 482 (TSS) visa is expiring soon and you would like to continue working for your employer, you may be eligible to renew your visa for another 2 years.'
                // shortGeneralDesc112='If your Short-term 482 (TSS) visa is expiring soon and you would like to continue working for your employer, you may be eligible to renew your visa for another 2 years.'
                shortGeneralDesc12='If you are already on your second 482 Temporary Skill Shortage Visa, you cannot renew. Generally, if your occupation is listed on the STSOL, you will have limited permanent visa options, however, you may find a pathway to permanent residency through you may find a pathway to permanent residency through '
                subclass='subclass'
                subClassHoverContent1='190 visa (State Nominated)'
                subClassHoverContent2='491 visa (Regional Sponsored)'
                subClassHoverContent3='494 visa (Employer-Sponsored Regional)'
                stayPeriodHead='Stay Period'
                stayPeriodContent='Short-Term TSS Visa holder can stay in Australia for: '
                stayPeriodContent1='2 years in most cases'
                stayPeriodContent2='4 years if an ITO applies but the maximum length will depend on the actual ITO relevant to your circumstances'
                stayPeriodContent3='5 years if you are a Hong Kong passport holder'
            />
        </div>
        }
      {activeTab === 'MediumTermStream' && 
      <div className='Tabs_container_layout'>
         <MediumTermBody
                shortHeading='Medium Term Stream'
                shortTermDesc='The medium-term TSS (Subclass 482) Visa stream allows applicants to stay for up to 4 years, with the option to apply for another TSS Visa or Permanent Residency.'
                shortGeneralHead1='General'
                shortGeneralDesc1='Applicants must have a minimum of 2 years work experience in the relevant field'
                shortGeneralDesc2='Occupation must be on Medium Long-Term Strategic Skills List (MLTSSL) (For non-regional applications)'
                shortGeneralDesc3='Applicants don’t need to meet genuine temporary entrant requirement'
                shortGeneralDesc4='Applicants have a transitional pathway to Permanent Residency after 3 years'
                shortGeneralDesc5='Immediate family members may be included, with the support of the employer'
                shortGeneralHead2='English'
                shortGeneralDesc6='The primary applicant for the TSS Visa must meet the minimum English requirement.'
                shortGeneralDesc7='You will need to take an English test unless one of the following applies:'
                shortGeneralDesc8='you are a passport holder from Canada, Ireland, UK, USA or NZ'
                shortGeneralDesc9='you have completed at least 5 years of full-time study in at least a secondary level institution and where the medium of instruction was English.'
                shortGeneralDesc10='If you need to take an English test, you can take'
                here='Here'
                shortGeneralHead3='Extending Your Stay'
                shortGeneralDesc11='If your Medium-term 482 (TSS) visa is expiring soon and you would like to continue working for your employer:'
                shortGeneralDesc111='your employer will need to submit a new nomination'
                shortGeneralDesc112='you can apply for a new 482 TSS visa'
                shortGeneralDesc12='You may also be eligible to apply for one of the below permanent visas if your occupation is listed on the Medium Long-Term Strategic Skills List (MLTSSL)'
                subClassHoverContent1='189 Visa'
                subClassHoverContent2='190 Visa'
                subClassHoverContent3='187 Visa (RSMS Visa)'
                subClassHoverContent4='186 Visa (ENS Visa)'
                stayPeriodHead='Stay Period'
                stayPeriodContent4='Medium-term TSS Visa holder can stay in Australia for up to 4 years'
            />
        </div>}
      {activeTab === 'LabourAgreementStream' && 
      <div className='Tabs_container_layout'>
        <LabourAgreement />
      </div>}
      {activeTab === 'IncludingFamily' && 
      <div className='Tabs_container_layout'>
       <IncludingFamily content1="Subclass 482 (TSS) Visa applicants can add family members to their application before lodgement or after approval. To be eligible to include family members, applicants must meet the below criteria:your employer agrees to extend sponsorship
your family members meet the eligibility criteria" 
content2="TSS visa holders and applicants may be able to include the below family members to their application as secondary applicants partner
dependent child
partner’s dependent child"
content3="Family members must meet the health and character requirements.
TSS Visa secondary applicants can enter and remain in Australia for the duration of the main applicant’s TSS Visa (Subclass 482).
Secondary applicants can live, work and study while residing in Australia.
Family members can be included at the time of 482 visa application or after the visa has been approved.
More about adding family members to your TSS Subclass 482 visa."



/>
        
     </div>}
     {activeTab === 'ForEmployers' && <div className='Tabs_container_layout'>
      <ForEmployers />
      
      </div>}
      <Footer />
    </div>
  );
};

export default EmployerSponsered;
