import React from 'react'
import "../styles/Visa187Overview.css"
import ConsultationComponent from './ConsultationComponent'
import Visa187Img from "../images/Visa187Img.png"
import HowComponent187 from './HowComponent187'
import Heading from './Heading'
import { useNavigate } from 'react-router-dom';

function Visa187Overview() {
        const navigate = useNavigate();
        const handleMenuItemClick = (path) => {
          navigate(path);
        };
  return (
    <div>
        <Heading heading="The 187 Visa (RSMS) previously had two streams:"/>
        <div className='VisaStream_container_fields'>
        <div className='VisaStream_container'>
        <li>The 187 visa is a permanent visa for skilled foreign workers who have a nominating employer in a regional or low population growth area of Australia</li>
<li>In November 2019, the Direct Entry Stream of the 187 Visa was replaced by the <u onClick={() =>{ handleMenuItemClick('/SkilledVisa'); window.scrollTo(0,0);}} style={{ color: '#B0383D', cursor: 'pointer',fontSize:"24px" }}>  Skilled Employer Sponsored Regional (Provisional) – Subclass 494 Visa</u></li>
<li>APPLICANTS CAN STILL APPLY FOR THE 187 VISA THROUGH THE TRANSITION STREAM </li> 
        </div>
        <div className='VisaStream_container'>
       <div className='VisaStream_title'>DIRECT ENTRY STREAM:</div>
       <div className='VisaStream_desc'>This Stream is no longer accepting application (From 15th November 2019)</div>
        </div>
        <div className='VisaStream_container'>
       <div className='VisaStream_title'>TRANSITIONAL STREAM:</div>
       <div className='VisaStream_desc_light'><li>For 457 or 482 TSS Visa holders who have worked for their nominating employer for a minimum of two or three years.</li>
<li>Applicants must agree to remain employed with their nominating employer in regional Australia for a minimum of 2 years after their 187 visa approval.</li>
<li>This permanent Regional Sponsored Migration Stream (RSMS) Subclass 187 Visa allows skilled overseas workers to live and work in a regional area of Australia.</li></div>
        </div>
        </div>
        <Heading heading="187 Visa holders can:" backgroundColor="#FFF7F1" color="#B0383D"/>
        <div className='VisaStream_container_fields'>
        <div className='VisaStream_container_holders'>
        <li>The 187 visa is a permanent visa for skilled foreign workers who have a nominating employer in a regional or low population growth area of Australia</li>
<li>In November 2019, the Direct Entry Stream of the 187 Visa was replaced by the Skilled Employer Sponsored Regional (Provisional) – Subclass 494 Visa</li>
<li>APPLICANTS CAN STILL APPLY FOR THE 187 VISA THROUGH THE TRANSITION STREAM </li> 
        </div>
        <div className='VisaStream_container_holders'>
       <div className='VisaStream_title'>DIRECT ENTRY STREAM:</div>
       <div className='VisaStream_desc'>This Stream is no longer accepting application (From 15th November 2019)</div>
        </div>
        <div className='VisaStream_container_holders'>
       <div className='VisaStream_title'>TRANSITIONAL STREAM:</div>
       <div className='VisaStream_desc_light'><li>For 457 or 482 TSS Visa holders who have worked for their nominating employer for a minimum of two or three years.</li>
<li>Applicants must agree to remain employed with their nominating employer in regional Australia for a minimum of 2 years after their 187 visa approval.</li>
<li>This permanent Regional Sponsored Migration Stream (RSMS) Subclass 187 Visa allows skilled overseas workers to live and work in a regional area of Australia.</li></div>
        </div>
        </div>
          <HowComponent187
                EmpHowHeading='How it works'

                EmpHowImage={Visa187Img}

                EmpHowSubHead1='Step 1'

                EmpHowSubHead2='Step 2'

                EmpHowSubHead3='Step 3'

                EmpHowSubHead4='Step 4'

                EmpHowSubHead5='Step 5'

                EmpHowDesc1='You have an Australian employer who is willing to sponsor you.'

                EmpHowDesc2='Our migration agents liaise with you and your employer to collect documents.'

                EmpHowDesc3='We lodge your nomination and visa application with the Department.'

                EmpHowDesc4='We wait until a decision is made on your nomination and visa application.'

                EmpHowDesc5='You are expected to work for your employer for 2 years after approval.'

            />
            <div className='ConsultationComponent_field'>
            <ConsultationComponent 
            title="Are You Eligible?"
            description="Take our online eligibility assessment to find out if you meet the requirements for the 482 Visa            "
            button="Check Eligibility"
            />
             <ConsultationComponent 
            title="Book A Consultation"
            description="Speak to one of our migration agents and simply book a consultation. We'll assist you in understanding the costs involved"
            button="Consult"
            />
             <ConsultationComponent
            title="Get A Quote"
            description="Have a visa in mind? Contact us for a fixed price quote. We'll assist you in understanding the costs involved.            "
            button="Get a quote"
            />
            </div>
    </div>
  )
}

export default Visa187Overview