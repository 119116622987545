import React, { useState } from 'react'
import '../styles/PermanentEmpHow.css'
import { XCircle } from "@phosphor-icons/react";
import Option1Overview from './Option1Overview';
import ModalComp from './ModalComp';

const PermanentEmpHow = (props) => {
    const [activeTab, setActiveTab] = useState('Vacancies');

    const handleTabClick = tabName => {
      setActiveTab(tabName);
    };
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
      setIsModalOpen(true);
    };
  
    const closeModal = () => {
      setIsModalOpen(false);
    };
    const handleButtonClick = (onClick) => {
        if (typeof onClick === 'function') {
          onClick();
          openModal(); // Open modal on button click
        } else {
          console.error('Invalid onClick function provided');
        }
      };
    return (
        <>
            <div className='EmpHowLayout'>
                <div className='EmpHowHeadBox'>
                    <div className='EmpHowHeading'>{props.EmpHowHeading}</div>
                </div>
               
                <div className='EmpHowImage'>
  <video className='EmpHow-Image' autoPlay muted loop playsInline >
    <source src={props.EmpHowImage} type='video/mp4' />

  </video>
</div>
                <div className='EmpHowSubLayout'>
                    <div className='howSubLayout'>
                        <div className='EmpHowSubHead'>{props.EmpHowSubHead1}</div>
                        <div className='EmpHowDesc'>{props.EmpHowDesc1} <u style={{color:'#B0383D', cursor:'pointer'}}   onClick={() => handleButtonClick(props.onClick1)}>{props.learnMoreDesc}</u></div>
                    </div>
                    <div className='howSubLayout'>
                        <div className='EmpHowSubHead'>{props.EmpHowSubHead2}</div>
                        <div className='EmpHowDesc'>{props.EmpHowDesc2} <u style={{color:'#B0383D', cursor:'pointer'}}   onClick={() => handleButtonClick(props.onClick2)}>{props.learnMoreDesc}</u></div>
                    </div>
                    <div className='howSubLayout'>
                        <div className='EmpHowSubHead'>{props.EmpHowSubHead3}</div>
                        <div className='EmpHowDesc'>{props.EmpHowDesc3} <u style={{color:'#B0383D', cursor:'pointer'}}   onClick={() => handleButtonClick(props.onClick3)}>{props.learnMoreDesc}</u></div>
                    </div>
                    <div className='howSubLayout'>
                        <div className='EmpHowSubHead'>{props.EmpHowSubHead4}</div>
                        <div className='EmpHowDesc'>{props.EmpHowDesc4} <u style={{color:'#B0383D', cursor:'pointer'}}  onClick={() => handleButtonClick(props.onClick4)}>{props.learnMoreDesc}</u></div>
                    </div>
                </div>
            </div>
           
    
        </>
    )
}

export default PermanentEmpHow