import React from 'react'
import { useNavigate } from 'react-router-dom';
import '../styles/HelpYou.css'

const HelpYou = (props) => {
    const navigate = useNavigate();
    const handleMenuItemClick = (path) => {
      navigate(path);
    };
    const containerStyle = {
        backgroundColor: props.containerColor || '#F4F4F4', 
        color: props.textColor || '#014A91', 
      };
      const containerStyle2 = {
        backgroundColor: props.containerColor2 || '#F4F4F4', 
        color: props.textColor2 || 'rgba(59, 59, 59, 1)', 
      };

    return (
        <>
            <div className='helpYouMainLayout'>
                <div className='helpYouLayout'>
                    <div className='helpYouHeadingBox' style={containerStyle}>
                        <div className='helpYouTitle' >{props.helpYouTitle}</div>
                    </div>
                    <div className='helpYouDesc' style={containerStyle2}>
                        <div className='helpOuterBox'>
                            <div className='helpSliderBox'>
                                <div className='helpDescImage' >
                                    {/* <img className='helpYou-Image' src={props.helpYou} alt='' /> */}
                                    <video className='helpYou-Image' autoPlay muted loop playsInline>
                            <source src={props.helpYou1} type='video/mp4' />
                        </video>
                                </div>
                                <div className='helpYou-Desc'>
                                    <div className='helpDescHeading'>{props.helpDescHeading1}</div>
                                    <div className='helpDescContent'>{props.helpDescContent1}</div>
                                    <div className='helpDescLearn' onClick={() =>{ handleMenuItemClick('/VisaFinderLandingScreen'); window.scrollTo(0,0);}}>{props.helpDescLearn}</div>
                                </div>
                            </div>
                            <div className='helpSliderBox'>
                                <div className='helpDescImage'>
                                <video className='helpYou-Image' autoPlay muted loop playsInline>
                            <source src={props.helpYou2} type='video/mp4' />
                        </video>
                                </div>
                                <div className='helpYou-Desc'>
                                    <div className='helpDescHeading'>{props.helpDescHeading2}</div>
                                    <div className='helpDescContent'>{props.helpDescContent2}</div>
                                    <div className='helpDescLearn' onClick={() =>{ handleMenuItemClick('/FamilyVisa'); window.scrollTo(0,0);}}>{props.helpDescLearn}</div>
                                </div>
                            </div>
                            <div className='helpSliderBox'>
                                <div className='helpDescImage'>
                                <video className='helpYou-Image' autoPlay muted loop playsInline>
                            <source src={props.helpYou3} type='video/mp4' />
                        </video>
                                </div>
                                <div className='helpYou-Desc'>
                                    <div className='helpDescHeading'>{props.helpDescHeading3}</div>
                                    <div className='helpDescContent'>{props.helpDescContent3}</div>
                                    <div className='helpDescLearn' onClick={() =>{ handleMenuItemClick('/InternationalRecruitment'); window.scrollTo(0,0);}}>{props.helpDescLearn}</div>
                                </div>
                            </div>
                            <div className='helpSliderBox'>
                                <div className='helpDescImage'>
                                <video className='helpYou-Image' autoPlay muted loop playsInline>
                            <source src={props.helpYou4} type='video/mp4' />
                        </video>
                                </div>
                                <div className='helpYou-Desc'>
                                    <div className='helpDescHeading'>{props.helpDescHeading4}</div>
                                    <div className='helpDescContent'>{props.helpDescContent4}</div>
                                    <div className='helpDescLearn' onClick={() =>{ handleMenuItemClick('/SkilledVisa'); window.scrollTo(0,0);}}>{props.helpDescLearn}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default HelpYou