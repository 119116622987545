import React from 'react'
import BlogBanner from "../images/WomenOnlineWriting.mp4"
import DottedLines from '../images/DottedLines.png'
import NavBarComponent from '../components/NavBarComponent'
import HeroBanner from '../components/HeroBanner'
import HelpYou from '../components/HelpYou'
import helpYou from '../images/Flag.mp4'
import "../styles/DeatiledBlog.css"
import Footer from '../components/Footer'
import HelpYouBlog from '../components/HelpYouBlog'

function DeatiledBlog2() {
  return (
    <div>
        <NavBarComponent />
        <HeroBanner
        heroHeading='The Benefits Of Studying In Australia'
        heroSubHeading=''
        connectButton='Let’s Connect'
        Banner={[BlogBanner]}
        DottedLines={DottedLines}
        />
        <div className='Deatiled_blog_layout'>
          <div className='VisaFamily_Box'>
          Australia is a popular destination for people from all over the world who are seeking a new life abroad. In this article, we’ll explore the top 5 reasons why people choose to migrate to Australia.         </div>
         <div className='Deatiled_Blog_double_fields'>
         <div className='Deatiled_blog_fields'>
            <div className='Deatiled_blog_title'>1. High Quality of Life</div>
            <div className='Deatiled_blog_desc'>
            Australia offers a high quality of life with access to excellent healthcare, education, and public services. The country also boasts a strong economy and low unemployment rates, making it an attractive destination for those seeking better job opportunities and financial stability.                </div>
         </div>
         <div className='Deatiled_blog_fields'>
            <div className='Deatiled_blog_title'>2.Cultural Diversity</div>
            <div className='Deatiled_blog_desc'>Australia is a culturally diverse country that welcomes people from all over the world. This diversity is reflected in the country’s food, music, art, and festivals, creating a rich and vibrant culture that is celebrated across the nation.

</div>
         </div>
         </div>
         <div className='Deatiled_Blog_double_fields'>
         <div className='Deatiled_blog_fields'>
            <div className='Deatiled_blog_title'>3.Beautiful Scenery</div>
            <div className='Deatiled_blog_desc'>Australia is known for its stunning natural landscapes, from the Great Barrier Reef to the Outback to the beaches of Bondi. Many migrants are attracted to Australia’s natural beauty and seek to enjoy the country’s many outdoor activities, such as hiking, surfing, and camping.</div>
         </div>
         <div className='Deatiled_blog_fields'>
            <div className='Deatiled_blog_title'>4.Excellent Education System</div>
            <div className='Deatiled_blog_desc'>Australia has a world-class education system with top-ranked universities and institutions. Many migrants are attracted to Australia for the opportunities it provides for education and the chance to gain internationally recognized qualifications.
</div>
         </div>
         </div>
         </div>
        <HelpYouBlog
        containerColor2="rgba(255, 247, 241, 1)"
        containerColor="rgba(255, 247, 241, 1)"
        textColor2="rgba(59, 59, 59, 1)"
        textColor="rgba(176, 56, 61, 1)"
        helpYouTitle='Similar Blogs'
        helpYou={helpYou}
        helpDescContent1='The Top 5 Reasons Why People choose to Migrate To Australia'
        helpDescContent2='The Benefits Of Studying In Australia'
        helpDescContent3='Understanding The Different Types Of Australian Visas'
        
        // helpDescContent='Australia is a popular destination for people from all over the world who are seeking a new life abroad. In this article,…'
        helpDescLearn='Read More'
      />
      <Footer />
    </div>
  )
}

export default DeatiledBlog2