import React, { useState } from 'react'
import "../styles/ConsultComp2.css"
import ContactModal from './ContactModal';

function ConsultComp3(props) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
      setIsModalOpen(true);
  };

  const closeModal = () => {
      setIsModalOpen(false);
  };
  return (
    <div className='Consult_Comp_Layout_double'>
        <div className='Consult_Comp_container1'>
            <div className='Consult_Comp_title'>{props.title}</div>
            <div className='Consult_Comp_desc'>{props.desc}</div>
            <div className='Consult_Comp_btn' onClick={openModal}>{props.btn}</div>
        </div>
        <div className='Consult_Comp_container1'>
            <div className='Consult_Comp_title'>{props.title2}</div>
            <div className='Consult_Comp_desc'>{props.desc2}</div>
            <div className='Consult_Comp_btn' onClick={openModal}>{props.btn2}</div>
        </div>   
        {isModalOpen && <div className="modal-background">
                <div className='modalFinal'>
                   
                    <ContactModal closeModal={closeModal}/>

                </div>
            </div>}   
    </div>
  )
}

export default ConsultComp3