import React from 'react'
import Navbar from '../components/Navbar'
import PermanentEmpBanner from '../components/PermanentEmpBanner'
import DottedLines from '../images/DottedLines.png'
import Passport from '../images/passport.png'
import Footer from '../components/Footer'
import howitworks from '../images/howitworks.png'
import Visa186How from '../components/Visa186How'
import Visa186TransComp from '../components/Visa186TransComp'

const Visa186Transition = () => {
  return (
    <>
      <Navbar />
      <PermanentEmpBanner
        heroHeading='Employer Nomination Scheme ENS (Subclass 186 Visa)'
        connectButton='Let’s Connect'
        Banner={Passport}
        DottedLines={DottedLines}
      />
      <Visa186TransComp
        whyUsHeadingMain='Meet the following requirements:'
        whyUsHeading5='Age'
        whyUsDesc5='You must be under 45 years of age when you apply for this visa.'
        whyUsDesc51='You may be older than 45 years if you are;'
        whyUsDesc52='you held (or had applied for) a subclass 457 visa on 18 April 2017. In this case, you can apply up to 50 years of age'
        whyUsDesc53='you are nominated as an academic by an Australian University'
        whyUsDesc54='you are nominated as a scientist, researcher or technical specialist by a Scientific Government Agency'
        whyUsDesc55='you are a medical practitioner who has worked in your position for at least 3 years as a 457 or 482 visa holder, and 2 of those years were in a regional area.'
        whyUsDesc56='you have been working for your employer for 3 years on a 457 or 482 visa and you were earning at least the Fair Work High Income Threshold for each year of that period'
        whyUsHeading1='Current Visa'
        whyUsHeading2='Health & Character'
        whyUsDesc1='You must hold one of the below visas to apply for the 186 Visa through the Transition Stream:'
        whyUsDesc11='457 Visa'
        whyUsDesc111='482 Visa'
        whyUsDesc2='Meet the health and character requirements (Unless you are exempt). This requires police and medical checks.'
        whyUsHeading3='English'
        whyUsHeading4='Experience'
        whyUsDesc3='The primary applicant for the Employer Nomination Scheme visa must have Competent English.'
        whyUsDesc31='You will need to take an English test unless one of the following applies: You are a passport holder from Canada, Ireland, UK, USA or NZ'
        whyUsDesc32='If you need to take an English test, you can take one of the tests from here:'
        whyUsDesc42='You must have been working full-time for your employer on your subclass 457 or subclass 482 visa for at least 3 years. Unless you held (or had applied for) a subclass 457 visa on 18 April 2017, then you only need to have been working for your employer for at least 2 years.'
        visaTransacHead1='COVID Update'
        visaTransacHead2='Nomination'
        visaTransacDesc1='If you were temporarily stood down, had to take unpaid leave or had your hours reduced due to COVID-19, this time can still be counted towards the time worked for your employer.'
        visaTransacDesc2='You must be nominated by an Australian employer and apply for the 186 visa within 6 months of the nomination being approved (If the visa and nomination applications were not lodged at the same time) '
        visaTransacDesc3='The visa will not be granted if the nomination is withdrawn. The Department does not link new nominations to existing visa applications.'
      />
      <Visa186How
        EmpHowHeading='How it works'
        EmpHowImage={howitworks}
        EmpHowSubHead1='Step 1'
        EmpHowSubHead2='Step 2'
        EmpHowSubHead3='Step 3'
        EmpHowSubHead4='Step 4'
        EmpHowSubHead5='Step 5'
        EmpHowDesc1='You have an Australian employer who is willing to sponsor you.'
        EmpHowDesc2='Our migration agents liaise with you and your employer to collect documents.'
        EmpHowDesc3='We lodge your nomination and visa application with the Department.'
        EmpHowDesc4='We wait until a decision is made on your nomination and visa application.'
        EmpHowDesc5='You are expected to work for your employer for 2 years after approval.'
      />
      <Footer />
    </>
  )
}

export default Visa186Transition